import React, { useState, useEffect, useContext } from "react";
import { TouchableOpacity } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { MainScreen, Question, Box, Text, Alert } from "../../../components";
import { updateUserSubmission } from "../../../graphql";

import { interpolate, logEvent } from "../../../utils";
import { optionsBack } from "../../../headerUtils";
import {
  alert,
  currentUserIdAtom,
  useAtom,
  isWebAtom,
  otherUserIdAtom,
  getUserSubmissionAtom,
  userSubmissionsAtom,
  currentRoleTypeAtom,
} from "../../../atoms";
import { format } from "date-fns";
import { useQueryClient } from "react-query";

const Button = ({ title, suffix, prefix, onPress }) => (
  <TouchableOpacity
    style={{
      flex: 1,
      alignItems: "center",
      padding: 20,
      margin: 10,
      borderWidth: 1,
      borderColor: "#004E7A",
      borderRadius: 5,
    }}
    onPress={onPress}
  >
    {suffix}
    <Text fontSize={18} fontWeight={"500"} color={"#004E7A"} paddingRight={5}>
      {title}
    </Text>
    {prefix}
  </TouchableOpacity>
);

const AnswerContext = React.createContext({
  answers: {},
  setAnswers: () => {},
});

const Step = ({ navigation, route }) => {
  const {
    page,
    version,
    initialQuestionNumber,
    submission,
    readOnly,
    currentIndex,
    pages,
    consumer,
    submittable,
    onSubmit: onOuterSubmit,
  } = route.params;

  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const queryClient = useQueryClient();

  const { answers, setAnswers } = useContext(AnswerContext);

  const setValue = (id, value) => {
    setAnswers((answers) => ({ ...answers, [id]: value }));
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  const onSubmit = async () => {
    if (page.buttonRight === "Finalise" || page.buttonRight === "Finalize") {
      onOuterSubmit();
      if (submittable) {
        logEvent("form_submitted", {
          id: submission.id,
        });
        const form = JSON.parse(submission.staticAdvice.form);
        const results =
          form?.results.map(({ label, value }) => ({
            label,
            value: interpolate(value, answers),
          })) || [];
        await updateUserSubmission({
          input: {
            id: submission.id,
            version: version,
            answers: JSON.stringify(answers),
            results: JSON.stringify(results),
          },
        });
        getUserSubmissionAtom.update((v) => ({
          ...v,
          version: version,
          answers: JSON.stringify(answers),
          updatedBy: currentUserIdAtom.getValue(),
          updatedAt: new Date().toISOString(),
        }));
        userSubmissionsAtom.update((v) =>
          v.map((item) => {
            if (item.id === submission.id) {
              return {
                ...item,
                version: version,
                answers: JSON.stringify(answers),
                updatedBy: currentUserIdAtom.getValue(),
                updatedAt: new Date().toISOString(),
              };
            }
            return item;
          })
        );
        if (consumer) {
          queryClient.invalidateQueries(["me", "activity"]);
          queryClient.invalidateQueries([null, "submissions"]);
        }
      }
    }
    if (pages[currentIndex + 1]) {
      page.questions
        .filter((question) => question.calculated)
        .forEach((question) => {
          const result = interpolate(question.calculated, {
            ...answers,
            completed: readOnly,
          });
          setValue(question.id, result);
        });
      const hasUnAnsweredQuestion = page.questions.find((question) => {
        const qans = answers[question.id];
        return (
          question.required &&
          (typeof qans === "undefined" ||
            qans == null ||
            (question.multi && Array.isArray(qans) && qans.length === 0))
        );
      });
      if (!hasUnAnsweredQuestion) {
        for (let i = currentIndex + 1; i < pages.length; i++) {
          const nextPage = pages[i];
          if (nextPage.show) {
            const result = interpolate(nextPage.show, {
              ...answers,
              completed: readOnly,
            });
            if (result === "false") {
              continue;
            }
          }
          navigation.navigate(nextPage.name);
          break;
        }
      } else {
        alert("", "A mandatory field is blank; please complete it.");
      }
    } else {
      navigation.popToTop();
      navigation.goBack();
    }
  };
  if (!initialized) {
    return null;
  }
  let qIndex = -1;
  const hasNext = pages[currentIndex + 1]?.name;

  return (
    <MainScreen
      backgroundColor="white"
      style={{ flex: 1 }}
      scrollEnabled={scrollEnabled}
    >
      <Box
        flex={1}
        backgroundColor="white"
        padding={20}
        justifyContent="center"
      >
        {page.questions.map((question, index) => {
          if (question.show) {
            const result = interpolate(question.show, {
              ...answers,
              completed: readOnly,
            });
            if (result === "false") {
              return;
            }
          }
          if (question.type !== "text") {
            qIndex += 1;
          }
          return (
            <Question
              key={page.name + index + question.id}
              no={initialQuestionNumber + qIndex}
              question={question}
              readOnly={readOnly}
              value={answers[question.id]}
              setValue={setValue}
              setOuterScrollEnabled={setScrollEnabled}
            />
          );
        })}
      </Box>
      <Box justifyContent="flex-end">
        <Box flexDirection="row">
          {hasNext ? (
            <Button title="Back" onPress={() => navigation.goBack()} />
          ) : null}
          <Button
            title={
              page.buttonRight ? page.buttonRight : hasNext ? "Next" : "Done"
            }
            onPress={onSubmit}
          />
        </Box>
      </Box>
    </MainScreen>
  );
};

export const FormOptions = (web) =>
  optionsBack(web, ({ route: { params } }) => params.title);

export const newInitializeAnswers = (questions, setAnswers) => {
  questions.forEach((question) => {
    if (question.id) {
      const v =
        question.type === "input" && question.inputType === "date"
          ? format(new Date(), "dd/MM/yyyy")
          : null;
      setAnswers((answers) => ({ ...answers, [question.id]: v }));
    }
    if (question.type === "section") {
      newInitializeAnswers(question.questions);
    }
  });
};

const Stack = createStackNavigator();
function OGFormNavigator({
  route,
  navigation,
  setConfirmGoBack,
  submission,
  pages: _pages,
  version,
  id,
  submittable,
  ...props
}) {
  const pages = _pages.some((page) =>
    ["Finalise", "Finalize"].includes(page.buttonRight)
  )
    ? _pages
    : [
        ..._pages,
        {
          name: "StepFinal",
          show: "${completed === false}",
          questions: [
            {
              fontSize: "large",
              type: "text",
              title: "You have now completed this questionnaire.",
            },
            {
              fontSize: "normal",
              type: "text",
              title:
                'If you would like to change any of your answers, you may do so by pressing the "Back" button prior to finalizing the questionnaire.',
            },
            {
              fontSize: "normal",
              type: "text",
              title:
                'Please finalise the questionnaire by pressing the "Finalise" button. Once you press "Finalise", you will not be able to go back to review or change your answers.',
            },
          ],
          buttonRight: "Finalise",
        },
        {
          name: "StepDone",
          show: "${completed === false}",
          questions: [
            {
              fontSize: "large",
              type: "text",
              title: "Thank you for completing this questionnaire.",
            },
            {
              fontSize: "large",
              type: "text",
              title: "Your clinician will now be notified.",
            },
          ],
          buttonRight: "Done",
        },
      ];

  

  const readOnly = !!submission?.updatedAt;

  // currentRoleType !== "personal" || submittable

  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (submission && submission.answers) {
      setAnswers(
        typeof submission.answers === "string"
          ? JSON.parse(submission.answers)
          : submission.answers
      );
    } else {
      pages.forEach((page) => {
        newInitializeAnswers(page.questions, setAnswers);
      });
    }
  }, [id]);

 

  let questionIndex = 1;
  return (
    <AnswerContext.Provider value={{ answers, setAnswers }}>
      <Stack.Navigator
        screenOptions={{ headerShown: false, gestureEnabled: false }}
      >
        {pages.map((page, index) => {
          const initialQuestionNumber = questionIndex;
          questionIndex =
            initialQuestionNumber + page.questions.filter((q) => q.id).length;

          return (
            <Stack.Screen
              key={page.name + index}
              name={page.name}
              component={Step}
              options={{
                gestureEnabled: false,
              }}
              initialParams={{
                id,
                pages,
                currentIndex: index,
                page,
                version,
                submission,
                readOnly,
                initialQuestionNumber,
                submittable,
                onSubmit: () => setConfirmGoBack(false),
              }}
            />
          );
        })}
      </Stack.Navigator>
    </AnswerContext.Provider>
  );
}

// moved the navigation logic out so the component can be used in storybook
const FormNavigator = ({ route, navigation, ...props }) => {
  const [confirmGoBack, setConfirmGoBack] = useState(true);
  const currentRoleType = useAtom(currentRoleTypeAtom);
  const currentUserId = useAtom(currentUserIdAtom);
  const { id, version, pages, submission } = route.params;
  const submittable =
    (currentRoleType === "personal" || submission?.userId === currentUserId) &&
    submission &&
    !submission.updatedAt;


  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        if (!confirmGoBack) {
          return;
        }

        e.preventDefault();

        if (submittable) {
          alert(
            "Discard changes?",
            "Are you sure you want to return to the start? You will lose all your completed answers",

            async () => {
              navigation.dispatch(e.data.action);
              return true;
            }
          );
        } else {
          alert(
            "Go back?",
            "Are you sure you want to return to the start of the form?",
            async () => {
              navigation.dispatch(e.data.action);
              return true;
            }
          );
        }
      }),
    [navigation, confirmGoBack]
  );
  return (
    <OGFormNavigator
      navigation={navigation}
      route={route}
      setConfirmGoBack={setConfirmGoBack}
      submission={submission}
      pages={pages}
      version={version}
      id={id}
      submittable={submittable}
      {...props}
    />
  );
};

export default FormNavigator;
export { OGFormNavigator };
