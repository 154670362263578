import React, { useState } from 'react';
import { View, Text, Platform } from 'react-native';
import * as Updates from 'expo-updates';
import { MainScreen, HeaderButton, Spinner } from '../../components';
import { configAtom, useAtom, isWebAtom } from '../../atoms';
import { screen } from '../../reactUtils';
import IconLogo from '../../assets/images/logo.svg';

export default screen(({ navigation, route }) => {
  const { forcedUpdate, nativeUpdate, next } = route.params;
  const web = useAtom(isWebAtom);
  const config = useAtom(configAtom);
  const [loading, setLoading] = useState(false);
  if (loading) {
    return (
      <View style={{ backgroundColor: 'white', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Spinner size="large" />
        <View style={{ marginTop: 40 }}>
          <Text
            style={{
              fontSize: web ? 20 : 18,
              fontWeight: '400',
              color: 'rgb(57, 63, 70)',
            }}
          >
            Updating
          </Text>
        </View>
      </View>
    );
  }
  return (
    <MainScreen statusMargin={true} backgroundColor="white" style={{ flex: 1 }} isList={false}>
      <View style={{ flex: 1 }}>
        <View style={{ alignItems: 'center' }}>
          <IconLogo width={180} height={web ? 180 : 80} />
        </View>
        <View style={{ alignItems: 'center', marginTop: 150, padding: 20 }}>
          <Text style={{ fontSize: web ? 20 : 18, fontWeight: '400', color: 'rgb(57, 63, 70)' }}>A new version of the app is available.</Text>
          <Text style={{ fontSize: web ? 21 : 19, fontWeight: '500', color: '#004E7A', marginTop: 20, marginBottom: 20 }}>
            v{config.release.buildNumber}
          </Text>
          <Text style={{ fontSize: web ? 20 : 18, fontWeight: '400', color: 'rgb(57, 63, 70)' }}>
            {nativeUpdate
              ? 'You need to update the app from the App Store'
              : forcedUpdate
              ? 'There are major changes in this version and you need to update the app now.'
              : 'Do you want to update the right app now?'}
          </Text>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 40 }}>
            <HeaderButton
              title={forcedUpdate || nativeUpdate ? 'OK' : 'Yes'}
              onPress={async () => {
                if (!nativeUpdate) {
                  if (Platform.OS === 'web') {
                    window.location.reload();
                  } else {
                    setLoading(true);
                    const { isNew } = await Updates.fetchUpdateAsync();
                    if (isNew) {
                      await Updates.reloadAsync();
                      setLoading(false);
                    } else {
                      navigation.navigate(next.screen, next.params);
                    }
                  }
                } else {
                  navigation.navigate(next.screen, next.params);
                }
              }}
            />
            {forcedUpdate || nativeUpdate ? null : <HeaderButton title="No" onPress={() => navigation.navigate(next.screen, next.params)} />}
          </View>
        </View>
        <View style={{ marginLeft: 20, padding: 20 }}>
          <Text style={{ fontSize: web ? 20 : 18, fontWeight: '500', color: 'rgb(57, 63, 70)', marginTop: 30 }}>Changes</Text>
          {config.release.changes.map((text) => (
            <Text key={text} style={{ fontSize: web ? 20 : 18, fontWeight: '300', color: 'rgb(57, 63, 70)', marginTop: 15, marginLeft: 20 }}>
              {'- ' + text}
            </Text>
          ))}
        </View>
      </View>
    </MainScreen>
  );
});
