import React from 'react';
import { TouchableOpacity } from 'react-native';
import { TextInput, Button, ErrorMessage, AuthScreen } from '../../components';
import { Title, Text, Field, FieldLabel, ButtonContainer, Footer, FooterValue } from './common';
import { resetPassword } from '../../utils';
import { useForm } from '../../hooks';

export default ({ route, navigation }) => {
  const { email } = route.params;
  const { control, isSubmitting, onSubmit, error, errors } = useForm({
    schema: (yup) => ({
      code: yup.string().required('Please enter the code sent to your email'),
      password: yup.string().required('No password provided'),
      passwordConfirmation: yup
        .string()
        .required('Re-enter your password again')
        .oneOf([yup.ref('password')], 'Passwords must match'),
    }),
    onSubmit: async ({ code, password }) => {
      await resetPassword({ email: email.toLowerCase(), code: ('' + code).trim(), password: password });
      navigation.replace('SignInScreen', { isSignout: true, email: email.toLowerCase() });
    },
  });
  return (
    <AuthScreen>
      <Title>
        <Text>A reset code was sent to your email</Text>
      </Title>
      <Field>
        <FieldLabel>CODE</FieldLabel>
        <TextInput name="code" type="numeric" control={control} errors={errors} />
      </Field>
      <Field>
        <FieldLabel>NEW PASSWORD</FieldLabel>
        <TextInput name="password" secure={true} help={true} errors={errors} control={control} />
      </Field>
      <Field>
        <FieldLabel>CONFIRM NEW PASSWORD</FieldLabel>
        <TextInput name="passwordConfirmation" secure={true} errors={errors} control={control} />
      </Field>
      <ErrorMessage err={error} />
      <ButtonContainer>
        <Button loading={isSubmitting} onPress={onSubmit}>
          RESET
        </Button>
      </ButtonContainer>
      <Footer style={{ marginTop: 20 }}>
        <TouchableOpacity onPress={navigation.goBack}>
          <FooterValue>BACK</FooterValue>
        </TouchableOpacity>
      </Footer>
    </AuthScreen>
  );
};
