import React, {useState} from "react";

import {
  useAtom,
  currentOrganisationAtom,
  currentUserAtom,
} from "../../../atoms";
import { otherUserIdAtom } from "../../../atoms";
import { getOrganisationDistributionContacts2 } from "../../../graphql";

import ContactList from "../../../new/components/contacts/List.js";
import SelectListItem from "../../../new/components/contacts/SelectListItem";
import { useInfiniteQuery } from "react-query";

export default function ({ navigation, route }) {
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const currentUser = useAtom(currentUserAtom);

  const [filterString, setFilterString] = useState("");

  const apiFn = (props) =>
    getOrganisationDistributionContacts2({
      id: currentOrganisation.id,
      ...props,
    });
  const pathFn = ({
    data: {
      getOrganisation: {
        distributionContacts: { items, ...props },
      },
    },
  }) => ({
    items: items.filter((c) => c.adviceInstances.items?.length > 0),
    ...props,
  });

  const {
    data: pageData,
    fetchNextPage: loadMore,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [currentOrganisation, "contacts", filterString],
    ({ pageParam }) => apiFn({filterString, ...pageParam}).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );
  const loading = isLoading || isFetchingNextPage;

  const data = pageData ? pageData.pages.flatMap((x) => x.items) : [];

  const renderItem = (props) => (
    <SelectListItem
      onPress={() => {
        otherUserIdAtom.update(props.item.userId);
        navigation.navigate("ProfileScreen", {
          isOther: props.item.userId != currentUser.id ? true : false,
          item: props.item,
          contactId: props.item.id,
          name: props.item.name,
        });
      }}
      {...props}
    />
  );

  return (
    <ContactList
      setSearchText={setFilterString}
      data={data}
      loadMore={loadMore}
      hasMore={hasNextPage}
      loading={loading}
      renderItem={renderItem}
    />
  );
}
