import React, {useState} from "react";
import { getOrganisationDistributionContacts2 } from "../../../graphql";
import ContactList from "../../../new/components/contacts/List.js";
import SelectListItem from "../../../new/components/contacts/SelectListItem";
import { useAtom, currentOrganisationAtom } from "../../../atoms";
import { useInfiniteQuery } from "react-query";

export default function ({ navigation, route }) {
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const [filterString, setFilterString] = useState("");

  const apiFn = (props) =>
    getOrganisationDistributionContacts2({
      id: currentOrganisation.id,
      ...props,
    });

  const pathFn = (result) => result.data.getOrganisation.distributionContacts;

  const {
    data: pageData,
    fetchNextPage: loadMore,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [currentOrganisation.id, "contacts", filterString],
    ({ pageParam }) => apiFn({filterString, ...pageParam}).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const loading = isLoading || isFetchingNextPage;

  const data = pageData ? pageData.pages.flatMap((x) => x.items) : [];

  const renderItem = (props) => (
    <SelectListItem
      onPress={() => {
        navigation.navigate(route.params.selectionOriginatingScreen, {
          ...route.params.previousScreenParams,
          selectedContacts: [
            props.item,
            ...route.params.selectedContacts.filter(
              (i) => i.id !== props.item.id
            ),
          ],
        });
      }}
      {...props}
    />
  );

  return (
    <>
      <ContactList
        setSearchText={setFilterString}
        data={data}
        loadMore={loadMore}
        hasMore={hasNextPage}
        loading={loading}
        renderItem={renderItem}
      />
    </>
  );
}
