import React from "react";
import { Platform } from "react-native";

import { createStackNavigator } from "@react-navigation/stack";
import { options, optionsBack } from "../../../headerUtils";
import PractitionerScreen from "./PractitionerScreen";
import WelcomeScreen, { WelcomeOptions } from "./WelcomeScreen";
import { useAtom, isWebAtom } from "../../../atoms";
import { useHasOnlyRole } from "../../../hooks";

const Stack = createStackNavigator();

export default () => {
  const onlyCurator = useHasOnlyRole("curator");
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="PractitionerScreen"
        component={PractitionerScreen}
        options={web ? options("Home") : options()}
      />
      <Stack.Screen
        name="WelcomeScreen"
        component={WelcomeScreen}
        options={
          web ? optionsBack(web, "Welcome to iOWNA", null) : WelcomeOptions(web)
        }
      />
    </Stack.Navigator>
  );
};
