import useValidateField from "../useValidation/useValidateField";
import useCaptureInvalidFields from "../useValidation/useCaptureInvalidFields";

type ValidationFnT<VT, IT = VT> = Parameters<
  typeof useValidateField<VT, IT>
>[0]["validate"];

const useValidateAndCapture = <VT, IT>({
  label,
  validate: _validate,
  onChange: _onChange,
  value: _value,
}: {
  label: string;
  validate?: ValidationFnT<VT, IT>;
  onChange: (data: VT) => void;
  value: VT | IT;
}) => {
  const { validate } = useCaptureInvalidFields(
    label,
    _validate
      ? _validate
      : (data: VT | IT) => ({ isSuccess: true, data: data as VT })
  );

  const { onChange, error, value } = useValidateField({
    validate,
    onValid: _onChange,
    value: _value,
  });
  return {
    label,
    error,
    value,
    onChange,
  };
};


export type { ValidationFnT };
export default useValidateAndCapture;
