import type { ComponentProps } from "react";
import { NumericInput } from "./Input";
import FieldWithValidation from "./FieldWithValidation";
import type { FieldWithValidationPropsT } from "./FieldWithValidation";

type InputT = ComponentProps<typeof NumericInput>;

const NumericField = ({
  onChange,
  label,
  value,
  validate,
}: InputT &
  Omit<
    FieldWithValidationPropsT<number | undefined, number | undefined>,
    "render"
  >) => {
  return (
    <FieldWithValidation
      label={label}
      onChange={onChange}
      value={value}
      render={NumericInput}
      validate={validate}
    />
  );
};

export { NumericField };
export default NumericField;
