import { createContext } from "react";
import type { Dispatch, SetStateAction } from "react";

type Error = { path: string; error: string };

const SubFormContext = createContext<
  {
    path: string;
  } & (
    | {
        setErrors: Dispatch<SetStateAction<Error[]>>;
        errors: Error[];
      }
    | {
        setErrors?: never;
        errors?: never;
      }
  )
>({
  path: "root",
});

export default SubFormContext;

export type { Error };
