import React, { Fragment } from 'react';
import { View, Text } from 'react-native';
import get from 'lodash/get';
import { currentOrganisationAtom, useAtom, isWebAtom } from '../atoms';
import { containerWithoutLoader } from '../reactUtils';

const BreadCrumb = containerWithoutLoader(({ parts = [] }) => {
  const web = useAtom(isWebAtom);
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const getPart = (key) => {
    if (key === 'currentOrganisation.name') {
      return currentOrganisation.name;
    }
    return get(currentOrganisation, key, key);
  };
  if (!web) {
    const last = parts[parts.length - 1];
    if (typeof last === 'string') {
      return <Text style={{ fontSize: 20, color: 'rgb(0, 78, 122)' }}>{getPart(last)}</Text>;
    }
    return last;
  }
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {parts.map((part, index) =>
        index !== parts.length - 1 ? (
          <Fragment key={part}>
            <Text style={{ fontSize: 20, color: 'rgb(0, 78, 122)' }}>{getPart(part)}</Text>
            <View style={{ marginLeft: 15, marginRight: 15 }}>
              <Text style={{ fontSize: 16, color: '#6c757d' }}>/</Text>
            </View>
          </Fragment>
        ) : typeof part === 'string' ? (
          <Text key={part} style={{ fontSize: 20, color: '#6c757d' }}>
            {getPart(part)}
          </Text>
        ) : (
          part
        )
      )}
    </View>
  );
});

export default BreadCrumb;
