import React, { useState, useEffect } from "react";
import type { ComponentProps } from "react";
import { Button, View, TouchableOpacity, Text } from "react-native";
import { useAList } from "./../../hooks";
import Form from "../Form";
import Question from "./Question";
import { AntDesign } from "@expo/vector-icons";


type QuestionT = ComponentProps<typeof Question>["question"];

type PageT = {
  name: string;
  show: string;
  questions: QuestionT[];
  buttonRight?: string;
};


const Divider = () => (
  <View
    style={{
      borderBottomWidth: 2,
      borderBottomColor: "#C6C6C8",
      margin: 5,
      padding: 20
    }}
  />
);
const Page = ({
  updateItem: updatePageItem,
  page: _page,
}: {
  updateItem: (arg0: PageT) => void;
  page?: PageT;
}) => {
  const [page, setPage] = useState<PageT>(
    _page ? _page : { name: "", show: "", questions: [] }
  );
  const questions = useAList<QuestionT>(page.questions);

  useEffect(() => {
    if (page.name) {
      updatePageItem(page);
    }
  }, [page]);

  useEffect(() => {
    setPage((prev) => ({
      ...prev,
      questions: questions.items.filter(
        (item) => item !== undefined
      ) as QuestionT[],
    }));
  }, [questions.items]);

  return (
    <>
      <Form.TextField
        value={page.name}
        label={"Page Title"}
        onChange={(val) => setPage((prev) => ({ ...prev, name: val }))}
        validate={(data) => {
          if (!data) {
            return {
              isSuccess: false,
              error: "name is required",
            };
          }
          if (data.length < 3 || data.length > 100) {
            return {
              isSuccess: false,
              error: "name must be more than 3 characters",
            };
          }
          return {
            isSuccess: true,
            data,
          };
        }}
      />
      <Form.TextField
        value={page.show}
        label={"Page Show"}
        onChange={(val) => setPage((prev) => ({ ...prev, show: val }))}
      />

      {questions.render(
        ({ updateItem, deleteItem, item, key, moveItemUp, moveItemDown }) => (
          <Form.SubForm key={key} name={`questions.${key}`}>
            <View style={{padding: 20}}></View>
            <Question updateItem={updateItem} question={item} />
           

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  flex: 1,
                  backgroundColor: "#AEB1B5",
                  borderRadius: 5,
                  margin: 5,
                  height: 50,
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onPress={moveItemUp}
              >
                <AntDesign
                  style={{ paddingLeft: 20 }}
                  name="caretup"
                  size={24}
                  color="white"
                />
                <Text
                  style={{
                    paddingLeft: 20,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  MOVE QUESTION UP
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  flex: 1,
                  backgroundColor: "#AEB1B5",
                  borderRadius: 5,
                  margin: 5,
                  height: 50,
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onPress={moveItemDown}
              >
                <AntDesign
                  style={{ paddingLeft: 20 }}
                  name="caretdown"
                  size={24}
                  color="white"
                />
                <Text
                  style={{
                    paddingLeft: 20,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  MOVE QUESTION DOWN
                </Text>
              </TouchableOpacity>
              <View style={{ flex: 1 }}>
                <Button title="delete question" onPress={deleteItem} />
              </View>

            </View>
            <Divider/>
          </Form.SubForm>
        )
      )}

      <View>
        <Button
          title="new Question"
          onPress={() => questions.addItem(undefined)}
        />
      </View>
    </>
  );
};

export default Page;
