import React, { useRef } from 'react';
import { TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import { debounce } from 'lodash';

export default ({ withoutFeedback, onPress, children, ...props }) => {
  const debounceRef = useRef(debounce(onPress, 250, { leading: true, trailing: false }));
  const Comp = withoutFeedback ? TouchableWithoutFeedback : TouchableOpacity;
  return (
    <Comp onPress={debounceRef.current} style={props}>
      {children}
    </Comp>
  );
};
