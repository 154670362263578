import React, {useRef, useEffect} from "react";
import { View, Text, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  confirm,
  useAtom,
  isWebAtom,
  usePromise,
  currentUserAtom,
  setPdfId,
} from "../../../atoms";
import { handleError, getThumbnailUrl, formatCalender } from "../../../utils";
import {
  Box,
  Accordion,
  AccordionButton,
  CardSeperator,
} from "../../../components";
import { containerSmall } from "../../../reactUtils";

const onRemovePress = async (removeFn, item) => {
  confirm(
    "Delete content",
    `Are you sure you want to delete the content "${item.name}"?`,
    handleError(removeFn)
  )(removeFn);
};

//__typename 'Content' is patient uploaded material. __typename 'userContent' is clinician or organisation uploaded content
export default function ({ item, isOpen, onOpen, onDelete }) {
  const navigation = useNavigation();
  const web = useAtom(isWebAtom);
  const user = useAtom(currentUserAtom);




  const Thumbnail = ({ downloadUrl, thumbnailUrl }) => {

    return <Image style={{ height: 100, width: 100 }} source={{ uri: thumbnailUrl }} />;
  };

  if (item.__typename === "Content") {
    return (
      <>
        <Box>
          <Accordion
            open={isOpen}
            onPress={() => onOpen(item)}
            title={
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItem: "center" }}>
                  <View
                    style={{
                      border: 1,
                      backgroundColor: "#ffffff",
                      marginHorizontal: web ? 20 : 10,
                      marginVertical: 10,
                      flex: web ? 1 : 2,
                    }}
                  >
                    {/* downloadURL changed to point to inlineUrl so on click on thumbnail you open the item inline */}
                    <Thumbnail
                      downloadUrl={item.inlineUrl}
                      thumbnailUrl={item.thumbnailUrl}
                    />
                  </View>

                  <View style={{ flex: 5, flexDirection: "row" }}>
                    <View
                      style={{
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: web ? 8 : 3,
                      }}
                    >
                      <View
                        style={{ justifyContent: "space-between", margin: 10 }}
                      >
                        {user ? (
                          <Text style={{ fontSize: 14 }}>
                            {user.firstName} {user.lastName}
                          </Text>
                        ) : (
                          <Text>you</Text>
                        )}
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            marginVertical: 5,
                          }}
                        >
                          {item.name}
                        </Text>
                        <Text style={{ fontSize: 14 }}>
                          {" "}
                          {item.createdAt ? formatCalender(item.createdAt) : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            }
          >
            <AccordionButton
              items={[
                {
                  title: "View",
                  onPress: () => {
                    navigation.navigate("SimpleContentViewScreen", {
                      uri: item.inlineUrl,
                      item: item,
                    });
                  },
                },
                {
                  title: "Delete",
                  onPress: () => {
                    onRemovePress(onDelete, item);
                  },
                },
              ]}
            />
          </Accordion>
        </Box>
        <CardSeperator />
      </>
    );
  }
  // if the item has been uploaded by the clinician/organisation then the content item takes this form
  else {
    return (
      <>
        <Box>
          <Accordion
            open={isOpen}
            onPress={() => onOpen(item)}
            title={
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      border: 1,
                      backgroundColor: "#ffffff",
                      marginHorizontal: web ? 20 : 10,
                      marginVertical: 10,
                      flex: web ? 1 : 2,
                    }}
                  >
                    <Thumbnail
                      downloadUrl={item.staticAdvice.inlineUrl}
                      thumbnailUrl={item.staticAdvice.thumbnailUrl}
                    />
                  </View>
                  <View style={{ flex: 5, flexDirection: "row" }}>
                    <View
                      style={{
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",

                        flex: web ? 8 : 3,
                      }}
                    >
                      <View
                        style={{ justifyContent: "space-between", margin: 10 }}
                      >
                        <Text
                          style={{ fontSize: 14 }}
                        >{`${item.organisation.name}`}</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            marginVertical: 5,
                          }}
                        >
                          {item.staticAdvice.displayName}
                        </Text>

                        <Text style={{ fontSize: 14 }}>
                          {item.createdAt ? formatCalender(item.createdAt) : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            }
          >
            <AccordionButton
              items={[
                {
                  title: "View",
                  onPress: () => {
                    if (item.staticAdvice.state === "archived") {
                      navigation.navigate("ContentExpiredScreen", {
                        contentName: item.staticAdvice.displayName,
                      });
                    } else {
                      setPdfId(item.staticAdviceId);
                      navigation.navigate("PdfScreen", {
                        id: item.staticAdviceId,
                        contentId: item.id,
                      });
                    }
                  },
                },
              ]}
            ></AccordionButton>
          </Accordion>
        </Box>
        <CardSeperator />
      </>
    );
  }
}
