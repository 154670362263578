import type { ComponentProps } from "react";
import useValidateAndCapture from "../useValidation/useValidateAndCapture";
import type { ValidationFnT } from "../useValidation/useValidateAndCapture";
import FieldWithError from "./FieldWithError";

type UseValidateAndCaptureT<VT, IT> = typeof useValidateAndCapture<VT, IT>;
type UseValidateAndCapturePropsT<VT, IT> = Parameters<
  UseValidateAndCaptureT<VT, IT>
>[0];

const FieldWithValidation = <VT, IT>({
  value: _selected,
  onChange: _onChange,
  label,
  validate,
  render: Render,
}: UseValidateAndCapturePropsT<VT, IT> & {
  label: string;
  render: ({
    value,
    onChange,
  }: {
    value: Parameters<ValidationFnT<VT, IT>>[0];
    onChange: (data: VT) => void;
  }) => JSX.Element;
}) => {
  const { error, value, onChange } = useValidateAndCapture({
    label,
    validate,
    onChange: _onChange,
    value: _selected,
  });

  const render = <Render value={value} onChange={onChange} />;
  // const MemoizedRender = memo(Render);

  return (
    <FieldWithError
      label={label}
      error={error}
      input={render}
      // input={<MemoizedRender value={value} onChange={onChange} />}
    />
  );
};

export default FieldWithValidation;

type FieldWithValidationT<VT, IT> = typeof FieldWithValidation<VT, IT>;
type FieldWithValidationPropsT<VT, IT> = ComponentProps<
  FieldWithValidationT<VT, IT>
>;

export type { FieldWithValidationT, FieldWithValidationPropsT };
