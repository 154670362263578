import React, { useState } from "react";
import List from "../List";
import Empty from "../../../components/Empty.js";
import { PRIMARY_TEXT_LIGHT } from "../../../themes";


export default function ({ renderItem, data, loadMore, hasMore, loading }) {
  const [searchText, setSearchText] = useState("");

  

  const displayedData = !!searchText
    ? data.filter((item) =>
        searchText
          .toLowerCase()
          .split(/\s+/)
          .every((searchFacet) =>
            [...item.name.toLowerCase().split(/\s+/)].find(
              (itemFacet) => itemFacet.includes(searchFacet)
            )
          )
      )
    : data;


  if (!loading && hasMore && displayedData.length < 50) loadMore();

  return (
    <List
      setSearchText={setSearchText}
      loading={loading}
      flatListParams={{
        data: displayedData,
        renderItem,
        onEndReached: loadMore,
      }}
      ListEmptyComponent={
        <Empty
          title={
            displayedData.length == 0 && searchText.length > 0
              ? "No contacts with this name have been sent this content. Please reset the search."
              : ""
          }
          center={true}
        ></Empty>
      }
    />
  );
}

