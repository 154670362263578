import React from "react";
import { TouchableOpacity, Text, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useAtom, isWebAtom } from "../atoms";

const HeaderButton = ({
  icon,
  customIcon,
  title,
  backgroundColor,
  onPress,
  hasBorder = true,
  style,
  ...props
}) => {
  const web = useAtom(isWebAtom);
  return (
    <TouchableOpacity
      style={{
        maxHeight: 30,
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: web ? 'none' : 170,
        borderWidth: web && hasBorder ? 1 : 0,
        borderColor: "#004E7A",
        borderRadius: 5,
        marginRight: web ? 30 : 0,
        backgroundColor: backgroundColor,
        ...style,
      }}
      onPress={onPress}
      {...props}
    >
      {icon ? (
        typeof icon === "string" ? (
          <Ionicons
            name={icon}
            size={24}
            color="#004E7A"
            style={{ marginRight: 10 }}
          />
        ) : (
          icon
        )
      ) : null}
      {customIcon ? customIcon : null}
      {web || !icon ? (
        <Text
          style={{
            fontSize: web ? 16 : 18,
            fontWeight: "500",
            color: "#004E7A",
            paddingRight: 5,
          }}
        >
          {title}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
};

export default HeaderButton;
