import React, { useState, useEffect, useRef } from "react";
import { View, TouchableWithoutFeedback, Image } from "react-native";
import { Controller } from "react-hook-form";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useTheme } from "@emotion/react"
import Modal from "./Modal";
import ErrorMessage from "./ErrorMessage";
import RNPhoneInput, { CountryPicker } from "./ReactNativePhoneInput";
import countries from "./ReactNativePhoneInput/countries";

// Because multiple countries have +44 we wan't to show UK's flag since UK is in the last of the array
const reversedCountries = countries.slice().reverse();

export default function PhoneInput({
  name,
  initialNumber,
  control,
  errors,
  width,
  watch,
  clearErrors,
}) {
  const theme = useTheme();
  const [number, setNumber] = useState(
    !!initialNumber ? parsePhoneNumberFromString(initialNumber) : null
  );
  const [showModal, setShowModal] = useState(false);
  const [countryData, setCountryData] = useState(() => {
    return countries.find((item) => item.iso2 === "gb");
  });

  const dialcode = useRef("44");

  useEffect(() => {
    if (initialNumber) {
      const parsedNumber = parsePhoneNumberFromString(initialNumber);
      if (parsedNumber) {
        setNumber(parsedNumber);
        setCountryData(
          countries.find(
            (item) => item.iso2 === parsedNumber.country.toLowerCase()
          )
        );
      }
    } else {
      setNumber("");
    }
  }, [initialNumber]);

  const containerStyle = {
    height: 50,
    width: width,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
    borderBottomColor: errors[name] ? theme.error : theme.textInputBorderBottom,
    borderBottomWidth: 1,
  };
  return (
    <View>
    <View style={containerStyle}>
      <Controller
        render={({field: {onChange, value}}) => (
          <View>
            <TouchableWithoutFeedback onPress={() => setShowModal(true)}>
              <Image
                source={countryData.flag}
                style={{
                  height: 20,
                  width: 30,
                  borderRadius: 2,
                  borderWidth: 0.5,
                  borderColor: "#cecece",
                  backgroundColor: "#cecece",
                }}
              />
            </TouchableWithoutFeedback>
            <Modal
              isList={true}
              isVisible={showModal}
              title="Select Country"
              onClose={() => setShowModal(false)}
              style={{ maxWidth: 450 }}
            >
              <CountryPicker
                onChangeCountry={(val) => {
                  setShowModal(false);
                  setCountryData(val);
                  dialcode.current = val.dialCode;
                  onChange(val.dialCode);
                }}
              />
            </Modal>
          </View>
        )}
        control={control}
        name="countryCode"
        defaultValue={countryData.dialCode}
      />
      <Controller
        render={({field: {onChange, value}}) => (
          <RNPhoneInput
            width={width}
            dialCode={countryData.dialCode}
            onChangeText={onChange}
            value={value}
          />
        )}
        control={control}
        name={name}
        defaultValue={number ? number.nationalNumber : ""}
        rules={{
          required: "Phone number must be valid",
          validate: {
            asyncValidate: async (mobile) => {
              const phoneNumber = parsePhoneNumberFromString(
                "+" + dialcode.current + mobile
              );
              clearErrors();
              return (
                (phoneNumber && phoneNumber.isValid()) ||
                "phone number must be valid"
              );
            },
          },
        }}
      />
  
    </View>
    <ErrorMessage name={name} errors={errors} />
    </View>
  );
}
