import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { HeaderButton, Modal } from "../../../components";
import {
  deleteDistributionTag,
  getDistributionTagContacts,
} from "../../../graphql";
import ContactList from "../../../new/components/contacts/List.js";
import TagListItem from "../../../new/components/lists/TagListItem";
import EditDistributionList from "../../../new/components/lists/EditDistributionList";
import {
  useAtom,
  currentOrganisationAtom,
  isWebAtom,
  alert,
  confirm,
} from "../../../atoms";
import { untagDistributionContact } from "../../../graphql";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { AntDesign, Feather } from "@expo/vector-icons";


export default function ({ route, navigation }) {
  const web = useAtom(isWebAtom);

  const {  item, tagId } = route.params;


  // console.log("item", item);

  const currentOrganisation = useAtom(currentOrganisationAtom);

  const [openItem, setOpenItem] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const deleteMutation = useMutation(
    ({ tagId }) => {
      return deleteDistributionTag({ input: { id: tagId } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [currentOrganisation.id, "distributionLists"],
        });
        navigation.goBack();
      },
      onError: (err) => {
        const message = err.errors?.[0]?.message;
        if (message) {
          console.log("the first errror");
          alert(
            "Error",
            "You cannot delete a list that has contacts in. Empty the list first."
          );
          // throw new Error(message);
        } else {
          alert(JSON.stringify(err));
          // throw new Error(JSON.stringify(err));
        }
      },
    }
  );


  const apiFn = (props) =>
    getDistributionTagContacts({ id: route.params.item.id, ...props });
  const pathFn = (result) => result.data.distributionTag.contacts;

  const {
    data: pageData,
    fetchNextPage: loadMore,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [currentOrganisation.id, "contacts", "byTag", route.params.item.id],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );
  const loading = isLoading || isFetchingNextPage;

  const data = pageData ? pageData.pages.flatMap((x) => x.items) : [];

  const queryClient = useQueryClient();

  const removeTag = useMutation(
    ({ id }) =>
      untagDistributionContact({
        input: { contactId: id, tagId: route.params.item.id },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          currentOrganisation.id,
          "contacts",
          "byTag",
          route.params.item.id,
        ]);
      },
      onError: (err) => {
        const message = err.errors?.[0]?.message;
        if (message) {
          alert(message);
          // throw new Error(message);
        } else {
          alert(JSON.stringify(err));
          // throw new Error(JSON.stringify(err));
        }
      },
    }
  );

  const renderItem = (props) => (
    <TagListItem
      isOpen={openItem?.id === props.item.id}
      onOpen={() => {
        setOpenItem((prevSetting) =>
          prevSetting === props.item ? null : props.item
        );
      }}
      onRemove={() => removeTag.mutate(props.item)}
      tagId={route.params.item.id}
      {...props}
    />
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => {
        return web ? (
          <>
            <View style={{ flexDirection: "row" }}>
              <HeaderButton
                icon={<Feather name="edit" size={18} color="#004e7a" />}
                title=" Rename list"
                onPress={() => {
                  setShowModal(true);
                }}
              />
              <HeaderButton
                icon="ios-trash"
                title="Delete list"
                onPress={() => {
                 

                  deleteMutation.mutate({ tagId });
                }}
              />
            </View>
          </>
        ) : (
          <>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                }}
              >
                <AntDesign name="delete" size={18} color="#004e7a" />
              </TouchableOpacity>
            </View>
          </>
        );
      },
    });
  }, []);

  return (
    <>
      <ContactList
        data={data}
        loadMore={loadMore}
        hasMore={hasNextPage}
        loading={loading}
        renderItem={renderItem}
      />
      {showModal && (
        <Modal
          isVisible={showModal}
          style={{ maxWidth: 400 }}
          title={"Rename list"}
          onClose={() => setShowModal(false)}
        >
          <EditDistributionList
            buttonTitle={"Rename"}
            onSave={() => {
              queryClient.invalidateQueries({
                queryKey: [currentOrganisation.id, "contacts", "byTag", item.id],
              });
              queryClient.invalidateQueries({
                queryKey: [currentOrganisation.id, "distributionLists"],
              });
            }}
            item={route.params.item}
            itemId={route.params.item.id}
            tagName={route.params.item.name}
      
          />
        </Modal>
      )}
    </>
  );
}
