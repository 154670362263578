import React, { Suspense } from 'react';
import { View } from 'react-native';
import { CenteredSpinner, ErrorBoundary } from './components';

export const container = (Comp) => (props) => (
  <ErrorBoundary>
    <Suspense fallback={<CenteredSpinner />}>
      <Comp {...props} />
    </Suspense>
  </ErrorBoundary>
);

export const containerSmall = (Comp) => (props) => (
  <ErrorBoundary>
    <Suspense fallback={<CenteredSpinner size="small" />}>
      <Comp {...props} />
    </Suspense>
  </ErrorBoundary>
);

export const containerWithoutLoader = (Comp) => (props) => (
  <ErrorBoundary>
    <Suspense fallback={<View />}>
      <Comp {...props} />
    </Suspense>
  </ErrorBoundary>
);

export const screen = (Comp) => (props) => (
  <ErrorBoundary>
    <Suspense fallback={<CenteredSpinner />}>
      <Comp {...props} />
    </Suspense>
  </ErrorBoundary>
);
