import React from 'react';
import { Dimensions } from 'react-native';
import { Box, Text, Touch, AuthScreen } from '../../components';
import { testId } from '../../utils';

export default ({ navigation }) => {
  const width = Math.min(Dimensions.get('window').width - 80, 360);
  return (
    <AuthScreen>
      <Box marginBottom={200} width={width}>
        <Text color="white" fontSize={28} fontWeight="500" textAlign="center">Content curated by doctors, for clinicians to give to patients</Text>
      </Box>
      <Touch
        backgroundColor="white"
        width={width}
        height={50}
        borderRadius={25}
        alignItems="center"
        justifyContent="center"
        marginBottom={25}
        onPress={() => navigation.navigate('AccountTypeScreen')}
        {...testId('buttonSignUp')}
      >
        <Text color="#004E7A" fontSize={20}>
          Create account
        </Text>
      </Touch>
      <Touch
        backgroundColor="#004E7A"       
        width={width}
        height={52}
        alignItems="center"
        justifyContent="center"
        borderWidth={2}
        borderColor="white"
        borderRadius={25}
        onPress={() => navigation.navigate('SignInScreen')}
        {...testId('buttonSignIn')}
      >
        <Text color="white" fontSize={20}>
          Log in
        </Text>
      </Touch>
    </AuthScreen>
  );
};
