import React, { useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { View, Text } from "react-native";
import { Accordion, AccordionButton } from "../../../components";
import { confirm } from "../../../atoms";
import { handleError } from "../../../utils";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

const onRemovePress = async (removeFn) => {
  confirm(
    "Remove Bundle",
    `Are you sure you want to remove this bundle`,
    handleError(removeFn)
  )(removeFn);
};

export default function ({
  item,
  isOpen,
  onOpen,
  onEdit,
  onDelete,
  canSetAdvice,

  selectionOriginatingScreen,
  bundleData,
}) {
  const navigation = useNavigation();
  const route = useRoute();
  const selected = route.params.selected;

  const [currentOpenItem, setCurrentOpenItem] = useState();

  return (
    <Accordion
      showArrow={!canSetAdvice}
      open={(currentOpenItem?.id === item.id, isOpen)}
      onPress={() => {
        if (canSetAdvice) {
          navigation.navigate("BundleAdviceListScreen", {
            canSetAdvice,
            bundleId: item.id,
            bundleName: item.name,
            displayAs: "mobile",
            selectionOriginatingScreen,
            selected,
          });
        } else {
          onOpen(item);
        }
      }}
      title={
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            padding: 20,
            backgroundColor: "white",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 16, color: "#000000" }}>{item.name}</Text>
          </View>
        </View>
      }
    >
      <AccordionButton
        items={[
          {
            title: "View",
            icon: <Ionicons name="ios-eye" size={18} color="#004e7a" />,
            onPress: () => {
              navigation.navigate("BundleAdviceListScreen", {
                canSetAdvice: false,
                bundleId: item.id,
                bundleName: item.name,
                displayAs: "mobile",
                bundleData,
                
              });
            },
          },
          {
            title: "Edit",
            icon: (
              <MaterialCommunityIcons name="pencil" size={18} color="#004e7a" />
            ),
            onPress: () => {
              onEdit();
            },
          },
          {
            title: "Remove",
            icon: <Ionicons name="ios-trash" size={18} color="#004e7a" />,
            onPress: () => {
              onRemovePress(onDelete);
              setCurrentOpenItem(null);
            },
          },
        ]}
      ></AccordionButton>
    </Accordion>
  );
}
