import React from 'react';
import { View, StatusBar, Platform, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ThemeProvider } from "@emotion/react";
import * as Device from 'expo-device';
import BaseScreen from './BaseScreen';
import CookieBanner from '../CookieBanner';
import LogoCircle from '../../assets/images/logo-circle.svg';
import { AuthTheme } from '../../themes';
import { useAtom, isWebAtom } from '../../atoms';

const AuthScreen = ({ onLogoPress = () => {}, children }) => {
  const web = useAtom(isWebAtom);
  let marginTop = -30;
  let height = 220;
  let viewBox = '';
  if (!web || Platform.OS === 'ios') {
    viewBox = '0 0 1000 700';
    switch (Device.modelName) {
      case 'iPhone 7':
        height = 180;
        break;
      case 'iPhone 8':
        marginTop = -30;
        height = 180;
        break;
    }
  } else if (Platform.OS === 'android') {
    height = 190;
    viewBox = '0 0 1000 700';
  } else {
    height = 300;
    viewBox = '0 0 1000 780';
  }
  return (
    <ThemeProvider theme={AuthTheme}>
      <BaseScreen style={{ marginTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0 }}>
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          extraHeight={200}
          contentContainerStyle={{ flexGrow: 1, backgroundColor: AuthTheme.background, paddingBottom: 30 }}
        >
          <TouchableOpacity activeOpacity={1} onPress={onLogoPress}>
            <LogoCircle viewBox={viewBox} width="100%" height={height} style={{ marginTop: marginTop }} />
          </TouchableOpacity>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>{children}</View>
        </KeyboardAwareScrollView>
        <CookieBanner />
      </BaseScreen>
    </ThemeProvider>
  );
};

export default AuthScreen;
