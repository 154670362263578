import React from "react";
import { Platform, Dimensions, View } from "react-native";
import styled from "@emotion/native";

const Container = styled.View`
  padding: ${Platform.select({ web: "0px", ios: "20px", android: "20px" })};
  align-items: center;
  justify-content: center;
`;

const Title = styled.Text`
  font-size: ${Platform.select({ web: "22px", ios: "20px", android: "20px" })};
  font-weight: 700;
  color: ${(props) => props.theme.primary};
  margin-bottom: 10;
  text-align: center;
`;

const Description = styled.Text`
  font-size: 18;
  color: ${(props) => props.theme.textLight};
  text-align: center;
`;

export default function Empty({ title, description, center = false }) {
  const marginTop = center ? 0 : Dimensions.get("window").height / 3 - 64;
  const style = center
    ? { flex: 1, alignItems: "center", justifyContent: "center", marginTop: 40 }
    : { marginTop };
  return (
    <View
      testID="Empty"
      accessibilityLabel={Platform.select({ android: "Empty" })}
      style={style}
    >
      <Title>{title}</Title>
      <Description>{description}</Description>
    </View>
  );
}
