import { API, graphqlOperation } from "aws-amplify";

export const graphql = (query) => (variables) =>
  API.graphql(graphqlOperation(query, variables));

export const updateTsCs = graphql(`
  mutation updateTsCs($input: UpdateTermsAndConditionsInput!) {
    updateTsCs(input: $input) {
      version
    }
  }
`);

export const updateUser = graphql(`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      title
      firstName
      lastName
      biologicalSex
      genderIdentity
      religion {
        name
      }
    }
  }
`);

export const Religion = graphql(`
  mutation createReligion($input: CreateReligionInput!) {
    createReligion(input: $input) {
      userId
      name
    }
  }
`);

export const acceptInvite = graphql(`
  mutation acceptOrganisationRoleInvite($id: ID!) {
    acceptOrganisationRoleInvite(id: $id) {
      organisationId
      userId
      role
    }
  }
`);

export const rejectInvite = graphql(`
  mutation rejectOrganisationRoleInvite($id: ID!) {
    rejectOrganisationRoleInvite(id: $id) {
      id
    }
  }
`);

export const getOrganisationDistributionTags = graphql(`
  query getOrganisationDistributionTags(
    $id: ID!
    $limit: Int = 10000
    $nextToken: String
  ) {
    getOrganisation(id: $id) {
      id
      distributionTags(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          description
          contacts(limit: 100000) {
            items {
              id
              name
              givenName
              familyName
              description
              phone
              userId
              consentConfirmedAt
              consentConfirmedBy
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`);

export const getOrganisationRoleInvites = graphql(`
  query getOrganisationRoleInvites(
    $id: ID!
    $limit: Int = 10000
    $nextToken: String
  ) {
    getOrganisation(id: $id) {
      id
      roleInvites(limit: $limit, nextToken: $nextToken) {
        items {
          id
          email
          role
        }
        nextToken
      }
    }
  }
`);

export const getOrganisationUserRoles = graphql(`
  query getOrganisationRoles($id: ID!) {
    getOrganisation(id: $id) {
      userRoles(limit: 100000) {
        items {
          userId
          role
          user {
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`);

export const getOrganisationDistributionContacts2 = graphql(`
  query getOrganisationDistributionContacts(
    $id: ID!
    $limit: Int = 50
    $nextToken: String
    $filterString: String
  ) {
    getOrganisation(id: $id) {
      distributionContacts(limit: $limit, nextToken: $nextToken, filterString: $filterString) {
        items {
          id
          name
          familyName
          givenName
          description
          phone
          userId
          consentConfirmedAt
          consentConfirmedBy
          adviceInstances(limit: 1) {
            items {
              id
            }
          }
          tags {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
    }
  }
`);

export const getOrganisationDistributionContacts = graphql(`
  query getOrganisationDistributionContacts($id: ID!) {
    getOrganisation(id: $id) {
      distributionContacts(limit: 100000) {
        items {
          id
          name
          firstName
          givenName
          description
          phone
          userId
          consentConfirmedAt
          consentConfirmedBy
          tags {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export const getOrganisationBundleNames = graphql(`
  query getOrganisationBundles($id: ID!) {
    getOrganisation(id: $id) {
      bundles(limit: 100000) {
        items {
          id
          name
          adviceIds
        }
      }
    }
  }
`);

export const getOrganisationBundles = graphql(`
  query getOrganisationBundles($id: ID!) {
    getOrganisation(id: $id) {
      bundles(limit: 100000) {
        items {
          id
          name
          organisationId
          adviceIds
          createdBy
          advices {
            id
            displayName
            tags
            state
            createdBy
          }
        }
      }
    }
  }
`);

//new code starts here
export const getOrganisationBundleEntity = graphql(`
  query getOrganisationBundles($id: ID!) {
    getOrganisation(id: $id) {
      bundles(limit: 100000) {
        
      }
    }
  }

`);

export const deleteOrganisationBundle = graphql(`
  mutation deleteOrganisationBundle($input: DeleteOrganisationBundleInput!) {
    deleteOrganisationBundle(input: $input) {
      id
    }
  }
`);

export const createBulkNotification = graphql(`
  mutation createBulkNotification($input: BulkNotificationInput!) {
    createBulkNotification(input: $input) {
      accepted
      failures {
        number
        message
      }
    }
  }
`);

export const sendContentToDistributionList = graphql(`
  mutation sendContentToDistributionList(
    $input: SendContentToDistributionListInput!
  ) {
    sendContentToDistributionList(input: $input) {
      id
    }
  }
`);

export const listOrganisation = graphql(`
  query ListOrganisation($limit: Int = 10000, $nextToken: String) {
    listOrganisation(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contentRestriction
        messageThrottle {
          limit
          frequency
        }
        licenses {
          items {
            id
            expiresOn
            active
          }
        }
      }
      nextToken
    }
  }
`);

export const listAdvice = graphql(`
  query ListAdvice($organisationId: ID, $limit: Int = 10, $nextToken: String) {
    listAdvice(
      listGlobal: true
      organisationId: $organisationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        displayName
        filename
        tags
        avStatus
        organisationId
        createdBy
      }
      nextToken
    }
  }
`);

export const listTags = graphql(`
  query listTags($id: ID!, $limit: Int = 10000, $nextToken: String) {
    getOrganisation(id: $id) {
      id
      distributionTags(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          contacts {
            items {
              id
            }
          }
        }
        nextToken
      }
    }
  }
`);

export const createOrganisation = graphql(`
  mutation createOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      id
      name
      contentRestriction
      licenses {
        items {
          id
          expiresOn
          active
        }
      }
    }
  }
`);

export const createUserOrganisationTrial = graphql(`
  mutation createUserOrganisationTrial($input: CreateOrganisationInput!) {
    createUserOrganisationTrial(input: $input) {
      userId
      organisationId
      organisation {
        id
        name
        contentRestriction
      }
    }
  }
`);

export const updateOrganisation = graphql(`
  mutation updateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      name
      contentRestriction
      messageThrottle {
        limit
        frequency
      }
      licenses {
        items {
          id
          expiresOn
          active
        }
      }
    }
  }
`);

export const updateUserOrganisationTrial = graphql(`
  mutation updateUserOrganisationTrial(
    $input: UpdateUserOganisationTrialInput!
  ) {
    updateUserOrganisationTrial(input: $input) {
      organisation {
        id
        name
        contentRestriction
        messageThrottle {
          limit
          frequency
        }
        licenses {
          items {
            id
            expiresOn
            active
          }
        }
      }
    }
  }
`);

export const createDistributionTag = graphql(`
  mutation createDistributionTag($input: CreateDistributionTagInput!) {
    createDistributionTag(input: $input) {
      id
      name
      description
      contacts {
        items {
          id
        }
      }
    }
  }
`);

export const updateDistributionTag = graphql(`
  mutation updateDistributionTag($input: UpdateDistributionTagInput!) {
    updateDistributionTag(input: $input) {
      id
      name
      description
    }
  }
`);

export const deleteDistributionTag = graphql(`
  mutation deleteDistributionTag($input: DeleteDistributionTagInput!) {
    deleteDistributionTag(input: $input) {
      id
    }
  }
`);

export const createDistributionContact = graphql(`
  mutation createDistributionContact($input: CreateDistributionContactInput!) {
    createDistributionContact(input: $input) {
      id
      name
      givenName
      familyName
      description
      phone
      userId
      consentConfirmedAt
      consentConfirmedBy
      tags {
        items {
          id
          name
        }
      }
    }
  }
`);

export const updateDistributionContact = graphql(`
  mutation updateDistributionContact($input: UpdateDistributionContactInput!) {
    updateDistributionContact(input: $input) {
      id
      name
      familyName
      givenName
      description
      phone
      consentConfirmedAt
      consentConfirmedBy
    }
  }
`);

export const deleteDistributionContact = graphql(`
  mutation deleteDistributionContact($input: DeleteDistributionContactInput!) {
    deleteDistributionContact(input: $input) {
      id
    }
  }
`);

export const tagDistributionContact = graphql(`
  mutation tagDistributionContact($input: TagDistributionContactInput!) {
    tagDistributionContact(input: $input) {
      id
    }
  }
`);

export const untagDistributionContact = graphql(`
  mutation untagDistributionContact($input: UntagDistributionContactInput!) {
    untagDistributionContact(input: $input) {
      id
    }
  }
`);

export const getDistributionTagContacts = graphql(`
  query getDistributionTag($id: ID!, $limit: Int = 10000, $nextToken: String) {
    distributionTag(tagId: $id) {
      id
      name
      contacts(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          givenName
          familyName
          description
          phone
          consentConfirmedAt
          consentConfirmedBy
          userId
          tags {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
    }
  }
`);

export const getSingleDistributionContact = graphql(`
  query getSingleDistributionContact($id: ID!) {
    distributionContact(id: $id) {
      id
      name
      description
      phone
      consentConfirmedAt
      consentConfirmedBy
      userId
      tags {
        items {
          id
          name
        }
      }
    }
  }
`);

export const getSingleAdviceInlineDownload = graphql(`
  query getSingleAdvice($id: ID!) {
    getAdvice(id: $id) {
      id
      displayName
      filename
      tags
      downloadUrl(inline: true)
      createdBy
      avStatus
      form
      patches
    }
  }
`);

export const getSingleAdvice = graphql(`
  query getSingleAdvice($id: ID!) {
    getAdvice(id: $id) {
      id
      displayName
      filename
      tags
      downloadUrl(inline: false)
      createdBy
      avStatus
    }
  }
`);

export const putSignedUrl = graphql(`
  mutation putSignedUrl($input: PutSignedUrlInput!) {
    putSignedUrl(input: $input) {
      url
      key
      metadata
    }
  }
`);

export const createAdvice = graphql(`
  mutation createAdvice($input: CreateStaticAdviceInput!) {
    createAdvice(input: $input) {
      id
      displayName
      filename
      tags
      message
      avStatus
      createdBy
      organisationId
    }
  }
`);

export const updateAdvice = graphql(`
  mutation updateAdvice($input: UpdateStaticAdviceInput!) {
    updateAdvice(input: $input) {
      id
      displayName
      filename
      tags
      message
      avStatus
      createdBy
      organisationId
    }
  }
`);

export const deleteAdvice = graphql(`
  mutation deleteAdvice($input: DeleteStaticAdviceInput!) {
    deleteAdvice(input: $input) {
      id
    }
  }
`);

export const addRole = graphql(`
  mutation createOrganisationUserRole(
    $input: CreateOrganisationUserRoleInput!
  ) {
    createOrganisationUserRole(input: $input) {
      userId
      organisationId
      role
    }
  }
`);

export const removeRole = graphql(`
  mutation deleteOrganisationUserRole(
    $input: DeleteOrganisationUserRoleInput!
  ) {
    deleteOrganisationUserRole(input: $input) {
      userId
      organisationId
      role
    }
  }
`);

export const createOrganisationRoleInvite = graphql(`
  mutation CreateOrganisationRoleInvite(
    $input: CreateOrganisationRoleInviteInput!
  ) {
    createOrganisationRoleInvite(input: $input) {
      id
      organisationId
      email
    }
  }
`);

export const deleteOrganisationRoleInvite = graphql(`
  mutation deleteOrganisationRoleInvite($id: ID!) {
    deleteOrganisationRoleInvite(input: { organisationRoleInviteId: $id }) {
      id
    }
  }
`);

export const search = graphql(`
  query Search($organisationId: String, $body: AWSJSON!) {
    search(searchGlobal: true, organisationId: $organisationId, body: $body)
  }
`);

export const searchSuggestion = graphql(`
  query SearchSuggestion($body: AWSJSON!) {
    querySuggestion(body: $body)
  }
`);

export const searchContacts = graphql(`
  query SearchContacts($organisationId: String!, $body: AWSJSON!) {
    searchContacts(organisationId: $organisationId, body: $body)
  }
`);

export const querySuggestionContacts = graphql(`
  query QuerySuggestionContacts($organisationId: String!, $body: AWSJSON!) {
    querySuggestionContacts(organisationId: $organisationId, body: $body)
  }
`);

export const initialiseUserContent = graphql(`
  mutation initialiseUserContent {
    initialiseUserContent {
      id
      content {
        items {
          staticAdvice {
            displayName
          }
        }
      }
    }
  }
`);

export const getUserContents = graphql(`
  query getUserContents($limit: Int = 10000, $nextToken: String) {
    getUser {
      content(limit: $limit, nextToken: $nextToken) {
        items {
          id
          userId
          staticAdviceId
          staticAdvice {
            filename
            displayName
            tags
            thumbnailUrl
            downloadUrl(inline: true)
            state
            form
            patches
          }
          organisationId
          organisation {
            name
          }
          createdAt
          sentByUserId
          sentByUser {
            title
            firstName
            lastName
          }
          __typename
        }
        nextToken
        startedAt
      }
    }
  }
`);

export const contentConnection = graphql(`
  query contentConnection(
    $order: SortOrder = desc
    $limit: Int = 10000
    $nextToken: String
  ) {
    contentConnection(order: $order, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        downloadUrl
        inlineUrl
        thumbnailUrl
        createdAt
        __typename
        tags
      }
      nextToken
    }
  }
`);

export const getOrganisations = graphql(`
  query GetCurrentUser {
    getUser {
      id
      organisationRoles(limit: 10000) {
        items {
          role
          organisation {
            id
            name
            contentRestriction
          }
        }
      }
    }
  }
`);

export const getUserById = graphql(`
  query getUserById($id: ID) {
    getUser(id: $id) {
      id
      title
      firstName
      lastName
    }
  }
`);

export const getCurrentUser = graphql(`
  query GetCurrentUser {
    getUser {
      id
      title
      firstName
      lastName
      dob
      biologicalSex
      genderIdentity
      religion {
        name
      }
      termsAndConditions {
        version
      }
      systemRoles {
        items {
          role
        }
      }
      organisationRoles(limit: 1) {
        items {
          role
        }
      }
      organisationRoleInvites {
        items {
          id
        }
      }
    }
  }
`);

export const getTsCs = graphql(`
  query GetTsCs {
    getTsCs {
      userId
      version
    }
  }
`);

export const createUserPushToken = graphql(`
  mutation CreateUserPushToken($input: CreateUserPushTokenInput!) {
    createUserPushToken(input: $input) {
      id
      userId
      token
    }
  }
`);

export const deleteUserPushToken = graphql(`
  mutation DeleteUserPushToken($input: DeleteUserPushTokenInput!) {
    deleteUserPushToken(input: $input) {
      id
    }
  }
`);

export const updateUserNotification = graphql(`
  mutation UpdateUserNotification($input: UpdateUserNotificationInput!) {
    updateUserNotification(input: $input) {
      id
    }
  }
`);

export const createOrganisationBundle = graphql(`
  mutation createOrganisationBundle($input: CreateOrganisationBundleInput!) {
    createOrganisationBundle(input: $input) {
      id
      name
      organisationId
      createdBy
      adviceIds
      advices {
        id
        displayName
        tags
        state
        downloadUrl(inline: true)
        createdBy
      }
    }
  }
`);

export const getUser = graphql(`
  query GetUser {
    getUser {
      id
      title
      firstName
      lastName
      dob
      biologicalSex
      genderIdentity
      religion {
        name
      }
      termsAndConditions {
        version
      }
      systemRoles {
        items {
          role
        }
      }
    }
  }
`);

export const getUserOrganisations = graphql(`
  query GetUser {
    getUser {
      id
      organisationRoles(limit: 10000) {
        items {
          role
          organisation {
            id
            name
            contentRestriction
            messageThrottle {
              limit
              frequency
            }
            licenses {
              items {
                id
                expiresOn
                active
              }
            }
          }
        }
      }
    }
  }
`);

export const getUserInvites = graphql(`
  query GetUser {
    getUser {
      id
      organisationRoleInvites {
        items {
          id
          email
          role
          organisation {
            id
            name
            contentRestriction
            messageThrottle {
              limit
              frequency
            }
            licenses {
              items {
                id
                expiresOn
                active
              }
            }
          }
        }
      }
    }
  }
`);

export const getUserNotifications = graphql(`
  query GetUser {
    getUser {
      id
      notifications(limit: 300) {
        items {
          id
          userId
          type
          title
          body
          data {
            adviceId
            submissionId
          }
          viewed
          createdAt
          createdBy
        }
      }
    }
  }
`);

export const getUserPushTokens = graphql(`
  query GetUser {
    getUser {
      id
      pushTokens(limit: 100) {
        items {
          id
          token
          userId
        }
      }
    }
  }
`);

export const getUserSubmissions = graphql(`
  query getUserSubmissions($id: ID) {
    getUser(id: $id) {
      id
      submissions(limit: 10000) {
        items {
          id
          userId
          staticAdviceId
          staticAdvice {
            id
            displayName
            form
            tags
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          version
          answers
          createdByUser {
            id
            title
            firstName
            lastName
          }
          pdfDownloadUrl
        }
      }
    }
  }
`);

export const updateUserSubmission = graphql(`
  mutation updateUserSubmission($input: UpdateUserSubmissionInput!) {
    updateUserSubmission(input: $input) {
      answers
    }
  }
`);

export const getUserSubmission = graphql(`
  query getUserSubmission($id: ID!) {
    getUserSubmission(id: $id) {
      id
      userId
      staticAdviceId
      staticAdvice {
        id
        displayName
        form
        tags
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      version
      answers
    }
  }
`);

export const getContactActivity = graphql(`
  query getContactActivity($id: ID!, $nextToken: String) {
    contact(id: $id) {
      content(nextToken: $nextToken) {
        nextToken
        items {
          id
          sentAt
          submission {
            id
            updatedAt
            answers
            results
            pdfDownloadUrl
            staticAdvice {
              id
              displayName
              form
              tags
            }
          }
          content {
            id
            displayName
          }
        }
      }
    }
  }
`);

export const getSubmissionData = graphql(`
    query getSubmissionDataQuery($id: ID!){
        getUserSubmission(id: $id) {
            id
            updatedAt
            answers
            results
            pdfDownloadUrl
            createdByUser {
                id
                firstName
                lastName
                title
            }
            staticAdvice {
                id
                displayName
                form
                tags
            }
        }
    }
`);

export const contactByUserIdOrgId = graphql(`
  query contactByUserIdOrgId($organisationId: ID!, $userId: ID!) {
    contactByUserIdOrgId(userId: $userId, organisationId: $organisationId) {
      id
      name
    }
  }
`);

export const createContent = graphql(`
  mutation createContent($input: CreateContentInput!) {
    createContent(input: $input) {
      content {
        name
      }
    }
  }
`);

export const deleteContent = graphql(`
  mutation deleteContent($input: DeleteContentInput!) {
    deleteContent(input: $input) {
      contentId
    }
  }
`);

export const getActivityByList = graphql(`
  query getActivityByList($listid: ID!) {
    distributionTag(tagId: $listid) {
      activity {
        items {
          id
          sentAt
          content {
            displayName
            id
            downloadUrl(inline: true)
            tags
          }
          submissionCount {
            complete
            total
          }
        }
      }
    }
  }
`);

export const addContentBundleToContactList = graphql(`
  mutation addContentBundleToContactList(
    $input: AddContentBundleToContactListInput!
  ) {
    addContentBundleToContactList(input: $input) {
      id
    }
  }
`);

export const getActivityByDistributionList = graphql(`
  query getActivityByDistributionList($id: ID!) {
    distributionTag(tagId: $id) {
      activity {
        items {
          id
          sentAt
          content {
            displayName
            id
            downloadUrl(inline: true)
            tags
          }
          submissionCount {
            complete
            total
          }
          activity {
            items {
              contact {
                id
                givenName
                familyName
              }
              id
              submission {
                id
                answers
              }
            }
          }
        }
      }
    }
  }
`);
