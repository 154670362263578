import React from "react";
import { View } from "react-native";
import { Button, FieldLabel, TextInput } from "../../../components";
import { currentOrganisationAtom, useAtom } from "../../../atoms";

import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { createOrganisationBundle } from "../../../graphql";

export default function ({ onClose, onSave }) {
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const { control, handleSubmit, formState: {errors}, setError } = useForm({
    defaultValues: { bundleName: "" },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ bundleName }) => {
      return createOrganisationBundle({
        input: {
          name: bundleName.trim(),
          organisationId: currentOrganisation.id,
          adviceIds: [],
        },
      })
        .then((res) => res.data.createOrganisationBundle)
        .catch((err) => {
          const message = err.errors?.[0]?.message;
          setError("name", {
            type: "duplicate",
            message: "This bundle name is linked to an existing bundle",
          });
          if (message) {
            throw new IOWNAError("There is an error");
          } else {
            throw new Error(JSON.stringify(err));
          }
        });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([currentOrganisation.id, "bundles"]);
        onSave();

        onClose();
      },
    }
  );

  return (
    <View>
      <FieldLabel>Name</FieldLabel>
      <TextInput
        name="bundleName"
        width="100%"
        control={control}
        errors={errors}
        rules={{
          required: "A bundle name is required",
          minLength: {
            value: 2,
            message: "A bundle name must be at least 2 characters long",
          },
          maxLength: {
            value: 256,
            message: "A bundle cannot be longer than 256 characters long",
          },
        }}
      />

      <View
        style={{
          marginTop: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      ></View>
      <View
        style={{
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          loading={mutation.isLoading}
          onPress={handleSubmit(mutation.mutate)}
        >
          Create
        </Button>
      </View>
    </View>
  );
}
