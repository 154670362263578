import { useCallback, useEffect, useMemo, useState } from "react";
import * as Updates from "expo-updates";
import { Platform } from "react-native";
import Constants from "expo-constants";
import analytics from "./analytics";
import * as Notifications from "expo-notifications";
import { format } from "date-fns";
import { get } from "lodash";
import Sentry from "../sentry";
import {
  interpolate,
  refreshCurrentSession,
  verifiedContact,
  transformRole,
  getErrorMessage,
} from "./utils";
import {
  getUser,
  getUserNotifications,
  getUserInvites,
  getUserPushTokens,
  getUserById,
  getUserSubmission,
  getUserSubmissions,
  getSingleAdviceInlineDownload, 
  updateUserNotification,
  rejectInvite,
  acceptInvite,
  getUserOrganisations,
} from "./graphql";

export type Updater<S> = (updater: S | ((oldValue: S) => S)) => void;

export type Atom<S> = {
  getValue: () => S;
  subscribe: (listener: (value: S) => void) => () => void;
  update: Updater<S>;
};

export type AtomGetter = <V>(atom: Atom<V>) => V;
export type DerivedAtomReader<S> = (read: AtomGetter) => S | Promise<S>;

export const atom = <S>(
  initial: S | DerivedAtomReader<S>,
  { lazy = false, deps = [] } = {}
): Atom<S> => {
  let value;
  const isDerived = typeof initial === "function";
  const isAsync = isDerived && initial.constructor.name === "AsyncFunction";
  const subs = new Set<(value: S) => void>();
  const promises = [];
  let ref = null;
  const compute = () => {
    if (isAsync) {
      // @ts-ignore
      value = initial(get);
      subs.forEach((sub) => {
        sub(value);
      });
      value
        .catch((err) => err)
        .then((res) => {
          ref = null;
          value = res;
          subs.forEach((sub) => {
            sub(value);
          });
        });
      // promises.push(value);
      // // order the promises and get the last one
      // // so that we don't end up updating to a previous but delayed one
      // if (!ref) {
      //   ref = setTimeout(() => {
      //     promises
      //       .pop()
      //       .catch((err) => err)
      //       .then((res) => {
      //         ref = null;
      //         value = res;
      //         console.log('value', value);
      //         subs.forEach((sub) => {
      //           sub(value);
      //         });
      //       });
      //   }, 100);
      // }
    } else {
      // @ts-ignore
      value = initial(get);
      subs.forEach((sub) => {
        sub(value);
      });
    }
  };
  const get = <A>(a: Atom<A>) => {
    a.subscribe(compute);
    return a.getValue();
  };
  if (isDerived) {
    if (lazy) {
      deps.forEach((dep) => {
        dep.subscribe(compute);
      });
    } else {
      compute();
    }
  } else {
    value = initial;
  }
  return {
    getValue(): S {
      return value;
    },
    subscribe(fn) {
      subs.add(fn);
      return () => subs.delete(fn);
    },
    async update(updater: S | ((oldValue: S) => S)) {
      if (updater instanceof Function) {
        value = updater(value instanceof Promise ? await value : value);
      } else {
        value = updater;
      }
      subs.forEach((sub) => {
        sub(value);
      });
    },
  };
};

const atomDerived = <S>(
  deps: Array<Atom<any>>,
  initial: DerivedAtomReader<S>
): Atom<S> => {
  return atom(initial, { lazy: true, deps });
};

const promiseCache = new Map();
export const useAtom = <S>(atom: Atom<S>): S => {
  const [cache, setCache] = useState<S>(atom.getValue());
  useEffect(() => {
    const unsub = atom.subscribe((value) => {
      promiseCache.delete(cache);
      setCache(value);
    });
    return unsub;
  }, [atom, cache]);
  if (cache instanceof Promise) {
    cache
      .then((res) => {
        promiseCache.set(cache, { res });
      })
      .catch((err) => {
        promiseCache.set(cache, { err });
      });
    const v = promiseCache.get(cache);
    if (v && "err" in v) {
      throw v.err;
    } else if (v && "res" in v) {
      return v.res;
    } else {
      throw cache;
    }
  }
  return cache;
};

export const useNewAtom = <S>(fn: () => Promise<S>, params: any): S => {
  const cb = useCallback(fn, [params]);
  return useAtom<S>(useMemo(() => atom(cb), [cb]));
};

const usePromiseCache: Map<Function, Map<string, any>> = new Map();
export const usePromise = (fn, params, field) => {
  if (!params) {
    return null;
  }
  const fnCache = usePromiseCache.get(fn) || new Map();
  if (!usePromiseCache.get(fn)) {
    usePromiseCache.set(fn, fnCache);
  }
  const key = typeof params === "string" ? params : JSON.stringify(params);
  const value = fnCache.get(key);
  if (value) {
    if (value instanceof Promise) {
      throw value;
    } else if (value instanceof Error || value?.errors) {
      throw value;
    }
    if (field) {
      return get(value, field, null);
    } else {
      return value;
    }
  }

  fnCache.set(
    key,
    fn(params)
      .then((res) => fnCache.set(key, res))
      .catch((err) => fnCache.set(key, err))
  );
  throw fnCache.get(key);
};

type Alert = {
  isVisible: Boolean;
  title: String;
  msg: String;
  onConfirm?: Function;
  onCancel?: Function;
};

type User = {
  id: String;
  title: String;
  firstName: String;
  lastName: String;
};

type Organisation = {
  id: String;
  name: String;
  roles?: Array<String>;
};

type UserInvite = {
  id: String;
  role: String;
  organisation: Organisation;
};

type PdfScreenData = {
  url: String;
  form: Object;
};

type StaticAdvice = {
  id: String;
  displayName: String;
  form: any;
};

type Bundle = {
  id: String;
  name: String;
  items: Array<StaticAdvice>;
};

type Tag = {
  id: String;
  name: String;
};

type BundleAdvices = {
  id: String;
  adviceIds: Array<String>;
};
const handleError = (fn: Function, cleanup?: Function) => {
  return async (...params: any) => {
    try {
      const res = await fn(...params);
      if (cleanup) {
        cleanup();
      }
      resetAlert();
      return res;
    } catch (err) {
      const msg = getErrorMessage(err);
      if (msg === "") {
        Sentry.withScope((scope: any) => {
          scope.setExtras({ params, error: JSON.stringify(err), err });
          Sentry.captureException(err);
        });
        alert("Error", "Oops something went wrong");
      } else {
        alert("Error", msg);
      }
      if (cleanup) {
        cleanup();
      }
    }
  };
};

type Config = {
  release: {
    buildNumber: String;
  };
  versionCode: Number;
  isNative: Boolean;
  changes: Array<String>;
};

type RoleType = "professional" | "personal";

export const configAtom = atom<Config>(null);
export const currentUserIdAtom = atom("");
export const currentNavigatorAtom = atom("");
export const currentRoleTypeAtom = atom<RoleType>("personal");
export const alertAtom = atom<Alert>({ isVisible: false, title: "", msg: "" });
export const otherUserIdAtom = atom("");
export const pdfIdAtom = atom("");
export const bundleAtom = atom<Bundle>({ id: "", name: "", items: [] });
export const bundleAdvicesAtom = atom<BundleAdvices>({ id: "", adviceIds: [] });
export const selectedTagAtom = atom<Tag>({ id: "", name: "" });
export const selectedContactAtom = atom<string>("");
export const submissionIdAtom = atom("");
export const currentOrganisationAtom = atom<Organisation>(null);
export const editorInstanceAtom = atom(null);
export const filenameAtom = atom("Untitled");
export const loadingModalAtom = atom({ show: false, title: "" });
export const currentUserSystemRolesAtom = atom<Array<String>>([]);
export const isWebAtom = atom(Platform.OS === "web");
export const showModalAtom = atom(false);
export const showContactsModalAtom = atom(false);
// @ts-ignore
export const sendContactList = atom<SendContactList>({ id: "" });

export const setShowModal = (v: boolean) => showModalAtom.update(v);
export const setShowContactsModal = (v: boolean) =>
  showContactsModalAtom.update(v);

export const setTag = (t: Tag) => selectedTagAtom.update(t);
export const setContact = (t: string) => selectedContactAtom.update(t);

export const setIsWeb = (p: boolean) => isWebAtom.update(p);

export const setCurrentUserSystemRoles = (roles: Array<String>) =>
  currentUserSystemRolesAtom.update(roles);

export const setCurrentOrganisation = (org: Organisation) =>
  currentOrganisationAtom.update(org);

export const setConfig = (config: Config) => configAtom.update(config);

export const setFilename = (name: string) => filenameAtom.update(name);

export const setEditorInstance = (v: any) => editorInstanceAtom.update(v);

export const showLoadingModal = (title: string) =>
  loadingModalAtom.update({ show: true, title });

export const hideLoadingModal = () =>
  loadingModalAtom.update({ show: false, title: "" });

export const setCurrentUserId = (id: string) => currentUserIdAtom.update(id);

export const setCurrentRoleType = (v: RoleType) =>
  currentRoleTypeAtom.update(v);

export const setCurrentNavigator = (nav: string) =>
  currentNavigatorAtom.update(nav);

export const setPdfId = (id: string) => {
  pdfIdAtom.update(id);
};

export const setBundle = (params: Bundle) => bundleAtom.update(params);

export const setBundleAdvices = (params: BundleAdvices) =>
  bundleAdvicesAtom.update(params);

export const setSubmissionId = (v: string) => submissionIdAtom.update(v);

export const alert = (title: string, msg: string, onConfirm?: Function) =>
  alertAtom.update({ isVisible: true, title, msg, onConfirm });

export const confirm =
  (title: string, msg: string, fn: Function) =>
  async (...args) =>
    alertAtom.update({
      isVisible: true,
      title,
      msg,
      onConfirm: () => fn(...args),
    });

export const confirmAsync = (title: string, msg: string) => {
  return new Promise((res) => {
    alertAtom.update({
      isVisible: true,
      title,
      msg,
      onCancel: () => res(false),
      onConfirm: () => {
        res(true);
        return true;
      },
    });
  });
};

export const resetAlert = () =>
  alertAtom.update((v) => ({ ...v, isVisible: false }));

export const pdfScreenDataAtom = atomDerived<PdfScreenData>(
  [pdfIdAtom],
  async (get) => {
    const id = get(pdfIdAtom);

    const res = await getSingleAdviceInlineDownload({ id: id });

  
    return {
      id: id,
      filename: res.data.getAdvice.filename,
      displayName: res.data.getAdvice.displayName,
      url: res.data.getAdvice.downloadUrl,
      form: res.data.getAdvice.form
        ? JSON.parse(res.data.getAdvice.form)
        : null,
    };
  }
);

export const forceQAAtom = atom(false);
export const isQAAtom = atom((get) => {
  const enforceQA = get(forceQAAtom);
  if (enforceQA) {
    return true;
  }
  if (Platform.OS === "web") {
    if (window.location.origin.indexOf("https://app.iowna.com") > -1) {
      return false;
    }
  } else {
    if (Updates.channel === "master") {
      return false;
    }
  }
  return true;
});

export const currentUserAtom = atom<User>(async (get) => {
  const currentUserId = get(currentUserIdAtom);
  if (currentUserId) {
    const res = await getUser();
    const user = res.data.getUser;
    const data = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    Sentry.configureScope((scope) => {
      scope.setUser(data);
    });
    if (!isQAAtom.getValue()) {
      analytics.setUserId(user.id);
      analytics.setUserProperties(data);
    }
    setCurrentUserSystemRoles(user.systemRoles.items.map((item) => item.role));
    return user;
  }
  return { id: "", title: "", firstName: "", lastName: "" };
});

// @ts-ignore
export const userByIdAtom = atom<User>(async (get) => {
  const otherUserId = get(otherUserIdAtom);
  if (otherUserId) {
    const res = await getUserById({ id: otherUserId });
    const user = res.data.getUser;
    return {
      id: user.id,
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
    };
  }
  // TODO: allow null returns
  return {};
});

export const organisationsRolesAtom = atom(async (get) => {
  const currentUserId = get(currentUserIdAtom);
  if (currentUserId) {
    const res = await getUserOrganisations();
    return res.data.getUser.organisationRoles.items;
  }
  return [];
});

export const organisationsTransformedAtom = atom(async (get) => {
  const organisationsRoles = await get(organisationsRolesAtom);
  const organisationsMap = {};
  organisationsRoles.forEach(({ role, organisation }) => {
    const org = organisationsMap[organisation.id];
    if (!org) {
      organisationsMap[organisation.id] = { ...organisation, roles: [] };
    }
    organisationsMap[organisation.id].roles.push(role);
  });
  return Object.keys(organisationsMap).map((id) => organisationsMap[id]);
});

export const hasOrganisationsAtom = atom<Boolean>(async (get) => {
  const orgs = await get(organisationsRolesAtom);
  return orgs.length > 0;
});

type UserNotification = {
  id: String;
  viewed: Boolean;
};

export const userNotificationsAtom = atom<Array<UserNotification>>(
  async (get) => {
    const currentUserId = get(currentUserIdAtom);
    if (currentUserId) {
      const res = await getUserNotifications();
      return res.data.getUser.notifications.items;
    }
    return [];
  }
);

export const addPushNotification = (item, viewed = false) => {
  userNotificationsAtom.update((notifications) => {
    return [
      {
        ...item,
        viewed,
      },
    ].concat(notifications);
  });
};

export const markNotification = async ({ id, viewed }: UserNotification) => {
  if (viewed === false) {
    updateUserNotification({
      input: {
        id: id,
        viewed: true,
      },
    });
    userNotificationsAtom.update((notifications) => {
      return notifications.map((item) => {
        if (item.id === id) {
          return { ...item, viewed: true };
        }
        return item;
      });
    });
    const badgeCount = await Notifications.getBadgeCountAsync();
    if (badgeCount !== 0) {
      Notifications.setBadgeCountAsync(badgeCount - 1);
    }
  }
};

export const userInvitesAtom = atom<Array<UserInvite>>(async (get) => {
  const currentUserId = get(currentUserIdAtom);
  if (currentUserId) {
    const res = await getUserInvites();
    return res.data.getUser.organisationRoleInvites.items;
  }
  return [];
});

export const rejectUserInvites = handleError(async (ids) => {
  userInvitesAtom.update((invites) =>
    invites.filter((item) => !ids.includes(item.id))
  );
  for (const id of ids) {
    await rejectInvite({ id: id });
  }
});

export const acceptUserInvites = handleError(async (ids) => {
  showLoadingModal("Accepting invites");
  for (const id of ids) {
    await acceptInvite({ id: id });
  }
  refreshCurrentSession();
  const invites = (await userInvitesAtom.getValue()).filter((item) =>
    ids.includes(item.id)
  );
  organisationsRolesAtom.update((v) => v.concat(invites));
  userInvitesAtom.update((invites) =>
    invites.filter((item) => !ids.includes(item.id))
  );
}, hideLoadingModal);

export const userInvitesTransformedAtom = atom(async (get) => {
  const invites = await get(userInvitesAtom);
  const invitationsMap: any = {};
  invites.forEach((invite) => {
    const key = invite.organisation.id;
    // @ts-ignore
    invitationsMap[key] = invitationsMap[key] || {
      id: key,
      organisationName: invite.organisation.name,
      ids: [],
      roles: [],
    };
    // @ts-ignore
    invitationsMap[key].ids.push(invite.id);
    // @ts-ignore
    invitationsMap[key].roles.push(invite.role);
  });
  return Object.keys(invitationsMap)
    .map((key) => invitationsMap[key])
    .map((item) => ({
      id: item.id,
      type: "invite",
      title: "You have been invited",
      body: `You have been invited to ${
        item.organisationName
      } with roles ${item.roles.map((role) => transformRole(role)).join(", ")}`,
      data: item,
      viewed: false,
      createdAt: null,
      createdBy: null,
    }));
});

export const userNotificationsCountAtom = atom(async (get) => {
  const notifications = await get(userNotificationsAtom);
  const invites = await get(userInvitesTransformedAtom);
  return notifications.filter((item) => !item.viewed).length + invites.length;
});

export const userPushTokensAtom = atom(async (get) => {
  const currentUserId = get(currentUserIdAtom);
  if (currentUserId) {
    const res = await getUserPushTokens();
    return res.data.getUser.pushTokens.items;
  }
  return [];
});

export const userSubmissionsAtom = atom(async (get) => {
  const currentUserId = get(currentUserIdAtom);
  if (currentUserId) {
    const res = await getUserSubmissions({ id: currentUserId });
    return res.data.getUser.submissions.items;
  }
  return [];
});

export const otherUserSubmissionsAtom = atom(async (get) => {
  const otherUserId = get(otherUserIdAtom);
  if (otherUserId) {
    const res = await getUserSubmissions({ id: otherUserId });
    return res.data.getUser.submissions.items;
  }
  return [];
});

export const transformSubmission = (item, isOther) => {
  const answers = item.answers ? JSON.parse(item.answers) : {};
  const form = JSON.parse(item.staticAdvice.form);
  const data = [];
  if (item.updatedAt) {
    form?.pages?.forEach((page) => {
      if (page.show) {
        const result = interpolate(page.show, { ...answers, completed: true });
        if (result === "false") {
          return;
        }
      }
      page.questions.forEach((question) => {
        if (question.id) {
          let ans = answers[question.id];
          if (question.type === "radio") {
            ans = question.choices
              .filter((choice) =>
                Array.isArray(ans) ? ans.includes(choice.id) : ans === choice.id
              )
              .map((selectedChoices) => selectedChoices.name);
          }
          data.push([question.shortName || question.title, ans]);
        }
      });
    });
  }
  const results = form?.results || [];
  return {
    ...item,
    title: form.title,
    answers: answers,
    tableResults: data,
    results: item.updatedAt
      ? results.map((result) => ({
          label: result.label,
          value: interpolate(result.value, answers),
        }))
      : [],
  };
};

export const userSubmissionsResultsAtom = atom(async (get) => {
  const submissions = await get(userSubmissionsAtom);
  return submissions.map((item) => transformSubmission(item, false));
});

export const otherUserSubmissionsResultsAtom = atom(async (get) => {
  const submissions = await get(otherUserSubmissionsAtom);
  return submissions.map((item) => transformSubmission(item, true));
});

export const getUserSubmissionAtom = atomDerived(
  [submissionIdAtom],
  async (get) => {
    const submissionId = get(submissionIdAtom);
    if (submissionId) {
      try {
        const res = await getUserSubmission({ id: submissionId });
        return res.data.getUserSubmission;
      } catch (err) {
        // console.log("err", err);
        return null;
      }
    }
    return null;
  }
);

export const isUserContactVerifiedAtom = atom(async (get) => {
  const currentUserId = get(currentUserIdAtom);
  if (currentUserId) {
    // @ts-ignore
    return (await verifiedContact()).verified.phone_number;
  }
  return false;
});

export const setUserContactVerified = (success: Boolean) =>
  isUserContactVerifiedAtom.update(success);

export const userTodosTransformedAtom = atom(async (get) => {
  const submissions = await get(userSubmissionsAtom);
  const user = await get(currentUserAtom);
  const isPhoneNumberVerified = await get(isUserContactVerifiedAtom);

  const bonuses = [
    {
      id: "complete-profile",
      type: "complete-profile",
      title: "Complete your profile",
      description: "",
      important: user.firstName ? false : true,
      createdAt: null,
      createdBy: "system-user-id",
      done: !!user.firstName,
      staticAdvice: null,
      createdByUser: null,
    },
    {
      id: "verify-phone-number",
      type: "verify-phone-number",
      title: "Verify your phone number",
      description: "",
      important: isPhoneNumberVerified ? false : true,
      createdAt: null,
      createdBy: "system-user-id",
      done: isPhoneNumberVerified,
      staticAdvice: null,
      createdByUser: null,
    },
  ];

  return [
    ...bonuses,
    ...submissions.map((item: any) => ({
      id: item.id,
      type: "questionnaire",
      title: "Complete Form",
      important: false,
      description: item.staticAdvice?.displayName || "",
      createdAt: item.createdAt,
      createdBy: item.createdBy,
      done: !!item.answers,
      staticAdvice: item.staticAdvice,
      createdByUser: item.createdByUser,
    })),
  ];
});

export const userTodosCountAtom = atom(async (get) => {
  const todos = await get(userTodosTransformedAtom);
  return todos.filter((item) => !item.done).length;
});
