import React from 'react';
import { Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import BottomTabItem from '@react-navigation/bottom-tabs/src/views/BottomTabItem';

const TabButton = ({ icon, label, isCenter = false, tabItemStyle = {}, ...props }) => {
  const selectedColor = props.children.props.children[1].props.style[1].color;
  const centerStyle = isCenter
    ? {}
    : {
        borderLeftWidth: 1,
        borderLeftColor: '#d6d4db',
        borderRightWidth: 1,
        borderRightColor: '#d6d4db',
      };
  return (
    <BottomTabItem
      {...props}
      style={{
        ...tabItemStyle,
        borderTopWidth: 1,
        borderTopColor: selectedColor,
        ...centerStyle,
      }}
      icon={({ focused, size }) => (typeof icon === 'string' ? <Ionicons name={icon} size={size} color={selectedColor} /> : icon({ selectedColor }))}
      labelStyle={{ color: selectedColor }}
      label={label}
      testID={`test${label}`}
      accessibilityLabel={Platform.select({android: `test${label}`})}
    />
  );
};

export default TabButton;
