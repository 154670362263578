import type { ComponentProps } from "react";
import { Meta, StoryObj } from "@storybook/react-native";
import { useState } from "react";
import { ScrollView, View, Text } from "react-native";
import {OGFormNavigator as FormScreen} from "./FormScreen";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { QueryClient, QueryClientProvider } from "react-query";

const FS = (props: ComponentProps<typeof FormScreen>) => {
  return (
    <>
      <FormScreen {...props} />
    </>
  );
};

const StoryBookStack = createStackNavigator();

const meta: Meta<typeof FS> = {
  title: "FormScreen",
  component: FS,
  argTypes: {
    onChange: { action: "onChange called" },
  },
  args: {
    route: {
      params: {
        id: "newId",
        pages: [
          {
            name: "Step0",
            questions: [
              {
                inputType: "date",
                id: "date",
                type: "input",
                shortName: "Date",
                title: "Date",
                required: true,
              },
              {
                fontSize: "large",
                type: "text",
                title: "BASDAI",
              },
              {
                fontSize: "normal",
                type: "text",
                title:
                  "Please read each question and tick the box you feel is the most appropriate to describe how severe your condition has been in this area. ",
              },
              {
                fontSize: "normal",
                type: "text",
                title:
                  "Each question relates to how you have felt IN THE PAST WEEK. There is no wrong answer. ",
              },
            ],
          },
          {
            name: "Step1",
            questions: [
              {
                orientation: "horizontal",
                ranges: [
                  {
                    fromLabel: " ",
                    toLabel: "None",
                    color: "green",
                    from: 0,
                    to: 1,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "None",
                    toLabel: " ",
                    color: "green",
                    from: 1,
                    to: 5,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: " ",
                    toLabel: "Very severe",
                    color: "tomato",
                    from: 5,
                    to: 9,
                    smiley: "😕",
                  },
                  {
                    fromLabel: "Very severe",
                    toLabel: " ",
                    color: "tomato",
                    from: 9,
                    to: 10,
                    smiley: "😕",
                  },
                ],
                id: "a",
                type: "slider",
                title:
                  "How would you describe the overall level of fatigue or tiredness you have experienced?",
                required: true,
              },
            ],
          },
          {
            name: "Step2",
            questions: [
              {
                orientation: "horizontal",
                ranges: [
                  {
                    fromLabel: " ",
                    toLabel: "None",
                    color: "green",
                    from: 0,
                    to: 1,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "None",
                    toLabel: " ",
                    color: "green",
                    from: 1,
                    to: 5,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: " ",
                    toLabel: "Very severe",
                    color: "tomato",
                    from: 5,
                    to: 9,
                    smiley: "😕",
                  },
                  {
                    fromLabel: "Very severe",
                    toLabel: " ",
                    color: "tomato",
                    from: 9,
                    to: 10,
                    smiley: "😕",
                  },
                ],
                id: "b",
                type: "slider",
                title:
                  "How would you describe the overall level of AS neck, back or hip pain you have had?",
                required: true,
              },
            ],
          },
          {
            name: "Step3",
            questions: [
              {
                orientation: "horizontal",
                ranges: [
                  {
                    fromLabel: " ",
                    toLabel: "None",
                    color: "green",
                    from: 0,
                    to: 1,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "None",
                    toLabel: " ",
                    color: "green",
                    from: 1,
                    to: 5,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: " ",
                    toLabel: "Very severe",
                    color: "tomato",
                    from: 5,
                    to: 9,
                    smiley: "😕",
                  },
                  {
                    fromLabel: "Very severe",
                    toLabel: " ",
                    color: "tomato",
                    from: 9,
                    to: 10,
                    smiley: "😕",
                  },
                ],
                id: "c",
                type: "slider",
                title:
                  "How would you describe the overall level of pain or swelling in joints other than the neck, back or hips?",
                required: true,
              },
            ],
          },
          {
            name: "Step4",
            questions: [
              {
                orientation: "horizontal",
                ranges: [
                  {
                    fromLabel: " ",
                    toLabel: "None",
                    color: "green",
                    from: 0,
                    to: 1,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "None",
                    toLabel: " ",
                    color: "green",
                    from: 1,
                    to: 5,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: " ",
                    toLabel: "Very severe",
                    color: "tomato",
                    from: 5,
                    to: 9,
                    smiley: "😕",
                  },
                  {
                    fromLabel: "Very severe",
                    toLabel: " ",
                    color: "tomato",
                    from: 9,
                    to: 10,
                    smiley: "😕",
                  },
                ],
                id: "d",
                type: "slider",
                title:
                  "How would you describe the overall level of discomfort you have from any tender areas to touch or pressure?",
                required: true,
              },
            ],
          },
          {
            name: "Step5",
            questions: [
              {
                orientation: "horizontal",
                ranges: [
                  {
                    fromLabel: " ",
                    toLabel: "None",
                    color: "green",
                    from: 0,
                    to: 1,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "None",
                    toLabel: " ",
                    color: "green",
                    from: 1,
                    to: 5,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: " ",
                    toLabel: "Very severe",
                    color: "tomato",
                    from: 5,
                    to: 9,
                    smiley: "😕",
                  },
                  {
                    fromLabel: "Very severe",
                    toLabel: " ",
                    color: "tomato",
                    from: 9,
                    to: 10,
                    smiley: "😕",
                  },
                ],
                id: "e",
                type: "slider",
                title:
                  "How would you describe the overall level of morning stiffness you have from the time you wake up?",
                required: true,
              },
            ],
          },
          {
            name: "Step6",
            questions: [
              {
                orientation: "horizontal",
                ranges: [
                  {
                    fromLabel: " ",
                    toLabel: "None",
                    color: "green",
                    from: 0,
                    to: 1,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "None",
                    toLabel: "1hr",
                    color: "green",
                    from: 1,
                    to: 5,
                    smiley: "🙂",
                  },
                  {
                    fromLabel: "1hr",
                    toLabel: "≥2hrs",
                    color: "tomato",
                    from: 5,
                    to: 9,
                    smiley: "😕",
                  },
                  {
                    fromLabel: "≥2hrs",
                    toLabel: " ",
                    color: "tomato",
                    from: 9,
                    to: 10,
                    smiley: "😕",
                  },
                ],
                id: "f",
                type: "slider",
                title:
                  "How long does your morning stiffness last from the time you wake up? (0 = none, 10 = very severe)",
                required: true,
              },
            ],
          },
          {
            name: "Step7",
            show: "${completed === false}",
            questions: [
              {
                fontSize: "large",
                type: "text",
                title: "You have now completed this questionnaire.",
              },
              {
                fontSize: "normal",
                type: "text",
                title:
                  'If you would like to change any of your answers, you may do so by pressing the "Back" button prior to finalizing the questionnaire.',
              },
              {
                fontSize: "normal",
                type: "text",
                title:
                  'Please finalise the questionnaire by pressing the "Finalise" button. Once you press ”Finalise", you will not be able to go back to review or change your answers.',
              },
            ],
            buttonRight: "Finalise",
          },
          {
            name: "Step8",
            show: "${completed === false}",
            questions: [
              {
                fontSize: "large",
                type: "text",
                title: "Thank you for completing this questionnaire.",
              },
              {
                fontSize: "large",
                type: "text",
                title: "Your clinician will now be notified.",
              },
            ],
            buttonRight: "Done",
          },
        ],
        currentIndex: 0,
        page: {
          name: "Step0",
          questions: [
            {
              inputType: "date",
              id: "date",
              type: "input",
              shortName: "Date",
              title: "Date",
              required: true,
            },
            {
              fontSize: "large",
              type: "text",
              title: "BASDAI",
            },
            {
              fontSize: "normal",
              type: "text",
              title:
                "Please read each question and tick the box you feel is the most appropriate to describe how severe your condition has been in this area. ",
            },
            {
              fontSize: "normal",
              type: "text",
              title:
                "Each question relates to how you have felt IN THE PAST WEEK. There is no wrong answer. ",
            },
          ],
        },
        version: "v1.0.0",
        readOnly: false,
        initialQuestionNumber: 1,
        submittable: false,
      },
    },
  },
  decorators: [
    (Story) => {
      const Screen = () => Story();
      return (
        <QueryClientProvider client={new QueryClient()}>
          <NavigationContainer independent={true}>
            <StoryBookStack.Navigator>
              <StoryBookStack.Screen
                name="MyStoryBookScreen"
                component={Screen}
                options={{ header: () => null }}
              />
              {/* <View>
              <ScrollView>
                <Story />
              </ScrollView>
            </View> */}
            </StoryBookStack.Navigator>
          </NavigationContainer>
        </QueryClientProvider>
      );
    },
  ],
};

export default meta;
type Story = StoryObj<typeof FS>;

export const FormScreenStory: Story = {
  name: "FormScreen",
};
