import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Button, TextInput, ErrorMessage, AuthScreen } from '../../components';
import { Title, Text, Field, FieldLabel, ButtonContainer, Footer, FooterValue } from './common';
import { forgotPassword } from '../../utils';
import { useForm } from '../../hooks';

export default ({ navigation }) => {
  const { control, isSubmitting, onSubmit, error, errors } = useForm({
    schema: (yup) => ({
      email: yup.string().required('Please enter your email').email('This is not a valid email'),
    }),
    onSubmit: async ({ email }) => {
      await forgotPassword({ email: email.toLowerCase() });
      navigation.navigate('ResetPasswordScreen', { email: email.toLowerCase() });
    },
  });
  return (
    <AuthScreen>
      <Title>
        <Text>Forgot Password</Text>
      </Title>
      <Field>
        <FieldLabel>EMAIL</FieldLabel>
        <TextInput name="email" errors={errors} control={control} />
      </Field>
      <ErrorMessage err={error} />
      <ButtonContainer>
        <Button loading={isSubmitting} onPress={onSubmit}>
          SUBMIT
        </Button>
      </ButtonContainer>
      <Footer style={{ marginTop: 20 }}>
        <TouchableOpacity onPress={navigation.goBack}>
          <FooterValue>BACK</FooterValue>
        </TouchableOpacity>
      </Footer>
    </AuthScreen>
  );
};
