import React from "react";
import { View } from "react-native";
import { currentOrganisationAtom, useAtom } from "../../../atoms";
import { createDistributionTag } from "../../../graphql";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { FieldLabel, TextInput, Button } from "../../../components";

export default function ({ onClose, onSave, tagName }) {
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    formState,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: { name: tagName ? tagName : "" },
  });

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const mutation = useMutation(
    ({ name }) =>
      createDistributionTag({
        input: {
          organisationId: currentOrganisation.id,
          name: name.trim(),
        },
      }).then(async (res) => {
        await sleep(1000);
        return res;
      }),
    {
      onMutate: () => {},

      onSuccess: ({ data }) => {
        queryClient.invalidateQueries({
          queryKey: [currentOrganisation.id, "distributionLists"],
        });

        onSave();
        onClose();
      },
    }
  );

  return (
    <View>
      <FieldLabel>Name</FieldLabel>
      <TextInput
        name="name"
        control={control}
        errors={errors}
        rules={{ required: "List Name required" }}
      />
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        {/* <ErrorMessage err={error} /> */}
      </View>
      <View
        style={{
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          loading={mutation.isLoading}
          onPress={handleSubmit(mutation.mutate)}
        >
          {"Create List"}
        </Button>
      </View>
    </View>
  );
}
