import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Box, Touch, SortButton, HeaderButton } from "../../../components";
import { options, optionsBack } from "../../../headerUtils";
import { useAtom, isWebAtom } from "../../../atoms";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import ContentExpiredScreen from "./ContentExpiredScreen";
import SimpleContentViewScreen, {
  SingleContentOptions,
} from "./SimpleContentViewScreen";
import LibraryScreen, {
  setShowFilters,
  showFiltersAtom,
} from "./LibraryScreen";
import FilterIcon from "../../../assets/icons/filter.svg";
import { useNavigation, useRoute } from "@react-navigation/native";

const Header = () => {
  const web = useAtom(isWebAtom);
  const navigation = useNavigation();
  const route = useRoute();
  const sortMode = route.params?.sortMode || "desc";
  const [_sortMode, setSortMode] = useState(sortMode);
  useEffect(() => {
    navigation.setParams({ sortMode: _sortMode });
  }, [_sortMode]);

  const showFilters = useAtom(showFiltersAtom);

  return (
    <Box
      flexDirection="row"
      alignItems="center"
    >
      <HeaderButton
        icon="ios-cloud-upload"
        style={{ paddingLeft: 5, paddingRight: 0 }}
        title="Upload"
        onPress={() => {
          navigation.setParams({ patientUpload: true });
        }}
      />
      <SortButton
        activeMode={sortMode}
        onPress={() => setSortMode((m) => (m === "asc" ? "desc" : "asc"))}
      />
      <Touch
        paddingHorizontal={10}
        onPress={() => setShowFilters(!showFilters)}
      >
        <FilterIcon width={20} height={20} color="#004E7A" />
      </Touch>
    </Box>
  );
};

const Stack = createStackNavigator();
export default ({ route: { params } }) => {
  const web = useAtom(isWebAtom);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="LibraryScreen"
        component={LibraryScreen}
        options={options("Library", () => (
          <Header />
        ))}
      />
      <Stack.Screen
        name="SimpleContentViewScreen"
        component={SimpleContentViewScreen}
        options={SingleContentOptions(web, null, null)}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
      <Stack.Screen
        name="ContentExpiredScreen"
        component={ContentExpiredScreen}
        options={optionsBack(web, null, null)}
      />
    </Stack.Navigator>
  );
};
