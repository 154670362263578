const DatePicker = ({ value, onChange, style }) => {
  // const _value = !!value ? value.toISOString().substring(0, 10) : null;

  const onChangeDate = (event) => {
    onChange(event.target.valueAsDate);
  };
  return (
    <input
      type="date"
      // value={_value}
      onChange={onChangeDate}
      style={{
        height: 30,
        padding: 5,
        border: "2px solid #677788",
        borderRadius: 5,
        width: 250,
       
        ...style,
      }}
      max={new Date().toISOString().substring(0, 10)}
    />
  );
};

export default DatePicker;
