import type { ComponentProps } from "react";
import { DropDownInput } from "./Input";
import FieldWithValidation from "./FieldWithValidation";
import type { FieldWithValidationPropsT } from "./FieldWithValidation";

type InputT<T> = ComponentProps<typeof DropDownInput<T>>;

const DropdownField = <T,>({
  label,
  data,
  value,
  onChange,
  placeholder,
  validate,
}: InputT<T> & Omit<FieldWithValidationPropsT<T, undefined>, "render">) => {
  const _DropDownInput = (
    props: Omit<ComponentProps<typeof DropDownInput<T>>, "data">
  ) => <DropDownInput<T> {...props} data={data} placeholder={placeholder} />;

  return (
    <FieldWithValidation
      label={label}
      onChange={onChange}
      value={value}
      render={_DropDownInput}
      validate={validate}
    />
  );
};

export default DropdownField;
