import { useState, useEffect, useCallback } from "react";
import { Button, View } from "react-native";
import type { ComponentProps } from "react";
import { useAList } from "./../../hooks";
import Form from "../Form";
import Page from "./Page";

type PageT = ComponentProps<typeof Page>["page"];

type FormT = {
  id: string;
  version: string;
  title: string;
  subTitle: string;
  pages: PageT[];
  results: any[];
};

const FormBuilder = ({
  value,
  onChange,
}: {
  value?: FormT;
  onChange: (data: FormT) => void;
}) => {
  const [data, setData] = useState<FormT>(
    value
      ? value
      : {
          title: "",
          id: "",
          version: "",
          subTitle: "",
          pages: [],
          results: [],
        }
  );

  useEffect(() => {
    onChange(data);
  }, [data, onChange]);

  const pages = useAList<PageT | undefined>(data.pages);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      pages: pages.items.filter((page) => page !== undefined) as PageT[],
    }));
  }, [pages.items]);

 

  return (
    <>
      <Form.TextField
        value={data.title}
        label={"Form Title"}
        onChange={(val) => setData((prev) => ({ ...prev, title: val }))}
        validate={(data) => {
          if (!data) {
            return {
              isSuccess: false,
              error: "title is required",
            };
          }
          if (data.length < 3 || data.length > 100) {
            return {
              isSuccess: false,
              error: "Title must be more than 3 characters",
            };
          }
          return {
            isSuccess: true,
            data,
          };
        }}
      />

      <Form.TextField
        validate={(data) => {
          if (!data) {
            return {
              isSuccess: false,
              error: "A subtitle for the form is required",
            };
          }
          if (data.length < 3 || data.length > 100) {
            return {
              isSuccess: false,
              error: "The form subtitle must be more than 3 characters",
            };
          }
          return { isSuccess: true, data };
        }}
        value={data.subTitle}
        label={"Form Subtitle"}
        onChange={(val) => setData((prev) => ({ ...prev, subTitle: val }))}
      />
      {pages.render(({ updateItem, deleteItem, item, key }) => (
              <View style={{ backgroundColor: key % 2 === 0 ? "rgb(220, 220, 223)" : "white", borderColor: "#C6C6C8", borderWidth: 1, borderRadius: 15, padding: 15, margin: 10 }}>

        <Form.SubForm key={key} name={`pages.${key}`}>
          <Page
            updateItem={updateItem}
            page={item}
            key={key}
          />
          <Button title="delete Page" onPress={deleteItem} />
        </Form.SubForm>
        </View>
      ))}

      <View>
        <Button title="new Page" onPress={() => pages.addItem(undefined)} />
      </View>
    </>
  );
};

export default FormBuilder;
