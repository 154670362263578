import React, { useState, Fragment, useEffect } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  ScrollView,
  Platform,
  Linking,
  StyleSheet
} from "react-native";
import {
  useNavigation,
  useRoute,
  CommonActions,
  getFocusedRouteNameFromRoute
} from "@react-navigation/native";
import Constants from "expo-constants";
import { Ionicons, FontAwesome5, AntDesign } from "@expo/vector-icons";
import { isEmpty } from "lodash";
import { currentCommitId } from "../config";
import { Switch, Version } from "../components";
import NotificationCount from "./NotificationCount";
import { useGlobal, useHasAnyRoles } from "../hooks";
import {
  hasOrganisationsAtom,
  useAtom,
  currentNavigatorAtom,
  setCurrentNavigator,
  currentRoleTypeAtom,
  setCurrentRoleType,
  currentUserAtom,
  setCurrentUserId,
  currentOrganisationAtom,
  isWebAtom
} from "../atoms";
import { signOut, nameTitle, resetGlobalData, testId } from "../utils";
import { container } from "../reactUtils";
import TodoCount from "./TodoCount";
import LibraryIcon from "../assets/icons/library.svg";
import HomeIcon from "../assets/icons/home.svg";
import ActivityIcon from "../assets/icons/activity_history.svg";
import ShareIcon from "../assets/icons/share_content.svg";
import TodoIcon from "../assets/icons/todo.svg";
import ProfileIcon from "../assets/icons/profile.svg";
import ProfileOutlineIcon from "../assets/icons/profile_outline.svg";
import SignOutIcon from "../assets/icons/sign_out.svg";
import SupportIcon from "../assets/icons/support.svg";
import ContentIcon from "../assets/icons/content.svg";
import ContactIcon from "../assets/icons/contacts.svg";
import ManageListIcon from "../assets/icons/manage_contacts.svg";
import ManageBundleIcon from "../assets/icons/manage_bundles.svg";
import ManageUsersIcon from "../assets/icons/manage_users.svg";
import NotificationsIcon from "../assets/icons/notifications.svg";
import GridOutline from "../assets/icons/grid-outline.svg";
import IconLogoWhite from "../assets/images/logo-white.svg";

const Divider = () => (
  <View
    style={{ borderBottomWidth: 1, borderBottomColor: "rgb(220, 220, 223)" }}
  />
);

const Item = ({
                title,
                auth,
                navigateTo,
                navigationParams = {},
                containerStyle,
                fontSize,
                icon,
                ...props
              }) => {
  const web = useAtom(isWebAtom);
  const currentNavigator = useAtom(currentNavigatorAtom);
  const navigation = useNavigation();
  const navigationRoute = useRoute();

  const isActiveRoute = () => {
    if (web) {
      return currentNavigator === navigateTo;
    }
    const routeName = getFocusedRouteNameFromRoute(navigationRoute);
    return routeName === navigateTo;
  };

  return auth ? (
    <TouchableOpacity
      style={
        containerStyle
          ? containerStyle
          : {
            paddingTop: 20,
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderBottomColor: isActiveRoute()
              ? "rgb(25, 223, 247)"
              : "white"
          }
      }
      onPress={() => {
        navigation.navigate(navigateTo, navigationParams);
      }}
      {...props}
    >
      <Text
        style={{
          fontSize: fontSize || 20,
          color: isActiveRoute() ? "rgb(25, 223, 247)" : "white"
        }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  ) : null;
};

const IconItem = ({
                    title,
                    icon,
                    navigateTo,
                    showBottomBorder = true,
                    highlightActiveBorder = true,
                    rightComp,
                    params = {},

                    ...props
                  }) => {
  const web = useAtom(isWebAtom);
  const [, updateData] = useGlobal();
  const currentNavigator = useAtom(currentNavigatorAtom);
  const navigation = useNavigation();
  const route = useRoute();
  const navigationRoute = useRoute();

  const isActiveRoute = () => {
    if (web) {
      return currentNavigator === navigateTo;
    }
    const routeName = getFocusedRouteNameFromRoute(route);
    return routeName === navigateTo;
  };

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 15,
        paddingBottom: 15,
        borderBottomWidth: showBottomBorder ? 1 : 0,
        borderBottomColor:
          highlightActiveBorder && isActiveRoute()
            ? "rgb(25, 223, 247)"
            : "white"
      }}
      onPress={async () => {
        if (navigateTo === "ProfileNavigator") {
          navigation.navigate("ProfileNavigator");
        }
          // if (navigateTo === "Support") {
          //   console.log("clicked")
          //   Linking.openURL("https://iowna.com/support/");
        // }
        else if (navigateTo === "SignInScreen") {
          setCurrentUserId("");
          await signOut();
          await resetGlobalData(updateData);
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: navigateTo }]
            })
          );
        } else {
          if (isEmpty(params)) {
            navigation.navigate(navigateTo);
          } else {
            navigation.navigate(navigateTo, params);
          }
        }
      }}
      {...props}
    >
      {typeof icon === "string" ? (
        <Ionicons
          name={icon}
          color="white"
          size={24}
          style={{ marginLeft: 5 }}
        />
      ) : (
        icon
      )}
      <Text
        style={{
          color: isActiveRoute() ? "rgb(25, 223, 247)" : "white",
          fontSize: 20,
          marginLeft: 12
        }}
      >
        {title}
      </Text>
      {rightComp ? rightComp : null}
    </TouchableOpacity>
  );
};

const SwitchItem = ({ toggleMenu, value, onPress, ...props }) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigation = useNavigation();

  const onClick = () => {
    setIsOpen(!isOpen);
    onPress(!isOpen);
    navigation.navigate(toggleMenu[!isOpen ? "on" : "off"].navigateTo);
  };
  useEffect(() => {
    setIsOpen(value);
  }, [value]);
  return (
    <View style={{ marginBottom: isOpen ? 0 : 55 }}>
      {isOpen ? null : <Divider />}
      <View style={{ marginTop: 15, marginBottom: 15 }}>
        <TouchableOpacity
          onPress={onClick}
          style={{ flexDirection: "row", alignItems: "center" }}
          {...props}
        >
          <Switch onPress={onClick} value={isOpen} circleSize={15} />
          <Text style={{ marginLeft: 6, color: "white", fontSize: 20 }}>
            {isOpen ? toggleMenu.on.title : toggleMenu.off.title}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const DrawerContent = container(({ state: { history, index } }) => {
  const hasOrganisations = useAtom(hasOrganisationsAtom);
  const currentRoleType = useAtom(currentRoleTypeAtom);
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const currentUser = useAtom(currentUserAtom);
  const web = useAtom(isWebAtom);
  const [showVersion, setShowVersion] = useState(0);

  // const nameStyle = web
  //   ? { color: "white", fontSize: 18 }
  //   : { color: "white", fontSize: 14, fontWeight: "400" };
  const hasSystemAdmin = useHasAnyRoles(["system_admin"]);
  const hasPractitioner = useHasAnyRoles(["system_admin", "practitioner"]);
  const hasCurator = useHasAnyRoles(["system_admin", "curator"]);
  const hasSupport = useHasAnyRoles(["support"]);
  const hasUsers = useHasAnyRoles([
    "system_admin",
    "organisation_owner",
    "organisation_admin"
  ]);
  const organisationName = currentOrganisation
    ? currentOrganisation.name
    : hasSystemAdmin
      ? "Admin"
      : "";
  const organisationId = currentOrganisation ? currentOrganisation.id : null;
  const isProfessional = currentRoleType === "professional";
  useEffect(() => {
    if (history.length > 0) {
      const lastItem = history[history.length - 1];
      if (lastItem.type === "route") {
        const navigatorName = lastItem.key.split("-")[0];
        setCurrentNavigator(navigatorName);
      }
    }
  }, [history.length, index]);
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        paddingTop: Constants.statusBarHeight,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "#004E7A",
        overflow: web ? "visible" : undefined
      }}
    >
      <TouchableOpacity
        style={{ flexDirection: "row", marginTop: 20 }}
        disabled={showVersion === 2}
        onPress={() =>
          showVersion < 2 ? setShowVersion(showVersion + 1) : null
        }
      >
        <IconLogoWhite width={180} height={60} />
        <Text style={{ color: "white" }}>
          <Version />
        </Text>
      </TouchableOpacity>
      {showVersion === 2 ? (
        <View style={{ flexDirection: "row", marginTop: 20 }}>
          <Text
            style={[
              styles.nameStyle,
              { fontSize: web ? 18 : 14, fontWeight: web ? "400" : "normal" }
            ]}
          >
            {currentCommitId.split(0, 7)}
          </Text>
        </View>
      ) : null}
      <View
        style={{
          flexDirection: "row",
          marginTop: 20,
          marginBottom: isProfessional && hasOrganisations ? 10 : 20
        }}
      >
        <Text
          style={[
            styles.nameStyle,
            { fontSize: web ? 18 : 14, fontWeight: web ? "400" : "normal" }
          ]}
          testID="User Title"
          accessibilityLabel={Platform.select({ android: "User Title" })}
        >
          {nameTitle(currentUser.title) + " "}
        </Text>
        <Text
          style={[
            styles.nameStyle,
            { fontSize: web ? 18 : 14, fontWeight: web ? "400" : "normal" }
          ]}
          testID="User Name"
          accessibilityLabel={Platform.select({ android: "User Name" })}
        >
          {currentUser.firstName} {currentUser.lastName}
        </Text>
      </View>
      {isProfessional && hasSystemAdmin ? (
        <View style={{ flexDirection: "row", marginBottom: 20 }}>
          <Text
            style={[
              styles.nameStyle,
              { fontSize: web ? 18 : 14, fontWeight: web ? "400" : "normal" }
            ]}
            testID="System Admin"
            accessibilityLabel={Platform.select({ android: "System Admin" })}
          >
            System Admin
          </Text>
        </View>
      ) : null}
      {isProfessional && hasOrganisations && (
        <View style={{ flexDirection: "row", marginBottom: 20 }}>
          <Text
            style={[
              styles.nameStyle,
              { fontSize: web ? 18 : 14, fontWeight: web ? "400" : "normal" }
            ]}
            testID="Organisation"
            accessibilityLabel={Platform.select({ android: "Organisation" })}
          >
            {organisationName}
          </Text>
        </View>
      )}
      <Divider />

      {isProfessional && organisationId && hasPractitioner ? (
        <IconItem
          icon={
            <HomeIcon
              width={24}
              height={24}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Home"
          navigateTo="PractitionerNavigator"
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional && organisationId && hasPractitioner ? (
        <IconItem
          icon={
            <ShareIcon
              width={24}
              height={26}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Share Content"
          navigateTo="SendPlanNavigator"
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional && organisationId && hasPractitioner ? (
        <IconItem
          icon={
            <ActivityIcon
              width={30}
              height={26}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Activity History"
          navigateTo="ActivityNavigator"
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional && organisationId && hasPractitioner ? (
        <IconItem
          icon={
            <NotificationsIcon
              width={24}
              height={26}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Notifications"
          navigateTo="NotificationsNavigator"
          rightComp={<NotificationCount />}
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional &&
      organisationId &&
      (hasPractitioner || hasCurator || hasUsers) ? (
        <IconItem
          icon={
            <ContentIcon
              width={24}
              height={26}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Manage Content"
          navigateTo={"ContentNavigator"}
          navigationParams={{ displayAs: "web" }}
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional && organisationId && hasPractitioner ? (
        <IconItem
          icon={
            <ContactIcon
              width={24}
              height={24}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Manage Contacts"
          navigateTo="ContactsNavigator"
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional && organisationId && (hasPractitioner || hasUsers) ? (
        <IconItem
          icon={
            <ManageBundleIcon
              width={28}
              height={32}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Manage Bundles"
          navigateTo="BundleNavigator"
          showBottomBorder={false}
        ></IconItem>
      ) : null}

      {isProfessional && organisationId && hasPractitioner ? (
        <IconItem
          icon={
            <ManageListIcon
              width={28}
              height={33}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Manage Lists"
          showBottomBorder={false}
          navigateTo="TagsNavigator"
        ></IconItem>
      ) : null}

      {isProfessional &&
      ((organisationId && hasUsers) || (organisationId && hasSupport)) ? (
        <IconItem
          icon={
            <ManageUsersIcon
              width={28}
              height={33}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Manage Users"
          showBottomBorder={true}
          navigateTo="UsersNavigator"
        ></IconItem>
      ) : null}

      {isProfessional && (hasSystemAdmin || hasSupport) ? (
        <Item
          auth={isProfessional && (hasSystemAdmin || hasSupport)}
          title="Manage Organisations"
          navigateTo="OrganisationsNavigator"
          {...testId("Manage Organisations")}
          showBottomBorder={false}
        />
      ) : null}

      {!isProfessional ? (
        <IconItem
          icon={
            <HomeIcon
              width={24}
              height={24}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Home"
          navigateTo="ConsumerNavigator"
          showBottomBorder={false}
          {...testId("Home")}
        />
      ) : null}
      {!isProfessional ? (
        <IconItem
          icon={
            <LibraryIcon
              width={24}
              height={24}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="Library"
          navigateTo="LibraryNavigator"
          showBottomBorder={false}
          {...testId("Library")}
        />
      ) : null}
      {!isProfessional ? (
        <IconItem
          icon={
            <TodoIcon
              width={24}
              height={24}
              color="white"
              style={{ marginLeft: 5 }}
            />
          }
          title="To Do"
          navigateTo="TodoNavigator"
          rightComp={<TodoCount />}
          showBottomBorder={false}
          {...testId("Todo")}
        />
      ) : null}

      {!isProfessional ? (
        <IconItem
          icon={<NotificationsIcon width={24} height={24} color="white" />}
          title="Notifications"
          navigateTo="NotificationsNavigator"
          rightComp={<NotificationCount />}
          showBottomBorder={isProfessional ? true : false}
          params={{ showBackButton: false }}
          {...testId("Notifications")}
        />
      ) : null}
      {!isProfessional ? (
        <IconItem
          icon={<ProfileIcon width={24} height={24} color="white" />}
          title="Profile"
          navigateTo="ProfileNavigator"
          highlightActiveBorder={isProfessional ? true : false}
          {...testId("Profile")}
        />
      ) : null}

      <View
        style={{
          flex: 1,
          justifyContent: "flex-end"
        }}
      >
        {isProfessional ? (
          <IconItem
            icon={<ProfileOutlineIcon width={27} height={27} color="white" />}
            title="Profile"
            navigateTo="ProfileNavigator"
            highlightActiveBorder={isProfessional ? true : false}
            showBottomBorder={false}
            {...testId("Profile")}
          />
        ) : null}
        {hasOrganisations ? (
          <SwitchItem
            iconSize={24}
            value={currentRoleType === "professional"}
            toggleMenu={{
              on: {
                title: "Clinician",
                navigateTo: !hasOrganisations
                  ? "TrialOrganisationSetupScreen"
                  : web
                    ? "ContentNavigator"
                    : "PractitionerNavigator"
              },
              off: { title: "Patient", navigateTo: "ConsumerNavigator" }
            }}
            onPress={(value) =>
              setCurrentRoleType(value ? "professional" : "personal")
            }
            showBottomBorder={false}
            {...testId("Switch Role")}
          />
        ) : null}

        {hasOrganisations && currentRoleType === "professional" ? (
          <IconItem
            icon={<GridOutline width={24} height={24} />}
            title="Switch Organisation"
            navigateTo="ChooseOrganisationScreen"
            showBottomBorder={false}
            {...testId("Switch Organisation")}
          />
        ) : null}
        <IconItem
          icon={<SupportIcon width={27} height={27} color="white" />}
          title="Support"
          auth={true}
          showBottomBorder={false}
          onPress={() => Linking.openURL("https://iowna.com/support/")}
        />

        <IconItem
          icon={<SignOutIcon width={24} height={24} color="white" />}
          title="Sign out"
          navigateTo="SignInScreen"
          showBottomBorder={false}
          {...testId("Sign Out")}
        />

        <IconItem
          icon={<FontAwesome5 name="user-lock" size={24} color="white" />}
          title="Privacy Policy"
          onPress={isProfessional ? () => Linking.openURL("https://iowna.com/privacy/#for-clinicians") : () => Linking.openURL("https://iowna.com/privacy")}
        />
      </View>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  nameStyle: {
    color: "white"
  }
});

export default DrawerContent;
