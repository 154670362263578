import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Form from "../../Form";

interface Props {
  updateItem: (question: InputQuestionT) => void;
  question?: InputQuestionT;
}

interface InputQuestionT {
  inputType?: string;
}

const data: { label: string; value: InputQuestionT["inputType"] }[] = [
  { label: "Text Box", value: "textbox" },
  { label: "Date", value: "date" },
  { label: "Number Input", value: "numeric" },
];

const Input = ({ updateItem, question }: Props) => {
  const [inputType, setInputType] = useState<InputQuestionT["inputType"]>(
    question?.inputType || "textbox"
  );

  useEffect(() => {
    updateItem({
      inputType,
    });
  }, [inputType]);
  return (
    <>
      <View>
        <Form.RadioField
          selections={data}
          onChange={(val) => {
            setInputType(val);
          }}
          value={inputType}
          label="Input Type"
        />
      </View>
    </>
  );
};

export default Input;
