import React from "react";
import { View, ActivityIndicator } from "react-native";
import { useTheme } from "@emotion/react";

const Spinner = ({
  size = "large",
  color,
}: {
  size: React.ComponentProps<typeof ActivityIndicator>["size"];
  color: React.ComponentProps<typeof ActivityIndicator>["color"];
}) => {
  const theme =
    useTheme() as {spinner: React.ComponentPropsWithoutRef<typeof ActivityIndicator>["color"];};
  return (
    <ActivityIndicator
      testID="spinner"
      size={size}
      color={color || theme.spinner || "#004E7A"}
    />
  );
};

export const CenteredSpinner = ({
  size,
  color,
}: {
  size: React.ComponentProps<typeof ActivityIndicator>["size"];
  color: React.ComponentProps<typeof ActivityIndicator>["color"];
}) => (
  <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
    <Spinner size={size} color={color} />
  </View>
);

export default Spinner;
