import React, { useState, useEffect } from 'react';
import { Animated, Easing, TouchableOpacity, View } from 'react-native';

const Switch = ({ circleSize = 15, circlePadding = 2, value = false, onPress }) => {
  const [animatedValue] = useState(new Animated.Value(0));
  const trackLength = circleSize * 2;
  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: value ? trackLength - circleSize - circlePadding * 2 : 0,
      easing: Easing.elastic(0.7),
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [value]);

  return (
    <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View
        activeOpacity={0.5}
        style={{
          backgroundColor: value ? 'rgb(25, 223, 247)' : 'gray',
          width: Math.min(trackLength, circleSize * 2),
          height: circleSize + circlePadding * 2,
          borderRadius: circleSize,
          padding: circlePadding,
        }}
      >
        <Animated.View
          style={{
            width: circleSize,
            height: circleSize,
            borderRadius: circleSize,
            backgroundColor: 'white',
            transform: [{ translateX: animatedValue }],
            marginRight: circleSize,
          }}
        />
      </View>
    </TouchableOpacity>
  );
};

export default Switch;
