import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import { View, Text, TouchableOpacity, FlatList as NativeFlatList, SectionList, Platform } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { SwipeRow } from "react-native-swipe-list-view";
import { includes, uniqueId } from "lodash";
import Pagination from "./Pagination";
import Spinner from "./Spinner";
import Empty from "./Empty";
import SwipeActionBar from "./SwipeActionBar";
import FlatList from "./FlatList";
import { HeaderContainer, HeaderItem, HeaderText, ListItem, ListText } from "./Table";
import { usePagination } from "../hooks";
import { useAtom, isWebAtom } from "../atoms";
import { handleError } from "../utils";

const previewCache = {};

const Row = ({ name, index, item, renderItem, onEditPress, onRemovePress }) => {
  const rowRef = useRef();
  if (index === 0 && !previewCache[name]) {
    setTimeout(() => {
      previewCache[name] = true;
    }, 1000);
  }
  return (
    <SwipeRow
      ref={rowRef}
      key={item.id}
      disableRightSwipe
      rightOpenValue={-180}
      closeOnRowOpen={true}
      preview={index === 0 && !previewCache[name]}
      previewDuration={700}
      previewOpenDelay={1000}
      previewOpenValue={-180}
    >
      <SwipeActionBar
        onEditPress={() => onEditPress({ item, ref: rowRef.current })}
        onRemovePress={() => onRemovePress({ item, ref: rowRef.current })}
      />
      {renderItem({ item, ref: rowRef.current })}
    </SwipeRow>
  );
};

const PaginatedList = ({
                         displayAs,
                         name,
                         useList = () => [],
                         variables = {},
                         displayOtherList,
                         reset,
                         otherLoading,
                         otherList,
                         formatList = (data) => data,
                         title,
                         emptyTitle = "",
                         emptyDescription = "",
                         columnConfig = [],
                         rowConfig,
                         onRowPress,
                         useSectionList,
                         renderSectionHeader,
                         renderItem,
                         sections,
                         limit = 100,
                         containerStyle,
                         arrowProps = {},
                         hasSwipe,
                         onEditPress,
                         onRemovePress,
                         noDataAutoRefetch = false,
                         testIDExtractor = () => "",
                         ...props
                       }) => {
  const firstItemRef = useRef();
  const [cursor, setCursor] = useState({ limit });
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [refreshing, setRefreshing] = useState(false);
  const web = useAtom(isWebAtom);
  const [list = {}, , listLoading, error] = useList({ ...variables, ...cursor });
  const { next, prev, currentData, currentPage, setCurrentPage, maxPage, total } = usePagination(
    (Array.isArray(list) && list.length === 0) ? [] : (formatList(displayOtherList ? otherList : list.items) || []),
    itemsPerPage,
    displayOtherList ? null : list.nextToken,
    reset
  );
  const loading = listLoading || otherLoading;
  const start = (currentPage - 1) * itemsPerPage + 1;
  const end = Math.min(currentPage * itemsPerPage, total);
  const availableRows = useMemo(() => {
    const all = [15, 20, 50, 100];
    const valid = [];
    all.forEach((number) => {
      valid.push({ label: number, value: number });
    });
    return valid;
  }, []);

  useEffect(() => {
    if (!loading && refreshing) {
      setRefreshing(false);
    }
  }, [loading]);

  useEffect(() => {
    if (noDataAutoRefetch && (total === 0 || currentData().length === 0) && list.nextToken) {
      setCursor({ limit, nextToken: list.nextToken });
    }
  }, [list.nextToken]);

  useEffect(() => {
    setCurrentPage(1);
  }, [displayOtherList, reset]);

  if (error) {
    return typeof error === "string" && includes(error.toLowerCase(), "network") ? (
      <Empty title="You are not connected to the Internet" description="Please check your network settings" />
    ) : (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text testID={"error_global"} style={{ fontSize: 22, color: "#FF3e50" }}>
          {error}
        </Text>
      </View>
    );
  }

  if (displayAs === "web") {
    const data = currentData();
    return (
      <View
        style={{
          flex: 1,
          margin: 20,
          borderWidth: 1,
          borderColor: "#B1B3B5",
          backgroundColor: "#FFFFFF",
          maxHeight: Platform.OS !== "web" ? undefined : "100%",
          ...containerStyle
        }}
      >
        {columnConfig.map((columns, index) => (
          <HeaderContainer key={index} backgroundColor="#F1F1F1">
            {columns.map(({ text, render, textStyle, ...headerItemProps }, index) => {
              return (
                <HeaderItem key={index} {...headerItemProps}>
                  {typeof text !== "undefined" ? <HeaderText style={textStyle}>{text}</HeaderText> : render()}
                </HeaderItem>
              );
            })}
          </HeaderContainer>
        ))}
        <FlatList
          // onLayout={(event) => {
          //   if (calculatedItemsPerPage === 0 && firstItemRef.current) {
          //     const size = Math.floor(event.nativeEvent.layout.height / firstItemRef.current.clientHeight);
          //     setItemsPerPage(size - 1);
          //     setCalculatedItemsPerPage(size - 1);
          //   }
          // }}
          contentContainerStyle={{ flex: 1 }}
          loading={loading}
          title={title}
          data={data}
          keyExtractor={(item) => item.id}
          emptyTitle={emptyTitle}
          emptyDescription={emptyDescription}
          renderItem={({ index, item }) => {
            const rowProps = {
              testID: testIDExtractor(item),
              accessibilityLabel: testIDExtractor(item),
              ref: index === 0 ? firstItemRef : null,
              style: { flexDirection: "row", borderBottomColor: "#e1e1e1", borderBottomWidth: 1 }
            };

            const rowContent = rowConfig.map(({ width, text, render, style }, index) => {
              return (
                <ListItem key={index} width={width} style={typeof style === "function" ? style(item) : style}>
                  {text ? <ListText>{text(item)}</ListText> : render(item)}
                </ListItem>
              );
            });

            return onRowPress ? (
              <TouchableOpacity onPress={handleError(() => onRowPress(item))} {...rowProps}>
                {rowContent}
              </TouchableOpacity>
            ) : (
              <View {...rowProps}>
                {rowContent}
              </View>
            );
          }}
          {...props}
        />
        <View style={{
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingVertical: 5,
          backgroundColor: "#F1F1F1"
        }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: "#393F46", fontSize: web ? 16 : 14, marginRight: 10 }}>Rows per page</Text>
            <Picker
              selectedValue={itemsPerPage}
              onValueChange={(value) => {
                setItemsPerPage(+value);
              }}
              style={{
                height: 30,
                width: 70,
                fontSize: web ? 18 : 14,
                color: "#393F46",
                borderColor: "#393F46"
              }}
            >
              {availableRows.map((item) => (
                <Picker.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Picker>
          </View>
          <View style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
            {start && end ? (
              <Text style={{ color: "#393F46", fontSize: web ? 16 : 14 }}>
                Showing {start}-{end}
              </Text>
            ) : null}
            <Pagination
              currentPage={currentPage}
              maxPage={maxPage}
              next={() => {
                if (currentPage === maxPage - 1 && list.nextToken) {
                  setCursor({ limit, nextToken: list.nextToken });
                }
                next();
              }}
              prev={prev}
              buttonSize={web ? 40 : 24}
              {...arrowProps}
            />
          </View>
        </View>
      </View>
    );
  }

  const data = displayOtherList ? otherList : (Array.isArray(list) && list.length === 0) ? [] : list.items;
  const formattedList = formatList(data);
  const List = useSectionList ? SectionList : NativeFlatList;

  if (formattedList.length === 0 && !refreshing && loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Spinner />
      </View>
    );
  }
  return (
    <Fragment>
      <List
        onRefresh={() => {
          setCursor({ limit, resetKey: uniqueId() });
          setRefreshing(true);
        }}
        refreshing={refreshing}
        data={formattedList}
        sections={formattedList}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={() => (!loading ? <Empty title={emptyTitle} description={emptyDescription} /> : null)}
        renderSectionHeader={renderSectionHeader}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity
              testID={testIDExtractor(item)}
              accessibilityLabel={testIDExtractor(item)}
              disabled={!onRowPress}
              onPress={handleError(() => onRowPress(item))}
            >
              {hasSwipe ? (
                <Row name={name} index={index} item={item} renderItem={renderItem} onEditPress={onEditPress}
                     onRemovePress={onRemovePress} />
              ) : (
                renderItem({ item })
              )}
            </TouchableOpacity>
          );
        }}
        onEndReached={() => {
          if (list.nextToken) {
            setCursor({ limit, nextToken: list.nextToken });
          }
        }}
        onEndReachedThreshold={0.5}
        {...props}
      />
      {!refreshing && loading && (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", minHeight: 50 }}>
          <Spinner />
        </View>
      )}
    </Fragment>
  );
};

export default PaginatedList;
