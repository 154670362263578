import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { useAtom, isWebAtom } from "../atoms";
import { testId } from "../utils";

const Result = ({
  icon,
  title,
  date,
  description,
  containerStyles = {},
  titleStyles = {},
  descriptionStyles = {},
  showOkBtn = true,
  onPressOk = () => {},
  to,
}) => {
  const web = useAtom(isWebAtom);
  return (
    <View
      style={{
        alignItems: "center",
        ...containerStyles,
        flex: 1,
      }}
    >
      <View style={{ flex: 1, justifyContent: "flex-end" }}>{icon}</View>
      <View style={{ flex: 1 }}>
        <Text
          {...testId(title)}
          style={{
            fontSize: 40,
            fontWeight: "500",
            color: "rgb(0, 78, 122)",
            marginBottom: 30,
            ...titleStyles,
            textAlign: "center",
          }}
        >
          {title}{" "}
        </Text>
        <Text
          {...testId(description)}
          style={{
            fontSize: web ? 18 : 14,
            textAlign: "center",
            ...descriptionStyles,
          }}
        >
          {description}{" "}
        </Text>
        {date ? (
          <Text style={{ textAlign: "center", fontSize: web ? 18 : 14 }}>
            {date}
          </Text>
        ) : null}
        {to ? (<Text style={{textAlign: "center", fontSize: web ? 18 : 14}}>{to}</Text>) : null}
      </View>

      {showOkBtn && (
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            {...testId("OK")}
            style={{
              padding: web ? 10 : 8,

              backgroundColor: "white",
              borderWidth: 2,
              borderRadius: 35,
              borderStyle: "solid",
              borderColor: "rgb(0, 78, 122)",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",

              minWidth: web ? 80 : 60,
            }}
            onPress={onPressOk}
          >
            <Text>OK</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default Result;
