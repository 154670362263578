import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ProfileScreen, { ProfileOptions } from "./ProfileScreen";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import CompleteProfileScreen from "../../Auth/CompleteProfileScreen";
import { optionsBack } from "../../../headerUtils";
import { useAtom, isWebAtom } from "../../../atoms";
import { useNavigation, useRoute } from "@react-navigation/native";

const Stack = createStackNavigator();

export default () => {
  const web = useAtom(isWebAtom);
  const route = useRoute();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={ProfileOptions(web)}
      />
      <Stack.Screen
        name="UpdateProfileScreen"
        component={CompleteProfileScreen}
        options={optionsBack(web, "Edit Profile", null, "ProfileScreen")}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
    </Stack.Navigator>
  );
};
