import type { Meta, StoryObj } from "@storybook/react-native";
import type { ComponentProps } from "react";
import { useState } from "react";

import { ScrollView, View, Text } from "react-native";
import FormBuilder from "./FormBuilder";

const FB = (props: ComponentProps<typeof FormBuilder>) => {
  const [value, setValue] = useState<
    Parameters<ComponentProps<typeof FormBuilder>["onChange"]>[0] | undefined
  >(undefined);
  return (
    <>
      <FormBuilder {...props} onChange={setValue} />
      <Text>{JSON.stringify(value, null, 4)}</Text>
    </>
  );
};

const meta: Meta<typeof FB> = {
  title: "FormBuilder",
  component: FB,
  argTypes: {
    onChange: { action: "onChange called" },
  },
  args: {},
  // parameters: {
  //   backgrounds: {
  //     default: "white",
  //     values: [
  //       {name: "white", value: "#FFFFFF"}
  //     ],
  //   },
  // },
  decorators: [
    (Story) => (
      <View>
        <ScrollView>
          <Story />
        </ScrollView>
      </View>
    ),
  ],
};

export default meta;
type Story = StoryObj<typeof FB>;

export const FormBuilderStory: Story = {
  name: "FormBuilder",
};
