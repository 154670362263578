import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { get } from "lodash";
import { format } from "date-fns";
import { optionsBack } from "../../../headerUtils";
import {
  HeaderButton,
  MainScreen,
  TitleText,
  Button,
} from "../../../components";
import { BreadCrumb } from "../../../containers";

import {
  setPdfId,
  userByIdAtom,
  useAtom,
  currentUserAtom,
  isWebAtom,
  currentOrganisationAtom,
} from "../../../atoms";
import { nameTitle, interpolate, logEvent } from "../../../utils";
import { screen, containerWithoutLoader } from "../../../reactUtils";
import {
  getContactActivity,
  getSingleDistributionContact,
  getActivityByList,
  getOrganisationDistributionContacts2,
  getActivityByDistributionList,
} from "../../../graphql";

import { useInfiniteQuery, useQueryClient } from "react-query";
import { AntDesign } from "@expo/vector-icons";

import List from "../../../new/components/List";

const Field = ({
  fontSize,
  title,
  date,
  value,
  borderWidth,
  icon,
  ...props
}) => {
  const web = useAtom(isWebAtom);
  const padding = web ? 20 : 13;

  return (
    <>
      <View
        style={{
          flexDirection: "row",

          padding: 15,
          alignItems: "center",

          borderBottomWidth: borderWidth,
          borderBottomColor: "#C6C6C8",
        }}
      >
        {icon == "form" ? (
          <AntDesign name="form" size={24} color="#004E7A" />
        ) : (
          <AntDesign name="pdffile1" size={24} color="#004E7A" />
        )}
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            padding: 10,
            backgroundColor: "white",
          }}
          {...props}
        >
          <Text
            numberOfLines={1}
            style={{ fontSize: fontSize || 15, paddingRight: 8 }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontSize: fontSize || 14,
              paddingRight: 8,
              color: "#aaaaaa",
              opacity: 1,
            }}
          >
            {date}
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "column",
            paddingVertical: 25,
            paddingBottom: padding - 5,
          }}
          {...props}
        >
          {typeof value === "string" ? (
            <Text style={{ fontSize: fontSize || 16, paddingLeft: 8 }}>
              {value}
            </Text>
          ) : (
            value
          )}
        </View>
      </View>
    </>
  );
};

const DistributionListActivityScreen = screen(
  ({ navigation: { navigate }, route }) => {
    const web = useAtom(isWebAtom);

    const { item } = route.params || {};

    const apiFn = (props) =>
      getActivityByDistributionList({
        id: route.params.item.id,
        ...props,
      });
    const pathFn = (result) => result.data.distributionTag.activity;

    const {
      data: pageListData,
      fetchNextPage: listLoadMore,
      hasNextPage: listHasMore,
      isFetchingNextPage: isListFetchingNextPage,
      isLoading: isListLoading,
    } = useInfiniteQuery(
      [route.params.item.id, "activity"],
      ({ pageParam }) => apiFn(pageParam).then(pathFn),
      {
        getNextPageParam: (lastPage) =>
          lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
      }
    );

    const listLoading = isListLoading || isListFetchingNextPage;

    const loading = listLoading;

    const [searchText, setSearchText] = useState("");

    const data = pageListData ? pageListData.pages.flatMap((x) => x.items) : [];

    if (data.length < 50 && !listLoading && listHasMore) {
      listLoadMore();
    }

    const roundNumber = (number, decimal_digit) => {
      let powerOften = Math.pow(10, decimal_digit || 0);
      let result = Math.round(number * powerOften) / powerOften;
      return result;
    };

    const renderItem = ({ item, index }) => {
      const result = item.submissionCount;

      return (
        <>
          <TouchableOpacity
            onPress={() => {
              navigate("ContentActivityScreen", {
                item: item,
              });
            }}
          >
            <Field
              icon={item.activity.items[0].submission != null ? "form" : "pdf"}
              title={item.content.displayName}
              borderWidth={index === data.length - 1 ? 1 : 1}
              date={format(
                new Date(item.sentAt ? item.sentAt * 1000 : null),
                "dd/MM/yyyy"
              )}
              // Future implementation: waiting for the submissionCount object to be correct in the API and then this part of code can be uncommented out

              // value={
              //   result != null ? (
              //     <Text style={{ color: "#004E7A", alignSelf: "flex-end" }}>
              //       {result.complete} / {result.total} forms completed
              //     </Text>
              //   ) : (
              //     ""
              //   )
              // }
            />
          </TouchableOpacity>
        </>
      );
    };

    return (
      <>
        <MainScreen backgroundColor="#FFFFFF" style={{ flex: 1 }}>
          {/* <View style={styles.nameContainer}>
            <TitleText
              lineHeight={40}
              fontWeight="500"
              style={{ alignItems: "flex-start" }}
            >
              {name} list stats:
            </TitleText>
            <View style={{ flexDirection: "row", flex: 1, paddingTop: 5 }}>
              <Text style={{ color: "#8E8E91" }}>
                content items sent w/c dd/mm/yy:
              </Text>
              <Text style={{ color: "#004E7A", alignSelf: "flex-start" }}>
                {" "}
                34
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ color: "#8E8E91" }}>
                completed forms w/c dd/mm/yy:
              </Text>
              <Text style={{ color: "#004E7A", alignSelf: "flex-start" }}>
                98{" "}
              </Text>
            </View>
          </View> */}

          <List
            setSearchText={(casedSearch) =>
              setSearchText(casedSearch.toLowerCase())
            }
            flatListParams={{
              data: data.filter((i) =>
                i.content.displayName.toLowerCase().includes(searchText)
              ),
              renderItem,
              onEndReached: listLoadMore,
            }}
            loading={loading}
          />
        </MainScreen>
      </>
    );
  }
);

const ActivityScreenTitle = containerWithoutLoader(({ route }) => {
  const { name } = route.params || {};

  return <BreadCrumb parts={["currentOrganisation.name", "Activity", name]} />;
});

export const OptionsBack = (web) => optionsBack(web, ActivityScreenTitle);

const styles = StyleSheet.create({
  nameContainer: {
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingHorizontal: 21,
    alignItems: "flex-start",
    paddingVertical: 10,
    borderBottomColor: "#AAAAAA",
    borderBottomWidth: 1,
    width: "100%",
  },
});

export default DistributionListActivityScreen;
