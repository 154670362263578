import { useState } from "react";
import type { ComponentProps } from "react";
import { Text } from "react-native";
import type { Meta, StoryObj } from "@storybook/react-native";

import { ScrollView, View } from "react-native";
import Question from "./Question";
import Form from "../Form";

const Q = (props: ComponentProps<typeof Question>) => {
  const [value, setValue] = useState(props.question || "yolo");
  return (
    <Form.SubForm
      name="dingus"
      renderErrors={({ errors }) => (
        <Text>errors: {JSON.stringify(errors, null, 4)}</Text>
      )}
      renderOnClean={() => <Text>clean</Text>}
    >
      <Question {...props} question={undefined} updateItem={setValue} />
      <Text>external state: {JSON.stringify(value, null, 4)}</Text>
    </Form.SubForm>
  );
};

const meta: Meta<typeof Q> = {
  title: "Question",
  component: Q,
  argTypes: {
    updateItem: { action: "updateItem called" },
  },
  args: {},
  // parameters: {
  //   backgrounds: {
  //     default: "white",
  //     values: [
  //       {name: "white", value: "#FFFFFF"}
  //     ],
  //   },
  // },
  decorators: [
    (Story) => (
      <View>
        <ScrollView>
          <Story />
        </ScrollView>
      </View>
    ),
  ],
};

export default meta;
type Story = StoryObj<typeof Q>;

export const FormBuilderStory: Story = {
  name: "Question",
};
