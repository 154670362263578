import "react-native-gesture-handler";
import "./polyfills";
import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Platform, Dimensions, View, Text, TextInput } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createBrowserHistory, createMemoryHistory } from "history";
import { MenuProvider } from "react-native-popup-menu";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import * as Updates from "expo-updates";
import analytics from "./src/analytics";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { parseSemVer } from "semver-parser";
import * as Linking from "expo-linking";
import * as queryString from "query-string";
import * as LocalAuthentication from "expo-local-authentication";
import * as SecureStore from "expo-secure-store";
import AuthNavigator from "./src/screens/Auth";
import {
  AppBanner,
  ErrorBoundary,
  Spinner,
  Alert,
  NetworkAlert,
} from "./src/components";
import { LoadingModal } from "./src/containers";
import IconLogo from "./src/assets/images/logo.svg";
import {
  initConfig,
  signIn,
  IOWNAError,
  initializeAppScreen,
} from "./src/utils";
import { GlobalProvider, useGlobal } from "./src/hooks";
import { containerWithoutLoader } from "./src/reactUtils";
import { isQAAtom, setIsWeb, isWebAtom, useAtom } from "./src/atoms";
import { currentVersion } from "./src/config";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
// import Title from "./src/new/components/formbuilder/TextField";

// Text.defaultProps = Text.defaultProps || {};
// Text.defaultProps.allowFontScaling = false;
// TextInput.defaultProps = TextInput.defaultProps || {};
// TextInput.defaultProps.allowFontScaling = false;

const urlToScreen = (url) => {
  const parts = url.split("?");
  const params = queryString.parse(parts[1]);
  if (url.indexOf("/SignUp") > -1) {
    return { screen: "SignUpScreen", params };
  }
  if (url.indexOf("/SignIn") > -1) {
    return { screen: "SignInScreen", params };
  }
  return { screen: "", params: {} };
};

const tryLocalAuth = async () => {
  const rememberDevice =
    (await AsyncStorage.getItem("rememberDevice")) || "false";
  const hasLocalAuth = await LocalAuthentication.isEnrolledAsync();
  if (Platform.OS === "web" || rememberDevice === "false" || !hasLocalAuth) {
    return;
  }
  if (rememberDevice === "true" && hasLocalAuth) {
    const { success } = await LocalAuthentication.authenticateAsync({
      promptMessage: "Please authenticate to Sign In to iOWNA",
    });
    if (success) {
      return;
    }
  }
  throw new IOWNAError("Local Auth Failed");
};

const tryCognitoAuth = async () => {
  try {
    await Auth.currentSession();
    return true;
  } catch (err) {
    const rememberDevice =
      (await AsyncStorage.getItem("rememberDevice")) || "false";
    if (rememberDevice === "true" && Platform.OS !== "web") {
      const email = (await SecureStore.getItemAsync("email")) || "";
      const password = (await SecureStore.getItemAsync("password")) || "";
      return await signIn({ email, password });
    } else {
      throw new IOWNAError("Cognito Auth Failed");
    }
  }
};

const anyUpdates = async (config) => {
  if (Platform.OS === "web" || __DEV__) {
    return { hasUpdate: false, forcedUpdate: false, nativeUpdate: false };
  }
  // track https://github.com/expo/expo/issues/18480
  // and remove catch once fixed
  // const { isAvailable } = await Updates.checkForUpdateAsync();
  let isAvailable;
  try {
    const { _isAvailable } = await Updates.checkForUpdateAsync();
    isAvailable = _isAvailable;
  } catch (err) {
    if (err.code === "ERR_UPDATES_DISABLED") {
      return { hasUpdate: false, forcedUpdate: false, nativeUpdate: false };
    }
    throw err;
  }


  const hasUpdate =
    isAvailable && currentVersion !== config.release.buildNumber;
  const newSemVer = parseSemVer(config.release.buildNumber);
  const currentSemVer = parseSemVer(currentVersion || "0.0.0");
  let forcedUpdate = false;
  let nativeUpdate = false;
  if (newSemVer.major > currentSemVer.major) {
    forcedUpdate = true;
  } else if (newSemVer.major === currentSemVer.major) {
    forcedUpdate =
      newSemVer.minor - currentSemVer.minor >= config.release.minorN;
  }
  if (config.release.isNative && Platform.OS === "android") {
    nativeUpdate =
      config.release.versionCode > Constants.platform.android.versionCode;
  }
  if (config.release.isNative && Platform.OS === "ios") {
    const iosVer = parseSemVer(Constants.platform.ios.buildNumber || "0.0.0");
    nativeUpdate =
      newSemVer.major > iosVer.major || newSemVer.minor > iosVer.minor;
  }
  return { hasUpdate, forcedUpdate, nativeUpdate };
};

const InitializeApp = containerWithoutLoader(({ setInitial }) => {
  const web = Platform.OS === "web";
  const [, updateData] = useGlobal();
  let [fontsLoaded] = useFonts({
    "Text-Security-Disc": require("./src/assets/fonts/text-security-disc.ttf"),
  });
  const configure = async () => {
    const config = await initConfig();
    const url = await Linking.getInitialURL();
    if (url && url.indexOf("/LinkExpired") > -1) {
      const parts = url.split("?");
      const params = queryString.parse(parts[1]);
      setInitial({ screen: "LinkExpiredScreen", params });
      return;
    } else if (url && url.indexOf("/SignUp") > -1) {
      const parts = url.split("?");
      const params = queryString.parse(parts[1]);
      setInitial({ screen: "SignUpScreen", params });
      return;
    } else if (url && url.indexOf("/SignIn") > -1) {
      const parts = url.split("?");
      const params = queryString.parse(parts[1]);
      setInitial({ screen: "SignInScreen", params });
      return;
    }
    const updates = await anyUpdates(config);

    try {
      await tryLocalAuth();
      const confirmed = await tryCognitoAuth();
      await initializeAppScreen({
        ...updates,
        updateData,
        changeScreen: setInitial,
        confirmed,
      });
    } catch (err) {
      console.log("err", err);
      if (updates.hasUpdate) {
        setInitial({
          screen: "UpdateScreen",
          params: { ...updates, next: { screen: "SignInScreen", params: {} } },
        });
      } else {
        setInitial({ screen: "SelectScreen", params: {} });
      }
    }
  };
  useEffect(() => {
    configure();
  }, []);
  if (Platform.OS === "web") {
    return (
      <View style={{ backgroundColor: "white", flex: 1, alignItems: "center" }}>
        <IconLogo width={180} height={web ? 180 : 80} />
        <View style={{ flex: 1, alignItems: "center", marginTop: 150 }}>
          <Spinner size="large" />
        </View>
      </View>
    );
  }
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return <AppLoading />;
});

const App = () => {
  const web = Platform.OS === "web";

  const history =
    Platform.OS === "web" ? createBrowserHistory() : createMemoryHistory();
  const [initial, setInitial] = useState({ screen: "", params: {} });
  const navigationRef = useRef();
  const routeRef = useRef("");

  useEffect(() => {
    const dimsHandler = ({ window }) => {
      if (window.width < 600 && Platform.OS === "web" && web) {
        setIsWeb(false);
      }
      if (window.width > 600 && Platform.OS === "web" && !web) {
        setIsWeb(true);
      }
    };
    const handleUrl = async ({ url }) => {
      const currentUrl = await Linking.getInitialURL();
      console.log("currentUrl", currentUrl);
      console.log("linkingUrl", url);
      const { screen, params } = urlToScreen(url);
      if (screen && navigationRef.current && currentUrl !== url) {
        // TODO: need to check if mounted first
        // https://sentry.io/organizations/iowna-whealth/issues/1799872783/?project=5226821&referrer=slack
        navigationRef.current.navigate(screen, params);
      }
    };
    const dimensionsSubscription = Dimensions.addEventListener(
      "change",
      dimsHandler
    );
    dimsHandler({ window: Dimensions.get("window") });
    const linkingSubscription = Linking.addEventListener("url", handleUrl);
    return () => {
      dimensionsSubscription.remove();
      linkingSubscription.remove();
    };
  }, [web]);
  useEffect(() => {
    return history.listen((location, action) => {
      if (action === "POP" && navigationRef.current) {
        const screenName = location.pathname.replace("/", "") + "Screen";
        navigationRef.current.navigate(screenName);
      }
    });
  }, [navigationRef]);
  if (initial.screen === "") {
    return (
      <GlobalProvider>
        <InitializeApp setInitial={setInitial} web={web} />
      </GlobalProvider>
    );
  }
  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <GlobalProvider>
        <MenuProvider>
          <AppBanner />
          <Alert />
          <NetworkAlert />
          <LoadingModal />
          <ErrorBoundary>
            <NavigationContainer
              ref={navigationRef}
              documentTitle={{
                enabled: true,
                formatter: (options, route) => {
                  if (web) {
                    const isNavigator = route.name.indexOf("Navigator") > -1;
                    if (!isNavigator && routeRef.current !== route.name) {
                      if (!isQAAtom.getValue()) {
                        analytics.logEvent("screen_view", {
                          screen_name: route.name,
                        });
                      }
                      const routeName = route.name.replace("Screen", "");
                      const routeParams = {};
                      for (const key of Object.keys(route.params || {})) {
                        const value = route.params[key];
                        if (typeof value === "string") {
                          routeParams[key] = value;
                        }
                      }
                      // console.log("route.name", routeName);
                      // console.log("route.params", JSON.stringify(routeParams));
                      const queryParams = queryString.stringify(routeParams);
                      history.push(
                        routeName + (queryParams ? "?" + queryParams : "")
                      );
                      // if (params && params.reload) {
                      //   history.replace(currentRouteName.replace('Screen', ''));
                      // }
                      routeRef.current = route.name;
                      return "iOWNA | " + routeName;
                    }
                  }
                  return "iOWNA";
                },
              }}
            >
              <AuthNavigator
                initialRouteName={initial.screen}
                initialParams={initial.params}
              />
            </NavigationContainer>
          </ErrorBoundary>
        </MenuProvider>
      </GlobalProvider>
    </SafeAreaView>
  );
};

const EntryPoint =
  Constants.expoConfig?.extra?.storybookEnabled === "true"
    ? require("./.storybook").default
    : () => (
        <SafeAreaProvider>
          <App />
        </SafeAreaProvider>
      );

export default EntryPoint;
