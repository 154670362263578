import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBbvJqjh5aYsAqsK8UlHG9tBiGeam4Kwl8",
  authDomain: "iowna-app-firebase.firebaseapp.com",
  databaseURL: "https://iowna-app-firebase.firebaseio.com",
  projectId: "iowna-app-firebase",
  storageBucket: "iowna-app-firebase.appspot.com",
  messagingSenderId: "854041679746",
  appId: "1:854041679746:web:e6bbea3f51c684456ae19b",
  measurementId: "G-3PVW1T0R5N",
};

const app = initializeApp(firebaseConfig);

export default app