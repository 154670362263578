import { FC } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

interface Props {
  onPress: () => void;
  title: string;
}

const SelectFileButton: FC<Props> = ({ onPress, title }) => {
  const styles = StyleSheet.create({
    appButtonContainer: {
      backgroundColor: "transparent",
      borderRadius: 10,
      paddingVertical: 5,
      paddingHorizontal: 12,
      borderColor: "black",
      borderWidth: 1
    },
    appButtonText: {
      fontSize: 18,
      color: "black",
      alignSelf: "center"
    }
  });

  return <View style={styles.appButtonContainer}>
    <TouchableOpacity onPress={onPress}>
      <Text style={styles.appButtonText}>{title}</Text>
    </TouchableOpacity>
  </View>;
};

export default SelectFileButton;