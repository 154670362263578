import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Ajv from 'ajv';
import ace from 'ace-builds/src-noconflict/ace';
import jsonWorkerUrl from 'ace-builds/src-noconflict/worker-json';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-crimson_editor';
import 'ace-builds/src-noconflict/ext-language_tools';
import AceEditor from 'react-ace';
import { Box, HeaderButton, Touch } from '../../../components';
import { BreadCrumb } from '../../../containers';
import { useAtom, showLoadingModal, hideLoadingModal, pdfScreenDataAtom, atom } from '../../../atoms';
import { containerWithoutLoader, screen } from '../../../reactUtils';
import { handleError, IOWNAError, jsonStringifySorted } from '../../../utils';
import { updateAdvice } from '../../../graphql';
import schema from './schema';

ace.config.setModuleUrl('ace/mode/json_worker', jsonWorkerUrl);

export const formTextAtom = atom('');

const ajv = new Ajv();
const validate = ajv.compile(schema);

const defaultQuestionnaire = {
  id: 'newid',
  version: 'v1.0.0',
  title: 'New Form',
  subTitle: 'New Form',
  pages: [
    {
      name: 'Step0',
      questions: [
        {
          type: 'text',
          fontSize: 'large',
          title: 'New Form',
        },
        {
          type: 'text',
          fontSize: 'large',
          title: 'Health Questionnaire',
        },
      ],
    },
    {
      name: 'Step1',
      questions: [
        {
          id: 'mobility',
          required: true,
          type: 'radio',
          title: 'MOBILITY',
          choices: [
            {
              id: 'no',
              name: 'I have no problems in walking about',
            },
            {
              id: 'some',
              name: 'I have some problems walking about',
            },
            {
              id: 'yes',
              name: 'I am confined to bed',
            },
          ],
        },
      ],
    },
    {
      name: 'Step8',
      show: '${completed === false}',
      questions: [
        {
          type: 'text',
          fontSize: 'large',
          title: 'You have now completed this questionnaire.',
        },
        {
          type: 'text',
          fontSize: 'normal',
          title:
            'If you would like to change any of your answers, you may do so by pressing the "Back" button prior to finalizing the questionnaire.',
        },
        {
          type: 'text',
          fontSize: 'normal',
          title:
            'Please finalise the questionnaire by pressing the "Finalise" button. Once you press ”Finalise", you will not be able to go back to review or change your answers.',
        },
      ],
      buttonRight: 'Finalise',
    },
    {
      name: 'Step9',
      show: '${completed === false}',
      questions: [
        {
          type: 'text',
          fontSize: 'large',
          title: 'Thank you for completing EQ-5D',
        },
      ],
      buttonRight: 'Done',
    },
  ],
  results: [],
};
const keyOrder = ['id', 'version', 'title', 'subTitle', 'results', 'pages'];

const FormEditorScreen = screen(() => {
  const { form } = useAtom(pdfScreenDataAtom);
  const formText = jsonStringifySorted(form || defaultQuestionnaire, {
    cmp: (a, b) => keyOrder.indexOf(a.key) - keyOrder.indexOf(b.key),
    space: 2,
  });

  console.log('formText', formText)
  useEffect(() => {
    formTextAtom.update(formText);
  }, [form]);
  const onChange = (data) => {
    formTextAtom.update(data);
  };
  return (
    <AceEditor
      name="form_ace_edit"
      mode="json"
      theme="crimson_editor"
      style={{
        width: '100%',
        height: '100%',
      }}
      defaultValue={formText}
      showGutter={true}
      showPrintMargin={false}
      onValidate={(g) => {
        console.log(g);
      }}
      setOptions={{
        useWorker: true,
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
      editorProps={{ $blockScrolling: true }}
      onChange={onChange}
    />
  );
});

export const FormEditorOptions = (web) => ({
  headerShown: true,
  header: containerWithoutLoader(({ navigation }) => {
    const { id, displayName, tags } = useAtom(pdfScreenDataAtom);
    return (
      <Box display="flex" flexDirection="row" backgroundColor="white" paddingHorizontal={13} paddingBottom={13}>
        <Touch justifyContent="flex-end" marginRight={10} onPress={() => navigation.goBack()}>
          <Ionicons name="ios-arrow-back" size={32} color="#004e79" />
        </Touch>
        <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between" flexFlow="wrap">
          <View style={{ flexDirection: 'row', paddingTop: 13, marginRight: 20, height: 51 }}>
            <BreadCrumb parts={['currentOrganisation.name', 'Content', displayName]} />
          </View>
          <View style={{ flexDirection: 'row', paddingTop: 13 }}>
            <HeaderButton
              title="Preview Form"
              onPress={() => {
                const form = JSON.parse(formTextAtom.getValue());
                navigation.navigate('FormScreen', {
                  id: id,
                  ...form,
                });
              }}
            />
            <HeaderButton
              title="Save"
              onPress={handleError(
                async () => {
                  const valid = validate(JSON.parse(formTextAtom.getValue()));
                  if (!valid) {
                    const errMessage = validate.errors.map((error) => `${error.dataPath} ${error.message}`).join('\n');
                    throw new IOWNAError(errMessage);
                  }
                  showLoadingModal('Saving form');
                  await updateAdvice({
                    input: {
                      id: id,
                      displayName: displayName,
                      tags: tags,
                      form: formTextAtom.getValue(),
                    },
                  });
                },
                () => hideLoadingModal()
              )}
            />
          </View>
        </Box>
      </Box>
    );
  }),
});

export default FormEditorScreen;
