import React, { useEffect, useState } from "react";
import { Text, View, Button } from "react-native";
import { useAList } from "./../../../hooks";
import Form from "../../Form";
import useValidateAndCapture from "../../Form/useValidation/useValidateAndCapture";

interface Props {
  updateItem: (question: SliderQuestionT) => void;
  question?: SliderQuestionT;
  defaultValue?: string;
}

interface SliderQuestionT {
  orientation?: "horizontal" | "vertical";
  ranges: RangeT[];
}

interface RangeT {
  readonly from: number | undefined;
  readonly to: number | undefined;
  readonly color: string;
  readonly smiley: string;
  toLabel: string | " ";
  fromLabel: string | " ";
}

// validation only happens if data is undefined because if we used !data and input was 0 it would be falsey

const Range = ({
  onChange,
  value,
}: {
  onChange: (data: RangeT) => void;
  value: RangeT;
}) => {
  return (
    <>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Form.NumericField
          label={"From:"}
          onChange={(val) => onChange({ ...value, from: val })}
          value={value.from}
          validate={(data) => {
            if (data === undefined) {
              return {
                isSuccess: false,
                error: "Required",
              };
            }

            return {
              isSuccess: true,
              data,
            };
          }}
        />

        <Form.NumericField
          label={"To:"}
          onChange={(val) => onChange({ ...value, to: val })}
          value={value.to}
          validate={(data) => {
            if (!data) {
              return {
                isSuccess: false,
                error: "Required",
              };
            }

            return {
              isSuccess: true,
              data,
            };
          }}
        />
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Form.TextField
          label={"Colour"}
          value={value.color}
          onChange={(val) => onChange({ ...value, color: val })}
          validate={(data) => {
            if (!data) {
              return {
                isSuccess: false,
                error: "A color is required",
              };
            }
            return {
              isSuccess: true,
              data,
            };
          }}
        />

        <Form.TextField
          label={"Smiley"}
          value={value.smiley}
          onChange={(val) => onChange({ ...value, smiley: val })}
          validate={(data) => {
            if (!data) {
              return {
                isSuccess: false,
                error: "A smiley value is required",
              };
            }
            return {
              isSuccess: true,
              data,
            };
          }}
        />
      </View>

      <Text>Optional labels</Text>

      <View style={{ flexDirection: "row" }}>
        <Form.TextField
          label={"From label"}
          value={value?.fromLabel ? value.fromLabel : " "}
          onChange={(val) => onChange({ ...value, fromLabel: val })}
        />
        <Form.TextField
          label={"To label"}
          value={value?.toLabel ? value.toLabel : " "}
          onChange={(val) => onChange({ ...value, toLabel: val })}
        />
      </View>
    </>
  );
};

const SliderQuestion = ({ updateItem: _onChange, question: _value }: Props) => {
  const { onChange, error, value } = useValidateAndCapture<
    SliderQuestionT,
    SliderQuestionT | undefined
  >({
    label: "Slider Question",
    validate: (data?: SliderQuestionT) => {
      if (!data || data.ranges === undefined || data.ranges.length < 1) {
        return {
          isSuccess: false,
          error: "Please enter at least one range",
        };
      }
      return {
        isSuccess: true,
        data,
      };
    },
    onChange: _onChange,
    value: _value,
  });

  const rangeList = useAList<RangeT>(value?.ranges ? value.ranges : []);

  const [orientation, setOrientation] = useState<
    SliderQuestionT["orientation"]
  >(value?.orientation || "horizontal");

  useEffect(() => {
    onChange({
      ranges: rangeList.items,
      orientation: orientation,
    });
  }, [rangeList.items, orientation]);

  const data: { label: string; value: SliderQuestionT["orientation"] }[] = [
    { label: "Horizontal", value: "horizontal" },
    { label: "Vertical", value: "vertical" },
  ];

  return (
    <>
      <Form.WithError error={error}>
        <View>
          <Form.RadioField
            selections={data}
            onChange={(e) => {
              setOrientation(e);
            }}
            value={orientation}
            label="orientation"
          />
        </View>

        {rangeList.render(({ item: range, key, updateItem, deleteItem }) => (
          <Form.SubForm key={key} name={`range.${key}`}>
            <View>
              <Text>Fill in required range info</Text>
              <Range value={range} onChange={updateItem} />
              <Button title="delete range" onPress={deleteItem} />
            </View>
          </Form.SubForm>
        ))}

        <View style={{ paddingBottom: 10 }}>
          <Button
            title="new range"
            onPress={() =>
              rangeList.addItem({
                from: undefined,
                to: undefined,
                color: "",
                smiley: "",
                toLabel: " ",
                fromLabel: " ",
              })
            }
          />
        </View>
      </Form.WithError>
    </>
  );
};

export default SliderQuestion;
