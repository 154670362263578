import type { ComponentProps } from "react";
import { SwitchInput } from "./Input";
import FieldWithValidation from "./FieldWithValidation";
import type { FieldWithValidationPropsT } from "./FieldWithValidation";

type InputT = ComponentProps<typeof SwitchInput>;

const SwitchField = ({
  onChange,
  label,
  value = false,
  validate,
}: InputT & Omit<FieldWithValidationPropsT<boolean, boolean>, "render">) => {
  return (
    <FieldWithValidation
      label={label}
      onChange={onChange}
      value={value}
      render={SwitchInput}
      validate={validate}
    />
  );
};

export default SwitchField;
