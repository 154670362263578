import type { ComponentProps } from "react";
import { TextInput } from "./Input";
import FieldWithValidation from "./FieldWithValidation";
import type { FieldWithValidationPropsT } from "./FieldWithValidation";

type InputT = ComponentProps<typeof TextInput>;

const TextField = ({
  onChange,
  label,
  value = "",
  validate,
}: Omit<FieldWithValidationPropsT<string, string>, "render"> & InputT) => {
  return (
    <FieldWithValidation
      label={label}
      onChange={onChange}
      value={value}
      render={TextInput}
      validate={validate}
    />
  );
};

export default TextField;
