import React from "react";
import Box from "./Box";
import Touch from "./Touch";
import Text from "./Text";
import CardSeperator from "./CardSeperator";
import Radio from "./Radio";
import { isWebAtom, useAtom } from "../atoms";
import FilterIcon from "../assets/icons/filter.svg";

const FilterByCategory = ({
  filterMenu = [],
  selected,
  setSelected,
  onApply,
}) => {
  const web = useAtom(isWebAtom);
  return (
    <Box>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingHorizontal={15}
        paddingVertical={web ? 15 : 10}
      >
        <Box flexDirection="row" alignItems="center">
          <FilterIcon width={20} height={20} color="#848488" />
          <Text fontSize={18} marginLeft={5}>
            Filter by category
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Touch
            paddingRight={20}
            onPress={() => {
              setSelected(null);
              onApply();
            }}
          >
            <Text fontSize={17} color={selected ? "#004e7a" : "#aeb1b5"}>
              Clear
            </Text>
          </Touch>
          <Touch onPress={onApply}>
            <Text fontSize={17} color={selected ? "#004e7a" : "#aeb1b5"}>
              Apply
            </Text>
          </Touch>
        </Box>
      </Box>
      <CardSeperator color="#f6f6f6" />

      {filterMenu.map((menu) => {
        return (
          <Box key={menu.value}>
            <Box>
              <Box
                flexDirection="row"
                paddingVertical={web ? 15 : 5}
                paddingHorizontal={40}
                alignItems="center"
                justifyContent="space-between"
                backgroundColor={
                  selected?.value === menu.value ? "#e1e1e1" : "#ffffff"
                }
              >
                <Text color="#004e7a">{menu.text}</Text>
                <Radio
                  checked={selected?.value === menu.value}
                  onPress={() => setSelected(menu)}
                />
              </Box>
            </Box>
            <CardSeperator space={0} color="#f6f6f6" />
          </Box>
        );
      })}
    </Box>
  );
};

export default FilterByCategory;
