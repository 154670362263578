import React from "react";


const PdfViewer = ({ uri }: { uri: string }) =>  (

        <iframe
          src={uri}
          style={{ display: "flex", flexDirection: "column", flex: 1 }}
          width="100%"
          height="100%"
        />
   
  );
;

export default PdfViewer;
