import React, { useState, useReducer, useMemo, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { ButtonOutline, Modal, Button } from "../../../../components";
import DateTimePicker from "./DateTimePicker";
import NumericInput from "react-native-numeric-input";
import Constants from "expo-constants";
import {Picker} from '@react-native-picker/picker';

const stateFn = (state, action) => {
  if (action.type === "changeDaysBy" || action.type == "changeDays") {
    const newHowManyDaysLater =
      action.type == "changeDaysBy"
        ? state.howManyDaysLater + action.payload
        : action.payload;
    const newSelectedDate = new Date(state.baseDate.valueOf());
    newSelectedDate.setDate(newSelectedDate.getDate() + newHowManyDaysLater);
    return {
      ...state,
      howManyDaysLater: newHowManyDaysLater,
      selectedDate: newSelectedDate,
    };
  }
  if (action.type === "setSelectedDate") {
    const msDifference = action.payload.getTime() - state.baseDate.getTime();
    const howManyDaysLater = msDifference / 1000 / 60 / 60 / 24;

    return {
      ...state,
      howManyDaysLater: howManyDaysLater,
      selectedDate: action.payload,
    };
  }
  if (action.type === "reset") {
    return initState(action.payload);
  }

  throw new Error();
};

const initState = (state) => ({
  baseDate: state.baseDate,
  selectedDate: state.baseDate,
  howManyDaysLater: 0,
  ...state,
});

// const useDateTime = ({ baseDate } = { baseDate: new Date() }) => {
const useDateTime = ({ baseDate }) => {
  const [state, dispatch] = useReducer(
    stateFn,
    { baseDate: baseDate },
    initState
  );
  const changeDays = useMemo(
    () => (d) => dispatch({ type: "changeDays", payload: d }),
    [dispatch]
  );
  const changeDaysBy = useMemo(
    () => (d) => dispatch({ type: "changeDaysBy", payload: d }),
    [dispatch]
  );

  return {
    changeDaysBy: changeDaysBy,
    changeDays: changeDays,
    setSelectedDate: (date) =>
      dispatch({ type: "setSelectedDate", payload: date }),
    selectedDate: state.selectedDate,
    baseDate: state.baseDate,
    howManyDaysLater: Math.ceil(state.howManyDaysLater),
  };
};

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }
//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true, error: error };
//   }

//   componentDidCatch(error, errorInfo) {
//     console.error("Uncaught error:", error, errorInfo);
//   }

//   render() {
//     return this.state.hasError ? (
//       <View>
//         {this.state.error && <Text>{this.state.error.toString()}</Text>}
//       </View>
//     ) : (
//       this.props.children
//     );
//   }
// }

const AndoidDT = ({ minimumDate, value, onChange }) => {
  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);

  const _onChange = (event, selectedDate) => {
    setShowDate(false);
    setShowTime(false);
    const r = onChange(event, selectedDate);
    return r;
  };

  return (
    <>
      <TouchableOpacity
        style={{ ...styles.button, flex: 1 }}
        onPress={() => setShowDate(true)}
      >
        <Text>{value.toDateString()}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{ ...styles.button, flex: 1 }}
        onPress={() => setShowTime(true)}
      >
        <Text>{value.toTimeString()}</Text>
      </TouchableOpacity>
      {showDate && (
        <DateTimePicker
          // timeZoneOffsetInMinutes={tzOffsetInMinutes}
          // minuteInterval={interval}
          // maximumDate={maximumDate}
          minimumDate={minimumDate}
          value={value}
          mode="date"
          is24Hour
          onChange={_onChange}
          // style={styles.iOsPicker}
          // textColor={textColor || undefined}
          // accentColor={accentColor || undefined}
          // neutralButtonLabel={neutralButtonLabel}
          // disabled={disabled}
        />
      )}
      {showTime && (
        <DateTimePicker
          // timeZoneOffsetInMinutes={tzOffsetInMinutes}
          // minuteInterval={interval}
          // maximumDate={maximumDate}
          minimumDate={minimumDate}
          value={value}
          // display="spinner"
          mode="time"
          is24Hour
          onChange={_onChange}
          // style={styles.iOsPicker}
          // textColor={textColor || undefined}
          // accentColor={accentColor || undefined}
          // neutralButtonLabel={neutralButtonLabel}
          // disabled={disabled}
        />
      )}
    </>
  );
};

const IOSDt = ({ minimumDate, value, onChange }) => (
  <DateTimePicker
    // timeZoneOffsetInMinutes={tzOffsetInMinutes}
    // minuteInterval={interval}
    // maximumDate={maximumDate}
    minimumDate={minimumDate}
    value={value}
    mode={"datetime"}
    is24Hour
    onChange={onChange}
    style={styles.iOsPicker}
    // disabled={disabled}
  />
);

const WebDT = ({ minimumDate, value, onChange }) => {
  const tzOffsetInMinutes = new Date().getTimezoneOffset();

  const internalDate = new Date(
    value.getTime() - tzOffsetInMinutes * 60 * 1000
  );

  const internalMinimumDate = new Date(
    minimumDate.getTime() - tzOffsetInMinutes * 60 * 1000
  );
  const minimumLocalDate = internalMinimumDate.toISOString().substring(0, 10);
  const localDate = internalDate.toISOString().substring(0, 10);

  const minimumLocalTime = internalMinimumDate.toISOString().substring(11, 19);

  const localTime = internalDate.toISOString().substring(11, 19);

  const onChangeDate = (event) => {
    const newDate = new Date(
      event.target.valueAsDate.toISOString().substring(0, 11) +
        internalDate.toISOString().substring(11, 19)
    );
    onChange(event, newDate);
  };

  const onChangeTime = (event) => {
    const newDate = new Date(
      internalDate.toISOString().substring(0, 11) +
        event.target.valueAsDate.toISOString().substring(11, 19)
    );
    onChange(event, newDate);
  };

  const [yyyy, mm, dd] = localDate.split("-").map(Number);
  const [hh, _MM] = localTime.split(":").map(Number);
  const MM = Math.floor(_MM/10) * 10;
  return (
    <View>
      <Picker
        selectedValue={yyyy}
        onValueChange={(itemValue) => {
          const newDate = new Date(
            internalDate.toISOString().substring(0, 19)
          )
          newDate.setFullYear(itemValue);
          onChange(null, newDate);
        }}
      >
        {[Number(minimumLocalDate.split("-")[0]), Number(minimumLocalDate.split("-")[0]) + 1].map(h =>
          <Picker.Item label={h} value={h} />
        )}
      </Picker>
      <Picker
        selectedValue={mm}
        onValueChange={(itemValue) => {
          const newDate = new Date(
            internalDate.toISOString().substring(0, 19)
          )
          newDate.setMonth(itemValue -1);
          onChange(null, newDate);
        }}
      >
        {[...Array(12).keys()].map(h =>
          <Picker.Item label={h +1} value={h + 1} />
        )}
      </Picker>
      <Picker
        selectedValue={dd}
        onValueChange={(itemValue) => {
          const newDate = new Date(
            internalDate.toISOString().substring(0, 19)
          )
          newDate.setDate(itemValue);
          onChange(null, newDate);
        }}
      >
        {[...Array(31).keys()].map(h =>
          <Picker.Item label={h + 1} value={h + 1} />
        )}
      </Picker>


      <Picker
        selectedValue={hh}
        onValueChange={(itemValue) => {
          const newDate = new Date(
            internalDate.toISOString().substring(0, 19)
          )
          newDate.setHours(itemValue);
          onChange(null, newDate);
        }}
      >
        {[...Array(24).keys()].map(h =>
          <Picker.Item label={h} value={h} />
        )}
      </Picker>
      <Picker
        selectedValue={MM}
        onValueChange={(itemValue) => {
          const newDate = new Date(
            internalDate.toISOString().substring(0, 19)
          )
          newDate.setMinutes(itemValue);
          onChange(null, newDate);
        }}
      >
        {[...Array(6).keys()].map(h =>
          <Picker.Item label={h * 10} value={h * 10} />
        )}
      </Picker>


      <input
        type="date"
        value={localDate}
        min={minimumLocalDate}
        name="date"
        onChange={onChangeDate}
      />
      <input
        type="time"
        value={localTime}
        name="time"
        onChange={onChangeTime}
        min={minimumLocalTime}
      />
    </View>
  );
};

const DT = ({ minimumDate, value, onChange }) => {
  const Component =
    Platform.OS === "android"
      ? AndoidDT
      : Platform.OS === "ios"
      ? IOSDt
      : WebDT;
  return (
    <Component minimumDate={minimumDate} value={value} onChange={onChange} />
  );
};

export default ({ web, onSubmit }) => {
  const baseDateInput = new Date();
  baseDateInput.setDate(baseDateInput.getDate());
  baseDateInput.setHours(9);
  baseDateInput.setMinutes(0);
  baseDateInput.setSeconds(0);
  baseDateInput.setMilliseconds(0);

  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);

  const {
    changeDays,
    changeDaysBy,
    setSelectedDate,
    selectedDate,
    baseDate,
    howManyDaysLater,
  } = useDateTime({ baseDate: baseDateInput });

  useEffect(() => changeDays(6), [changeDays]);

  const onChange = (event, selectedDate) => {
    const date = selectedDate;
    if (!!date) {
      setSelectedDate(date);
    }
    // if (Platform.OS === "android") {
    //   setShowDate(false);
    //   setShowTime(false);
    // }
  };

  return (
    <>
      <View style={{ marginTop: 30, flex: 3 }}>
        <ButtonOutline onPress={() => setShowDatePickerModal(true)}>
          Send Later
        </ButtonOutline>
      </View>
      {showDatePickerModal && (
        <Modal
          isVisible={showDatePickerModal}
          title={"Send Later"}
          onClose={() => {
            setShowDatePickerModal(false);
          }}
          style={{ maxWidth: 400 }}
        >
          <View style={styles.container}>
            <View
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              {!web ? <Text>Days from now: </Text> : null}

              {!web ? (
                <NumericInput
                  initValue={howManyDaysLater}
                  type="up-down"
                  onChange={(value) => changeDays(value)}
                  rounded
                  minValue={0}
                />
              ) : null}
            </View>

            <View
              style={{
                flexDirection: "row",
                marginVertical: 20,
                justifyContent: "space-between",
              }}
            >
              <Text>Send on:</Text>
              <DT
                minimumDate={baseDate}
                value={selectedDate}
                onChange={onChange}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginVertical: 15,
              }}
            >
              <Button
                width={140}
                onPress={(baseDateInput) => setShowDatePickerModal(false)}
              >
                Cancel
              </Button>

              <Button
                width={140}
                onPress={() => {
                  onSubmit(selectedDate);
                  setShowDatePickerModal(false);

                  // setShowDate(true);
                }}
              >
                Send Later
              </Button>
            </View>
            {/* {showDate ? <Text>{JSON.stringify(selectedDate)}</Text> : null}
            <Text>selectedDate is {JSON.stringify(selectedDate)}</Text>
            <Text>baseDate is {JSON.stringify(baseDate)}</Text> */}
          </View>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    padding: 8,
  },
  iOsPicker: {
    flex: 1,
  },
  button: {
    borderColor: "green",
    borderWidth: 1,
    padding: 5,
  },
});
