import React, { useEffect } from "react";
import { View, FlatList, TouchableOpacity, Text } from "react-native";
import ActivityScreen, {
  OptionsBack as ActivityOptionsBack, transformSubmissionObject
} from "../ActivityNavigator/ActivityScreen";
import ResultsScreen, {
  ResultOptionsBack
} from "../ActivityNavigator/ResultsScreen";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import {
  MainScreen, HeaderButton, Empty, Box, Spinner
} from "../../../components";
import { createStackNavigator } from "@react-navigation/stack";
import { NotificationCount } from "../../../containers";
import {
  currentUserAtom,
  otherUserIdAtom,
  setPdfId,
  userNotificationsAtom,
  userInvitesTransformedAtom,
  markNotification,
  rejectUserInvites,
  acceptUserInvites,
  useAtom,
  currentOrganisationAtom,
  isWebAtom
} from "../../../atoms";
import { contactByUserIdOrgId, getSubmissionData, getUserById } from "../../../graphql";
import { handleError, formatCalender } from "../../../utils";
import { options, displayTitle } from "../../../headerUtils";
import { screen } from "../../../reactUtils";
import { useQuery } from "react-query";

const TheContactButton = ({ navigation, id, viewed, createdBy, submissionData, body }) => {
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const getSubmissionPdfDownloadRoute = async () => {
    const result = await getSubmissionData({
      id: submissionData.submissionId
    });

    const transformedData = transformSubmissionObject({ submission: result.data.getUserSubmission });

    return {
      pdfDownloadUrl: result.data.getUserSubmission.pdfDownloadUrl,
      updatedAt: result.data.getUserSubmission.updatedAt,
      results: transformedData.results,
      tableResults: transformedData.tableResults,
      title: transformedData.title,
      answers: transformedData.answers,
      name: body
    };
  };

  const {
    data,
    isLoading
  } = useQuery([currentOrganisation.id, id, "getSubmissionPdfDownloadRoute", createdBy], async () => await getSubmissionPdfDownloadRoute());

  const navigateToResults = async () => {
    await markNotification({ id, viewed });
    otherUserIdAtom.update(createdBy);
    navigation.navigate("ResultsScreen", {
      name: data.name,
      title: data.title,
      results: data.results,
      pdfDownloadUrl: data.pdfDownloadUrl,
      tableResults: data.tableResults,
      updatedAt: data.updatedAt
    });
  };

  if (!data) {
    return <Spinner size="small" />;
  }
  return <HeaderButton
    title={"View"}
    onPress={handleError(async () => {
      await navigateToResults();
    })}
  />;
};

const NotificationsScreen = screen(({ navigation }) => {
  const userNotifications = useAtom(userNotificationsAtom);
  const userInvites = useAtom(userInvitesTransformedAtom);
  const list = userInvites.concat(userNotifications);


  return (<MainScreen backgroundColor="#F2F2F7" isList={true}>
    <FlatList
      data={list}
      keyExtractor={(item) => item.id}
      ListEmptyComponent={<Empty title="You don't have any notifications" description="" />}
      renderItem={({
                     item: {
                       id, type, title, body, viewed, createdAt, createdBy, data, userId
                     }
                   }) => {
        const TheButton = () => type === "submission_complete" ? (<TheContactButton
          navigation={navigation}
          id={id}
          viewed={viewed}
          createdBy={createdBy}
          body={body}
          submissionData={data}
        />) : (<HeaderButton
          title={type === "invite" ? "Accept" : "View"}
          onPress={handleError(async () => {
            if (type === "invite") {
              acceptUserInvites(data.ids);
            } else if (type === "new_content") {
              markNotification({ id, viewed });
              setPdfId(data.adviceId);
              navigation.navigate("PdfScreen", {
                id: data.adviceId, contentId: id, consumer: true
              });
            }
          })}
        />);

        return (<View
          style={{
            backgroundColor: "white", borderBottomColor: "#c6c6c8", borderBottomWidth: 1
          }}
        >
          <View
            style={{
              flex: 1, paddingTop: 20, paddingBottom: 20, marginLeft: 20, marginRight: 20
            }}
          >
            <Text style={{ fontSize: 16, color: "#000000" }}>{title}</Text>
            <Text
              style={{
                fontSize: 16, color: "#000000", fontWeight: viewed ? "400" : "700", marginTop: 10
              }}
            >
              {body}
            </Text>
            <Text style={{ fontSize: 14, marginTop: 10 }}>
              {createdAt ? formatCalender(createdAt) : ""}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingBottom: 10,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            <TheButton />
            {type === "invite" ? (<HeaderButton
              title="Reject"
              onPress={handleError(async () => {
                rejectUserInvites(data.ids);
              })}
            />) : null}
          </View>
        </View>);
      }}
    />
  </MainScreen>);
});

const Stack = createStackNavigator();
export default ({ route: { params } }) => {
  const web = useAtom(isWebAtom);
  return (<Stack.Navigator>
    <Stack.Screen
      name="NotificationsScreen"
      component={NotificationsScreen}
      initialParams={params}
      options={options(() => (<Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        marginRight={20}
      >
        {displayTitle("Notifications")}
        <NotificationCount />
      </Box>))}
      // options={options("Notifications", ({ route, navigation }) => {
      //   const { showBackButton = false } = params;
      //   return showBackButton ? (
      //     <TouchableOpacity
      //       style={{ paddingHorizontal: 20 }}
      //       onPress={() => navigation.goBack()}
      //     >
      //       <Text style={{ fontSize: 14 }}>Back</Text>
      //     </TouchableOpacity>
      //   ) : null;
      // })}
    />
    <Stack.Screen
      name="PdfScreen"
      component={PdfScreen}
      options={PdfOptions(web)}
    />
    <Stack.Screen
      name="FormScreen"
      component={FormScreen}
      options={FormOptions(web)}
    />
    <Stack.Screen
      name="ActivityScreen"
      component={ActivityScreen}
      options={ActivityOptionsBack(web)}
    />
    <Stack.Screen
      name="ResultsScreen"
      component={ResultsScreen}
      options={ResultOptionsBack(web)}
    />
  </Stack.Navigator>);
};
