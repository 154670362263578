import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
} from "react-native";

import { Button } from "../../../components";
import ArrowForwardIcon from "../../../assets/icons/arrow-forward.svg";
import CircleSelectIcon from "../../../assets/icons/radio_filter_off.svg";
import CircleTickedIcon from "../../../assets/icons/radio_ticked.svg";
import FilterOffIcon from "../../../assets/icons/filter_off.svg";

const Divider = () => (
  <View
    style={{
      borderBottomWidth: 2,
      borderBottomColor: "#C6C6C8",
      margin: 5,
      padding: 20
    }}
  />
);

const HeaderForContentFilter = ({
  onSave,
  onClear,
  title,
  buttonColor,
  buttonTwoTitle,
}) => (
  <View style={{ flexDirection: "row", alignItems: "center" }}>
    <View style={{ flexDirection: "row" }}>
      <FilterOffIcon
        width={28}
        height={28}
        color="#999999"
        style={{ marginLeft: 15 }}
      />

      <Text style={{ fontSize: 18, marginTop: 7 }}>{title}</Text>
    </View>
    <View
      style={{
        flexDirection: "row",
        marginTop: 7,
        flex: 1,
        justifyContent: "flex-end",
      }}
    >
      <Button
        color={buttonColor}
        fontSize={18}
        style={{
          backgroundColor: "transparent",
          width: 49,
          marginRight: 15,
        }}
        onPress={onClear}
      >
        Clear
      </Button>

      <Button
        color={buttonColor}
        fontSize={18}
        style={{ backgroundColor: "transparent", width: 80, marginRight: 15 }}
        onPress={onSave}
      >
        {buttonTwoTitle}
      </Button>
    </View>
  </View>
);

function RenderListItem({
  item: { name, item },
  onPress,
  isSelected,
  filterItems,
}) {
  return (
    <>
      <TouchableOpacity onPress={() => onPress({ name, item })}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={styles.item}>{name}</Text>
          {Array.isArray(item) ? (
            <ArrowForwardIcon width={28} height={28} color="#9B9B9B" />
          ) : isSelected ? (
            <CircleTickedIcon />
          ) : (
            <CircleSelectIcon color={"#AAAAAA"} />
          )}
        </View>
      </TouchableOpacity>
      {Array.isArray(item) && (
        <View>
          <FlatList
            data={item.filter((item) => filterItems.includes(item.item))}
            renderItem={({ item }) => (
              <Text style={styles.listTagItem}>{item.name}</Text>
            )}
            ItemSeparatorComponent={Divider}
            keyExtractor={(item) => item.name}
          />
        </View>
      )}
    </>
  );
}

function List({
  setFilterItems,
  filterItems,
  resetFilterItems,
  name,
  items,
  onPress,
}) {
  const [focused, setFocused] = useState(null);
  const itemOnPress = (item) =>
    Array.isArray(item.item)
      ? setFocused(item)
      : setFilterItems((prev) =>
          prev.includes(item.item)
            ? prev.filter((i) => i !== item.item)
            : [item.item, ...prev]
        );

  return focused ? (
    <List
      name={focused.name}
      setFilterItems={setFilterItems}
      resetFilterItems={() =>
        setFilterItems((prev) =>
          prev.filter((i) => !focused.item.find((f) => f.item === i))
        )
      }
      items={focused.item}
      filterItems={filterItems}
      onPress={() => setFocused(null)}
    />
  ) : (
    <View style={{ height: "100%" }}>
      <HeaderForContentFilter
        title={`Filter by ${name}`}
        onSave={onPress}
        onClear={resetFilterItems}
        buttonTwoTitle="Save"
        buttonColor={"#004D7A"}
      />
      <Divider />

      <View style={{ marginHorizontal: 22, flex: 1 }}>
        <FlatList
          data={items}
          renderItem={(props) => (
            <RenderListItem
              onPress={itemOnPress}
              isSelected={filterItems.includes(props.item.item)}
              filterItems={filterItems}
              {...props}
            />
          )}
          ItemSeparatorComponent={Divider}
          keyExtractor={(item) => item.name}
        />
      </View>
    </View>
  );
}

export default List;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 22,
  },
  item: {
    margin: 5,
    color: "#004D7A",
    fontSize: 18,
    alignSelf: "center",
  },
  listTagItem: {
    marginLeft: 24,
    marginTop: 10,
    color: "black",
    fontSize: 15,
  },
});
