import React from "react";
import { TouchableOpacity, View, Text } from "react-native";

export default function ({ item, onPress }) {
  console.log("this is the SELECT item", item);
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          padding: 20,
          borderBottomColor: "#c6c6c8",
          borderBottomWidth: 1,
          backgroundColor: "white",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          {item.name ? (<Text style={{ fontSize: 16, color: "#000000" }}>{item.name}</Text>) : (<Text style={{ fontSize: 16, color: "#000000" }}>{item.contact.givenName} {item.contact.familyName}</Text>)}

        </View>
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontSize: 16, color: "#014d78" }}>{item.submission? item.submission.answers ? "Form Completed" : "Form Uncompleted" : "View sent pdf"}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
