import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import SelectScreen from "./SelectScreen";
import SignInScreen from "./SignInScreen";
import SignUpScreen from "./SignUpScreen";
import ConfirmSignUpScreen from "./ConfirmSignUpScreen";
import ForgotPasswordScreen from "./ForgotPasswordScreen";
import ResetPasswordScreen from "./ResetPasswordScreen";
import TermsScreen from "./TermsScreen";
import ChooseOrganisationScreen from "./ChooseOrganisationScreen";
import LinkExpiredScreen from "./LinkExpiredScreen";
import AcceptPendingInvitationsScreen from "./AcceptPendingInvitationsScreen";
import HomeNavigator from "../Home";
import UpdateScreen from "./UpdateScreen";
import AccountTypeScreen from "./AccountTypeScreen";

const Stack = createStackNavigator();
const noHeader = { header: () => null };
export default ({ initialRouteName, initialParams }) => {
  // console.log("THESE ARE THE INITIAL PARAMS", initialParams);
  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{ gestureEnabled: false }}
    >
      <Stack.Screen
        name="SelectScreen"
        options={noHeader}
        component={SelectScreen}
      />
      <Stack.Screen
        name="AccountTypeScreen"
        options={noHeader}
        component={AccountTypeScreen}
      />
      <Stack.Screen
        name="SignInScreen"
        component={SignInScreen}
        initialParams={{ isSignout: false, email: "" }}
        options={({ route }) => ({
          header: noHeader.header,
          animationTypeForReplace: route.params.isSignout ? "pop" : "push",
        })}
      />
      <Stack.Screen
        name="SignUpScreen"
        options={noHeader}
        component={SignUpScreen}
        initialParams={initialParams}
      />
      <Stack.Screen
        name="ConfirmSignUpScreen"
        options={noHeader}
        component={ConfirmSignUpScreen}
        initialParams={initialParams}
      />
      <Stack.Screen
        name="ForgotPasswordScreen"
        options={noHeader}
        component={ForgotPasswordScreen}
      />
      <Stack.Screen
        name="ResetPasswordScreen"
        options={noHeader}
        component={ResetPasswordScreen}
      />
      <Stack.Screen
        name="TermsScreen"
        options={{ title: "Terms & Conditions" }}
        component={TermsScreen}
        initialParams={initialParams}
      />
      <Stack.Screen
        name="AcceptPendingInvitationsScreen"
        options={noHeader}
        component={AcceptPendingInvitationsScreen}
      />
      <Stack.Screen
        name="ChooseOrganisationScreen"
        options={noHeader}
        component={ChooseOrganisationScreen}
      />
      <Stack.Screen
        name="LinkExpiredScreen"
        options={noHeader}
        component={LinkExpiredScreen}
      />
      <Stack.Screen
        name="UpdateScreen"
        options={noHeader}
        component={UpdateScreen}
        initialParams={initialParams}
      />
      <Stack.Screen
        name="HomeNavigator"
        options={noHeader}
        component={HomeNavigator}
      />
    </Stack.Navigator>
  );
};
