import { TextInput, StyleSheet } from "react-native";

const NumericInput = ({
  onChange,
  value,
}: {
  onChange: (value?: number) => void;
  value?: number;
}) => (
  <TextInput
    value={value === undefined ? "" : value.toString()}
    style={styles.input}
    onChangeText={(newValue) => {
      if (newValue === "") {
        onChange(undefined);
      } else {
        const n = parseInt(newValue);

        !isNaN(n) && onChange(n);
      }
    }}
    keyboardType="numeric"
  />
);

export default NumericInput;

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});
