import React from 'react';
import { Linking, Platform, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import AuthScreen from '../../components/layouts/AuthScreen';
import Button from '../../components/Button';
import { Title, Text, ButtonContainer, Footer, FooterValue } from './common';
import { useAtom, isWebAtom } from '../../atoms';
import { getAppUrl, testId } from '../../utils';

export default () => {
  const web = useAtom(isWebAtom);
  return (
    <AuthScreen onLogoPress={() => Linking.openURL(`https://iowna.com`)}>
      <Ionicons name="ios-warning" size={web ? 60 : 50} color="#eaffff" />
      <Title>
        <Text
          style={{
            fontWeight: 500,
            fontSize: web ? 30 : 20,
            lineHeight: web ? 40 : 30,
          }}
        >
          Your link has expired
        </Text>
      </Title>
      <Text
        style={{
          fontSize: web ? 20 : 16,
          lineHeight: web ? 30 : 24,
          paddingHorizontal: 40,
          textAlign: 'center',
          maxWidth: 500,
        }}
      >
        The information you were sent is still available when you log into the iOWNA app.
      </Text>
      <ButtonContainer>
        <Button onPress={() => Linking.openURL(`${getAppUrl()}/SignUp`)} {...testId('buttonSignUp')}>
          SIGN UP
        </Button>
      </ButtonContainer>
      <Footer style={{ marginTop: 10, justifyContent: 'center' }}>
        <TouchableOpacity onPress={() => Linking.openURL(`${getAppUrl()}/SignIn`)} {...testId('buttonSignIn')}>
          <FooterValue>SIGN IN</FooterValue>
        </TouchableOpacity>
      </Footer>
    </AuthScreen>
  );
};
