import React from "react";
import { View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { get, find } from "lodash";
import { createStackNavigator } from "@react-navigation/stack";
import { Touch } from "../../../components";

import { useAtom, isWebAtom } from "../../../atoms";

import HeaderButton from "../../../components/HeaderButton";
import { BreadCrumb } from "../../../containers";
import { options, optionsBack } from "../../../headerUtils";
import ContactsScreen from "./ContactsScreen";
import ListScreen from "./DistributionListScreen";
import DistributionListActivityScreen from "./DistributionListActivityScreen";

import PdfScreen, { PdfOptions } from "./../ContentNavigator/PdfScreen";
import ContentActivityScreen from "./ContentActivityScreen";
import FormScreen, { FormOptions } from "./../ContentNavigator/FormScreen";

const Stack = createStackNavigator();
export default () => {
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ListsScreen"
        component={ListScreen}
        options={options(() => (
          <BreadCrumb
            parts={["currentOrganisation.name", "Distribution Lists"]}
          />
        ))}
      />
      <Stack.Screen
        name="ContactsScreen"
        component={ContactsScreen}
        options={optionsBack(web, ({ route }) => (
          <BreadCrumb
            parts={[
              "currentOrganisation.name",
              "Distribution Lists",
              "Contacts",
            ]}
          />
        ))}
      />
      <Stack.Screen
        name="DistributionListActivityScreen"
        component={DistributionListActivityScreen}
        options={optionsBack(
          web,
          ({ route }) => (
            <BreadCrumb
              parts={[
                "currentOrganisation.name",
                "Distribution List Activity",
                route.params.item.name,
              ]}
            />
          ),
          ({ navigation, route }) => (
            <>
              <View style={{ flexDirection: "row" }}>
                <HeaderButton
                  title="Share Content"
                  onPress={() =>
                    navigation.navigate("SendPlanNavigator", {
                      screen: "SendScreen",
                      params: {
                        selectedDistributionLists: [route.params.item],
                      },
                    })
                  }
                />

                {/* <HeaderButton
                  customIcon={
                    <AntDesign
                      name="linechart"
                      size={24}
                      color="#004E7A"
                      style={{ marginRight: 10 }}
                    />
                  }
                  title="Dashboard"
                  onPress={() => navigation.navigate("ContentDashboardScreen")}
                ></HeaderButton> */}
              </View>
            </>
          )
        )}
      />

      <Stack.Screen
        name="ContentActivityScreen"
        component={ContentActivityScreen}
        options={optionsBack(web, ({ route }) => (
          <BreadCrumb
            parts={[
              "currentOrganisation.name",
              "Content Dashboard",
              route.params.item.content.displayName,
            ]}
          />
        ))}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={({ navigation }) => ({
          headerShown: true,
          gestureEnabled: false,
          headerLeft: () => (
            <Touch
              marginLeft={10}
              onPress={() => {
                navigation.goBack();
              }}
            >
              <Ionicons name="arrow-back-outline" size={32} color="#004e79" />
            </Touch>
          ),
        })}
        options={FormOptions(web)}
      />
    </Stack.Navigator>
  );
};
