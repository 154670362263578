import { useEffect, useState } from "react";
import { StyleSheet, View, Switch } from "react-native";

const SwitchInput = ({
  value = false,
  onChange,
}: {
  value?: boolean;
  onChange: (value: boolean) => void;
}) => {
  const [isEnabled, setIsEnabled] = useState(value);

  useEffect(() => {
    onChange(isEnabled);
  }, [isEnabled]);

  return (
    <View style={styles.container}>
      <Switch
        trackColor={{ false: "#767577", true: "#5EBA7D" }}
        thumbColor="#f5f5f5"
        ios_backgroundColor="#3e3e3e"
        // @ts-ignore
        activeThumbColor="#f5f5f5"
        onValueChange={() => setIsEnabled((prevState) => !prevState)}
        value={value}
      />
    </View>
  );
};

export default SwitchInput;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 15,
  },
});
