import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Modal,
  View,
  FlatList,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import useSelect from "../../hooks/useSelect";

interface Props<T> {
  readonly placeholder?: string;
  readonly data: { label: string; value: T }[];
  onChange: (item: T) => void;
  value?: T;
}

interface DataT<T> {
  readonly label: string;
  readonly value: T;
}

const DropDown = <T,>({
  placeholder,
  data,
  onChange,
  value: _selected,
}: Props<T>) => {
  const [visible, setVisible] = useState(false);
  const { selections, selected } = useSelect({ data, initialValue: _selected });
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownLeft, setDropdownLeft] = useState(0);
  const [height, setHeight] = useState(0);

  const DropdownButton = useRef();

  const toggleDropdown = (): void => {
    visible ? setVisible(false) : openDropdown();
  };

  const openDropdown = (): void => {
    DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
      setDropdownTop(py + h);
      setDropdownLeft(_px);
      setHeight(h);
    });
    setVisible(true);
  };

  useEffect(() => {
    if (selected.value !== undefined) {
      onChange(selected.value);
    }
  }, [selected.value, onChange]);

  const renderItem = ({
    item: { select, label },
  }: {
    item: { key: string; select: () => void; label: string };
  }) => (
    <TouchableOpacity
      style={styles.item}
      onPress={() => {
        select();
        setVisible(false);
      }}
    >
      <Text>{label} </Text>
    </TouchableOpacity>
  );

  //the transparent prop on the Modal means that the modal will fill the screen but any part without content will have a transparent overlay.
  const RenderDropdown = () => {
    return (
      <Modal visible={visible} transparent animationType="none">
        <TouchableOpacity
          style={styles.overlay}
          onPress={() => setVisible(false)}
        >
          <View
            style={[styles.dropdown, { top: dropdownTop, left: dropdownLeft }]}
          >
            <FlatList
              data={selections}
              renderItem={renderItem}
              keyExtractor={({ key }) => key}
            />
          </View>
        </TouchableOpacity>
      </Modal>
    );
  };

  return (
    <TouchableOpacity
      ref={DropdownButton}
      style={styles.button}
      onPress={toggleDropdown}
    >
      <RenderDropdown />
      <Text style={styles.buttonText}>
        {selected.label ? selected.label : placeholder}
      </Text>
      <AntDesign name="caretdown" size={24} color="black" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",

    backgroundColor: "#efefef",
    height: 40,
    width: "100%",

    paddingHorizontal: 10,
    zIndex: 1,
  },
  buttonText: {
    flex: 1,
    textAlign: "left",
  },
  dropdown: {
    position: "absolute",
    backgroundColor: "#fff",
    width: "100%",
    // width: "200px",
    paddingHorizontal: 10,
    shadowColor: "#000000",
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
  },
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderBottomWidth: 1,
  },
  overlay: {
    width: "100%",
    height: "100%",
  },
});

export default DropDown;
