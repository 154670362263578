import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const Pagination = ({ style, buttonSize, currentPage, maxPage, next, prev }) => {
  const isPrevDisabled = currentPage <= 1;
  const isNextDisabled = currentPage >= maxPage;
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <TouchableOpacity
        style={{
          marginHorizontal: 10,
        }}
        disabled={isPrevDisabled}
        onPress={prev}
      >
        <Ionicons name="ios-chevron-back-circle-outline" size={buttonSize} color={isPrevDisabled ? '#CDCDE0' : '#393F46'} />
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          marginHorizontal: 10,
        }}
        disabled={isNextDisabled}
        onPress={next}
      >
        <Ionicons name="ios-chevron-forward-circle-outline" size={buttonSize} color={isNextDisabled ? '#CDCDE0' : '#393F46'} />
      </TouchableOpacity>
    </View>
  );
};

Pagination.defaultProps = {
  style: {},
  buttonSize: 40,
};

export default Pagination;
