import app from "./firebaseConfig";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
// import * as _fbmethods from "firebase/analytics";

const analytics = getAnalytics(app);

// function _normaliseAnalytics(_analyticsObj) {
//   const handler = {
//     get(target, propKey, _receiver) {
//       const origMethod = target[propKey];
//       return function (...args) {
//         return propKey in target
//           ? origMethod.apply(this, [analytics, ...args])
//           : _fbmethods[propKey](target, ...args);
//         return _fbmethods[propKey](analytics, ...args);
//         return origMethod.apply(this, [analytics, ...args]);
//       };
//     },
//   };
//   return new Proxy(_analyticsObj, handler);
// }

// export default () => _normaliseAnalytics(analytics);

export default {
  logEvent: (
    eventName: string,
    eventParams?: { [key: string]: any } | undefined
  ): void => logEvent(analytics, eventName, eventParams),
  setUserId: (id: string | null): void => setUserId(analytics, id),
  setUserProperties: (properties: { [key: string]: string | null }): void =>
    setUserProperties(analytics, properties),
};
