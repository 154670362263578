import { useContext, useMemo } from "react";
import useValidateField from "./useValidateField";
import SubFormContext from "../SubForm/SubFormContext";

const useCaptureInvalidFields = <VT, IT>(
  name: string,
  fn: Parameters<typeof useValidateField<VT, IT>>[0]["validate"]
) => {
  const { path, setErrors } = useContext(SubFormContext);
  const fieldPath = `${path}.${name}`;
  const validate = useMemo(
    () => (data: VT | IT) => {
      const result = fn(data);
      Promise.resolve(result).then((result) => {
        if (setErrors !== undefined) {
          if (!result.isSuccess) {
            setErrors((prev) => {
              const filtered = prev.filter(
                ({ path: filterPath }) => filterPath !== fieldPath
              );
              return [...filtered, { path: fieldPath, error: result.error }];
            });
          } else {
            setErrors((prev) => {
              const filtered = prev.filter(
                ({ path: filterPath }) => filterPath !== fieldPath
              );
              return filtered;
            });
          }
        }
      });
      return result;
    },
    [fn, path, name, setErrors]
  );
  return {
    validate,
  };
};

export default useCaptureInvalidFields;
