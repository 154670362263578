import React, { useEffect } from "react";
import { Text, View, Button } from "react-native";
import { useAList } from "./../../../hooks";
import Form from "../../Form";
import useValidateAndCapture from "../../Form/useValidation/useValidateAndCapture";

interface Props {
  updateItem: (question: RatingQuestionT) => void;
  question?: RatingQuestionT;
  defaultValue?: string;
}

interface RatingQuestionT {
  scale?: RatingScaleT[];
}

interface RatingScaleT {
  value: number | undefined;
  topLabel: string;
  bottomLabel: string;
}

const Rating = ({
  onChange,
  value,
}: {
  onChange: (data: RatingScaleT) => void;
  value: RatingScaleT;
}) => {
  return (
    <>
      <Form.NumericField
        label={"Value:"}
        onChange={(val) => onChange({ ...value, value: val })}
        value={value.value}
        validate={(data) => {
          if (!data) {
            return {
              isSuccess: false,
              error: "Required",
            };
          }
          return {
            isSuccess: true,
            data,
          };
        }}
      />

      <Form.TextField
        label={"Top Label"}
        value={value.topLabel}
        onChange={(val) => onChange({ ...value, topLabel: val })}
        validate={(data) => {
          return {
            isSuccess: true,
            data,
          };
        }}
      />
      <Form.TextField
        label={"Bottom Label"}
        value={value.bottomLabel}
        onChange={(val) => onChange({ ...value, bottomLabel: val })}
        validate={(data) => {
          return {
            isSuccess: true,
            data,
          };
        }}
      />
    </>
  );
};

const RatingQuestion = ({ updateItem: _onChange, question: _value }: Props) => {
  const { onChange, error, value } = useValidateAndCapture<
    RatingQuestionT,
    RatingQuestionT | undefined
  >({
    label: "Rating Question",
    validate: (data?: RatingQuestionT) => {
      if (!data || data.scale === undefined || data.scale.length === 0) {
        return {
          isSuccess: false,
          error: "Please enter at least one rating",
        };
      }
      return {
        isSuccess: true,
        data,
      };
    },
    onChange: _onChange,
    value: _value,
  });

  const ratingScaleList = useAList<RatingScaleT>(
    value?.scale
      ? value.scale
      : [{ value: undefined, topLabel: "", bottomLabel: "" }]
  );

  useEffect(() => {
    onChange({
      scale: ratingScaleList.items,
    });
  }, [ratingScaleList.items]);

  return (
    <>
      <Form.WithError error={error}>
          {ratingScaleList.render(
            ({ item: e, key, updateItem, deleteItem }) => (
              <Form.SubForm key={key} name={`scale.${key}`}>
                <View>
                  <Text>Please fill in rating information</Text>
                  <Rating onChange={updateItem} value={e} />
                  <Button title="Delete" onPress={deleteItem} />
                  </View>
              </Form.SubForm>
            )
          )}

          <View>
            <Button
              title="Add Rating"
              onPress={() =>
                ratingScaleList.addItem({
                  value: undefined,
                  topLabel: "",
                  bottomLabel: "",
                })
              }
            />
          </View>
       
      </Form.WithError>
    </>
  );
};

export default RatingQuestion;
