import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { testId } from '../utils';

export default function AccordionButton({ items }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: "wrap",
        justifyContent: 'flex-end',
        borderTopColor: '#c6c6c8',
        borderBottomColor: '#c6c6c8',
        borderTopWidth: 1,
        borderBottomWidth: 1,
      }}
    >
      {items.map(({ title, icon, onPress }, index) => (
        <TouchableOpacity key={title + index}
          {...testId(title)}
          style={{
            // flex: 1,
            flexGrow: 1,
            height: 52,
            minWidth: "25%",
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
            backgroundColor: '#f6f6f6',
            borderWidth: 1,
            borderColor: '#c6c6c8',
          }}
          onPress={onPress}
        >
          {icon}
          <Text style={{ color: '#004e7a', fontWeight: '600', marginTop: 3 }}>{title}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
}
