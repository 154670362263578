import { useState, useEffect, useLayoutEffect } from "react";
import type { ComponentProps } from "react";
import { View, Button, ScrollView, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { containerWithoutLoader, screen } from "../../../reactUtils";
import { optionsBack } from "../../../headerUtils";
import { Box, Touch, HeaderButton, Header } from "../../../components";
import { Ionicons } from "@expo/vector-icons";
import {
  useAtom,
  pdfScreenDataAtom,
  atom,
  showLoadingModal,
  hideLoadingModal,
  isWebAtom,
} from "../../../atoms";
import { BreadCrumb } from "../../../containers";
import { jsonStringifySorted } from "../../../utils";
import { useMutation } from "react-query";
import { updateAdvice } from "../../../graphql";
import { IOWNAError } from "../../../utils";
import FormBuilder from "../../../new/components/formbuilder/FormBuilder";
import Form from "../../../new/components/Form";

export const formTextAtom = atom("");

type FormT = ComponentProps<typeof FormBuilder>["value"];

const FormBuilderScreen = screen(() => {
  const navigation = useNavigation();
  const web = useAtom(isWebAtom);

  const [isValid, setIsValid] = useState(false);

 

  const { form } = useAtom(pdfScreenDataAtom) as unknown as { form: FormT };

  const [formToEdit, setFormToEdit] = useState<FormT>(
    form
      ? form
      : {
          title: "",
          id: "",
          version: "v1.0.0",
          subTitle: "",
          results: [],
          pages: [],
        }
  );

  const keyOrder = ["id", "version", "title", "subTitle", "results", "pages"];

  const formText = jsonStringifySorted(formToEdit, {
    cmp: (a, b) => keyOrder.indexOf(a.key) - keyOrder.indexOf(b.key),
    space: 2,
  });

  const [pageArrayUndefined, setPageArrayUndefined] = useState(true);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => {
        return web ? (
          <>
            <View style={{ flexDirection: "row", paddingTop: 13 }}>
              <HeaderButton
                title="Preview Form"
                onPress={() => {
                  const form = JSON.parse(formTextAtom.getValue());
                  if (isValid && !pageArrayUndefined && form.pages.length > 0) {
                    navigation.navigate("FormScreen", {
                      id: id,
                      ...form,
                    });
                  } else {
                    alert(
                      "You need pages to preview the form or you have errors to fix"
                    );
                  }
                }}
              />
            </View>
          </>
        ) : null;
      },
    });
  }, [navigation, isValid]);

  useEffect(() => {
    if (formToEdit?.pages === undefined) {
      setPageArrayUndefined(true);
    } else {
      setPageArrayUndefined(false);
    }
  }, [formToEdit]);

  useEffect(() => {
    formTextAtom.update(formText);
  }, [formToEdit]);

  const { id, displayName, tags } = useAtom(pdfScreenDataAtom);

  const mutation = useMutation(
    () => {
      showLoadingModal("Saving form");
      return updateAdvice({
        input: {
          id: id,
          displayName: displayName,
          tags: tags,
          form: formTextAtom.getValue(),
        },
      })
        .then((res) => res.data.updateAdvice)
        .catch((err) => {
          const message = err.errors?.[0]?.message;
          if (message) {
            throw new IOWNAError("Unable to save form");
          } else {
            throw new Error(JSON.stringify(err));
          }
        });
    },
    {
      onSuccess: () => {
        hideLoadingModal();
      },
    }
  );

  return (
    <ScrollView style={{ backgroundColor: "white", paddingBottom: 50 }}>
      <Form.SubForm
        name="form"
        renderOnClean={() => {
          useEffect(() => {
            return setIsValid(true);
          }, []);
          return (
            <Button
              onPress={() => {
                mutation.mutate();
              }}
              title="submit"
            />
          );
        }}
        renderErrors={() => {
          setIsValid(false);
          return <></>;
        }}
      >
        <FormBuilder
          onChange={(data) => data && setFormToEdit(data)}
          value={form ? form : formToEdit}
        />
      </Form.SubForm>
    </ScrollView>
  );
});

export const FormBuilderScreenOptions = (web) => ({
  headerShown: true,
  headerTitle: containerWithoutLoader(() => {
    const { id, displayName, tags } = useAtom(pdfScreenDataAtom);

    return (
      <BreadCrumb
        parts={["currentOrganisation.name", "Content", displayName]}
      />
    );
  }),
});
export default FormBuilderScreen;
