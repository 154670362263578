import React from 'react';
import { View, Text, FlatList } from 'react-native';
import Empty from './Empty';
import Spinner from './Spinner';

const Title = ({ title }) => (
  <View>
    <Text style={{ fontSize: 18, fontWeight: '500', color: 'rgb(0, 78, 122)' }}>{title}</Text>
  </View>
);

export default ({ title, emptyTitle, emptyDescription, data, contentContainerStyle, loading, error, ...props }) => {
  if (loading) {
    return (
      <View style={contentContainerStyle}>
        <Title title={title} />
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </View>
      </View>
    );
  }
  if (error) {
    return (
      <View style={contentContainerStyle}>
        <Title title={title} />
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontSize: 22, color: '#FF3e50' }}>{error}</Text>
        </View>
      </View>
    );
  }
  if (data.length === 0) {
    return (
      <View style={contentContainerStyle}>
        <Title title={title} />
        <Empty center={true} title={emptyTitle} description={emptyDescription} />
      </View>
    );
  }
  return (
    <View style={contentContainerStyle}>
      <Title title={title} />
      <FlatList data={data} keyExtractor={(item) => item.id} {...props} />
    </View>
  );
};
