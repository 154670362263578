import React, { useState } from "react";
import Modal from "./Modal";
import styled from "@emotion/native";
import Spinner from "./Spinner";
import { testId } from "../utils";
import { alertAtom, useAtom, resetAlert } from "../atoms";
import { useNavigation } from "@react-navigation/native";

const Popup = styled.View`
  flex-direction: column;
`;

const ContentWrapper = styled.View`
  padding: 20px;
`;

const Title = styled.Text`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Message = styled.Text`
  font-size: 16px;
  line-height: 22px;
`;

const Divider = styled.View`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ebebf1;
`;

const ActionWrapper = styled.View`
  flex-direction: row;
`;

const ButtonContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ebebf1;
`;

const TouchableOpacity = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50;
`;

const ButtonText = styled.Text`
  font-size: 14px;
`;

const Button = ({ loading = false, onPress, children, ...props }) => {
  return (
    <ButtonContainer>
      {loading ? (
        <Spinner />
      ) : (
        <TouchableOpacity onPress={onPress} {...props}>
          <ButtonText>{children}</ButtonText>
        </TouchableOpacity>
      )}
    </ButtonContainer>
  );
};

const Alert = () => {
  const {
    isVisible = false,
    title = "",
    msg = "",
    onConfirm,
    onCancel,
  } = useAtom(alertAtom);
  const [submitting, setSubmitting] = useState(false);

  const onClickOk = async () => {
    if (onConfirm) {
      setSubmitting(true);
      const shouldClose = await onConfirm();
      if (shouldClose) {
        resetAlert();
      }
      setSubmitting(false);
    } else {
      resetAlert();
    }
  };

  const onClickCancel = () => {
    resetAlert();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      hideCrossBtn
      onBackdropPress={() => {}}
      style={{ maxWidth: 400 }}
      contentStyle={{ padding: 0 }}
      onClose={() => {
        resetNotification();
        setSubmitting(false);
      }}
    >
      <Popup>
        <ContentWrapper>
          <Title {...testId("Title")}>{title}</Title>
          <Message {...testId("Message")}>{msg}</Message>
        </ContentWrapper>
        <ActionWrapper>
          <Button
            loading={submitting}
            onPress={onClickOk}
            {...testId("OKButton")}
          >
            OK
          </Button>
          {onConfirm && !submitting && (
            <>
              <Divider />
              <Button onPress={onClickCancel} {...testId("CancelButton")}>
                CANCEL
              </Button>
            </>
          )}
        </ActionWrapper>
      </Popup>
    </Modal>
  );
};

export default Alert;
