import type { ReactNode } from "react";
import { View, Text } from "react-native";

const Field = ({ label, input }: { label: string; input: ReactNode }) => {
  return (
    <View>
      <Text>{label}</Text>
      {input}
    </View>
  );
};

export default Field;
