import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Auth, Amplify } from "aws-amplify";
import * as Notifications from "expo-notifications";
import * as SecureStore from "expo-secure-store";
import firebase from "firebase/compat/app";
import analytics from "./analytics";
import * as VideoThumbnails from "expo-video-thumbnails";
import * as Updates from "expo-updates";
import moment from "moment";
import { get, filter } from "lodash";
import format from "date-fns/format";
import Sentry from "../sentry";
import * as _ExpoSentry from "sentry-expo";
import {
  getCurrentUser,
  getTsCs,
  createUserPushToken,
  deleteUserPushToken,
  createUserOrganisationTrial,
} from "./graphql";
import { currentCommitId } from "./config";
import {
  setCurrentUserId,
  isQAAtom,
  resetAlert,
  alert,
  setConfig,
  userPushTokensAtom,
  setCurrentRoleType,
} from "./atoms";

export class IOWNAError extends Error {}

export const PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{6,}"
);

export const signIn = async ({ email, password }) => {
  console.log("Sign In for " + email);
  try {
    await Auth.signIn(email, password);
    return true;
  } catch (err) {
    if (err.code === "UserNotConfirmedException") {
      return false;
    }
    if (err.message.includes("Only radix 2, 4, 8, 16, 32 are supported")) {
      throw new IOWNAError("Username or password incorrect");
    }
    throw err;
  }
};

export const signUp = async ({ email, password }) => {
  console.log("Sign Up for " + email);
  await Auth.signUp({
    username: email,
    password: password,
    attributes: {
      email: email,
    },
  });
};

export const confirmSignUp = async ({ email, code }) => {
  console.log("confirmSignUp for " + email);
  await Auth.confirmSignUp(email, code);
};

export const resendSignUp = async ({ email }) => {
  console.log("resendSignUp for " + email);
  await Auth.resendSignUp(email);
};

export const forgotPassword = async ({ email }) => {
  console.log("forgotPassword for " + email);
  return await Auth.forgotPassword(email);
};

export const resetPassword = async ({ email, code, password }) => {
  console.log("resetPassword for " + email);
  return await Auth.forgotPasswordSubmit(email, code, password);
};

export const updateUserAttributes = async (attributes) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  return await Auth.updateUserAttributes(cognitoUser, attributes);
};

export const verifiedContact = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  return await Auth.verifiedContact(cognitoUser);
};

export const verifyCurrentUserAttribute = async (attribute) => {
  return await Auth.verifyCurrentUserAttribute(attribute);
};

export const verifyCurrentUserAttributeSubmit = async (attribute, code) => {
  return await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
};

export const signOut = () => {
  return Auth.signOut();
};

export const refreshCurrentSession = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const { refreshToken } = cognitoUser.getSignInUserSession();
  return new Promise((resolve, reject) => {
    return cognitoUser.refreshSession(refreshToken, (err, sess) => {
      if (err) {
        return reject(err);
      }
      return resolve(sess); // reject(new Error('test'))
    });
  });
};

export const showRefreshError = async (navigation) => {
  Auth.signOut();
  const rememberDevice =
    (await AsyncStorage.getItem("rememberDevice")) || "false";
  if (rememberDevice === "true" && Platform.OS !== "web") {
    const email = (await SecureStore.getItemAsync("email")) || "";
    const password = (await SecureStore.getItemAsync("password")) || "";
    try {
      console.log("rememberDevice signIn");
      await Auth.signIn(email, password);
      return;
    } catch (err) {
      console.log("rememberDevice failed", err);
    }
  }
  alert(
    "Session Expired",
    "Your session has expired. You need to login again",
    () => {
      navigation.popToTop();
      navigation.replace("SignInScreen", { isSignout: true });
      return true;
    }
  );
};

// export const initializeAppScreen = async ({
//   hasUpdate = false,
//   forcedUpdate = false,
//   nativeUpdate = false,
//   email = "",
//   updateData,
//   changeScreen,
//   confirmed,
// }) => {
//   let next = {};
//   if (confirmed) {
//     const { user, hasOrganisations, hasUserInvites, terms, tsCsVersion } =
//       await initializeGlobalData(updateData);
//     const oldTermsVersion =
//       typeof tsCsVersion === "string" ? parseInt(tsCsVersion) : tsCsVersion;
//     if (
//       !tsCsVersion ||
//       new Date(oldTermsVersion).getTime() < new Date(terms.version).getTime()
//     ) {
//       next = { screen: "TermsScreen", params: {} };
//     } else if (hasUserInvites) {
//       next = { screen: "AcceptPendingInvitationsScreen", params: {} };
//     } else if (hasOrganisations) {
//       next = { screen: "ChooseOrganisationScreen", params: {} };
//     } else {
//       if (!hasOrganisations) {
//         setCurrentRoleType("personal");
//       }
//       next = { screen: "HomeNavigator", params: {} };
//     }
//   } else {
//     next = { screen: "ConfirmSignUpScreen", params: { email } };
//   }
//   if (hasUpdate) {
//     changeScreen({
//       screen: "UpdateScreen",
//       params: { forcedUpdate, nativeUpdate, next },
//     });
//   } else {
//     changeScreen(next);
//   }
// };

export const initializeAppScreen = async ({
  hasUpdate = false,
  forcedUpdate = false,
  nativeUpdate = false,
  email = "",
  updateData,
  changeScreen,
  confirmed,
}) => {
  let next = {};
  if (confirmed) {
    const { user, hasOrganisations, hasUserInvites, terms, tsCsVersion } =
      await initializeGlobalData(updateData);
    const oldTermsVersion =
      typeof tsCsVersion === "string" ? parseInt(tsCsVersion) : tsCsVersion;
    if (!tsCsVersion || oldTermsVersion < terms.version) {
      next = { screen: "TermsScreen", params: { asClinician: false } };
    } else if (
      !tsCsVersion ||
      (hasOrganisations && oldTermsVersion < terms.version + 1)
    ) {
      next = { screen: "TermsScreen", params: { asClinician: true } };
    } else if (hasUserInvites) {
      next = { screen: "AcceptPendingInvitationsScreen", params: {} };
    } else if (hasOrganisations) {
      next = { screen: "ChooseOrganisationScreen", params: {} };
    } else {
      if (!hasOrganisations) {
        setCurrentRoleType("personal");
      }
      next = { screen: "HomeNavigator", params: {} };
    }
  } else {
    next = { screen: "ConfirmSignUpScreen", params: { email } };
  }
  if (hasUpdate) {
    changeScreen({
      screen: "UpdateScreen",
      params: { forcedUpdate, nativeUpdate, next },
    });
  } else {
    changeScreen(next);
  }
};

export const initializeGlobalData = async (updateData) => {
  const newOrganisationName =
    (await AsyncStorage.getItem("newOrganisationName")) || "";
  if (newOrganisationName) {
    await createUserOrganisationTrial({ input: { name: newOrganisationName } });
    await refreshCurrentSession();
    await AsyncStorage.removeItem("newOrganisationName");
  }
  const res = await getCurrentUser();
  const user = res.data.getUser;
  const tsCsRes = await getTsCs();
  const tsCsVersion = tsCsRes.data.getTsCs.version;
  const terms = await fetchTerms();

  const globalData = {
    terms: terms,
    tsCsVersion: tsCsVersion,
    roleInvites: { items: [] },
    userRoles: { items: [] },
    distributionTags: { items: [] },
    distributionContacts: { items: [] },
    allOrganisations: { items: [] },
    advices: { items: [] },
    bundles: { items: [] },
  };
  updateData((draft) => {
    draft.terms = terms;
    draft.tsCsVersion = tsCsVersion;
    draft.userInvites = globalData.userInvites;
    draft.userRoles = globalData.userRoles;
    draft.distributionTags = globalData.distributionTags;
    draft.distributionContacts = globalData.distributionContacts;
    draft.allOrganisations = globalData.allOrganisations;
    draft.advices = globalData.advices;
    draft.bundles = globalData.bundles;
  });
  setCurrentUserId(user.id);
  return {
    user,
    hasOrganisations: get(user, "organisationRoles.items", []).length > 0,
    hasUserInvites: get(user, "organisationRoleInvites.items", []).length > 0,
    terms,
    tsCsVersion,
  };
};

export const resetGlobalData = async (updateData) => {
  updateData((draft) => {
    draft.systemRoles = [];
    draft.roleInvites = { items: [] };
    draft.userRoles = { items: [] };
    draft.distributionTags = { items: [] };
    draft.distributionContacts = { items: [] };
    draft.allOrganisations = { items: [] };
    draft.advices = { items: [] };
    draft.bundles = { items: [] };
  });
  if (Platform.OS !== "web") {
    await AsyncStorage.setItem("rememberDevice", "false");
    await SecureStore.deleteItemAsync("email");
    await SecureStore.deleteItemAsync("password");
  }
};

export const changeOrg = (updateData, organisation) => {
  updateData((draft) => {
    draft.advices = { items: [] };
    draft.roleInvites = { items: [] };
    draft.userRoles = { items: [] };
    draft.distributionTags = { items: [] };
    draft.distributionContacts = { items: [] };
    Sentry.configureScope((scope) => {
      scope.setUser({
        user: JSON.stringify(draft.user, 2, 2),
        systemRoles: JSON.stringify(draft.systemRoles, 2, 2),
      });
    });
  });
};

export const logEvent = (name, params) => {
  if (!isQAAtom.getValue()) {
    analytics.logEvent(name, params);
  }
};

export const getErrorMessage = (err) => {
  console.log("getErrorMessage", err, JSON.stringify(err));
  if (err instanceof IOWNAError) {
    if (err.message.includes("502")) {
      return "Our servers are experiencing degraded performance. We will be back up shortly.";
    }
    return err.message;
  } else if ((err && err.code) || err.name) {
    if (err.message.includes("Member must have length greater than")) {
      return err.message
        .replace(
          `1 validation error detected: Value at 'password' failed to satisfy constraint: `,
          ""
        )
        .replace("Member", "Password");
    }
    if (err.message.includes("Password did not conform with policy")) {
      return err.message.replace("Password did not conform with policy: ", "");
    }
    // TODO: find a better way to do this using context
    if (err.message.includes("Refresh Token has expired")) {
      alert("Session Expired", "Your session has expired.");
      if (Platform.OS === "web") {
        window.location.reload();
      } else {
        Updates.reloadAsync();
      }
    }
    return err.message;
    // (^$*.[]{}()?-"!@#%&/\,><':;|_~)
    // An account with the given email already exists. // User already exists
    // 'User does not exist', /user.*not.*exist/i
  } else if (err && err.errors && err.errors.length > 0) {
    const { message, errorType, path } = err.errors[0];
    if (message.includes("as a valid email address.")) {
      return "You need to enter a valid email";
    }
    if (message.includes("Not Authorized")) {
      const retMsg =
        "You don't have the right permissions or roles to perform this action.";
      if (message.includes("deleteAdvice")) {
        return "You can only delete content uploaded by you. Please contact an administrator if you would like this content removed.";
      }
      if (message.includes("updateAdvice")) {
        return "You can only update content uploaded by you. Please contact an administrator if you would like this content to be updated.";
      }
      if (message.includes("deleteOrganisationBundle")) {
        return "You can only delete bundle uploaded by you. Please contact an administrator if you would like this bundle removed.";
      }
      if (message.includes("createOrganisationBundle")) {
        return "You can only update bundle uploaded by you. Please contact an administrator if you would like this bundle to be updated.";
      }
      return retMsg;
    }
    if (message.includes("non-nullable type")) {
      return "Oops something went wrong in our servers. Please try again later.";
    }
    if (message.includes("502")) {
      return "Our servers are experiencing degraded performance. We will be back up shortly.";
    }
    if (
      errorType === "Duplicate" &&
      path[0] === "createOrganisationRoleInvite"
    ) {
      return "A user with this email has already been invited";
    }
    if (
      errorType === "400" &&
      message.includes("The key has an invalid file extension")
    ) {
      return "You can only upload a PDF file";
    }
    if (errorType === "4002") {
      return "Phone number is required";
    }
    if (errorType === "4003") {
      return "Phone number is not a valid mobile number";
    }
    if (errorType === "4090") {
      return "Phone number is already linked to another account";
    }
    if (errorType === "400" && path[0] === "createUserOrganisationTrial") {
      return "You have already created a trial organisation";
    }
    // if (err.errors[0].errorType === 'Duplicate') {
    //   if (err.errors[0].message.includes('already invited')) {
    //     return 'This email has already been invited';
    //   }
    // }
    if (message.includes("Network Error")) {
      return "You are not connected to the Internet. Please check your network settings";
    }
  }
  return "";
};

export const handleError = (fn, cleanup) => {
  return async (...params) => {
    try {
      const res = await fn(...params);
      if (cleanup) {
        cleanup();
      }
      resetAlert();
      return res;
    } catch (err) {
      console.log("handleError");
      const msg = getErrorMessage(err);
      if (msg === "") {
        Sentry.withScope((scope) => {
          scope.setExtras({ params, error: JSON.stringify(err), err });
          Sentry.captureException(err);
        });
        alert("Error", "Oops something went wrong");
      } else {
        alert("Error", msg);
      }
      if (cleanup) {
        cleanup();
      }
    }
  };
};

export const getApiUrl = () =>
  isQAAtom.getValue()
    ? "https://api.qa.nonprod.iowna.com"
    : "https://api.iowna.com";
export const getAppUrl = () =>
  isQAAtom.getValue()
    ? "https://app.qa.nonprod.iowna.com"
    : "https://app.iowna.com";
export const fetchConfig = () =>
  fetch(getAppUrl() + "/config.json").then((res) => res.json());
export const fetchTerms = () =>
  fetch(getAppUrl() + "/terms.json").then((res) => res.json());
export const initConfig = async () => {
  const config = await fetchConfig();
  _ExpoSentry.init({
    dsn: config.sentryDSN,
    environment: config.environment,
    enableInExpoDevelopment: false,
    enabled: __DEV__ === false,
    debug: false,
  });
  Sentry.setTag("currentCommitId", currentCommitId);
  Amplify.configure({
    ...config,
    API: {
      graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
    },
  });
  setConfig(config);
  return config;
};

export const titleList = [
  { value: "mr", label: "Mr" },
  { value: "mrs", label: "Mrs" },
  { value: "miss", label: "Miss" },
  { value: "dr", label: "Dr" },
  { value: "ms", label: "Ms" },
  { value: "prof", label: "Prof" },
  { value: "rev", label: "Rev" },
  { value: "lady", label: "Lady" },
  { value: "sir", label: "Sir" },
];

export const nameTitle = (v) => {
  switch (v) {
    case "mr":
      return "Mr.";
    case "mrs":
      return "Mrs.";
    case "miss":
      return "Miss";
    case "dr":
      return "Dr.";
    case "ms":
      return "Ms.";
    case "prof":
      return "Prof.";
    case "rev":
      return "Rev.";
    case "lady":
      return "Lady";
    case "sir":
      return "Sir";
    default:
      return "";
  }
};

export const biologicalSexList = [
  { label: "Not Specified", value: "notSpecified" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const biologicalSexTitle = (v) => {
  const found = biologicalSexList.find((item) => item.value == v);
  if (found) {
    return found.label;
  }
  return v;
};

export const genderIdentityList = [
  { label: "Not Specified", value: "notSpecified" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non Binary", value: "nonBinary" },
  { label: "Transgender", value: "transgender" },
];

export const genderIdentityTitle = (v) => {
  const found = genderIdentityList.find((item) => item.value == v);
  if (found) {
    return found.label;
  }
  return v;
};

export const religionList = [
  { label: "Prefer not to say", value: "prefer_not_to_say" },
  { label: "Baptist", value: "baptist" },
  { label: "Buddhist", value: "buddhist" },
  { label: "Brethren", value: "brethren" },
  { label: "Catholic", value: "catholic" },
  { label: "Church of Ireland", value: "church_of_ireland" },
  { label: "Christian", value: "christian" },
  { label: "Free Presbyterian", value: "free_presbyterian" },
  { label: "Hindu", value: "hindu" },
  { label: "Jewish", value: "jewish" },
  { label: "Methodist", value: "methodist" },
  { label: "None", value: "none" },
  { label: "Muslim", value: "muslim" },
  { label: "Presbyterian", value: "presbyterian" },
  { label: "Protestant", value: "protestant" },
  { label: "Sikh", value: "sikh" },
];

export const religionTitle = (v) => {
  const found = religionList.find((item) => item.value == v);
  if (found) {
    return found.label;
  }
  return v;
};

export const ALL_ROLES = [
  "organisation_owner",
  "organisation_admin",
  "practitioner",
  "curator",
];

export const transformRole = (role) => {
  switch (role) {
    case "organisation_owner":
      return "Owner";
    case "organisation_admin":
      return "Admin";
    case "practitioner":
      return "Practitioner";
    case "practitioner_external":
      return "External";
    case "practitioner_proxy":
      return "Proxy";
    case "curator":
      return "Curator";
    default:
      return role;
  }
};

export const ALL_CONTENT_TAGS = [
  "pils",
  "questionnaire",
  "proms",
  "letter",
  "video",
  "guidelines",
  "news",
  "medicalDirectory",
  "serviceProvider",
  "productDirectory",
];

export const PATIENT_CONTENT_TAGS = [
  "clinicLetter",
  "testResult",
  "prescription",
  "other",
];

export const transformContentTag = (tag) => {
  switch (tag) {
    case "pils":
      return "PILs (Patient Information Leaflets)";
    case "questionnaire":
      return "Form";
    case "proms":
      return "PROMS (Patient Reported Outcome Measures)";
    case "letter":
      return "Letter";
    case "video":
      return "Video";
    case "guidelines":
      return "Guidelines";
    case "news":
      return "News";
    case "medicalDirectory":
      return "Medical Directory";
    case "serviceProvider":
      return "Service Provider";
    case "productDirectory":
      return "Product Directory";

    default:
      return tag;
  }
};

export const transformPatientContentTag = (tag) => {
  switch (tag) {
    case "clinicLetter":
      return "Clinic Letter";
    case "testResult":
      return "Test Result";
    case "prescription":
      return "Prescriptions";
    case "other":
      return "Other Information";

    default:
      return tag;
  }
};

export const transformUserInvites = (userInvites) => {
  const invitationsMap = {};
  userInvites.forEach((invite) => {
    const key = invite.organisation.id;
    invitationsMap[key] = invitationsMap[key] || {
      id: key,
      organisationName: invite.organisation.name,
      ids: [],
      roles: [],
    };
    invitationsMap[key].ids.push(invite.id);
    invitationsMap[key].roles.push(invite.role);
  });
  return Object.keys(invitationsMap).map((key) => invitationsMap[key]);
};

export const getSections = (list, key, sort = true) => {
  const sections = [];
  const newList = [].concat(list);
  if (sort) {
    newList.sort((l, r) => l[key] > r[key]);
  }
  newList.forEach((item) => {
    const sectionTitle = item[key][0].toUpperCase();
    const section = sections.find((sec) => sec.title === sectionTitle);
    if (section) {
      section.data.push(item);
    } else {
      sections.push({
        title: sectionTitle,
        data: [item],
      });
    }
  });
  sections.sort((l, r) => l.title > r.title);
  return sections;
};

export const sortList = (list, sortKey) =>
  [].concat(list).sort((l, r) => l[sortKey] > r[sortKey]);

export const sanitizeFilename = (name = "") =>
  name
    .split(" ")
    .join("-")
    .replace(/[^a-zA-Z0-9\\.\\-]/g, "_");

export const getMobileOperatingSystem = () => {
  if (typeof window !== "undefined") {
    const userAgent =
      window.navigator.userAgent || window.navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "windows";
    }
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
  }
  return "unknown";
};

export const testId = (id) => {
  const props = {
    testID: id,
    accessibilityLabel: id,
  };
  return props;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const askPushNotificationPermission = async () => {
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== "granted") {
    return false;
  }
  return true;
};

export const registerForPushNotifications = async (existingPushTokens) => {
  const enabled = await askPushNotificationPermission();
  if (enabled) {
    const token = await Notifications.getExpoPushTokenAsync();
    if (token) {
      const hasToken = existingPushTokens.find(
        (item) => item.token === token.data
      );
      if (!hasToken) {
        const res = await createUserPushToken({
          input: {
            token: token.data,
          },
        });
        userPushTokensAtom.update((v) =>
          [res.data.createUserPushToken].concat(v)
        );
      }
    }
  }
};

export const unRegisterForPushNotifications = async (existingPushTokens) => {
  const token = await Notifications.getExpoPushTokenAsync();
  const hasToken = existingPushTokens.find((item) => item.token === token.data);
  if (hasToken) {
    await deleteUserPushToken({
      input: {
        id: hasToken.id,
      },
    });
    userPushTokensAtom.update((v) =>
      v.filter((item) => item.id !== hasToken.id)
    );
  }
};

export const checkAllowPushNotification = async (existingPushTokens) => {
  const enabled = await askPushNotificationPermission();
  const token = await Notifications.getExpoPushTokenAsync();
  const hasToken = existingPushTokens.find((item) => item.token === token.data);
  return enabled && hasToken;
};

export const formatCalender = (dateInISO) => {
  return moment(dateInISO).calendar({
    sameElse: "DD/MM/YYYY",
    lastWeek: function (now) {
      if (now.week() === this.week()) {
        return "[This] dddd [at] LT";
      } else {
        return "[Last] dddd [at] LT";
      }
    },
  });
};

export const interpolate = (template, params) => {
  const names = Object.keys(params);
  const vals = Object.values(params);
  let value = "";
  try {
    value = new Function(...names, `return \`${template}\`;`)(...vals);
  } catch (err) {
    console.log("could not interpolate", template, err);
    Sentry.withScope((scope) => {
      scope.setExtras({ template, params, error: JSON.stringify(err), err });
      Sentry.captureException(err);
    });
  }
  return value;
};

export const verifyRecaptcha = ({ token }) =>
  fetch(getAppUrl() + "/siteverify", {
    method: "POST",
    body: JSON.stringify({ token }),
  }).then((res) => res.json());

export const verifySecret = ({ secret }) =>
  fetch(getAppUrl() + "/secretverify", {
    method: "POST",
    body: JSON.stringify({ secret }),
  }).then((res) => res.json());

export const formatDate = (dateInIso, style = "dd/MM/yyyy") => {
  if (!dateInIso) return "";
  return format(new Date(dateInIso), style);
};

export const getUserFullName = (user) => {
  const title = get(user, "title", "");
  const firstName = get(user, "firstName", "");
  const lastName = get(user, "lastName", "");
  return `${nameTitle(title)} ${firstName} ${lastName}`.trim();
};

export const getThumbnailUrl = async ({ downloadUrl, thumbnailUrl }) => {
  if (thumbnailUrl) {
    return { uri: thumbnailUrl };
  }
  if (Platform.OS !== "web") {
    try {
      return await VideoThumbnails.getThumbnailAsync(downloadUrl, {
        time: 15000,
      });
    } catch (err) {}
  }
  return { uri: "" };
};

export const jsonStringifySorted = (obj, opts) => {
  if (!opts) opts = {};
  if (typeof opts === "function") opts = { cmp: opts };
  var space = opts.space || "";
  if (typeof space === "number") space = Array(space + 1).join(" ");
  var cycles = typeof opts.cycles === "boolean" ? opts.cycles : false;
  var replacer =
    opts.replacer ||
    function (key, value) {
      return value;
    };

  var cmp =
    opts.cmp &&
    (function (f) {
      return function (node) {
        return function (a, b) {
          var aobj = { key: a, value: node[a] };
          var bobj = { key: b, value: node[b] };
          return f(aobj, bobj);
        };
      };
    })(opts.cmp);

  var seen = [];
  return (function stringify(parent, key, node, level) {
    var indent = space ? "\n" + new Array(level + 1).join(space) : "";
    var colonSeparator = space ? ": " : ":";

    if (node && node.toJSON && typeof node.toJSON === "function") {
      node = node.toJSON();
    }

    node = replacer.call(parent, key, node);

    if (node === undefined) {
      return;
    }
    if (typeof node !== "object" || node === null) {
      return JSON.stringify(node);
    }
    if (Array.isArray(node)) {
      var out = [];
      for (var i = 0; i < node.length; i++) {
        var item =
          stringify(node, i, node[i], level + 1) || JSON.stringify(null);
        out.push(indent + space + item);
      }
      return "[" + out.join(",") + indent + "]";
    } else {
      if (seen.indexOf(node) !== -1) {
        if (cycles) return JSON.stringify("__cycle__");
        throw new TypeError("Converting circular structure to JSON");
      } else seen.push(node);

      var keys = Object.keys(node).sort(cmp && cmp(node));
      var out = [];
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var value = stringify(node, key, node[key], level + 1);

        if (!value) continue;

        var keyValue = JSON.stringify(key) + colonSeparator + value;
        out.push(indent + space + keyValue);
      }
      seen.splice(seen.indexOf(node), 1);
      return "{" + out.join(",") + indent + "}";
    }
  })({ "": obj }, "", obj, 0);
};

export const ALL_PILLARS = {
  "Arthritis & Autoimmunity": [
    "Immunology",
    "Rheumatology",
    "Allergy",
    "Sport and Exercise Medicine",
    "Rehabilitation Medicine",
    "Orthopaedic Surgery",
  ],
  Cancer: ["Medical Oncology", "Palliative Medicine"],
  "Obesity and Diabetes": ["Endocrinology and Diabetes Mellitus"],
  "Heart and Lung": [
    "Cardiology",
    "Paediatric Cardiology",
    "Pulmonary Hypertension",
    "Respiratory Medicine",
    "Cardiothoracic Surgery",
    "Vascular Surgery",
  ],
  "Mental Health and Ageing": ["Geriatric Medicine", "Psychiatry"],
  Eye: ["Medical Ophthalmology", "Surgical Ophthalmology"],
  Skin: ["Dermatology", "Plastic Surgery"],
  "Digestive health": ["Gastroenterology"],
  "Nervous system": ["Neurology", "Clinical Neurophysiology", "Neurosurgery"],
  Drugs: ["Clinical Pharmacology and Therapeutics", "Pharmaceutical Medicine "],
  Infection: ["Infectious Diseases", "Tropical Medicine"],
  "X-rays and scans": ["Nuclear Medicine"],
  "Sexual health": ["Genitourinary Medicine "],
  Blood: ["Haematology"],
  Hearing: ["Audiovestibular Medicine"],
  Genes: ["Clinical Genetics"],
  Kidney: ["Renal Medicine", "Urology"],
  "General Medicine": [
    "General (Internal) Medicine",
    "General Practice",
    "Acute Internal Medicine",
  ],
  "Aviation and Space": ["Aviation and Space Medicine"],
  "Women's Health": ["Gynaecology", "Obstetrics", "Urogynecology"],
  Paediatrics: ["Paediatric Surgery", "General Paediatrics"],
  Dental: [
    "Orthodontics",
    "Oral and Maxillofacial Surgery",
    "Paediatric Dentistry",
    "Periodontics",
    "Restorative Dentistry",
    "Endodontics",
    "Oral Medicine",
  ],
  Wellbeing: ["Wellbeing"],
  Physiotherapy: ["Physiotherapy"],
};

export const CONDITIONS = Object.keys(ALL_PILLARS);
export const onlyConditions = (items = []) =>
  filter(items, (i) => CONDITIONS.includes(i));

export const ALL_SPECIALITIES = Object.keys(ALL_PILLARS)
  .reduce((acc, key) => acc.concat(ALL_PILLARS[key]), [])
  .sort();

export const onlyTags = (items = []) =>
  filter(items, (i) => ALL_CONTENT_TAGS.includes(i));

export const onlyPatientContentTags = (items = []) =>
  filter(items, (i) => PATIENT_CONTENT_TAGS.includes(i));

export const onlySpecialities = (items = []) =>
  filter(items, (i) => ALL_SPECIALITIES.includes(i));
