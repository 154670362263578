import type { ReactNode } from "react";
import { View, Text } from "react-native";

const WithError = ({
  children,
  error,
}: {
  children: ReactNode;
  error?: string;
}) => {
  return (
    <View >
      {children}
      {error && <Text style={{ color: "red" }}>{error}</Text>}
    </View>
  );
};

export default WithError;
