import React from "react";
import { View, Text } from "react-native";
import { useAtom, isWebAtom } from "../atoms";

const Field = ({
  fontSize,
  title,
  value,
  valueColor,
  borderWidth,
  ...props
}) => {
  const web = useAtom(isWebAtom);
  const padding = web ? 20 : 13;
  return (
    <View
      style={{
        flexDirection: web ? "row" : "column",
        paddingTop: padding,
        paddingBottom: padding,
        backgroundColor: "white",
        alignItems: web ? "center" : "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        borderBottomWidth: borderWidth,
        borderBottomColor: "#C6C6C8",
      }}
      {...props}
    >
      <View
        style={{
          flex: 1,
          alignItems: !web ? "flex-end" : "flex-start",
          justifyContent: !web ? "flex-end" : "flex-start",
          marginTop: web ? 0 : 10,
        }}
      >
        <Text
          style={{
            fontSize: fontSize || 16,
            paddingRight: 8,
          }}
        >
          {title}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          flexGrow: 1,
          alignItems: web ? "flex-end" : "flex-start",
          justifyContent: web ? "flex-end" : "flex-start",
          marginTop: web ? 0 : 10,
        }}
      >
        <Text
          style={{
            flex: 1,
            fontSize: fontSize || 17,
            paddingLeft: web ? 8 : 0,
            color: valueColor,
          }}
        >
          {value}
        </Text>
      </View>
    </View>
  );
};

export default Field;
