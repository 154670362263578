import React from "react";
import WebView from "./WebView";
import PdfViewer from "./PdfViewer";


const MediaViewer = ({uri}: {uri: string}) => {
    const deconstructedUrl = new URL(uri);
    // console.log("deconstructedUrl", deconstructedUrl);
    return deconstructedUrl.pathname.endsWith(".pdf") ? (
        <PdfViewer uri={uri} />
    ) : (
        <WebView
            style={{ flex: 1 }}
            source={{
                uri: uri,
            }}
        />
    );
};

export default MediaViewer;