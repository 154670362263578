import React, { useEffect } from "react";
import { View } from "react-native";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  Button,
  FieldLabel,
  TextInput,
  PhoneInput,
  ErrorMessage,
} from "../../../components";
import { IOWNAError } from "../../../utils";

import { updateDistributionContact } from "../../../graphql";

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

export default function ({ onClose, onSave, item }) {
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      firstName: item.givenName,
      secondName: item.familyName,
      id: item.id,
    },
  });

  const mutation = useMutation(
    ({ mobile, firstName, secondName, countryCode }) => {
      const phoneNumber = parsePhoneNumberFromString(
        "+" + countryCode + mobile
      );
      const lastName = secondName == null ? "" : secondName;

      return updateDistributionContact({
        input: {
          id: item.id,
          givenName: firstName.trim(),
          familyName: lastName.trim(),
          phone: phoneNumber.number,
        },
      })
        .then((res) => res.data.updateDistributionContact)
        .catch((err) => {
          console.log("yolo err is ", err);

          const errorType = err.errors?.[0].errorType;

          if (errorType && errorType === "Unauthorized") {
            setError("mobile", {
              type: "unauthorised",
              message: "You are not able to perform this action",
            });
            throw new IOWNAError('You are not able to perform this action')
          }

          const message = err.errors?.[0]?.message;

          setError("mobile", {
            type: "duplicate",
            message: "This phone number is linked to an existing contact",
          });

          if (message) {
            throw new IOWNAError(
              "This phone number is linked to an existing contact"
            );
          } else {
            throw new Error(JSON.stringify(err));
          }
        });
    },
    {
      onSuccess: (item) => {
        onSave(item);

        onClose();
      },
    }
  );

  return (
    <View>
      <FieldLabel>First/Given Name</FieldLabel>
      <TextInput
        name="firstName"
        control={control}
        errors={errors}
        rules={{ required: "First Name/Given Name is required" }}
      />
      <FieldLabel>Second/Last Name</FieldLabel>
      <TextInput
        name="secondName"
        control={control}
        errors={errors}
        rules={{ required: "Second Name/Family Name is required" }}
      />
      <FieldLabel>Phone Number</FieldLabel>
      <PhoneInput
        name="mobile"
        initialNumber={item.phone}
        control={control}
        errors={errors}
        watch={watch}
        clearErrors={() => clearErrors("mobile")}
      />
      <View
        style={{
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          loading={mutation.isLoading}
          onPress={handleSubmit(mutation.mutate)}
        >
          Update
        </Button>
      </View>
    </View>
  );
}
