import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { get } from "lodash";
import HeaderButton from "../../../components/HeaderButton";
import BundleScreen, { setShowModal, updateBundle } from "./BundleScreen";
import BundleAdviceListScreen from "./BundleAdviceListScreen";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import { useGlobal } from "../../../hooks";
import BundleCreate from "../../../new/components/bundles/BundleCreate";
import { options, optionsBack } from "../../../headerUtils";
import ContentScreen from "../ContentNavigator/ContentScreen";
import {
  bundleAdvicesAtom,
  isWebAtom,
  setBundleAdvices,
  useAtom,
} from "../../../atoms";
import { BreadCrumb } from "../../../containers";
import { handleError, IOWNAError } from "../../../utils";

const Stack = createStackNavigator();
export default ({ route }) => {
  const web = useAtom(isWebAtom);
  const [data, updateData] = useGlobal();
  const bundleAdvices = useAtom(bundleAdvicesAtom);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="BundleScreen"
        component={BundleScreen}
        options={options(
          () => (
            <BreadCrumb parts={["currentOrganisation.name", "Bundles"]} />
          ),
          ({ navigation }) => (
            <HeaderButton
              icon="ios-add-circle-outline"
              title="Create Bundle"
              onPress={() => navigation.setParams({ createBundle: true })}
            />
          )
        )}
        initialParams={route.params}
      />

      <Stack.Screen
        name="BundleAdviceListScreen"
        component={BundleAdviceListScreen}
        options={optionsBack(web, ({ route }) =>
          web ? (
            <BreadCrumb
              parts={[
                "currentOrganisation.name",
                "Bundles",
                route.params.bundleName,
              ]}
            />
          ) : (
            route.params.bundleName
          )
        )}
      />
      <Stack.Screen
        name="ContentScreen"
        component={ContentScreen}
        options={optionsBack(
          web,
          ({ route }) => (
            <BreadCrumb
              parts={[
                "currentOrganisation.name",
                "Bundles",
                route.params.bundleName,
                "Add to Bundle",
              ]}
            />
          ),
          ({ route, navigation }) => {
            return route.params?.canAddToBundle ? (
              <HeaderButton
                icon={web ? "ios-checkmark-done" : null}
                title="Done"
                onPress={async () => {
                  await handleUpdateBundle();
                  navigation.goBack();
                }}
              />
            ) : null;
          }
        )}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
    </Stack.Navigator>
  );
};
