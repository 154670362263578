import { TextInput as RNTextInput, StyleSheet } from "react-native";

const TextInput = ({
  onChange,
  value,
}: {
  onChange: (value: string) => void;
  value?: string;
}) => (
  <RNTextInput value={value} style={styles.input} onChangeText={onChange} />
);

export default TextInput;

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});
