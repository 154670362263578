import React from 'react';
import { View, Text } from 'react-native';

const sizeMap = {
  small: 12,
  large: 24,
}

const fontSizeMap = {
  small: 8,
  large: 12,
}

export default ({ size = 'large', count }) => (
  <View
    style={{
      width: sizeMap[size],
      height: sizeMap[size],
      borderRadius: sizeMap[size],
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#EE4F32',
    }}
  >
    <Text style={{ fontSize: fontSizeMap[size], fontWeight: '700', color: '#FFFFFF' }}>{count}</Text>
  </View>
);
