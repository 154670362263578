import React, { useEffect, useState } from "react";
import { Button, View } from "react-native";
import { useAList } from "./../../../hooks";
import Form from "../../Form";
// import useCaptureResults from "../errors";
// import useValidate from "../../../hooks/useValidate";
import useValidateAndCapture from "../../Form/useValidation/useValidateAndCapture";

type ChoiceT = {
  id: string;
  name: string;
};

interface ChoicesQuestionT {
  itemType?: "radio" | "checkbox";
  choices: ChoiceT[];
  multi: boolean;
}

const ChoiceInput = ({
  onChange,
  value,
}: {
  onChange: (data: ChoiceT) => void;
  value: ChoiceT;
}) => {
  return (
    <>
      <Form.TextField
        label="Choice"
        value={value.name}
        onChange={(val) => onChange({ ...value, name: val })}
        validate={(data) =>
          data && data.length > 0
            ? { isSuccess: true, data }
            : { isSuccess: false, error: "Please enter a choice" }
        }
      />

      <Form.TextField
        label="i.d"
        value={value.id}
        onChange={(val) => onChange({ ...value, id: val })}
        validate={(data) =>
          data && data.length > 0
            ? { isSuccess: true, data }
            : { isSuccess: false, error: "Please enter a id" }
        }
      />
    </>
  );
};

const ChoicesQuestion = ({
  updateItem: _onChange,
  question: _value,
}: {
  updateItem: (question: ChoicesQuestionT) => void;
  question?: ChoicesQuestionT;
}) => {
  const { onChange, error, value } = useValidateAndCapture<
    ChoicesQuestionT,
    ChoicesQuestionT | undefined
  >({
    label: "choices",
    validate: (data?: ChoicesQuestionT) => {
    
      if (data?.choices !== undefined && data.choices.length > 0) {
        return {
          isSuccess: true,
          data,
        };
      } else {
        return {
          isSuccess: false,
          error: "Please enter at least one choice",
        };
      }
    },
    onChange: _onChange,
    value: _value,
  });

  const [questionOptions, setQuestionOptions] = useState<
    Omit<ChoicesQuestionT, "choices">
  >({
    itemType: value?.itemType || "radio",
    multi: value?.multi || false,
  });

  const choices = useAList<{ name: string; id: string }>(
    value?.choices ? value.choices : []
  );
  const itemTypeArray: {
    label: string;
    value: ChoicesQuestionT["itemType"];
  }[] = [
    { label: "Radio", value: "radio" },
    { label: "Checkbox", value: "checkbox" },
  ];
  const multiArray: { readonly label: string; readonly value: boolean }[] = [
    { label: "One", value: false },
    { label: "Multiple", value: true },
  ];

  useEffect(() => {
    onChange({ ...questionOptions, choices: choices.items });
  }, [questionOptions, choices.items]);

  return (
    <>
      <Form.WithError error={error}>
        <Form.RadioField
          label="Display Type"
          selections={itemTypeArray}
          onChange={(val) =>
            setQuestionOptions((prevState) => ({ ...prevState, itemType: val }))
          }
          value={questionOptions.itemType}
        />
        <Form.RadioField
          label="How many choices can the user select?"
          selections={multiArray}
          onChange={(val) => {
            setQuestionOptions((prevState) => ({ ...prevState, multi: val }));
          }}
          value={questionOptions.multi}
        />

        {choices.render(({ item: choice, key, updateItem, deleteItem }) => (
          <Form.SubForm key={key} name={`choices.${key}`}>
            <ChoiceInput value={choice} onChange={updateItem} />
            <Button title="delete" onPress={deleteItem} />
          </Form.SubForm>
        ))}
        <Button
          title="Add a choice option"
          onPress={() => choices.addItem({ name: "first choice", id: "id" })}
        />
      </Form.WithError>
    </>
  );
};

export default ChoicesQuestion;

export type { ChoicesQuestionT };
