import { useState, useContext, useEffect } from "react";
import SubFormContext from "./SubFormContext";
import type { Error } from "./SubFormContext";

const SubFormContextProvider = ({
  children,
  name: fieldName,
  renderErrors: RenderErrors = () => <></>,
  renderOnClean: RenderOnClean = () => <></>,
}: {
  children: React.ReactNode;
  name: string;
  renderErrors?: ({ errors }: { errors: Error[] }) => JSX.Element;
  renderOnClean?: () => JSX.Element;
}) => {
  const [stateErrors, setStateErrors] = useState<Error[]>([]);
  const {
    path,
    errors: contextErrors,
    setErrors: setContextErrors,
  } = useContext(SubFormContext);
  const setErrors = setContextErrors || setStateErrors;
  const errors = contextErrors || stateErrors;
  useEffect(() => {
    return () => {
      setErrors((prev) => {
        // console.log(`deleting ${path}.${fieldName} from `, prev);
        const filtered = prev.filter(
          ({ path: inspectingFieldName }) =>
            !inspectingFieldName.startsWith(`${path}.${fieldName}`)
        );
        return filtered;
      });
    };
  }, []);

// console.log('this is the errors', errors)

  return (
    <SubFormContext.Provider
      value={{ path: `${path}.${fieldName}`, setErrors, errors }}
    >
      {children}
      {errors.length > 0 ? <RenderErrors errors={errors} /> : <RenderOnClean />}
    </SubFormContext.Provider>
  );
};

export default SubFormContextProvider;
