import React from "react";
import { View, Text } from "react-native";
import styled from "@emotion/native";
import { get } from "lodash";
import {
  MainScreen,
  Button,
  TextInput,
  Picker,
  ErrorMessage,
} from "../../components";
import { titleList, testId } from "../../utils";
import { updateUser } from "../../graphql";
import { useAtom, currentUserAtom, isWebAtom } from "../../atoms";
import { useForm } from "../../hooks";
import { screen } from "../../reactUtils";

const Label = styled.Text`
  font-size: 16;
  font-weight: 500;
  color: ${(props) => props.theme.text};
  margin-bottom: 10;
  margin-top: 20;
`;

export default screen(({ route, navigation }) => {
  const { edit } = route.params || {};
  const currentUser = useAtom(currentUserAtom);
  const web = useAtom(isWebAtom);
  const { onSubmit, isSubmitting, control, errors, error } = useForm({
    initial: {
      title: get(currentUser, "title", ""),
      firstName: get(currentUser, "firstName", "") || "",
      lastName: get(currentUser, "lastName", "") || "",
    },
    schema: (yup) => ({
      title: yup.string().nullable(),
      firstName: yup.string().required("Please enter your first name"),
      lastName: yup.string().required("Please enter your last name"),
    }),
    onSubmit: async ({ title, firstName, lastName }) => {
      const res = await updateUser({
        input: {
          id: currentUser.id,
          title: title,
          firstName: firstName,
          lastName: lastName,
        },
      });
      currentUserAtom.update(res.data.updateUser);
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate("ProfileScreen");
      }
    },
  });
  return (
    <MainScreen backgroundColor="#F2F2F7" style={{ alignItems: "center" }}>
      <View
        style={{
          backgroundColor: "white",
          paddingHorizontal: web ? 20 : 20,
          marginTop: 20,
        }}
      >
        <View style={{ marginTop: web ? 30 : 10 }}>
          <Label>Title</Label>
          <Picker
            name="title"
            errors={errors}
            control={control}
            items={titleList}
          />
        </View>
        <View>
          <Label>First Name</Label>
          <TextInput
            name="firstName"
            errors={errors}
            control={control}
            {...testId("firstName")}
          />
        </View>
        <View>
          <Label>Last Name</Label>
          <TextInput
            name="lastName"
            errors={errors}
            control={control}
            {...testId("lastName")}
          />
        </View>
        <ErrorMessage err={error} />
        <View style={{ marginTop: 60, marginBottom: 40 }}>
          <Button
            loading={isSubmitting}
            invert={true}
            onPress={onSubmit}
            {...testId("complete")}
          >
            {edit ? "Update" : "Complete"}
          </Button>
        </View>
        <View>
          {/* <Text style={{color: "red"}}>Delete my account</Text> */}
        </View>
      </View>
    </MainScreen>
  );
});
