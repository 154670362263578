import React from "react";
import {
  Platform,
  Image,
  TextInput,
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import { useTheme } from "@emotion/react";
import countries from "./countries";
import { testId } from "../../utils";
import { useAtom, isWebAtom } from "../../atoms";
const { width, height } = Dimensions.get("window");

export const CountryPicker = ({ onChangeCountry }) => {
  const web = useAtom(isWebAtom);
  return (
    <FlatList
      data={countries}
      keyExtractor={(item) => item.iso2}
      style={{
        width: web ? 400 : width - 80,
        height: web ? 400 : height - 250,
      }}
      renderItem={({ item }) => (
        <TouchableOpacity
          key={item.iso2}
          onPress={() => {
            console.log(item);
            onChangeCountry(item);
          }}
        >
          <View
            style={{
              padding: 8,
              borderBottomWidth: 1,
              borderBottomColor: "#ccc",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 0.15 }}>
              <Image
                source={item.flag}
                resizeMode="stretch"
                style={{ width: 30, height: 16 }}
              />
            </View>
            <View style={{ flex: 0.7, alignItems: "center" }}>
              <Text
                style={{ textAlign: "center", color: "#434343", fontSize: 14 }}
              >
                {item.name}
              </Text>
            </View>
            <View style={{ flex: 0.15, alignItems: "flex-end" }}>
              <Text
                style={{ textAlign: "center", color: "grey", fontSize: 12 }}
              >
                {item.dialCode}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      )}
    />
  );
};

const countriesDialCodeMap = countries.reduce((acc, item) => {
  acc[item.dialCode] = item;
  return acc;
}, {});

const getDialCode = (number) => {
  let dialCode = "";
  // only interested in international numbers (starting with a plus)
  if (number.charAt(0) === "+") {
    let numericChars = "";
    // iterate over chars
    for (let i = 0; i < number.length; i++) {
      const c = number.charAt(i);
      // if char is number
      if (this.isNumeric(c)) {
        numericChars += c;
        // if current numericChars make a valid dial code
        if (countriesDialCodeMap[numericChars]) {
          // store the actual raw string (useful for matching later)
          dialCode = number.substr(0, i + 1);
        }
        // longest dial code is 4 chars
        if (numericChars.length === 4) {
          break;
        }
      }
    }
  }
  return dialCode;
};

const PhoneInput = ({ name, disabled = false, dialCode, width, ...props }) => {
  const theme = useTheme();
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginLeft: 10,
        alignItems: "center",
        justifyContent: "center",
        height: Platform.select({ web: 41, android: 38, ios: 38 }),
      }}
    >
      <Text style={{ fontSize: 18, color: theme.textInputText }}>
        +{dialCode}
      </Text>
      <TextInput
        {...testId("TextInput_" + name)}
        editable={!disabled}
        autoCorrect={false}
        autoCapitalize="none"
        returnKeyType="done"
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        style={{
          flex: 1,
          width: width,
          fontSize: 18,
          marginLeft: 5,
          color: theme.textInputText,
        }}
        {...props}
      />
    </View>
  );
};

export default PhoneInput;
