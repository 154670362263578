import React from "react";
import { useNavigation } from "@react-navigation/native";
import { View, Text } from "react-native";
import { Accordion, AccordionButton } from "../../../components";
import { alert, confirm } from "../../../atoms";
import { handleError } from "../../../utils";


const onRemovePress = async (removeFn) => {
  confirm(
    "Remove Contact",
    "Are you sure you want to remove this contact?",
    handleError(removeFn)
  )(removeFn);
};

export default function ({
  item,
  isOpen,
  onOpen,
  onAddToList,
  onEdit,
  onDelete,
}) {
  const navigation = useNavigation();

  return (
    <Accordion
      open={isOpen}
      onPress={() => onOpen(item)}
      title={
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            padding: 20,
            backgroundColor: "white",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 16, color: "#000000" }}>{item.name}</Text>
          </View>
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <Text style={{ fontSize: 16, color: "#014d78" }}>{item.phone}</Text>
          </View>
        </View>
      }
    >
      <AccordionButton
        items={(item.adviceInstances.items?.length > 0
          ? [
              {
                title: "Activity",
                onPress: () => {
                  navigation.navigate("ActivityScreen", {
                    contactId: item.id,
                    name: `${item.givenName} ${item.familyName}`,
                    item: item,
                  });
                },
              },
            ]
          : []
        ).concat([
          {
            title: "Add to list",
            onPress: () => onAddToList(),
          },
          {
            title: "Edit",
            onPress: () => onEdit(),
          },
          {
            title: "Delete",
            onPress: () => {
              onRemovePress(onDelete);
            },
          },
        ])}
      />
    </Accordion>
  );
}
