import React from 'react';
import { View } from 'react-native';
import { Badge } from '../components';
import { userNotificationsCountAtom, useAtom } from '../atoms';
import { containerWithoutLoader } from '../reactUtils';

const NotificationCount = containerWithoutLoader(({ size = 'large', ...props }) => {
  const count = useAtom(userNotificationsCountAtom);
  if (count === 0) {
    return null;
  }
  return (
    <View style={{ marginLeft: 10, ...props }}>
      <Badge size={size} count={count} />
    </View>
  );
});

export default NotificationCount;
