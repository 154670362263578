import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  Button,
  FieldLabel,
  TextInput,
  PhoneInput,
  ErrorMessage,
} from "../../../components";
import { currentOrganisationAtom, useAtom } from "../../../atoms";
import { createDistributionContact } from "../../../graphql";
import { IOWNAError } from "../../../utils";

import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

export default function ({ onClose, onSave }) {
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    formState,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: { firstName: "", secondName: "" },
  });

  const mutation = useMutation(
    ({ mobile, firstName, secondName, countryCode }) => {
      const phoneNumber = parsePhoneNumberFromString(
        "+" + countryCode + mobile
      );
      const lastName = secondName.length > 0 ? secondName : "";

      return createDistributionContact({
        input: {
          organisationId: currentOrganisation.id,
          givenName: firstName.trim(),
          familyName: lastName.trim(),
          phone: phoneNumber.number,
        },
      })
        .then((res) => res.data.createDistributionContact)

        .catch((err) => {
          const message = err.errors?.[0]?.message;

          setError("mobile", {
            type: "servererror",
            message,
          });
          if (message) {
            throw new IOWNAError(
              message
            );
          } else {
            throw new Error(JSON.stringify(err));
          }
        });
    },
    {
      onSuccess: (item) => {
        onSave(item);
        onClose();
      },
    }
  );

  return (
    <View>
      <FieldLabel>First/Given Name</FieldLabel>
      <TextInput
        name="firstName"
        control={control}
        errors={errors}
        rules={{ required: "First Name/Given Name is required" }}
      />
      <FieldLabel>Second/Last Name</FieldLabel>
      <TextInput
        name="secondName"
        control={control}
        errors={errors}
        rules={{ required: "Second Name/Family Name is required" }}
      />
      <FieldLabel>Phone Number</FieldLabel>
      <PhoneInput
        name="mobile"
        initialNumber={""}
        control={control}
        errors={errors}
        watch={watch}
        clearErrors={() => clearErrors("mobile")}
      />
      <View
        style={{ flexDirection: "row", marginTop: 60, alignItems: "center" }}
      ></View>

      <Button
        loading={mutation.isLoading}
        onPress={handleSubmit(mutation.mutate)}
      >
        Create
      </Button>
    </View>
  );
}
