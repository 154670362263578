import React, { useEffect, useState } from "react";
import { Text, View, Button } from "react-native";
import Form from "../../Form";
import { useAList } from "./../../../hooks";
import useValidateAndCapture from "../../Form/useValidation/useValidateAndCapture";
// interface Props {
//   updateItem: (question: TextDisplayT) => void;
//   question?: TextDisplayT;
// }

type SubtitleT = string;

interface TextDisplayT {
  fontSize?: string;
  subTitle?: SubtitleT[];
  align?: string;
}

const SubTitleInput = ({
  onChange,
  value,
}: {
  onChange: (data: SubtitleT) => void;
  value: SubtitleT;
}) => {
  return (
    <>
      <Form.TextField
        label="Bullet Point"
        value={value ?? ""}
        onChange={(val) => onChange(val)}
        validate={(data) =>
          data && data.length > 0
            ? { isSuccess: true, data }
            : { isSuccess: false, error: "Please enter a sub title" }
        }
      />
    </>
  );
};

const TextDisplay = ({
  updateItem: _onChange,
  question: _value,
}: {
  updateItem: (question: TextDisplayT) => void;
  question?: TextDisplayT;
}) => {
  const { onChange, error, value } = useValidateAndCapture<
    TextDisplayT,
    TextDisplayT | undefined
  >({
    label: "bullet points",
    validate: (data?: TextDisplayT) => {
        if (data?.subTitle !== undefined) {
        return {
          isSuccess: true,
          data,
        };
      } else {
        return {
          isSuccess: false,
          error: "Please enter a sub title",
        };
      }
    },
    onChange: _onChange,
    value: _value,
  });

  const [questionOptions, setQuestionOptions] = useState<
    Omit<TextDisplayT, "subTitle">
  >({ fontSize: value?.fontSize || "normal", align: value?.align || "center" });

  const fontOptions: { label: string; value: TextDisplayT["fontSize"] }[] = [
    { label: "Normal", value: "normal" },
    { label: "Large", value: "large" },
  ];

  const textAlign: { label: string; value: TextDisplayT["align"] }[] = [
    { label: "Left Aligned", value: "left" },
    { label: "Center Aligned", value: "center" },
  ];

  const subtitles = useAList<SubtitleT>(value?.subTitle ? value.subTitle : []);

  useEffect(() => {
    onChange({ ...questionOptions, subTitle: subtitles.items });
  }, [questionOptions, subtitles.items]);

  return (
    <>
      <Text>Enter Text Options</Text>
      <View>
        <Form.RadioField
          selections={fontOptions}
          onChange={(val) => {
            setQuestionOptions((prevState) => ({
              ...prevState,
              fontSize: val,
            }));
          }}
          value={questionOptions.fontSize}
          label="Text Size"
        />
      </View>

      <View>
        <Form.RadioField
          selections={textAlign}
          onChange={(val) => {
            setQuestionOptions((prevState) => ({ ...prevState, align: val }));
          }}
          value={questionOptions.align}
          label="Alignment options"
        />
      </View>
      {subtitles.render(({ item: subtitle, key, updateItem, deleteItem }) => (
        <Form.SubForm key={key} name={`subtitles.${key}`}>
          <SubTitleInput value={subtitle} onChange={updateItem} />
          <Button title="delete" onPress={deleteItem} />
        </Form.SubForm>
      ))}
      <Button
        title="Add a bullet point"
        onPress={() => subtitles.addItem("")}
      ></Button>
    </>
  );
};

export default TextDisplay;
