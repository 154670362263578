import React, { useState } from "react";
import { Dimensions, Linking, Pressable, View } from "react-native";
import { Box, Text, Touch, AuthScreen, Modal } from "../../components";
import { BackButton, Footer } from "./common";
import { testId } from "../../utils";

export default ({ navigation }) => {
  const width = Math.min(Dimensions.get("window").width - 80, 360);
  const [showModal, setShowModal] = useState(false);
  return (
    <AuthScreen>
      <Box marginBottom={100} width={width}>
        <Text color="white" fontSize={28} fontWeight="500" textAlign="center">
          Which account do you need?
        </Text>
      </Box>
      <Box marginBottom={100} alignItems="center" justifyContent="center">
        <Touch
          backgroundColor="white"
          width={width}
          height={50}
          borderRadius={25}
          alignItems="center"
          justifyContent="center"
          marginBottom={20}
          onPress={() => navigation.navigate("SignUpScreen")}
          {...testId("buttonSignUpPatient")}
        >
          <Text color="#004E7A" fontSize={20}>
            Patient
          </Text>
        </Touch>
      </Box>
      <Box marginBottom={50} alignItems="center" justifyContent="center">
        <Touch
          backgroundColor="white"
          width={width}
          height={50}
          borderRadius={25}
          alignItems="center"
          justifyContent="center"
          marginBottom={20}
          onPress={() => {
            setShowModal(true);
          }}
          {...testId("buttonSignUpClinician")}
        >
          <Text color="#004E7A" fontSize={20}>
            Clinician
          </Text>
        </Touch>
      </Box>
      {showModal ? (
        <Modal
          isVisible={showModal}
          title="Clinician"
          onClose={() => setShowModal(false)}
          style={{ maxWidth: 450 }}
        >
          <>
            <View style={{ flexDirection: "column" }}>
              <Text>Are you a clinician?</Text>
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <Text>Email us:</Text>
                <Pressable
                  accessible={true}
                  accessibilityLabel="Link to open email client"
                  onPress={() =>
                    Linking.openURL(
                      "mailto:hello@iowna.com?subject=Clinician%20Account&body=Please%20send%20me%20more%20details%20on%20opening%20an%20iOWNA%20clinician%20account"
                    )
                  }
                >
                  <Text color="#0000EE"> hello@iowna.com</Text>
                </Pressable>
              </View>
            </View>
          </>
        </Modal>
      ) : null}
      <Footer>
        <BackButton onPress={() => navigation.goBack()} />
      </Footer>
    </AuthScreen>
  );
};
