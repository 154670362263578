import React, { useState, useRef } from "react";
import { TouchableOpacity, FlatList } from "react-native";
import styled from "@emotion/native";
import { MainScreen, Button } from "../../components";
import { useGlobal, useForm } from "../../hooks";
import {
  userInvitesAtom,
  hasOrganisationsAtom,
  useAtom,
  setCurrentRoleType,
  isWebAtom,
} from "../../atoms";
import { updateTsCs } from "../../graphql";
import { screen } from "../../reactUtils";
import { testId } from "../../utils";
import TermsArrowIcon from "../../assets/icons/terms-arrow.svg";

const Title = styled.Text`
  font-size: 15;
  font-weight: 700;
  margin-top: 10;
  margin-bottom: 10;
`;

const Text = styled.Text`
  font-size: 14;
  margin-top: 10;
`;

const PointContainer = styled.Text`
  font-size: 14;
  margin-top: 5;
  margin-left: 15;
`;

const ButtonContainer = styled.View`
  background-color: #f2f2f2;
  padding-top: 10;
  padding-bottom: 10;
  padding-left: 10;
  padding-right: 10;
  border-top-color: ${(props) => props.theme.textLight};
  border-top-width: 1;
`;

const MainTitle = styled.Text`
font-size: 18;
font-weight: 700,
margin-top: 10
`;

const Point = ({ children }) => (
  <PointContainer>
    {"\u2022 "} {children}
  </PointContainer>
);

const TermsScreen = screen(({ navigation, route }) => {
  const [{ terms }] = useGlobal();
  const { asClinician } = route.params;
  const userInvites = useAtom(userInvitesAtom);
  const hasOrganisations = useAtom(hasOrganisationsAtom);
  const web = useAtom(isWebAtom);
  const [disabled, setDisabled] = useState(true);
  const scrollRef = useRef(null);
  const { onSubmit, isSubmitting, error } = useForm({
    initial: {},
    schema: (yup) => ({}),
    onSubmit: async () => {
      await updateTsCs({
        input: {
          version: terms.version + (!!asClinician ? 1 : 0),
        },
      });
      if (userInvites.length > 0) {
        navigation.replace("AcceptPendingInvitationsScreen", {});
      } else if (hasOrganisations) {
        if (!asClinician) {
          navigation.replace("TermsScreen", { asClinician: true });
        } else {
          navigation.replace("ChooseOrganisationScreen", {});
        }
      } else {
        if (!hasOrganisations) {
          setCurrentRoleType("personal");
        }
        navigation.replace("HomeNavigator", {});
      }
    },
  });
  const hideArrow = () => {
    setDisabled(false);
    if (scrollRef.current) {
      scrollRef.current.scrollToEnd();
    }
  };
  const style = web
    ? { flex: 1, margin: 10, marginLeft: "20%", marginRight: "20%" }
    : { margin: 10 };
  const arrowSize = web ? 80 : 40;

  const termsToDisplay = asClinician ? terms.clinicianText : terms.userText;
  return (
    <MainScreen
      backgroundColor="white"
      error={error}
      isList={true}
      style={{ flex: 1 }}
    >
      <FlatList
        ref={scrollRef}
        data={termsToDisplay}
        keyExtractor={(item, index) => index}
        contentContainerStyle={style}
        onEndReached={() => {
          setDisabled(false);
        }}
        onEndReachedThreshold={0.9}
        renderItem={({ item }) => {
          if (item["title"]) {
            return <Title>{item.title}</Title>;
          }
          if (item["point"]) {
            return <Point>{item.point}</Point>;
          }
          if (item["text"]) {
            return <Text>{item.text}</Text>;
          }
          if (item["mainTitle"]) {
            return <MainTitle>{item.mainTitle}</MainTitle>;
          }
        }}
      />

      {!web && disabled ? (
        <TouchableOpacity
          style={{ position: "absolute", left: "45%", right: 0, bottom: 100 }}
          onPress={hideArrow}
        >
          <TermsArrowIcon width={arrowSize} height={arrowSize} />
        </TouchableOpacity>
      ) : null}
      <ButtonContainer>
        <Button
          loading={isSubmitting}
          disabled={disabled}
          onPress={onSubmit}
          {...testId("accept")}
        >
          Accept
        </Button>
      </ButtonContainer>
    </MainScreen>
  );
});

export default TermsScreen;
