import React from "react";
import { View, TextInput } from "react-native";
import { Editor } from "@tinymce/tinymce-react";
import { Ionicons } from "@expo/vector-icons";
import { Box, HeaderButton, Touch } from "../../../components";
import { BreadCrumb } from "../../../containers";
import { getApiUrl, getAppUrl, handleError } from "../../../utils";
import {
  configAtom,
  filenameAtom,
  useAtom,
  setFilename,
  setEditorInstance,
  editorInstanceAtom,
  showLoadingModal,
  hideLoadingModal,
} from "../../../atoms";
import { screen, container } from "../../../reactUtils";
import FolderOpenIcon from "../../../assets/icons/folder-open-outline.svg";
import DownloadIcon from "../../../assets/icons/download-outline.svg";
import NewspaperIcon from "../../../assets/icons/newspaper-outline.svg";

const writeFile = (filename, blob) => {
  const a = document.createElement("a");
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

const EditorScreen = screen(() => {
  const config = useAtom(configAtom);
  return (
    <>
      <Editor
        apiKey={config.tinymceApiKey}
        initialValue="<p>Add some content...</p>"
        init={{
          height: "100%",
          branding: false,
          menubar: false,
          contextmenu: false,
          resize: false,
          statusbar: true,
          content_css: "/styles.css",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          spellchecker_language: "en_GB",
          plugins: [
            // "a11ychecker advcode casechange formatpainter linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker",
            // "advlist autolink lists link image charmap print preview anchor",
            // "searchreplace visualblocks code fullscreen",
            // "insertdatetime media table code help wordcount",
            "help", "lists", "link", "image", "table", "a11ychecker", "casechange", "checklist", "lists", "code", "tinymcespellchecker", "formatpainter", "pageembed", "permanentpen"
          ],
          toolbar1: `blocks | fontfamily fontsize bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify outdent indent | help`,
          toolbar2: `styles bullist numlist link image table customHeart customTables| a11ycheck casechange checklist code formatpainter pageembed permanentpen spellchecker`,
    
          style_formats: [
            {
              title: "Header",
              block: "div",
              classes: "sectionHeader maintitle",
              wrapper: true,
            },
            {
              title: "References",
              block: "div",
              classes: "sectionHeader",
              wrapper: true,
            },
            {
              title: "Gem",
              block: "div",
              classes: "gem",
              wrapper: true,
            },
            {
              title: "Nugget",
              block: "div",
              classes: "nugget",
              wrapper: true,
            },
          ],
          setup: (editor) => {
            editor.on("init", function () {
              this.getDoc().body.style.fontFamily = "Arial";
            });
            setEditorInstance(editor);
            editor.ui.registry.addButton("customHeart", {
              text: "❤️",
              tooltip: "Nugget Heart",
              onAction: () => {
                editor.insertContent(`<div style="display: flex">
                   <img src="/heart.svg" alt="Heart icon" style="width: 35px; height: 35px; margin-left: 20px; margin-right: 20px; margin-top: 15px;" />
                   <div class="nugget--tagline" style="display: flex; flex-direction: column;">
                    <p>placeholder</p>
                   </div>
                   </div>`);
              },
            });
            editor.ui.registry.addMenuButton("customTables", {
              text: "Tables",
              fetch: (callback) => {
                callback([
                  {
                    type: "menuitem",
                    text: "Table 1",
                    onAction: function () {
                      editor.insertContent(`
                        <table>
                          <thead>
                          <tr>
                              <th colspan="1" rowspan="1">Symptoms</th>
                              <th colspan="1" rowspan="1">Underlying condition</th>
                              <th colspan="1" rowspan="1">When to see a doctor</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                              <td colspan="1" rowspan="1">Nocturnal pain</td>
                              <td colspan="1" rowspan="1">Tumour/Infection</td>
                              <td colspan="1" rowspan="1">Within 7 days</td>
                          </tr>
                          </tbody>
                        </table>
                    `);
                    },
                  },
                  {
                    type: "menuitem",
                    text: "Table 2",
                    onAction: function () {
                      editor.insertContent(`
                        <table class="dos">
                          <thead>
                          <tr>
                              <th colspan="1" rowspan="1">Do</th>
                              <th colspan="1" rowspan="1">Don't</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                              <td colspan="1" rowspan="1">
                              <p></p>
                              </td>
                              <td colspan="1" rowspan="1">
                              <p></p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    `);
                    },
                  },
                ]);
              },
            });
          },
        }}
      />
    </>
  );
});

const Title = container(() => {
  const filename = useAtom(filenameAtom);
  const input = (
    <View style={{ flex: 1, flexDirection: "row", width: 400 }}>
      <View
        style={{ flex: 1, borderBottomWidth: 1, borderBottomColor: "gray" }}
      >
        <TextInput
          style={{ fontSize: 20, color: "#6c757d" }}
          value={filename}
          placeholder="Untitled"
          onChangeText={setFilename}
        />
      </View>
    </View>
  );
  return (
    <View
      style={{
        flexDirection: "row",
        paddingTop: 13,
        marginRight: 20,
        height: 51,
      }}
    >
      <BreadCrumb parts={["currentOrganisation.name", "Files", input]} />
    </View>
  );
});
const onLoadPress = () => {
  const input = document.createElement("input");
  input.type = "file";
  input.click();
  input.onchange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      setFilename(file.name.replace(".html", ""));
      editorInstanceAtom.getValue().setContent(reader.result);
    };
    reader.readAsText(file, "UTF-8");
  };
};

const onSavePress = () => {
  const html = editorInstanceAtom.getValue().getContent();
  writeFile(
    filenameAtom.getValue() + ".html",
    new Blob([html], { type: "application/html" })
  );
};

const onPdfPress = handleError(
  async () => {
    showLoadingModal("Saving Pdf");
    const html = editorInstanceAtom.getValue().getContent();
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(html, "text/html");
    const images = htmlDoc.getElementsByTagName("img");
    for (const img of images) {
      if (img.src && !img.src.includes("/heart.svg")) {
        img.style = `${img.style.cssText || ""} width: ${
          img.width
        }px; height: ${img.height}px`;
      }
    }
    const res = await fetch(getApiUrl() + "/print", {
      method: "POST",
      body: `
        <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
        <html>
        <head>
            <META http-equiv="Content-Type" content="text/html; charset=utf-8">
            <link rel="stylesheet" href="${getAppUrl()}/styles.css">
        </head>
        <body>
          <section>${htmlDoc.body.innerHTML}</section>
        </body>
        </html>
      `,
      headers: {
        "x-base-url":
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : ""),
      },
    });
    writeFile(
      filenameAtom.getValue() + ".pdf",
      new Blob([await res.blob()], { type: "application/pdf" })
    );
  },
  () => hideLoadingModal()
);

const Header = () => {
  const style = { marginRight: 10, marginTop: 3, marginBottom: 3 };
  return (
    <View style={{ flexDirection: "row", paddingTop: 13 }}>
      <HeaderButton
        icon={
          <FolderOpenIcon
            width={20}
            height={20}
            stroke="#004E7A"
            style={style}
          />
        }
        title="Load"
        onPress={onLoadPress}
      />
      <HeaderButton
        icon={
          <DownloadIcon width={20} height={20} stroke="#004E7A" style={style} />
        }
        title="Save"
        onPress={onSavePress}
      />
      <HeaderButton
        icon={
          <NewspaperIcon
            width={20}
            height={20}
            stroke="#004E7A"
            style={style}
          />
        }
        title="Pdf"
        onPress={onPdfPress}
      />
    </View>
  );
};

export const EditorOptions = (web) => ({
  headerShown: true,
  header: ({ scene, previous, navigation }) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        backgroundColor="white"
        paddingHorizontal={13}
        paddingBottom={13}
      >
        <Touch
          justifyContent="flex-end"
          marginRight={10}
          onPress={() => navigation.goBack()}
        >
          <Ionicons name="ios-arrow-back" size={32} color="#004e79" />
        </Touch>
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          flexFlow="wrap"
        >
          <Title />
          <Header {...scene} previous={previous} navigation={navigation} />
        </Box>
      </Box>
    );
  },
});

export default EditorScreen;
