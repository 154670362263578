export default [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    iso2: 'af',
    flag: require('./images/af.png'),
    dialCode: '93',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Albania (Shqipëri)',
    iso2: 'al',
    flag: require('./images/al.png'),
    dialCode: '355',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    iso2: 'dz',
    flag: require('./images/dz.png'),
    dialCode: '213',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'American Samoa',
    iso2: 'as',
    flag: require('./images/as.png'),
    dialCode: '1684',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Andorra',
    iso2: 'ad',
    flag: require('./images/ad.png'),
    dialCode: '376',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Angola',
    iso2: 'ao',
    flag: require('./images/ao.png'),
    dialCode: '244',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Anguilla',
    iso2: 'ai',
    flag: require('./images/ai.png'),
    dialCode: '1264',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Antigua and Barbuda',
    iso2: 'ag',
    flag: require('./images/ag.png'),
    dialCode: '1268',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Argentina',
    iso2: 'ar',
    flag: require('./images/ar.png'),
    dialCode: '54',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Armenia (Հայաստան)',
    iso2: 'am',
    flag: require('./images/am.png'),
    dialCode: '374',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Aruba',
    iso2: 'aw',
    flag: require('./images/aw.png'),
    dialCode: '297',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Australia',
    iso2: 'au',
    flag: require('./images/au.png'),
    dialCode: '61',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Austria (Österreich)',
    iso2: 'at',
    flag: require('./images/at.png'),
    dialCode: '43',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    iso2: 'az',
    flag: require('./images/az.png'),
    dialCode: '994',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bahamas',
    iso2: 'bs',
    flag: require('./images/bs.png'),
    dialCode: '1242',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    iso2: 'bh',
    flag: require('./images/bh.png'),
    dialCode: '973',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    iso2: 'bd',
    flag: require('./images/bd.png'),
    dialCode: '880',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Barbados',
    iso2: 'bb',
    flag: require('./images/bb.png'),
    dialCode: '1246',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Belarus (Беларусь)',
    iso2: 'by',
    flag: require('./images/by.png'),
    dialCode: '375',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Belgium (België)',
    iso2: 'be',
    flag: require('./images/be.png'),
    dialCode: '32',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Belize',
    iso2: 'bz',
    flag: require('./images/bz.png'),
    dialCode: '501',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Benin (Bénin)',
    iso2: 'bj',
    flag: require('./images/bj.png'),
    dialCode: '229',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bermuda',
    iso2: 'bm',
    flag: require('./images/bm.png'),
    dialCode: '1441',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bhutan (འབྲུག)',
    iso2: 'bt',
    flag: require('./images/bt.png'),
    dialCode: '975',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bolivia',
    iso2: 'bo',
    flag: require('./images/bo.png'),
    dialCode: '591',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    iso2: 'ba',
    flag: require('./images/ba.png'),
    dialCode: '387',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Botswana',
    iso2: 'bw',
    flag: require('./images/bw.png'),
    dialCode: '267',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Brazil (Brasil)',
    iso2: 'br',
    flag: require('./images/br.png'),
    dialCode: '55',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'io',
    flag: require('./images/io.png'),
    dialCode: '246',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'British Virgin Islands',
    iso2: 'vg',
    flag: require('./images/vg.png'),
    dialCode: '1284',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Brunei',
    iso2: 'bn',
    flag: require('./images/bn.png'),
    dialCode: '673',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bulgaria (България)',
    iso2: 'bg',
    flag: require('./images/bg.png'),
    dialCode: '359',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Burkina Faso',
    iso2: 'bf',
    flag: require('./images/bf.png'),
    dialCode: '226',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Burundi (Uburundi)',
    iso2: 'bi',
    flag: require('./images/bi.png'),
    dialCode: '257',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    iso2: 'kh',
    flag: require('./images/kh.png'),
    dialCode: '855',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cameroon (Cameroun)',
    iso2: 'cm',
    flag: require('./images/cm.png'),
    dialCode: '237',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Canada',
    iso2: 'ca',
    flag: require('./images/ca.png'),
    dialCode: '1',
    priority: 1,
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    iso2: 'cv',
    flag: require('./images/cv.png'),
    dialCode: '238',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Caribbean Netherlands',
    iso2: 'bq',
    flag: require('./images/bq.png'),
    dialCode: '599',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Cayman Islands',
    iso2: 'ky',
    flag: require('./images/ky.png'),
    dialCode: '1345',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    iso2: 'cf',
    flag: require('./images/cf.png'),
    dialCode: '236',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Chad (Tchad)',
    iso2: 'td',
    flag: require('./images/td.png'),
    dialCode: '235',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Chile',
    iso2: 'cl',
    flag: require('./images/cl.png'),
    dialCode: '56',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'China (中国)',
    iso2: 'cn',
    flag: require('./images/cn.png'),
    dialCode: '86',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Christmas Island',
    iso2: 'cx',
    flag: require('./images/cx.png'),
    dialCode: '61',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2: 'cc',
    flag: require('./images/cc.png'),
    dialCode: '61',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Colombia',
    iso2: 'co',
    flag: require('./images/co.png'),
    dialCode: '57',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    iso2: 'km',
    flag: require('./images/km.png'),
    dialCode: '269',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    iso2: 'cd',
    flag: require('./images/cd.png'),
    dialCode: '243',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    iso2: 'cg',
    flag: require('./images/cg.png'),
    dialCode: '242',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cook Islands',
    iso2: 'ck',
    flag: require('./images/ck.png'),
    dialCode: '682',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Costa Rica',
    iso2: 'cr',
    flag: require('./images/cr.png'),
    dialCode: '506',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Côte d’Ivoire',
    iso2: 'ci',
    flag: require('./images/ci.png'),
    dialCode: '225',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Croatia (Hrvatska)',
    iso2: 'hr',
    flag: require('./images/hr.png'),
    dialCode: '385',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cuba',
    iso2: 'cu',
    flag: require('./images/cu.png'),
    dialCode: '53',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Curaçao',
    iso2: 'cw',
    flag: require('./images/cw.png'),
    dialCode: '599',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cyprus (Κύπρος)',
    iso2: 'cy',
    flag: require('./images/cy.png'),
    dialCode: '357',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Czech Republic (Česká republika)',
    iso2: 'cz',
    flag: require('./images/cz.png'),
    dialCode: '420',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Denmark (Danmark)',
    iso2: 'dk',
    flag: require('./images/dk.png'),
    dialCode: '45',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Djibouti',
    iso2: 'dj',
    flag: require('./images/dj.png'),
    dialCode: '253',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Dominica',
    iso2: 'dm',
    flag: require('./images/dm.png'),
    dialCode: '1767',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    iso2: 'do',
    flag: require('./images/do.png'),
    dialCode: '1',
    priority: 2,
    areaCodes: ['809', '829', '849'],
  },
  {
    name: 'Ecuador',
    iso2: 'ec',
    flag: require('./images/ec.png'),
    dialCode: '593',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    iso2: 'eg',
    flag: require('./images/eg.png'),
    dialCode: '20',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'El Salvador',
    iso2: 'sv',
    flag: require('./images/sv.png'),
    dialCode: '503',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    iso2: 'gq',
    flag: require('./images/gq.png'),
    dialCode: '240',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Eritrea',
    iso2: 'er',
    flag: require('./images/er.png'),
    dialCode: '291',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Estonia (Eesti)',
    iso2: 'ee',
    flag: require('./images/ee.png'),
    dialCode: '372',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ethiopia',
    iso2: 'et',
    flag: require('./images/et.png'),
    dialCode: '251',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    iso2: 'fk',
    flag: require('./images/fk.png'),
    dialCode: '500',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Faroe Islands (Føroyar)',
    iso2: 'fo',
    flag: require('./images/fo.png'),
    dialCode: '298',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Fiji',
    iso2: 'fj',
    flag: require('./images/fj.png'),
    dialCode: '679',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Finland (Suomi)',
    iso2: 'fi',
    flag: require('./images/fi.png'),
    dialCode: '358',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'France',
    iso2: 'fr',
    flag: require('./images/fr.png'),
    dialCode: '33',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'French Guiana (Guyane française)',
    iso2: 'gf',
    flag: require('./images/gf.png'),
    dialCode: '594',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'French Polynesia (Polynésie française)',
    iso2: 'pf',
    flag: require('./images/pf.png'),
    dialCode: '689',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gabon',
    iso2: 'ga',
    flag: require('./images/ga.png'),
    dialCode: '241',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gambia',
    iso2: 'gm',
    flag: require('./images/gm.png'),
    dialCode: '220',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Georgia (საქართველო)',
    iso2: 'ge',
    flag: require('./images/ge.png'),
    dialCode: '995',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Germany (Deutschland)',
    iso2: 'de',
    flag: require('./images/de.png'),
    dialCode: '49',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ghana (Gaana)',
    iso2: 'gh',
    flag: require('./images/gh.png'),
    dialCode: '233',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gibraltar',
    iso2: 'gi',
    flag: require('./images/gi.png'),
    dialCode: '350',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Greece (Ελλάδα)',
    iso2: 'gr',
    flag: require('./images/gr.png'),
    dialCode: '30',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    iso2: 'gl',
    flag: require('./images/gl.png'),
    dialCode: '299',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Grenada',
    iso2: 'gd',
    flag: require('./images/gd.png'),
    dialCode: '1473',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guadeloupe',
    iso2: 'gp',
    flag: require('./images/gp.png'),
    dialCode: '590',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guam',
    iso2: 'gu',
    flag: require('./images/gu.png'),
    dialCode: '1671',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guatemala',
    iso2: 'gt',
    flag: require('./images/gt.png'),
    dialCode: '502',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guernsey',
    iso2: 'gg',
    flag: require('./images/gg.png'),
    dialCode: '44',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Guinea (Guinée)',
    iso2: 'gn',
    flag: require('./images/gn.png'),
    dialCode: '224',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    iso2: 'gw',
    flag: require('./images/gw.png'),
    dialCode: '245',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guyana',
    iso2: 'gy',
    flag: require('./images/gy.png'),
    dialCode: '592',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Haiti',
    iso2: 'ht',
    flag: require('./images/ht.png'),
    dialCode: '509',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Honduras',
    iso2: 'hn',
    flag: require('./images/hn.png'),
    dialCode: '504',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Hong Kong (香港)',
    iso2: 'hk',
    flag: require('./images/hk.png'),
    dialCode: '852',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Hungary (Magyarország)',
    iso2: 'hu',
    flag: require('./images/hu.png'),
    dialCode: '36',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iceland (Ísland)',
    iso2: 'is',
    flag: require('./images/is.png'),
    dialCode: '354',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'India (भारत)',
    iso2: 'in',
    flag: require('./images/in.png'),
    dialCode: '91',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Indonesia',
    iso2: 'id',
    flag: require('./images/id.png'),
    dialCode: '62',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iran (‫ایران‬‎)',
    iso2: 'ir',
    flag: require('./images/ir.png'),
    dialCode: '98',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    iso2: 'iq',
    flag: require('./images/iq.png'),
    dialCode: '964',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ireland',
    iso2: 'ie',
    flag: require('./images/ie.png'),
    dialCode: '353',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Isle of Man',
    iso2: 'im',
    flag: require('./images/im.png'),
    dialCode: '44',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    iso2: 'il',
    flag: require('./images/il.png'),
    dialCode: '972',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Italy (Italia)',
    iso2: 'it',
    flag: require('./images/it.png'),
    dialCode: '39',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Jamaica',
    iso2: 'jm',
    flag: require('./images/jm.png'),
    dialCode: '1876',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Japan (日本)',
    iso2: 'jp',
    flag: require('./images/jp.png'),
    dialCode: '81',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Jersey',
    iso2: 'je',
    flag: require('./images/je.png'),
    dialCode: '44',
    priority: 3,
    areaCodes: null,
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    iso2: 'jo',
    flag: require('./images/jo.png'),
    dialCode: '962',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kazakhstan (Казахстан)',
    iso2: 'kz',
    flag: require('./images/kz.png'),
    dialCode: '77',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Kenya',
    iso2: 'ke',
    flag: require('./images/ke.png'),
    dialCode: '254',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kiribati',
    iso2: 'ki',
    flag: require('./images/ki.png'),
    dialCode: '686',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    iso2: 'kw',
    flag: require('./images/kw.png'),
    dialCode: '965',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    iso2: 'kg',
    flag: require('./images/kg.png'),
    dialCode: '996',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Laos (ລາວ)',
    iso2: 'la',
    flag: require('./images/la.png'),
    dialCode: '856',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Latvia (Latvija)',
    iso2: 'lv',
    flag: require('./images/lv.png'),
    dialCode: '371',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    iso2: 'lb',
    flag: require('./images/lb.png'),
    dialCode: '961',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Lesotho',
    iso2: 'ls',
    flag: require('./images/ls.png'),
    dialCode: '266',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Liberia',
    iso2: 'lr',
    flag: require('./images/lr.png'),
    dialCode: '231',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    iso2: 'ly',
    flag: require('./images/ly.png'),
    dialCode: '218',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Liechtenstein',
    iso2: 'li',
    flag: require('./images/li.png'),
    dialCode: '423',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Lithuania (Lietuva)',
    iso2: 'lt',
    flag: require('./images/lt.png'),
    dialCode: '370',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Luxembourg',
    iso2: 'lu',
    flag: require('./images/lu.png'),
    dialCode: '352',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Macau (澳門)',
    iso2: 'mo',
    flag: require('./images/mo.png'),
    dialCode: '853',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    iso2: 'mk',
    flag: require('./images/mk.png'),
    dialCode: '389',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Madagascar (Madagasikara)',
    iso2: 'mg',
    flag: require('./images/mg.png'),
    dialCode: '261',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malawi',
    iso2: 'mw',
    flag: require('./images/mw.png'),
    dialCode: '265',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malaysia',
    iso2: 'my',
    flag: require('./images/my.png'),
    dialCode: '60',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Maldives',
    iso2: 'mv',
    flag: require('./images/mv.png'),
    dialCode: '960',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mali',
    iso2: 'ml',
    flag: require('./images/ml.png'),
    dialCode: '223',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malta',
    iso2: 'mt',
    flag: require('./images/mt.png'),
    dialCode: '356',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Marshall Islands',
    iso2: 'mh',
    flag: require('./images/mh.png'),
    dialCode: '692',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Martinique',
    iso2: 'mq',
    flag: require('./images/mq.png'),
    dialCode: '596',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    iso2: 'mr',
    flag: require('./images/mr.png'),
    dialCode: '222',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mauritius (Moris)',
    iso2: 'mu',
    flag: require('./images/mu.png'),
    dialCode: '230',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mayotte',
    iso2: 'yt',
    flag: require('./images/yt.png'),
    dialCode: '262',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Mexico (México)',
    iso2: 'mx',
    flag: require('./images/mx.png'),
    dialCode: '52',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Micronesia',
    iso2: 'fm',
    flag: require('./images/fm.png'),
    dialCode: '691',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Moldova (Republica Moldova)',
    iso2: 'md',
    flag: require('./images/md.png'),
    dialCode: '373',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Monaco',
    iso2: 'mc',
    flag: require('./images/mc.png'),
    dialCode: '377',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mongolia (Монгол)',
    iso2: 'mn',
    flag: require('./images/mn.png'),
    dialCode: '976',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Montenegro (Crna Gora)',
    iso2: 'me',
    flag: require('./images/me.png'),
    dialCode: '382',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Montserrat',
    iso2: 'ms',
    flag: require('./images/ms.png'),
    dialCode: '1664',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    iso2: 'ma',
    flag: require('./images/ma.png'),
    dialCode: '212',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mozambique (Moçambique)',
    iso2: 'mz',
    flag: require('./images/mz.png'),
    dialCode: '258',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Myanmar (Burma)',
    iso2: 'mm',
    flag: require('./images/mm.png'),
    dialCode: '95',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Namibia (Namibië)',
    iso2: 'na',
    flag: require('./images/na.png'),
    dialCode: '264',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nauru',
    iso2: 'nr',
    flag: require('./images/nr.png'),
    dialCode: '674',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nepal (नेपाल)',
    iso2: 'np',
    flag: require('./images/np.png'),
    dialCode: '977',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Netherlands (Nederland)',
    iso2: 'nl',
    flag: require('./images/nl.png'),
    dialCode: '31',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    iso2: 'nc',
    flag: require('./images/nc.png'),
    dialCode: '687',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'New Zealand',
    iso2: 'nz',
    flag: require('./images/nz.png'),
    dialCode: '64',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nicaragua',
    iso2: 'ni',
    flag: require('./images/ni.png'),
    dialCode: '505',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Niger (Nijar)',
    iso2: 'ne',
    flag: require('./images/ne.png'),
    dialCode: '227',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nigeria',
    iso2: 'ng',
    flag: require('./images/ng.png'),
    dialCode: '234',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Niue',
    iso2: 'nu',
    flag: require('./images/nu.png'),
    dialCode: '683',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Norfolk Island',
    iso2: 'nf',
    flag: require('./images/nf.png'),
    dialCode: '672',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    iso2: 'kp',
    flag: require('./images/kp.png'),
    dialCode: '850',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Northern Mariana Islands',
    iso2: 'mp',
    flag: require('./images/mp.png'),
    dialCode: '1670',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Norway (Norge)',
    iso2: 'no',
    flag: require('./images/no.png'),
    dialCode: '47',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    iso2: 'om',
    flag: require('./images/om.png'),
    dialCode: '968',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    iso2: 'pk',
    flag: require('./images/pk.png'),
    dialCode: '92',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Palau',
    iso2: 'pw',
    flag: require('./images/pw.png'),
    dialCode: '680',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    iso2: 'ps',
    flag: require('./images/ps.png'),
    dialCode: '970',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Panama (Panamá)',
    iso2: 'pa',
    flag: require('./images/pa.png'),
    dialCode: '507',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Papua New Guinea',
    iso2: 'pg',
    flag: require('./images/pg.png'),
    dialCode: '675',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Paraguay',
    iso2: 'py',
    flag: require('./images/py.png'),
    dialCode: '595',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Peru (Perú)',
    iso2: 'pe',
    flag: require('./images/pe.png'),
    dialCode: '51',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Philippines',
    iso2: 'ph',
    flag: require('./images/ph.png'),
    dialCode: '63',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Poland (Polska)',
    iso2: 'pl',
    flag: require('./images/pl.png'),
    dialCode: '48',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Portugal',
    iso2: 'pt',
    flag: require('./images/pt.png'),
    dialCode: '351',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Puerto Rico',
    iso2: 'pr',
    flag: require('./images/pr.png'),
    dialCode: '1',
    priority: 3,
    areaCodes: ['787', '939'],
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    iso2: 'qa',
    flag: require('./images/qa.png'),
    dialCode: '974',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Réunion (La Réunion)',
    iso2: 're',
    flag: require('./images/re.png'),
    dialCode: '262',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Romania (România)',
    iso2: 'ro',
    flag: require('./images/ro.png'),
    dialCode: '40',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Russia (Россия)',
    iso2: 'ru',
    flag: require('./images/ru.png'),
    dialCode: '7',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Rwanda',
    iso2: 'rw',
    flag: require('./images/rw.png'),
    dialCode: '250',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Barthélemy (Saint-Barthélemy)',
    iso2: 'bl',
    flag: require('./images/bl.png'),
    dialCode: '590',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Saint Helena',
    iso2: 'sh',
    flag: require('./images/sh.png'),
    dialCode: '290',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2: 'kn',
    flag: require('./images/kn.png'),
    dialCode: '1869',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Lucia',
    iso2: 'lc',
    flag: require('./images/lc.png'),
    dialCode: '1758',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    iso2: 'mf',
    flag: require('./images/mf.png'),
    dialCode: '590',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    iso2: 'pm',
    flag: require('./images/pm.png'),
    dialCode: '508',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'vc',
    flag: require('./images/vc.png'),
    dialCode: '1784',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Samoa',
    iso2: 'ws',
    flag: require('./images/ws.png'),
    dialCode: '685',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'San Marino',
    iso2: 'sm',
    flag: require('./images/sm.png'),
    dialCode: '378',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    iso2: 'st',
    flag: require('./images/st.png'),
    dialCode: '239',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    iso2: 'sa',
    flag: require('./images/sa.png'),
    dialCode: '966',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Senegal (Sénégal)',
    iso2: 'sn',
    flag: require('./images/sn.png'),
    dialCode: '221',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Serbia (Србија)',
    iso2: 'rs',
    flag: require('./images/rs.png'),
    dialCode: '381',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Seychelles',
    iso2: 'sc',
    flag: require('./images/sc.png'),
    dialCode: '248',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sierra Leone',
    iso2: 'sl',
    flag: require('./images/sl.png'),
    dialCode: '232',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Singapore',
    iso2: 'sg',
    flag: require('./images/sg.png'),
    dialCode: '65',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sint Maarten',
    iso2: 'sx',
    flag: require('./images/sx.png'),
    dialCode: '1721',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Slovakia (Slovensko)',
    iso2: 'sk',
    flag: require('./images/sk.png'),
    dialCode: '421',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Slovenia (Slovenija)',
    iso2: 'si',
    flag: require('./images/si.png'),
    dialCode: '386',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Solomon Islands',
    iso2: 'sb',
    flag: require('./images/sb.png'),
    dialCode: '677',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Somalia (Soomaaliya)',
    iso2: 'so',
    flag: require('./images/so.png'),
    dialCode: '252',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Africa',
    iso2: 'za',
    flag: require('./images/za.png'),
    dialCode: '27',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Korea (대한민국)',
    iso2: 'kr',
    flag: require('./images/kr.png'),
    dialCode: '82',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    iso2: 'ss',
    flag: require('./images/ss.png'),
    dialCode: '211',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Spain (España)',
    iso2: 'es',
    flag: require('./images/es.png'),
    dialCode: '34',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    iso2: 'lk',
    flag: require('./images/lk.png'),
    dialCode: '94',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    iso2: 'sd',
    flag: require('./images/sd.png'),
    dialCode: '249',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Suriname',
    iso2: 'sr',
    flag: require('./images/sr.png'),
    dialCode: '597',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso2: 'sj',
    flag: require('./images/sj.png'),
    dialCode: '47',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Swaziland',
    iso2: 'sz',
    flag: require('./images/sz.png'),
    dialCode: '268',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sweden (Sverige)',
    iso2: 'se',
    flag: require('./images/se.png'),
    dialCode: '46',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Switzerland (Schweiz)',
    iso2: 'ch',
    flag: require('./images/ch.png'),
    dialCode: '41',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    iso2: 'sy',
    flag: require('./images/sy.png'),
    dialCode: '963',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Taiwan (台灣)',
    iso2: 'tw',
    flag: require('./images/tw.png'),
    dialCode: '886',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tajikistan',
    iso2: 'tj',
    flag: require('./images/tj.png'),
    dialCode: '992',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tanzania',
    iso2: 'tz',
    flag: require('./images/tz.png'),
    dialCode: '255',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Thailand (ไทย)',
    iso2: 'th',
    flag: require('./images/th.png'),
    dialCode: '66',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Timor-Leste',
    iso2: 'tl',
    flag: require('./images/tl.png'),
    dialCode: '670',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Togo',
    iso2: 'tg',
    flag: require('./images/tg.png'),
    dialCode: '228',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tokelau',
    iso2: 'tk',
    flag: require('./images/tk.png'),
    dialCode: '690',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tonga',
    iso2: 'to',
    flag: require('./images/to.png'),
    dialCode: '676',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Trinidad and Tobago',
    iso2: 'tt',
    flag: require('./images/tt.png'),
    dialCode: '1868',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    iso2: 'tn',
    flag: require('./images/tn.png'),
    dialCode: '216',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Turkey (Türkiye)',
    iso2: 'tr',
    flag: require('./images/tr.png'),
    dialCode: '90',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Turkmenistan',
    iso2: 'tm',
    flag: require('./images/tm.png'),
    dialCode: '993',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Turks and Caicos Islands',
    iso2: 'tc',
    flag: require('./images/tc.png'),
    dialCode: '1649',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tuvalu',
    iso2: 'tv',
    flag: require('./images/tv.png'),
    dialCode: '688',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'U.S. Virgin Islands',
    iso2: 'vi',
    flag: require('./images/vi.png'),
    dialCode: '1340',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uganda',
    iso2: 'ug',
    flag: require('./images/ug.png'),
    dialCode: '256',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ukraine (Україна)',
    iso2: 'ua',
    flag: require('./images/ua.png'),
    dialCode: '380',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    iso2: 'ae',
    flag: require('./images/ae.png'),
    dialCode: '971',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'United Kingdom',
    iso2: 'gb',
    flag: require('./images/gb.png'),
    dialCode: '44',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'United States',
    iso2: 'us',
    flag: require('./images/us.png'),
    dialCode: '1',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uruguay',
    iso2: 'uy',
    flag: require('./images/uy.png'),
    dialCode: '598',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    iso2: 'uz',
    flag: require('./images/uz.png'),
    dialCode: '998',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vanuatu',
    iso2: 'vu',
    flag: require('./images/vu.png'),
    dialCode: '678',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    iso2: 'va',
    flag: require('./images/va.png'),
    dialCode: '39',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Venezuela',
    iso2: 've',
    flag: require('./images/ve.png'),
    dialCode: '58',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vietnam (Việt Nam)',
    iso2: 'vn',
    flag: require('./images/vn.png'),
    dialCode: '84',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Wallis and Futuna',
    iso2: 'wf',
    flag: require('./images/wf.png'),
    dialCode: '681',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    iso2: 'eh',
    flag: require('./images/eh.png'),
    dialCode: '212',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    iso2: 'ye',
    flag: require('./images/ye.png'),
    dialCode: '967',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Zambia',
    iso2: 'zm',
    flag: require('./images/zm.png'),
    dialCode: '260',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Zimbabwe',
    iso2: 'zw',
    flag: require('./images/zw.png'),
    dialCode: '263',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Åland Islands',
    iso2: 'ax',
    flag: require('./images/ax.png'),
    dialCode: '358',
    priority: 1,
    areaCodes: null,
  },
];
