import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useAtom, isWebAtom } from '../atoms';

export default function Radio({ checked, color = '#004E7A', borderColor = '#626262', onPress }) {
  const web = useAtom(isWebAtom);
  const size = web ? 16 : 24;
  const circleSize = web ? 8 : 12;
  return (
    <TouchableOpacity
      style={{
        height: size,
        width: size,
        borderRadius: 25,
        borderColor: borderColor,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={onPress}
    >
      {checked && <View testID="radio-mark" style={{ backgroundColor: color, borderRadius: 25, width: circleSize, height: circleSize }} />}
    </TouchableOpacity>
  );
}
