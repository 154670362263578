import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function ShareContentIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={23.258}
      height={26.525}
      viewBox="0 0 23.258 26.525"
      {...props}
    >
      <G data-name="Group 81" fill="#004d7a">
        <Path
          data-name="Path 377"
          d="M20.289 8.736h-4.208a.956.956 0 000 1.912h4.208a1.058 1.058 0 011.057 1.057v11.852a1.058 1.058 0 01-1.057 1.057H2.969a1.058 1.058 0 01-1.057-1.057V11.705a1.058 1.058 0 011.057-1.057h4.208a.956.956 0 000-1.912H2.969A2.972 2.972 0 000 11.705v11.852a2.972 2.972 0 002.969 2.969H20.29a2.972 2.972 0 002.969-2.969V11.705a2.972 2.972 0 00-2.97-2.969z"
        />
        <Path
          data-name="Path 378"
          d="M8.523 5.837l2.15-2.39V17.63a.956.956 0 001.912 0V3.443l2.15 2.39a.956.956 0 101.421-1.278L12.342.317l-.029-.031-.028-.028A.954.954 0 0011.632 0a.951.951 0 00-.644.251.649.649 0 00-.046.045l-.02.021-3.814 4.239a.956.956 0 101.421 1.278z"
        />
      </G>
    </Svg>
  )
}

export default ShareContentIcon