export const PRIMARY = "#004D7A";
export const PRIMARY_GREEN = "#31ABAB";
export const PRIMARY_LIGHT = "#5386A5";
export const PIMARY_TEXT = "#b8d6e8";
export const PRIMARY_TEXT_LIGHT = "#719CB6";
export const WHITE = "#FFFFFF";
export const RED = "#FF3e50";
export const BLACK = "#000000";
export const GRAY = "#EBEBF1";
export const TEXT_GRAY = "#AEB1B5";

export const AuthTheme = {
  primary: PRIMARY,
  primaryLight: PRIMARY_LIGHT,
  background: PRIMARY,
  buttonBackground: WHITE,
  buttonBackgroundDisabled: GRAY,
  buttonText: PRIMARY,
  text: PRIMARY_LIGHT,
  textLight: PRIMARY_TEXT_LIGHT,
  textPlaceholder: TEXT_GRAY,
  textInputBorderBottom: PRIMARY_TEXT_LIGHT,
  textInputText: PIMARY_TEXT,
  textInputPlaceholder: PRIMARY_TEXT_LIGHT,
  spinner: WHITE,
  error: WHITE,
  snackBarBackground: WHITE,
  snackBarAccent: PRIMARY,
  snackBarMessageColor: PRIMARY,
};

export const MainTheme = {
  primary: PRIMARY,
  primaryLight: PRIMARY_LIGHT,
  background: GRAY,
  buttonBackground: PRIMARY,
  buttonBackgroundDisabled: PRIMARY_LIGHT,
  buttonText: WHITE,
  text: PRIMARY_LIGHT,
  textLight: PRIMARY_TEXT_LIGHT,
  textPlaceholder: TEXT_GRAY,
  textInputBorderBottom: PRIMARY_TEXT_LIGHT,
  textInputText: BLACK,
  textInputPlaceholder: PRIMARY_TEXT_LIGHT,
  spinner: PRIMARY,
  error: RED,
  snackBarBackground: PRIMARY,
  snackBarAccent: PRIMARY_TEXT_LIGHT,
  snackBarMessageColor: WHITE,
  tabIcon: PRIMARY,
  tabIconInactive: PRIMARY_TEXT_LIGHT,
  tabText: PRIMARY_TEXT_LIGHT,
  switchThumb: PRIMARY,
  switchOn: GRAY,
  switchOff: GRAY,
};
