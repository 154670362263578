import React from "react";
import { format } from "date-fns";
import {
  MainScreen,
  Box,
  Text,
  Touch,
  Field,
  HeaderButton,
} from "../../../components";
import { useAtom, isWebAtom } from "../../../atoms";
import { HeaderTitle } from "../../../headerUtils";
import * as WebBrowser from "expo-web-browser";
import { View, TouchableOpacity, Platform, Linking } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import DownloadIcon from "../../../assets/icons/download-outline.svg";
import { container } from "../../../reactUtils";

const Header = container(() => {
  return (
    <View
      style={{
        flexDirection: "row",
        paddingHorizontal: 13,
        paddingBottom: 13,
      }}
    ></View>
  );
});

export function ResultsScreen({ route, navigation }) {
  const web = useAtom(isWebAtom);
  const { title, updatedAt, tableResults, results, pdfDownloadUrl, name, ...props } =
    route.params;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => {
        return (
          <>
            <View style={{ flexDirection: "row" }}>
              <Header {...props} />
              <HeaderButton
                icon={
                  web ? (
                    <DownloadIcon width={20} height={20} stroke="#004E7A" />
                  ) : (
                    <DownloadIcon width={35} height={35} stroke="#004E7A" />
                  )
                }
                title="  Download"
                onPress={async () => {
                  if (pdfDownloadUrl) {
                    if (Platform.OS === "web") {
                      Linking.openURL(pdfDownloadUrl);
                    } else {
                      await WebBrowser.openBrowserAsync(pdfDownloadUrl);
                    }
                  }
                }}
              />
            </View>
          </>
        );
      },
    });
  });

  const newResults = typeof results == "string" ? JSON.parse(results) : results;
  return (
    <MainScreen backgroundColor="white">
      <Box padding={16}>
        <Box alignItems="center" marginVertical={100}>
          <TouchableOpacity
            onPress={async () => {
              if (pdfDownloadUrl) {
                if (Platform.OS === "web") {
                  Linking.openURL(pdfDownloadUrl);
                } else {
                  await WebBrowser.openBrowserAsync(pdfDownloadUrl);
                }
              }
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                fontSize={22}
                lineHeight={40}
                color="black"
                fontWeight="500"
                marginBottom={40}
              >
                {title}
              </Text>
            </View>
          </TouchableOpacity>
          <Text fontSize={20}>
            {name ? name : "A patient"} on {format(new Date(updatedAt), "dd/MM/yyyy")}
          </Text>
        </Box>
        <Box
          backgroundColor="white"
          alignItems={web ? "none" : "center"}
          marginHorizontal={web ? "20%" : 0}
          paddingHorizontal={15}
        >
          {tableResults.map((item) => {
            const title = item[0];
            let value = "";
            if (typeof item[1] === "string") {
              value = "" + item[1].replace(/-/g, " ");
            } else if (Array.isArray(item[1])) {
              value = item[1].join(", ");
            } else if (typeof item[1] !== "undefined" && item[1] !== null) {
              value = item[1].toString();
            } else {
              value = item[1];
            }
            return <Field title={title} value={value} borderWidth={1} />;
          })}
        </Box>
        {newResults?.length > 0 ? (
          <Box
            backgroundColor="white"
            marginTop={40}
            alignItems={web ? "none" : "center"}
            marginHorizontal={web ? "20%" : 0}
            paddingHorizontal={15}
          >
            <Text
              fontSize={20}
              color="black"
              fontWeight="500"
              marginBottom={20}
            >
              Results
            </Text>
            {newResults.map((item) => {
              return (
                <Field title={item.label} value={item.value} borderWidth={1} />
              );
            })}
          </Box>
        ) : null}
      </Box>
    </MainScreen>
  );
}

export const ResultOptionsBack = (web, title, backScreen) => (props) => ({
  headerShown: true,
  gestureEnabled: false,
  headerTitleContainerStyle: {
    left: web ? 40 : 0,
  },
  headerLeft: () => (
    <Touch
      marginLeft={10}
      onPress={() => {
        if (backScreen) {
          props.navigation.navigate(backScreen);
        } else {
          props.navigation.goBack();
        }
      }}
    >
      <Ionicons name="arrow-back-outline" size={32} color="#004e79" />
    </Touch>
  ),
  headerTitle: () => <HeaderTitle title={title} {...props} />,
});

export default ResultsScreen;
