import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet } from "react-native";
import List from "../List";
import Empty from "../../../components/Empty.js";
import New from "../../../assets/icons/new.svg";
import { PRIMARY_TEXT_LIGHT } from "../../../themes";

//contentRecipients is an optional boolean prop from ContentActivityscreen.js that establishes how the search works and doesn't check against a phone number

export default function ({
  renderItem,
  data,
  loadMore,
  hasMore,
  loading,
  setSearchText,
}) {
  const [searchText, _setSearchText] = useState("");
  useEffect(() => {
    setSearchText && setSearchText(searchText);
  }, [searchText]);
  const displayedData = (setSearchText === undefined && !!searchText)
    ? data.filter((item) =>
    searchText
      .toLowerCase()
      .split(/\s+/)
      .every((searchFacet) =>
          [
            item.givenName.toLowerCase(),
            item.familyName.toLowerCase(),
            item.phone,
            ].find((itemFacet) => itemFacet.includes(searchFacet))
      )
      )
    : data;


  if (!loading && hasMore && displayedData.length < 50) loadMore();

  return (
    <List
      setSearchText={_setSearchText}
      loading={loading}
      flatListParams={{
        data: displayedData,
        renderItem,
        onEndReached: loadMore,
      }}
      ListEmptyComponent={
        <Empty
          title={
            displayedData.length == 0 && searchText.length > 0
              ? "There is no record of this contact"
              : "There are no contacts in this list"
          }
          center={true}
          description={
            displayedData.length == 0 && searchText.length > 0 ? (
              <>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={styles.textStyle}>Please click </Text>
                  <New></New>
                  <Text style={styles.textStyle}> to create a contact</Text>
                </View>
              </>
            ) : (
              <Text>Please add contacts via the 'Manage Contacts' page</Text>
            )
          }
        ></Empty>
      }
    />
  );
}

const styles = StyleSheet.create({
  textStyle: {
    color: PRIMARY_TEXT_LIGHT,
    fontSize: 18,
    textAlign: "center",
  },
});
