import React from "react";
import { View, Text } from "react-native";
import { Spinner, Modal } from "../components";
import {
  useAtom,
  loadingModalAtom,
  hideLoadingModal,
  isWebAtom,
} from "../atoms";

export const LoadingModal = () => {
  const web = useAtom(isWebAtom);
  const { show, title } = useAtom(loadingModalAtom);
  const style = web
    ? {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: 150,
        height: 300,
      }
    : {};
  return (
    <Modal
      isVisible={show}
      title={title}
      onClose={hideLoadingModal}
      style={{ width: 300 }}
    >
      <View style={style}>
        <View>
          <Text style={{ fontSize: 18 }}>{title}</Text>
          <View style={{ marginTop: 30 }}>
            <Spinner size="large" />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LoadingModal;
