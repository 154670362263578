import React from "react";

import { View, Text } from "react-native";
import { Accordion, AccordionButton } from "../../../components";
import { confirm } from "../../../atoms";
import { handleError } from "../../../utils";

const onRemovePress = async (removeFn) => {
  confirm(
    "Remove Contact",
    "Are you sure you want to remove this contact?",
    handleError(removeFn)
  )(removeFn);
};

export default function ({ item, isOpen, onOpen, tagId, onRemove }) {
  return (
    <Accordion
      open={isOpen}
      onPress={onOpen}
      title={
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            padding: 20,
            backgroundColor: "white",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 16, color: "#000000" }}>{item.name}</Text>
          </View>
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <Text style={{ fontSize: 16, color: "#014d78" }}>{item.phone}</Text>
          </View>
        </View>
      }
    >
      <AccordionButton
        items={[
          {
            title: "Remove from List",
            onPress: () => {
              onRemovePress(onRemove);
            },
          },
        ]}
      />
    </Accordion>
  );
}
