import React, { useState } from "react";
import List from "../List";
import { StyleSheet, Text, View } from "react-native";
import Empty from "../../../components/Empty.js";
import New from "../../../assets/icons/new.svg";
import { PRIMARY_TEXT_LIGHT } from "../../../themes";

export default function ({ renderItem, data, loadMore, hasMore, loading }) {
  const [searchText, setSearchText] = useState("");

  const displayedData = !!searchText
    ? data.filter((item) =>
        searchText
          .toLowerCase()
          .split(/\s+/)
          .every((searchFacet) =>
            [...item.name.toLowerCase().split(/\s+/)].find((itemFacet) =>
              itemFacet.includes(searchFacet)
            )
          )
      )
    : data;

  if (!loading && hasMore && displayedData.length < 50) loadMore();

  return (
    <List
      setSearchText={setSearchText}
      loading={loading}
      flatListParams={{
        data: displayedData,
        renderItem,
        onEndReached: loadMore,
      }}
      ListEmptyComponent={
        <Empty
          title="Nothing is here yet..."
          center={true}
          description={
            searchText.length === 0 ? (
              "Please add bundles via the Create Bundle option in Manage Bundles"
            ) : (
              <>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.textStyle}>Please click </Text>
                  <New fill="#719CB6"></New>
                  <Text style={styles.textStyle}> to create a bundle</Text>
                </View>
              </>
            )
          }
        ></Empty>
      }
    />
  );
}

const styles = StyleSheet.create({
  textStyle: {
    color: PRIMARY_TEXT_LIGHT,
    fontSize: 18,
    textAlign: "center",
  },
});
