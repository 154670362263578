import React, { useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { TouchableOpacity, Platform } from 'react-native';
import styled from '@emotion/native';
import { options } from '../../headerUtils';
import { useGlobal, useForm } from '../../hooks';
import { MainScreen, Button, Box, Modal, FieldLabel, TextInput, ErrorMessage } from '../../components';
import { NotificationIcon } from '../../containers';
import { changeOrg, refreshCurrentSession, testId } from '../../utils';
import { createUserOrganisationTrial } from '../../graphql';
import { organisationsRolesAtom, setCurrentRoleType, currentUserAtom, useAtom, setCurrentOrganisation, isWebAtom } from '../../atoms';

const Title = styled.Text`
  font-size: ${Platform.select({ web: '22px', ios: '20px', android: '20px' })};
  font-weight: 700;
  color: ${(props) => props.theme.primary};
  margin-bottom: 10;
  text-align: center;
`;

const Description = styled.Text`
  font-size: 18;
  color: ${(props) => props.theme.textLight};
  text-align: center;
`;

const Text = styled.Text`
  font-size: 16;
  color: ${(props) => props.theme.textInputText};
  text-align: center;
  line-height: 24;
`;

const TextBold = styled.Text`
  font-size: 16;
  font-weight: 500;
  color: ${(props) => props.theme.textInputText};
  text-align: center;
  line-height: 24;
`;

const TextLink = styled.Text`
  font-size: 16;
  font-weight: 500;
  color: ${(props) => props.theme.primary};
  text-align: center;
  text-decoration-line: underline;
  line-height: 20;
`;

const CreateTrialOrganisationModal = ({ onSuccess = () => {} }) => {
  const currentUser = useAtom(currentUserAtom);
  const { control, onSubmit, isSubmitting, errors, error } = useForm({
    initial: {
      name: `${currentUser.firstName} ${currentUser.lastName} Practice`,
    },
    schema: (yup) => ({
      name: yup.string().required('Please enter the organisation name'),
    }),
    onSubmit: async ({ name }) => {
      const response = await createUserOrganisationTrial({ input: { name } });
      await refreshCurrentSession();
      const organisation = response.data?.createUserOrganisationTrial?.organisation;
      if (organisation) {
        onSuccess({ ...organisation, roles: ['practitioner'] });
        organisationsRolesAtom.update((roles) => roles.concat([{ organisation, role: 'practitioner' }]));
      }
    },
  });

  return (
    <Box>
      <FieldLabel>Organisation Name</FieldLabel>
      <TextInput name="name" errors={errors} control={control} />
      <ErrorMessage err={error} />
      <Box marginTop={60}>
        <Button {...testId('createTrialOrgButton')} loading={isSubmitting} invert={true} onPress={onSubmit}>
          Create
        </Button>
      </Box>
    </Box>
  );
};

const TrialConfirmationScreen = ({ navigation, trialOrganisation }) => {
  const web = useAtom(isWebAtom);
  const [, updateData] = useGlobal();

  const onPress = () => {
    setCurrentOrganisation(trialOrganisation);
    changeOrg(updateData, trialOrganisation);
    navigation.navigate(web ? 'ContentNavigator' : 'PractitionerNavigator', {});
  };

  return (
    <MainScreen backgroundColor="white">
      <Box flex={1} alignItems="center" marginTop={100} paddingHorizontal={20}>
        <Text>Welcome to iOWNA Professional</Text>
        <Text>We have set you up with a trial organisation</Text>
        <TextBold>{trialOrganisation.name}</TextBold>
        <Box marginTop={20}>
          <Text>If you like to unlock the full iOWNA professional product please contact hello@iowna.com</Text>
        </Box>
        <Box marginTop={50}>
          <Text>Please select an organisation to get started</Text>
          <TouchableOpacity
            style={{ padding: 20, margin: 20, backgroundColor: '#004E7A' }}
            onPress={onPress}
            testID={`Choose Organisation`}
            accessibilityLabel={Platform.select({ android: `Choose Organisation` })}
          >
            <Text style={{ fontSize: 20, fontWeight: '500', color: 'white', textAlign: 'center' }}>{trialOrganisation.name}</Text>
          </TouchableOpacity>
        </Box>
      </Box>
    </MainScreen>
  );
};

export const TrialOrganisationSetupScreen = (props) => {
  const [trialOrganisation, setTrialOrganisation] = useState({});
  const [showModal, setShowModal] = useState(false);

  if (trialOrganisation.id) {
    return <TrialConfirmationScreen {...props} trialOrganisation={trialOrganisation} />;
  }
  return (
    <MainScreen backgroundColor="white">
      <Box flex={1} alignItems="center" justifyContent="center" paddingHorizontal={20}>
        <Box>
          <Title>Hello</Title>
          <Description>Trial iOWNA professional for up to 3 months</Description>
          <Box marginTop={20}>
            <Button {...testId('setupTrial')} onPress={() => setShowModal(true)}>
              Set up trial
            </Button>
          </Box>
          <Box marginVertical={100} alignItems="center" justifyContent="center">
            <Description>Are you a patient looking for info from your Doctor?</Description>
            <TouchableOpacity
              {...testId('switchToPersonal')}
              style={{ marginTop: 20 }}
              onPress={() => {
                setCurrentRoleType('personal');
                props.navigation.navigate('ConsumerNavigator');
              }}
            >
              <TextLink>Switch to iOWNA Patient</TextLink>
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
      <Modal
        isVisible={showModal}
        title="Create Organisation"
        onClose={() => {
          setShowModal(false);
        }}
      >
        <CreateTrialOrganisationModal onSuccess={(organisation) => setTrialOrganisation(organisation)} />
      </Modal>
    </MainScreen>
  );
};

const Stack = createStackNavigator();

export default () => {
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TrialOrganisationSetupScreen"
        component={TrialOrganisationSetupScreen}
        options={web ? options('Professional') : options(null, ({ navigation }) => <NotificationIcon navigation={navigation} />)}
      />
    </Stack.Navigator>
  );
};
