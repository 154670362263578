import React from "react";
import { Platform, View } from "react-native";
import WebView from "react-native-webview";
import { HeaderButton } from "../../../components";
import { options } from "../../../headerUtils";

const html = `
<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="https://iowna.com/wp-content/themes/iowna/style.css">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
    <style>
    /*
Theme Name: iOWNA
========
*/

/**
Use a better box model (opinionated).
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/**
Use a more readable tab size (opinionated).
*/

:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
     tab-size: 4;
}

/**
1. Correct the line height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
*/

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/*
Sections
========
*/

/**
Remove the margin in all browsers.
*/

body {
  margin: 0;
}

/**
Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
*/

body {
  font-family:
		
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

/*
Grouping content
================
*/

/**
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
*/

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
}

/*
Text-level semantics
====================
*/

/**
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/**
1. Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
2. Correct the odd 'em' font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/**
Prevent 'sub' and 'sup' elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
Tabular data
============
*/

/**
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
}

/*
Forms
=====
*/

/**
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
1. Remove the inheritance of text transform in Firefox.
*/

button,
select { /* 1 */
  text-transform: none;
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/

button,
[type='button'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
Remove the inner border and padding in Firefox.
*/

/**
Restore the focus styles unset by the previous rule.
*/

/**
Remove the additional ':invalid' styles in Firefox.
See: https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737
*/

/**
Remove the padding so developers are not caught out when they zero out 'fieldset' elements in all browsers.
*/

legend {
  padding: 0;
}

/**
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/**
Correct the cursor style of increment and decrement buttons in Safari.
*/

/**
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

/**
Remove the inner padding in Chrome and Safari on macOS.
*/

/**
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to 'inherit' in Safari.
*/

/*
Interactive
===========
*/

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}



button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Tailwind custom reset styles
 */



html {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, sans-serif; /* 1 */
  line-height: 1.5; /* 2 */
}



body {
  font-family: inherit;
  line-height: inherit;
}

/**
 * 1. Prevent padding and border from affecting element width.
 *
 *    We used to set this in the html element and inherit from
 *    the parent element for everything else. This caused issues
 *    in shadow-dom-enhanced elements like <details> where the content
 *    is wrapped by a div with box-sizing set to content-box.
 *
 *    https://github.com/mozdevs/cssremedy/issues/4
 *
 *
 * 2. Allow adding a border to an element by just adding a border-width.
 *
 *    By default, the way the browser specifies that an element should have no
 *    border is by setting it's border-style to none in the user-agent
 *    stylesheet.
 *
 *    In order to easily add borders to elements by just setting the border-width
 *    property, we change the default border-style for all elements to solid, and
 *    use border-width to hide them instead. This way our border utilities only
 *    need to set the border-width property instead of the entire border
 *    shorthand, making our border utilities much more straightforward to compose.
 *
 *    https://github.com/tailwindcss/tailwindcss/pull/116
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #ADBACB; /* 2 */
}

/*
 * Ensure horizontal rules are visible by default
 */

hr {
  border-top-width: 1px;
}

/**
 * Undo the border-style: none reset that Normalize applies to images so that
 * our border-{width} utilities have the expected effect.
 *
 * The Normalize reset is unnecessary for us since we default the border-width
 * to 0 on all elements.
 *
 * https://github.com/tailwindcss/tailwindcss/issues/362
 */

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #ADBACB;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #ADBACB;
}

input::placeholder,
textarea::placeholder {
  color: #ADBACB;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

a {
  color: inherit;
  text-decoration: inherit;
}

/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

/**
 * Use the configured 'mono' font family for elements that
 * are expected to be rendered with a monospace font, falling
 * back to the system monospace stack if there is no configured
 * 'mono' font family.
 */

pre,
code,
kbd,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/**
 * Make replaced elements display: block by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with svg added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their instrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 3.75rem;
  font-family: Roboto;
}

h2 {
  font-size: 2.25rem;
  font-family: Roboto;
}

h3 {
  font-size: 1.5rem;
  font-family: Roboto;
}

p {
  font-size: 1rem;
  font-family: Inter;
}


.toggle-btn-1 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 86, 126, var(--tw-text-opacity))
}

.toggle-btn-2 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 2px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.link:hover {
  --tw-border-opacity: 1;
  border-color: rgba(176, 149, 166, var(--tw-border-opacity));
}

.link {
  --tw-border-opacity: 0;
}

.link:hover {
  --tw-border-opacity: 1;
}

.link {
  border-bottom-width: 2px;
  cursor: pointer;
  display: block;
  line-height: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .link {
    display: inline-block;
    margin-top: 0px;
  }
}

.active {
  --tw-border-opacity: 1;
  border-color: rgba(176, 149, 166, var(--tw-border-opacity));
  --tw-border-opacity: 1
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-heather {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 149, 166, var(--tw-bg-opacity));
}

.bg-moss {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 133, 115, var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 126, var(--tw-bg-opacity));
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 222, 230, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 149, 166, var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-heather {
  --tw-border-opacity: 1;
  border-color: rgba(175, 149, 166, var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 86, 126, var(--tw-border-opacity));
}

.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(176, 149, 166, var(--tw-border-opacity));
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-items-center {
  justify-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.font-sans {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, sans-serif;
}

.font-serif {
  font-family: RobotoSlab, ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.h-4 {
  height: 1rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-64 {
  height: 16rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.m-2 {
  margin: 0.5rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-12 {
  margin: 3rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-special {
  margin-top: -5.65rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.opacity-60 {
  opacity: 0.6;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-hidden {
  overflow: hidden;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-10 {
  padding: 2.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pointer-events-none {
  pointer-events: none;
}

.relative {
  position: relative;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-new {
  --tw-shadow: black 8px 12px 25px -12px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(0, 86, 126, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.fill-current {
  fill: currentColor;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(171, 187, 203, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(130, 154, 177, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(173, 186, 203, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(173, 186, 203, var(--tw-text-opacity));
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(0, 86, 126, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 86, 126, var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 86, 126, var(--tw-text-opacity));
}

.hover\:text-heather:hover {
  --tw-text-opacity: 1;
  color: rgba(175, 149, 166, var(--tw-text-opacity));
}

.text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1;
}

.underline {
  text-decoration: underline;
}

.tracking-normal {
  letter-spacing: 0em;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.align-middle {
  vertical-align: middle;
}

.w-4 {
  width: 1rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-64 {
  width: 16rem;
}

.w-1\/4 {
  width: 25%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-full {
  width: 100%;
}

.gap-x-16 {
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.rotate-90 {
  --tw-rotate: 90deg;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:m-0 {
    margin: 0px;
  }

  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mb-0 {
    margin-bottom: 0px;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:ml-20 {
    margin-left: 5rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:pl-24 {
    padding-left: 6rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/12 {
    width: 8.333333%;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-4\/12 {
    width: 33.333333%;
  }

  .sm\:w-7\/12 {
    width: 58.333333%;
  }

  .sm\:w-8\/12 {
    width: 66.666667%;
  }

  .sm\:gap-x-40 {
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:-mt-28 {
    margin-top: -7rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-7\/12 {
    width: 58.333333%;
  }

  .md\:w-10\/12 {
    width: 83.333333%;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mt-2 {
    margin-top: 0.5rem;
  }

  .lg\:ml-20 {
    margin-left: 5rem;
  }

  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }

  .lg\:-mt-44 {
    margin-top: -11rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-7\/12 {
    width: 58.333333%;
  }
}

@media (min-width: 1280px) {
  .xl\:-mt-40 {
    margin-top: -10rem;
  }

  .xl\:-mt-44 {
    margin-top: -11rem;
  }

  .xl\:-mt-48 {
    margin-top: -12rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@media (min-width: 1536px) {
}
    </style>
</head>
<body class="leading-normal text-white gradient bg-blue-50 select-none pointer-events-none">
    <div class="pt-4">
        <div class="py-6 pl-8 sm:pl-24 mx-auto flex flex-wrap flex-col md:flex-col lg:flex-row">
            <div class="flex flex-col w-full lg:w-1/2 justify-center items-start sm:text-left">
                <h1 class="my-4 text-5xl leading-tight">
                    Welcome to iOWNA
                </h1>
                <p class="leading-normal text-gray-50 text-2xl font-normal my-8 mr-4">
                iOWNA’s mission is to enable people to live longer healthier lives through improving clinical outcomes by providing clinicians with solutions for patient-centric interactions.
                </p>
            </div>
            <!--Right Col-->
            <div class="w-full lg:w-1/2 md:flex md:justify-end md:items-end py-6">
                <img class="w-full z-50" src="https://iowna.com/wp-content/uploads/2024/05/4.png">
            </div>
        </div>
    </div>
    <div class="relative -mt-special md:-mt-28 lg:-mt-42 xl:-mt-40">
        <img src="https://iowna.com/wp-content/uploads/2024/04/curve.svg" />
    </div>
    <section class="bg-white pt-8 px-8">
        <div class="container max-w-5xl mx-auto mt-8">
           
            <div class="flex flex-wrap items-center mt-10">
                <div class="sm:w-1/2 sm:p-6">
                    <h3 class="text-4xl sm:text-5xl text-blue-800 font-bold leading-relaxed mb-3">
                        What is iOWNA?
                    </h3>
                    <p class="text-xl text-gray-600 mb-4">
                    iOWNA is a secure cloud-based platform that enables two-way trusted information exchange between clinicians and patients to improve patient experience and outcomes. We call this a clinician-patient engagement system (CPES). iOWNA is useful in both the clinical care and clinical research environments.
                        <br>
                        <br>
                    </p>
                    <p class="text-xl text-gray-600 mb-4">
                    In clinical care, iOWNA is being used by clinicians for service optimisation and drug optimisation pathways. 
                    In clinical research, clinician investigators are using iOWNA to deliver information to patients before, during and after clinical trials, in registries to manage trusted two-way communication of information from clinicians to patients and in audit.
                    
                        <br>
                        <br>
                    </p>
                    <p class="text-xl text-gray-600 mb-4">
                    iOWNA was founded by a Consultant Doctor who is passionate about the health and wellbeing of her patients, especially those with a known chronic disease. 
                    <br>
                    <br>
                    </p>
                </div>
                <div class="w-full sm:w-1/2 p-6">
                    <img src="https://iowna.com/wp-content/uploads/2024/05/2.png">
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-10">
                <div class="sm:w-1/2 sm:p-6">
                    <h3 class="text-4xl text-blue-800 font-bold leading-relaxed mb-3">
                        How does iOWNA help you?
                    </h3>
                    <ul style="list-style: inside">
                    <li style="color: rgba(173, 186, 203, 1); font-weight: 600; margin-bottom: 4px;   font-size: 1.25rem; ">
                    Provides you with trusted curated patient-friendly information
                        <br>
                        <br>
                    </li>
                    </ul>
                    <li style="color: rgba(173, 186, 203, 1); font-weight: 600; margin-bottom: 4px;   font-size: 1.25rem; ">
                    Facilitates two-way interactions via digital forms
                        <br>
                        <br>
                        </li>
                    <li style="color: rgba(173, 186, 203, 1); font-weight: 600; margin-bottom: 4px;   font-size: 1.25rem; ">
                    Facilitates PROMs and patient feedback capture
                        <br>
                        <br>
                        </li>
                    <li style="color: rgba(173, 186, 203, 1); font-weight: 600; margin-bottom: 4px;   font-size: 1.25rem; ">
                    Facilitates remote care monitoring & service optimisation with bespoke content curation
                        <br>
                        <br>
                        </li>
                        <li style="color: rgba(173, 186, 203, 1); font-weight: 600; margin-bottom: 4px;   font-size: 1.25rem; ">
                    Provides you with a medical directory of consultants
                    <br>
                        <br>
                        </li>
                        <li style="color: rgba(173, 186, 203, 1); font-weight: 600; margin-bottom: 4px;   font-size: 1.25rem; ">
                    Gives you tools to conduct clinical research
                        <br>
                        <br>
                        </li>
                    
                </div>
                <div class="w-full sm:w-1/2 p-6 mt-6">
                    <img src="https://iowna.com/wp-content/uploads/2024/05/5.png">
                </div>
            </div>
            <div class="flex flex-wrap">
        
                <div class="w-full sm:w-1/2 p-6">
                    <img src="https://iowna.com/wp-content/uploads/2024/05/1.png">
                </div>
            </div>
        </div>
    </section>
    <div class="relative bg-white -mt-14">
        <svg id="pagedivider_brown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1180 134.95">
            <path style="fill: #b2a8a2;"
                d="M860,0c-298.18,0-586.67,41.5-860,119V135A3174.91,3174.91,0,0,1,1180,66.53V16.11A3180.07,3180.07,0,0,0,860,0Z"
                transform="translate(0 -0.02)"></path>
        </svg>
    </div>
    <section class="bg-white py-8">
        <div class="container max-w-5xl mx-auto mt-8">
            <h1 class="w-full my-2 leading-normal text-2xl text-center text-blue-800 mb-20">
                Please send feedback to: <a class="text-lg"
                    href="mailto:heretohelp@iowna.com">heretohelp@iowna.com</a>&nbsp;
            </h1>
        </div>
    </section>
</body>
</html>
`;

const WelcomeScreen = () => {
  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <iframe
          src={`data:text/html,${encodeURIComponent(html)}`}
          width="100%"
          height="100%"
        />
      </View>
    );
  }
  return <WebView source={{ html }} />;
};

export const WelcomeOptions = (web) =>
  options("Welcome to iOWNA", ({ navigation }) => (
    <HeaderButton
      title="Back"
      testID="Back"
      accessibilityLabel="Back"
      onPress={() => {
        navigation.goBack();
      }}
    />
  ));

export default WelcomeScreen;
