import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";

import {
  tagDistributionContact,
  getOrganisationDistributionTags,
} from "../../../graphql";
import { Accordion, AccordionButton, HeaderButton } from "../../../components";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useAtom, isWebAtom, currentOrganisationAtom } from "../../../atoms";
import { useDistributionTags } from "../../../hooks";
import ContactList from "../../../new/components/lists/List.js";
import Modal from "../../../components/Modal";
import { handleError, IOWNAError } from "../../../utils";

import CreateDistributionList from "../../../new/components/lists/CreateDistributionList";

export default function ({ navigation, route }) {
  const web = useAtom(isWebAtom);

  // react query ready for new api
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const selectedDistributionLists = route.params?.selectedDistributionLists
    ? route.params.selectedDistributionLists
    : [];

  const apiFn = (props) =>
    getOrganisationDistributionTags({
      id: currentOrganisation.id,
      ...props,
    });

  const pathFn = (result) => result.data.getOrganisation.distributionTags;
  // console.log("new list screen query ", [
  //   currentOrganisation.id,
  //   "distributionLists",
  // ]);

  const {
    data: pageData,
    fetchNextPage: loadMore,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [currentOrganisation.id, "distributionLists"],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const loading = isLoading || isFetchingNextPage;

  const data = pageData ? pageData.pages.flatMap((x) => x.items) : [];

  const [accordianOpenId, setAccordianOpenId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => {
        return (
          <>
            <View style={{ flexDirection: "row" }}>
              <HeaderButton
                icon="ios-add-circle-outline"
                title="Create List"
                onPress={() => {
                  setShowModal(true);
                }}
              />
            </View>
          </>
        );
      },
    });
  }, []);

  const SelectDistributionListItem = ({ item, onPress, route }) => (
    <>
      <Accordion
        open={item.id === accordianOpenId}
        onPress={() =>
          setAccordianOpenId((currentId) =>
            currentId !== item.id ? item.id : null
          )
        }
        title={
          <View
            style={{
              flexDirection: "row",
              padding: 20,
              flex: 1,
            }}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 16, color: "#000000" }}>
                {item.name}
              </Text>
            </View>
          </View>
        }
      >
        <AccordionButton
          items={[
            {
              title: "Send Content",
              onPress: handleError(() => {
                if (item.contacts.items.length === 0) {
                  throw new IOWNAError(
                    "The Distribution List you selected has no contacts in it"
                  );
                }
                navigation.navigate("SendPlanNavigator", {
                  screen: "SendScreen",
                  params: { selectedDistributionLists: [item] },
                });
              }),
            },
            {
              title: "Edit List",
              onPress: () => {
                navigation.navigate("ContactsScreen", {
                  previousScreen: "ListsScreen",
                  view: "tagList",
                  tagId: item.id,
                  item: item,
                });
              },
            },
            {
              title: "Activity",
              onPress: () => {
                navigation.navigate("DistributionListActivityScreen", {
                  item: item,
                });
              },
            },
          ]}
        />
      </Accordion>
    </>
  );

  const renderItem = (props) => (
    <SelectDistributionListItem
      // onPress={(item) => {
      //   console.log('this is the item being pressed', item)
      // }}
      {...props}
    />
  );

  return (
    <>
      <ContactList hasMore={hasNextPage} data={data} renderItem={renderItem} loadMore={loadMore} loading={loading} />
      {showModal && (
        <Modal
          isVisible={showModal}
          style={{ maxWidth: 400 }}
          title={"Add a new list"}
          // onClose={handleError(() => {
          //   if (selectedItem) {
          //     setSelectedItem(null);
          //   }
          //   if (selectedRowRef) {
          //     selectedRowRef.closeRow();
          //     setSelectedRowRef(null);
          //   }
          //   setShowModal(false);
          // })}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <CreateDistributionList
            onSave={() => {
              //   queryClient.invalidateQueries({
              //     queryKey: [currentOrganisation.id, "distributionLists"],
              // })
            }}
          />
        </Modal>
      )}
    </>
  );
}
