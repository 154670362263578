import React, { useState } from "react";
import {
  TextInput as RNTextInput,
  Keyboard,
  TouchableOpacity,
  Platform,
  View,
} from "react-native";
import { useTheme } from "@emotion/react";
import { format } from "date-fns";
import { useAtom, isWebAtom } from "../atoms";
import Thermometer from "./Thermometer";
import Thermometer2 from "./Thermometer2";
import CheckBox, { RoundedCheckBox } from "./CheckBox";
import Box from "./Box";
import Text from "./Text";

function Radio({ center, items, readOnly, value, itemType, multi, onChange }) {
  const [id, setSelectedValue] = useState(
    multi ? (value ? (Array.isArray(value) ? value : [value]) : []) : value
  );
  const theme = useTheme();
  const onPress = (item) => {
    if (!readOnly) {
      const selectedIds = multi ? id : item.id;
      if (multi) {
        if (selectedIds.includes(item.id)) {
          selectedIds.splice(selectedIds.indexOf(item.id), 1);
        } else {
          selectedIds.push(item.id);
        }
      }
      setSelectedValue(selectedIds);
      if (onChange) {
        onChange(selectedIds);
      }
    }
  };
  const styles =
    itemType === "checkbox"
      ? {
          flexDirection: "column",
          flexWrap: "wrap",
        }
      : {};
  return (
    <Box {...styles}>
      {items.map((item, i) => {
        const selected = multi ? id.includes(item.id) : item.id === id;
        if (itemType === "checkbox") {
          return (
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    marginLeft={20}
                    marginRight={20}
                    marginTop={20}
                    width="50%"
                  >
                    <CheckBox
                      color={selected ? "black" : "#333333"}
                      checked={selected}
                      onPress={() => onPress(item)}
                    />
                    <Text
                      fontSize={16}
                      color="black"
                      marginLeft={20}
                      width="85%"
                    >
                      {item.name}
                    </Text>
                  </Box>
          );
        }

        return (
          <TouchableOpacity
            key={item.id}
            activeOpacity={1}
            style={{
              padding: 20,
              marginVertical: 15,
              backgroundColor: selected ? "rgb(222, 252, 255)" : "#ffffff",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: selected ? theme.primary : "#333333",
              borderRadius: 5,
              ...(center ? { textAlign: "center", alignItems: "center" } : {}),
            }}
            onPress={() => onPress(item)}
          >
            <Text fontSize={16} color={selected ? "black" : "#333333"}>
              {item.name}
            </Text>
          </TouchableOpacity>
        );
      })}
    </Box>
  );
}

function Rating({ scale, readOnly, value, onChange }) {
  const [id, setId] = useState(value);
  const theme = useTheme();
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      marginVertical={20}
      flexWrap="wrap"
    >
      {scale.map((item) => {
        const selected = item.value === id;
        return (
          <Box key={item.value}>
            {item.topLabel ? (
              <Text
                position="absolute"
                fontSize={14}
                color="black"
                top={-5}
                textAlign="center"
                minWidth="100%"
              >
                {item.topLabel}
              </Text>
            ) : null}
            <TouchableOpacity
              key={item.value}
              activeOpacity={1}
              style={{
                paddingVertical: 18,
                paddingHorizontal: item.value >= 10 ? 20 : 25,
                marginVertical: 15,
                marginHorizontal: 10,
                backgroundColor: selected ? "rgb(222, 252, 255)" : "#ffffff",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: selected ? theme.primary : "#333333",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
              onPress={() => {
                if (!readOnly) {
                  setId(item.value);
                  if (onChange) {
                    onChange(item.value);
                  }
                }
              }}
            >
              <Text fontSize={24} color={selected ? "black" : "#333333"}>
                {item.value}
              </Text>
            </TouchableOpacity>
            {item.bottomLabel ? (
              <Text
                position="absolute"
                fontSize={14}
                color="black"
                bottom={item.bottomLabel.length >= 9 ? -20 : -5}
                textAlign="center"
                minWidth="100%"
              >
                {item.bottomLabel}
              </Text>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
}

function TextInput({
  name,
  secure = false,
  multiline = false,
  type = "default",
  readOnly,
  width = 300,
  value,
  onChangeText,
  ...props
}) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <RNTextInput
        testID={"TextInput_" + name}
        keyboardAppearance="dark"
        placeholderTextColor="#666360"
        autoCapitalize="none"
        multiline={multiline}
        returnKeyType="done"
        blurOnSubmit={!multiline}
        onSubmitEditing={() => {
          Keyboard.dismiss();
        }}
        secureTextEntry={secure}
        keyboardType={type}
        value={value}
        editable={!readOnly}
        onChangeText={(value) => {
          if (!readOnly) {
            onChangeText(value);
          }
        }}
        style={{
          fontSize: 18,
          height: 40,
          borderBottomWidth: 1,
          width: width,
          color: theme.textInputText,
          textAlignVertical: "top",
          borderBottomColor: "black",
          ...props,
        }}
      />
      {/* <ErrorMessage error={error} /> */}
    </React.Fragment>
  );
}

const Title = ({ no, type, title, subTitle = "" }) => (
  <Box>
    <Text
      fontSize={20}
      lineHeight={30}
      color="black"
      fontWeight={"400"}
      marginBottom={20}
    >
      {title}
    </Text>
    {typeof subTitle === "string" ? (
      <Text
        fontSize={18}
        lineHeight={35}
        color="black"
        fontWeight={"400"}
        marginLeft={20}
        marginBottom={40}
      >
        {subTitle}
      </Text>
    ) : null}
    {Array.isArray(subTitle) ? (
      <Box marginTop={20} marginLeft={20} marginBottom={40}>
        {subTitle.map((t) => (
          <Text
            fontSize={18}
            lineHeight={35}
            color="black"
            fontWeight={"400"}
            marginBottom={10}
          >
            {"• " + t}
          </Text>
        ))}
      </Box>
    ) : null}
  </Box>
);

const Question = ({
  no,
  question,
  readOnly,
  parentType = "",
  value,
  setValue,
  setOuterScrollEnabled,
}) => {
  // console.log("question", question, no, readOnly, parentType, value, setValue,setOuterScrollEnabled)
  const questionNo = no ? no + ". " : "";
  const web = useAtom(isWebAtom);
  if (question.type === "input") {
    if (question.inputType === "textbox") {
      return (
        <Box marginTop={20}>
          <Title no={questionNo} type={question.type} title={question.title} />
          <TextInput
            name={question.id}
            multiline={true}
            padding={5}
            marginTop={20}
            width="100%"
            height={240}
            borderWidth={1}
            borderColor="black"
            borderRadius={5}
            readOnly={readOnly}
            value={value}
            onChangeText={(text) => setValue(question.id, text)}
          />
        </Box>
      );
    }
    if (question.inputType === "numeric") {
      return (
        <Box marginTop={5}>
          <Title no={questionNo} type={question.type} title={question.title} />
          <Box paddingLeft={parentType === "section" ? 0 : 15}>
            <TextInput
              name={question.id}
              type="numeric"
              marginTop={10}
              padding={5}
              width={200}
              borderWidth={1}
              borderColor="black"
              borderRadius={5}
              readOnly={readOnly}
              value={value}
              onChangeText={(value) => {
                const val = parseFloat(value);
                if (!isNaN(val)) {
                  setValue(question.id, val);
                }
              }}
            />
          </Box>
        </Box>
      );
    }
    return (
      <Box marginTop={5} marginBottom={40}>
        <Title no={questionNo} type={question.type} title={question.title} />
        <Box paddingLeft={parentType === "section" ? 0 : 20}>
          <TextInput
            name={question.id}
            padding={5}
            width={200}
            borderWidth={1}
            borderColor="black"
            borderRadius={5}
            readOnly={readOnly}
            value={question.inputType === "date" ? value : value}
            onChangeText={(value) => setValue(question.id, value)}
          />
        </Box>
      </Box>
    );
  }
  if (question.type === "radio") {
    return (
      <Box marginTop={40} marginBottom={40}>
        <Title
          no={questionNo}
          type={question.type}
          title={question.title}
          subTitle={question.subTitle}
        />
        <Radio
          center
          itemType={question.itemType}
          items={question.choices}
          readOnly={readOnly}
          value={value}
          multi={question.multi}
          onChange={(v) => setValue(question.id, v)}
        />
      </Box>
    );
  }
  if (question.type === "rating") {
    return (
      <Box marginTop={5}>
        <Title
          no={questionNo}
          type={question.type}
          title={question.title}
          subTitle={question.subTitle}
        />
        <Rating
          scale={question.scale}
          readOnly={readOnly}
          value={value}
          onChange={(v) => setValue(question.id, v)}
        />
      </Box>
    );
  }
  if (
    question.type === "slider" &&
    Platform.OS === "android" &&
    question.orientation === "vertical"
  ) {
    const min = question.min
      ? question.min
      : question.ranges
      ? question.ranges.reduce((acc, i) => (i.from < acc ? i.from : acc), 100)
      : 0;
    const max = question.max
      ? question.max
      : question.ranges
      ? question.ranges.reduce((acc, i) => (i.to > acc ? i.to : acc), 0)
      : 100;
    return (
      <Box marginTop={5}>
        <Title
          no={questionNo}
          type={question.type}
          title={question.title}
          subTitle={question.subTitle}
        />
        <Box
          marginTop={20}
          marginBottom={20}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Thermometer2
            min={min}
            max={max}
            step={1}
            ranges={
              question.ranges || [
                { from: 0, to: 100, color: "tomato", smiley: "😐" },
              ]
            }
            width={40}
            disabled={readOnly}
            value={value}
            onChange={(value) => {
              if (!readOnly) {
                setValue(question.id, value);
              }
            }}
            onComplete={(value) => {
              if (!readOnly) {
                setValue(question.id, value);
              }
            }}
          />
        </Box>
      </Box>
    );
  }
  if (question.type === "slider") {
    return (
      <Box marginTop={5}>
        <Title
          no={questionNo}
          type={question.type}
          title={question.title}
          subTitle={question.subTitle}
        />
        <Box
          height={320}
          marginTop={20}
          marginBottom={20}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Thermometer
            step={1}
            ranges={
              question.ranges || [
                { from: 0, to: 100, color: "tomato", smiley: "😐" },
              ]
            }
            width={40}
            disabled={readOnly}
            value={value}
            vertical={question.orientation === "vertical"}
            onChange={(value) => {
              if (!readOnly) {
                setValue(question.id, value);
              }
            }}
            setOuterScrollEnabled={setOuterScrollEnabled}
          />
        </Box>
      </Box>
    );
  }
  if (question.type === "text") {
    return (
      <Box
        padding={20}
        alignItems={question.align === "left" ? "flex-start" : "center"}
      >
        <Text
          fontSize={question.fontSize === "large" ? 32 : 20}
          textAlign={question.align || "center"}
        >
          {question.title}
        </Text>
        {question.subTitle && Array.isArray(question.subTitle) ? (
          <Box marginTop={20} marginLeft={20} marginBottom={40}>
            {question.subTitle.map((t) => (
              <Text
                fontSize={20}
                lineHeight={35}
                color="black"
                fontWeight={"400"}
                marginBottom={10}
                textAlign={question.align || "center"}
              >
                {"• " + t}
              </Text>
            ))}
          </Box>
        ) : null}
      </Box>
    );
  }
  if (question.type === "section") {
    return (
      <Box marginTop={5}>
        <Title no={questionNo} type={question.type} title={question.title} />
        <Box paddingLeft={15}>
          {question.questions.map((item) => (
            <Question
              no={""}
              parentType="section"
              question={item}
              setValue={setValue}
            />
          ))}
        </Box>
      </Box>
    );
  }
  return <Text>Question type not supported</Text>;
};

export default Question;
