import React, { useState } from "react";
import {
  NativeSyntheticEvent,
  NativeTouchEvent,
  Dimensions,
  Pressable,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import Spinner from "./Spinner";


export default ({
  title,
  onPress,
  type = "primary",
  width = Math.min(Dimensions.get("window").width - 80, 300),
  icon,

  testId,

  ...props
}: {
  title: string;
  onPress: (
    ev: NativeSyntheticEvent<NativeTouchEvent>
  ) => void | PromiseLike<void> ;
  type?: "primary" | "secondary";
  width?: number;
  icon?: React.ReactNode;
  testId?: string;
}) => {
  const [loading, setLoading] = useState(false);

  const _onPress = async (ev: NativeSyntheticEvent<NativeTouchEvent>) => {
    try {
      setLoading(true);
      await onPress(ev);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={_onPress}
        disabled={loading}
        style={[styles.button, buttonStyles[type]]}
      >
        {icon ? <View style={styles.icon}>{icon}</View> : null}
        <Text style={buttonText[type]}>{title}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  button: {
    width: Math.min(Dimensions.get("window").width - 80, 300),
    textAlign: "center",
    borderRadius: 50,
    height: 50,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  icon: {
    marginRight: 10,
    marginBottom: 5,
  },
});

const buttonStyles = StyleSheet.create({
  primary: {
    backgroundColor: "#004E7A",
  },
  secondary: {
    backgroundColor: "white",
    borderWidth: 3,
    borderColor: "#004E7A",
  },
});

const buttonText = StyleSheet.create({
  primary: {
    color: "white",
    fontSize: 20,
  },
  secondary: {
    color: "#004E7A",
    fontSize: 20,
  },
});
