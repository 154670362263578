import React from 'react';
import { View } from 'react-native';
import { Badge } from '../components';
import { userTodosCountAtom, useAtom } from '../atoms';
import { containerWithoutLoader } from '../reactUtils';

const TodoCount = containerWithoutLoader(({ size = 'large', ...props }) => {
  const count = useAtom(userTodosCountAtom);
  if (count === 0) {
    return null;
  }
  return (
    <View style={{ marginLeft: 10, ...props }}>
      <Badge size={size} count={count} />
    </View>
  );
});

export default TodoCount;
