import React from "react";
import { View, FlatList } from "react-native";

import PolymorphicExampleList from "./_PolymorphicType/PolymorphicExampleList";

import SearchBar from "./SearchBar";

// This in use component passes thru as a typescript polymorphic react component showcase.
const List = PolymorphicExampleList;

interface IListWithSearch
  extends React.ComponentProps<typeof PolymorphicExampleList> {
  hideSearch?: boolean;
  flatListParams: React.ComponentProps<typeof FlatList>;
  setSearchText: (text: string) => void;
}

const ListWithSearch = ({
  hideSearch = false,
  flatListParams, // TODO move to maybe ...listProps
  setSearchText,
  ...props
}: IListWithSearch) => {
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      {!!hideSearch ? null : (
        <View
          style={{ padding: 10, backgroundColor: "#FFFFFF" }} // TODO move style to css module
        >
          <SearchBar setSearchPhrase={setSearchText} />
        </View>
      )}

      <List
        // as={FlatList}
        {...flatListParams}
        {...props}
      />
    </View>
  );
};

export default ListWithSearch;
