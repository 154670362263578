import React from 'react';
import { View, Text, Animated, PanResponder, Easing } from 'react-native';

export default class VerticalSlider extends React.Component {
  _moveStartValue = 0;

  constructor(props) {
    super(props);

    let panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => false,
      onPanResponderGrant: () => {
        this._moveStartValue = this.state.value;
      },
      onPanResponderMove: (_event, gestureState) => {
        if (this.props.disabled) {
          return;
        }
        const value = this._fetchNewValueFromGesture(gestureState);
        this._changeState(value);
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      },
      onPanResponderRelease: (_event, gestureState) => {
        if (this.props.disabled) {
          return;
        }
        const value = this._fetchNewValueFromGesture(gestureState);
        this._changeState(value);
        if (this.props.onComplete) {
          this.props.onComplete(value);
        }
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderTerminate: (_event, gestureState) => {
        if (this.props.disabled) {
          return;
        }
        const value = this._fetchNewValueFromGesture(gestureState);
        this._changeState(value);
        if (this.props.onComplete) {
          this.props.onComplete(value);
        }
      },
    });

    this.state = {
      value: props.value,
      sliderHeight: new Animated.Value(0),
      ballHeight: new Animated.Value(-props.ballIndicatorHeight / 2),
      panResponder,
    };
  }

  _fetchNewValueFromGesture = (gestureState) => {
    const { min, max, step, height } = this.props;
    const ratio = -gestureState.dy / height;
    const diff = max - min;
    if (step) {
      return Math.max(min, Math.min(max, this._moveStartValue + Math.round((ratio * diff) / step) * step));
    }
    let value = Math.max(min, this._moveStartValue + ratio * diff);
    return Math.floor(value * 100) / 100;
  };

  _getSliderHeight = (value) => {
    const { min, max, height } = this.props;
    return ((value - min) * height) / (max - min);
  };

  _changeState = (value) => {
    const { ballIndicatorWidth = 48, ballIndicatorHeight = 48, renderIndicator = null, animationDuration } = this.props;

    const sliderHeight = this._getSliderHeight(value);
    const ballHeight = renderIndicator ? ballIndicatorHeight : ballIndicatorWidth;
    const ballPosition = sliderHeight - ballHeight / 2;
    Animated.parallel([
      Animated.timing(this.state.sliderHeight, {
        toValue: sliderHeight,
        easing: Easing.linear,
        duration: animationDuration || 0,
        useNativeDriver: false,
      }),
      Animated.timing(this.state.ballHeight, {
        toValue: ballPosition,
        easing: Easing.linear,
        duration: animationDuration || 0,
        useNativeDriver: false,
      }),
    ]).start();
    this.setState({ value });
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this._changeState(value);
    }
  }

  renderTiles(min = 0, max = 100, tileColor = 'white') {
    const { height } = this.props;
    const items = [];
    for (let i = min; i <= max; i++) {
      items.push(
        <View
          key={i}
          style={{
            marginTop: height / (max * 2),
            backgroundColor: tileColor,
            height: height / (max * 2),
          }}
        />
      );
    }
    return items;
  }

  render() {
    const {
      width,
      height,
      borderRadius,
      maximumTrackTintColor,
      maximumTrackTileColor,
      minimumTrackTintColor,
      minimumTrackTileColor,
      showBallIndicator,
      ballIndicatorColor,
      ballIndicatorWidth,
      ballIndicatorHeight,
      ballIndicatorPosition,
      ballIndicatorTextColor,
      showBackgroundShadow,
      shadowProps,
      stepLabelHeight,
      stepLabelDividerWidth,
      stepLabelDividerColor,
      renderIndicator,
      ranges,
    } = this.props;

    const shadowStyles = {
      shadowColor: shadowProps.shadowColor,
      shadowOffset: {
        width: shadowProps.shadowOffsetWidth,
        height: shadowProps.shadowOffsetHeight,
      },
      shadowOpacity: shadowProps.shadowOpacity,
      shadowRadius: shadowProps.shadowRadius,
      elevation: shadowProps.elevation,
    };
    const { value } = this.state;
    let trackColor = minimumTrackTintColor;
    for (const range of ranges) {
      if (value >= range.from && value <= range.to) {
        trackColor = range.color;
      }
    }
    const stepLabels = ranges.flatMap((range) =>
      [{name: range.fromLabel, position: range.from}, {name: range.toLabel, position: range.to}]
    )
    .sort((a, b) => a.position - b.position - (a.name ? 0.1 : 0))
    .reduce((unique, item) =>
      unique.filter((u) => u.position == item.position).length > 0 ? unique : [...unique, item], []
    );
    const min = Math.min(...ranges.map((range) => range.from));
    const max = Math.max(...ranges.map((range) => range.to));
    return (
      <View style={[showBackgroundShadow ? shadowStyles : {}, { height, width, borderRadius }]}>
        <View
          style={[
            {
              height,
              width,
              borderRadius,
              backgroundColor: maximumTrackTintColor,
              overflow: 'hidden',
            },
          ]}
          {...this.state.panResponder.panHandlers}
        >
          {this.renderTiles(min, max, maximumTrackTileColor)}
          <Animated.View
            style={{
              position: 'absolute',
              bottom: 0,
              height: this.state.sliderHeight,
              width: width,
              backgroundColor: trackColor,
            }}
          >
            {this.renderTiles(min, max, minimumTrackTileColor)}
          </Animated.View>
        </View>
        {showBallIndicator ? (
          <Animated.View
            style={[
              renderIndicator ? {} : { alignItems: 'center' },
              showBackgroundShadow ? shadowStyles : {},
              {
                position: 'absolute',
                justifyContent: 'center',
                height: renderIndicator ? ballIndicatorHeight : ballIndicatorWidth,
                bottom: this.state.ballHeight,
                left: ballIndicatorPosition - ballIndicatorWidth,
                width: ballIndicatorWidth,
              },
              renderIndicator
                ? {}
                : {
                    borderRadius: ballIndicatorWidth,
                    backgroundColor: ballIndicatorColor,
                  },
            ]}
            {...this.state.panResponder.panHandlers}
          >
            {renderIndicator ? renderIndicator(value) : <Text style={{ color: ballIndicatorTextColor }}>{Math.round(value * 100) / 100}</Text>}
          </Animated.View>
        ) : null}
        {stepLabels.map((label) => (
          <View
            key={label.position}
            style={{
              position: 'absolute',
              bottom: (label.position - min)/(max - min)*height,
              left: stepLabelDividerWidth + 2,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: stepLabelHeight,
            }}
          >

            <Text style={{ color: 'black', lineHeight: stepLabelHeight }}>{label.name}</Text>
          </View>
        ))}
      </View>
    );
  }
}

VerticalSlider.defaultProps = {
  step: 1,
  value: 0,
  disabled: false,
  width: 20,
  height: 400,
  borderRadius: 0,
  maximumTrackTintColor: '#77ADE6',
  maximumTrackTileColor: '#77ADE6',
  minimumTrackTintColor: '#3F2DA5',
  minimumTrackTileColor: '#3F2DA5',
  showBallIndicator: false,
  ballIndicatorColor: '#ECECEC',
  ballIndicatorWidth: 48,
  ballIndicatorHeight: 48,
  ballIndicatorPosition: -20,
  ballIndicatorTextColor: '#000000',
  showBackgroundShadow: false,
  shadowProps: {
    shadowOffsetWidth: 0,
    shadowOffsetHeight: 1,
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    shadowColor: '#000',
  },
  stepLabelHeight: 30,
  stepLabelDividerWidth: 40,
  stepLabelDividerColor: 'grey',
  renderIndicator: null,
  onChange: () => {},
  onComplete: () => {},
};
