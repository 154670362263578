import React from "react";
import { View } from "react-native";
import { Button, FieldLabel, TextInput } from "../../../components";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createOrganisationBundle } from "../../../graphql";
import { IOWNAError } from "../../../utils";

export default function ({ item, onClose, onSave }) {
  const { control, handleSubmit, formState: {errors} } = useForm({
    defaultValues: {
      name: item ? item.name : "",
    },
  });

  const mutation = useMutation(
    ({ name }) => {
      return createOrganisationBundle({
        input: {
          id: item.id,
          name: name.trim(),
          organisationId: item.organisationId,
          adviceIds: item.adviceIds,
        },
      })
        .then((res) => res.data.createOrganisationBundle)
        .catch((err) => {
          const message = err.errors?.[0]?.message;
          if (message) {
            throw new IOWNAError("NOT WORKING FOR CREATE BUNDLE");
          } else {
            throw new Error(JSON.stringify(err));
          }
        });
    },
    {
      onSuccess: () => {
        console.log("the edit succeeded");
        onSave();

        onClose();
      },
    }
  );

  return (
    <View>
      <FieldLabel>Name</FieldLabel>
      <TextInput
        name="name"
        control={control}
        errors={errors}
        rules={{ required: "Please enter the bundle name", maxLength: 70 }}
      />

      <View
        style={{
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          loading={mutation.isLoading}
          onPress={handleSubmit(mutation.mutate)}
        >
          {item ? "Update" : "Create"}
        </Button>
      </View>
    </View>
  );
}
