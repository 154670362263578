import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { MainScreen, HeaderButton } from '../../components';
import { screen } from '../../reactUtils';
import { userInvitesTransformedAtom, hasOrganisationsAtom, rejectUserInvites, acceptUserInvites, useAtom, isWebAtom } from '../../atoms';
import IconLogo from '../../assets/images/logo.svg';

const AcceptPendingInvitationsScreen = screen(({ navigation }) => {
  const web = useAtom(isWebAtom);
  const userInvites = useAtom(userInvitesTransformedAtom);
  const hasOrganisations = useAtom(hasOrganisationsAtom);
  const next = () => {
    if (hasOrganisations) {
      navigation.navigate('ChooseOrganisationScreen');
    } else {
      navigation.navigate('HomeNavigator', {});
    }
  };
  useEffect(() => {
    if (userInvites.length === 0) {
      next();
    }
  }, [userInvites.length]);
  return (
    <MainScreen statusMargin={true} backgroundColor="white" style={{ flex: 1, alignItems: 'center' }} isList={true}>
      <View style={{ alignItems: 'center', marginBottom: 20 }}>
        <IconLogo width={180} height={web ? 180 : 80} />
        <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
          <Text style={{ fontSize: web ? 20 : 18, fontWeight: '500', color: 'rgb(57, 63, 70)' }}>You have pending Organisation Invitations</Text>
        </View>
      </View>
      <FlatList
        data={userInvites}
        keyExtractor={(item) => item.id}
        renderItem={({ item: { body, data } }) => (
          <View
            style={{
              padding: 20,
              margin: web ? 20 : 10,
              borderColor: '#c6c6c8',
              borderWidth: 1,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: 16, color: '#000000' }}>{body}</Text>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}>
              <HeaderButton
                title="Accept"
                onPress={() => {
                  acceptUserInvites(data.ids);
                }}
              />
              <HeaderButton
                title="Reject"
                onPress={() => {
                  rejectUserInvites(data.ids);
                }}
              />
            </View>
          </View>
        )}
      />
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: web ? 50 : 20 }} onPress={next}>
          <Text style={{ fontSize: 20, fontWeight: '500', color: 'rgb(57, 63, 70)' }}>Skip</Text>
        </TouchableOpacity>
      </View>
    </MainScreen>
  );
});

export default AcceptPendingInvitationsScreen;
