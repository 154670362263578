import React from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { BreadCrumb } from "../../../containers";
import { HeaderButton } from "../../../components";
import { options, optionsBack } from "../../../headerUtils";
import { useAtom, isWebAtom } from "../../../atoms";
import ContactsScreen from "./ContactsScreen";
import ActivityScreen, {
  OptionsBack,
  OptionsBack as ProfileOptionsBack,
} from "../ActivityNavigator/ActivityScreen";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import ResultsScreen, {
  ResultOptionsBack,
} from "../ActivityNavigator/ResultsScreen";

const Stack = createStackNavigator();
export default ({ route }) => {
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ContactsScreen"
        component={ContactsScreen}
        options={options(
          () => (
            <BreadCrumb parts={["currentOrganisation.name", "Contacts"]} />
          ),
          ({ navigation }) => (
            <HeaderButton
              accessibilityLabel={Platform.select({
                android: "Create Contact",
              })}
              icon="ios-add-circle-outline"
              title="Create Contact"
              onPress={() => {
                navigation.setParams({ createContact: true });
              }}
            />
          )
        )}
        initialParams={route.params}
      />
      <Stack.Screen
        name="ProfileScreen"
        component={ActivityScreen}
        options={ProfileOptionsBack(web)}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
      <Stack.Screen
        name="ResultsScreen"
        component={ResultsScreen}
        options={ResultOptionsBack(web, "Results")}
      />
      <Stack.Screen
        name="ActivityScreen"
        component={ActivityScreen}
        options={OptionsBack(web, "Activity")}
      />
    </Stack.Navigator>
  );
};
