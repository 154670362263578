import React, { useState } from "react";
import { View } from "react-native";
import { find, chunk } from "lodash";
import {
  Picker,
  Tag,
  Button,
  FieldLabel,
  ErrorMessage,
  SnackBar,
  HeaderButton, Text
} from "../../../components";
import { useForm, useDistributionTags, useGlobal } from "../../../hooks";
import { tagDistributionContact } from "../../../graphql";

import { Modal } from "../../../components";
import { useAtom, currentOrganisationAtom, isWebAtom } from "../../../atoms";
import {
  getOrganisationDistributionContacts2,
  deleteDistributionContact
} from "../../../graphql";
import Empty from "../../../components/Empty.js";

import ContactList from "../../../new/components/contacts/List.js";
import ContactListItem from "../../../new/components/contacts/ContactListItem";
import ContactCreate from "../../../new/components/contacts/Create";
import ContactEdit from "../../../new/components/contacts/Edit";

import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";

// TODO break this out to new/components/contacts and move to react-query
const AddToDistributionListModal = ({ tagContacts = [], onClose }) => {
  const [showAdded, setShowAdded] = useState(false);
  const [data, updateData] = useGlobal();
  const [list, , loading, listErrors] = useDistributionTags();
  const { control, onSubmit, isSubmitting, errors, error } = useForm({
    initial: {},
    schema: () => {
    },
    onSubmit: async ({ distributionTagId }) => {
      for (const contact of tagContacts) {
        try {
          await tagDistributionContact({
            input: {
              contactId: contact.id,
              tagId: distributionTagId
            }
          });
        } catch (err) {
          if (
            err.errors &&
            err.errors[0] &&
            err.errors[0].message.indexOf("Already tagged") > -1
          ) {
            // don't throw error
          } else {
            throw err;
          }
        }
        updateData((draft) => {
          const distributionTag = find(draft.distributionTags.items, {
            id: distributionTagId
          });
          distributionTag.contacts.items.push(contact);
          draft.distributionContacts.items =
            draft.distributionContacts.items.map((item) => ({
              ...item,
              ...(item.id === contact.id
                ? {
                  tags: {
                    items: [
                      ...item.tags.items,
                      { id: distributionTag.id, name: distributionTag.name }
                    ]
                  }
                }
                : {}),
              checked: false
            }));
        });
      }
      setShowAdded(true);
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  });
  return (
    <View style={{ paddingTop: 20 }}>
      <FieldLabel>Select Distribution list</FieldLabel>
      <Picker
        name="distributionTagId"
        modal={true}
        control={control}
        items={list.items.map((item) => ({ label: item.name, value: item.id }))}
      />
      <View>
        <FieldLabel>Below contacts will be added</FieldLabel>
        {chunk(
          tagContacts.map((contact) => contact.phone),
          3
        ).map((items) => (
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {items.map((num) => (
              <Tag key={num} text={num} />
            ))}
          </View>
        ))}
      </View>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <ErrorMessage err={listErrors || error} />
      </View>
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "center"
        }}
      >
        <Button loading={isSubmitting} onPress={onSubmit}>
          Add to list
        </Button>
      </View>
      <SnackBar
        visible={showAdded}
        message={"Contacts added to the distribution list"}
        onPress={() => {
          setShowAdded(false);
        }}
      />
    </View>
  );
};

export default function({ navigation, route }) {
  const web = useAtom(isWebAtom);
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const [showAddToListModal, setShowAddToListModal] = useState(false);
  const [showEditNotPermittedModal, setShowEditNotPermittedModal] = useState(false);
  const [showContactEditModal, setShowContactEditModal] = useState(false);

  const [openItemId, setOpenItemId] = useState(null);

  const [filterString, setFilterString] = useState("");

  const apiFn = (props) =>
    getOrganisationDistributionContacts2({
      id: currentOrganisation.id,
      ...props
    });
  const pathFn = (result) => result.data.getOrganisation.distributionContacts;

  const {
    data: pageData,
    fetchNextPage: loadMore,
    hasNextPage,
    isLoading,
    isFetchingNextPage
  } = useInfiniteQuery(
    [currentOrganisation.id, "contacts", filterString],
    ({ pageParam }) => apiFn({ filterString, ...pageParam }).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined
    }
  );
  const loading = isLoading || isFetchingNextPage;

  const data = pageData ? pageData.pages.flatMap((x) => x.items) : [];


  const queryClient = useQueryClient();

  const isEditingContactDisabled = () => {
    return currentOrganisation.id === "bff8581c-c56c-4dd9-9671-07500c91ca1f"
  };


  const deleteMutation = useMutation(
    ({ id }) => {
      return deleteDistributionContact({
        input: {
          id: id
        }
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([currentOrganisation.id, "contacts"]);
      },
      onError: (err) => {
        const message = err.errors?.[0]?.message;
        if (message) {
          alert(message);
          // throw new Error(message);
        } else {
          alert(JSON.stringify(err));
          // throw new Error(JSON.stringify(err));
        }
      }
    }
  );

  const renderItem = (props) => {
    const onEdit = () => {
      if (isEditingContactDisabled()) {
        setShowEditNotPermittedModal(true);
      } else {
        setShowContactEditModal(true);
      }
    };


    return (
      <ContactListItem
        isOpen={openItemId === props.item.id}
        onOpen={(item) =>
          setOpenItemId((prevSetting) =>
            prevSetting === item.id ? null : item.id
          )
        }
        onAddToList={() => setShowAddToListModal(true)}
        onEdit={onEdit}
        onDelete={() => deleteMutation.mutate(props.item)}
        {...props}
      />
    );
  };

  const showCreateContactModal = route.params?.createContact;

  return (
    <>
      <ContactList
        setSearchText={setFilterString}
        data={data}
        loadMore={loadMore}
        hasMore={hasNextPage}
        loading={loading}
        renderItem={renderItem}
      />
      {showAddToListModal && (
        <Modal
          isVisible={showAddToListModal}
          title="Add to existing list"
          onClose={() => setShowAddToListModal(false)}
          style={{ maxWidth: 400 }}
        >
          <AddToDistributionListModal
            tagContacts={[data.find((item) => item.id === openItemId)]}
          />
        </Modal>
      )}
      {showContactEditModal && (
        <Modal
          isVisible={showContactEditModal}
          title={"Edit a Contact"}
          onClose={() => {
            setShowContactEditModal(false);
          }}
          style={{ maxWidth: 400 }}
        >
          <ContactEdit
            item={data.find((item) => item.id === openItemId)}
            onSave={() =>
              queryClient.invalidateQueries([
                currentOrganisation.id,
                "contacts"
              ])
            }
          />
        </Modal>
      )}

      {showCreateContactModal && (
        <Modal
          isVisible={showCreateContactModal}
          title="Create a new Contact"
          onClose={() => {
            navigation.setParams({ createContact: false });
          }}
          style={{ maxWidth: 400 }}
        >
          <ContactCreate
            onSave={() =>
              queryClient.invalidateQueries([
                currentOrganisation.id,
                "contacts"
              ])
            }
          />
        </Modal>
      )}

      {showEditNotPermittedModal && (
        <Modal
          isVisible={showEditNotPermittedModal}
          title="Not available"
          onClose={() => setShowEditNotPermittedModal(false)}
          style={{ maxWidth: 400 }}
        >
          <View>
            <Text marginBottom={20}>Your organisation does not allow you to edit contacts. Please get in touch with MKUH IT services if you
              are having issues.</Text>
            <Button onPress={() => setShowEditNotPermittedModal(false)}>
              Close
            </Button>
          </View>
        </Modal>
      )}
    </>
  );
}
