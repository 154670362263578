import React from 'react';
import { Dimensions } from 'react-native';
import styled from '@emotion/native';
import { Touch, Text as CText, Spinner } from '../../components';
import { testId } from '../../utils';
import ArrowBackIcon from '../../assets/icons/arrow-back.svg';

export const Field = styled.View`
  width: 300;
`;

export const FieldLabel = styled.Text`
  font-size: 16;
  color: #eaffff;
  margin-top: 25;
  margin-bottom: 10;
`;

export const ButtonContainer = styled.View`
  margin-top: 40;
`;

export const Footer = styled.View`
  flex-direction: row;
  width: 300;
  justify-content: space-between;
`;

export const FooterLabel = styled.Text`
  margin-top: 20;
  color: ${(props) => props.theme.textLight};
`;

export const FooterValue = styled.Text`
  margin-top: 20;
  font-weight: 400;
  color: #eaffff;
`;

export const Text = styled.Text`
  font-size: 17;
  text-align: center;
  line-height: 30;
  color: #eaffff;
`;

export const Title = styled.View`
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const Button = ({ title, loading, onPress, marginTop }) => {
  const width = Math.min(Dimensions.get('window').width - 80, 300);
  return (
    <Touch
      backgroundColor="white"
      width={width}
      height={50}
      borderRadius={25}
      alignItems="center"
      justifyContent="center"
      marginTop={marginTop}
      marginBottom={25}
      onPress={onPress}
    >
      {loading ? (
        <Spinner color="#004E7A" /> 
      ) : (
        <CText color="#004E7A" fontSize={20}>
          {title}
        </CText>
      )}
    </Touch>
  );
};

export const BackButton = ({ onPress }) => (
  <Touch flexDirection="row" alignItems="center" justifyContent="center" onPress={onPress} {...testId('buttonBackButton')}>
    <ArrowBackIcon width={14} height={14} />
    <CText marginLeft={3} fontSize={16} color="#eaffff">
      Back
    </CText>
  </Touch>
);
