import React, { useState } from "react";
import { Text, Linking } from "react-native";
import SnackBar from "./SnackBar";
import { useAtom, isWebAtom } from "../atoms";

export default function CookieBanner() {
  const web = useAtom(isWebAtom);
  const [cookies, setShowCookies] = useState(
    web ? typeof window !== 'undefined' && window.localStorage.getItem('cookiesAccepted') !== 'true' : false
  );

  if (web) {
    return (
      <SnackBar
        visible={cookies}
        message={
          <>
            <Text>
              iOWNA wHealth uses cookies to guarantee users the employment of
              its site features, offering a better experience. More information
              on our use of cookies can be found 
            </Text>
            <Text
              style={{ fontWeight: "bold", paddingRight: 5, paddingLeft: 5, textDecorationLine: "underline" }}
              onPress={() =>
                Linking.openURL(
                  "https://iowna.com/privacy/#:~:text=We%20may%20use%20both%20session,experience%20on%20the%20iOWNA%20Platform."
                )
              }
            >
               here. 
            </Text>
            <Text>
              By continuing to browse the site you're agreeing to our use of
              cookies.
            </Text>
          </>
        }
        actionText="ACCEPT"
        onPress={() => {
          window.localStorage.setItem("cookiesAccepted", "true");
          setShowCookies(false);
        }}
      />
    );
  }
  return null;
}
