import styled from '@emotion/native';

export const HeaderContainer = styled.View`
  background-color: ${(props) => props.backgroundColor};
  flex-direction: row;
  padding-top: 10;
  padding-bottom: 10;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(177, 179, 181);
  z-index: ${(props) => props.zIndex};
`;

export const HeaderItem = styled.View`
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  width: ${(props) => props.width};
`;

export const HeaderText = styled.Text`
  font-size: 18;
  font-weight: 500;
  color: rgb(0, 78, 122);
`;

export const ListItemContainer = styled.TouchableOpacity`
  flex-direction: row;
  border-bottom-style: solid;
  border-bottom: 1px;
  border-bottom-color: #e1e1e1;
`;

export const ListItem = styled.View`
  width: ${(props) => props.width};
  flex-direction: row;
  padding-top: 10;
  padding-bottom: 10;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
`;

export const ListText = styled.Text`
  font-size: 16;
  color: rgb(57, 63, 70);
`;

export const Title = styled.Text`
  flex: 1;
  font-size: 22;
  font-weight: 700;
  color: ${(props) => props.theme.primary};
  margin-bottom: 10;
`;

export const Description = styled.Text`
  font-size: 18;
  color: ${(props) => props.theme.textLight};
  text-align: center;
`;
