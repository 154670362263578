import React, { useState } from 'react';
import VerticalSlider from './VerticalSlider';
import { Entypo } from '@expo/vector-icons';
import Box from './Box';
import Text from './Text';

const Indicator = ({ ranges, value }) => {
  let smiley = '';
  for (const range of ranges) {
    if (value >= range.from && value <= range.to) {
      smiley = range.smiley;
      break;
    }
  }
  return (
    <Box justifyContent="flex-end" alignItems="center" flexDirection="row" cursor="pointer">
      <Box flexDirection="row" alignItems="center" marginRight={5}>
        <Text color="black" fontSize={30}>
          {smiley}
        </Text>
        <Text fontSize={20}>{value}</Text>
      </Box>
      <Entypo name="arrow-bold-right" size={30} color="black" />
    </Box>
  );
};
const Thermometer = (props) => {
  const [slider, setSlider] = useState(0);
  return (
    <VerticalSlider
      maximumTrackTintColor="gray"
      maximumTrackTileColor="white"
      minimumTrackTintColor="tomato"
      minimumTrackTileColor="white"
      showBallIndicator={true}
      renderIndicator={(value) => <Indicator ranges={props.ranges} value={value} />}
      showBackgroundShadow={false}
      {...props}
      value={slider}
      onChange={(v) => setSlider(v)}
      onComplete={(v) => props.onChange(v)}
    />
  );
};

export default Thermometer;
