import React, { useState, useEffect } from "react";
import { View, Text as RNText, FlatList } from "react-native";
import { useOrganisationBundle } from "../../../hooks";
import {
  useAtom,
  currentOrganisationAtom,
  isWebAtom,
  atom,
} from "../../../atoms";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  getOrganisationBundles,
  deleteOrganisationBundle,
} from "../../../graphql";
import List from "../../../new/components/bundles/List";
import BundleListItem from "../../../new/components/bundles/BundleListItem";
import BundleCreateModal from "../../../new/components/bundles/BundleCreate";
import Empty from "../../../components/Empty.js";
import BundleEdit from "../../../new/components/bundles/BundleEdit";
import { Modal } from "../../../components";
import New from "../../../assets/icons/new.svg";

export default function ({ navigation, route }) {
  const { selectionOriginatingScreen, canSetAdvice } = route.params || {};
  const currentOrganisation = useAtom(currentOrganisationAtom);

  const [showEditBundleModal, setShowEditBundleModal] = useState(false);
  const [openItemId, setOpenItemId] = useState(null);

  const apiFn = (props) =>
    getOrganisationBundles({
      id: currentOrganisation.id,
      ...props,
    });

  const pathFn = (result) => result.data.getOrganisation.bundles;

  // console.log("BUNDLE SCREEN ARRAY OF BUNDLES", [
  //   currentOrganisation.id,
  //   "bundles",
  // ]);

  const {
    data: pageData,
    fetchNextPage: loadMore,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [currentOrganisation.id, "bundles"],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const loading = isLoading || isFetchingNextPage;

  const data = pageData ? pageData.pages.flatMap((x) => x.items) : [];
  const queryClient = useQueryClient();

  //need to remove
  // queryClient.invalidateQueries([currentOrganisation.id, "bundles", bundleId]);

  const deleteMutation = useMutation(
    ({ id }) => {
      return deleteOrganisationBundle({
        input: {
          id: id,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([currentOrganisation.id, "bundles"]);
      },
      onError: (err) => {
        const message = err.errors?.[0]?.message;
        if (message) {
          console.log("this is the first message error", message);

          alert(message);
          // throw new Error(message);
        } else {
          console.log("ths is the err", err);
          alert(JSON.stringify(err));
          // throw new Error(JSON.stringify(err));
        }
      },
    }
  );

  const renderItem = (props) => (
    <BundleListItem
      bundleData={data}
      item={props.item}
      isOpen={openItemId === props.item.id}
      onOpen={(item) =>
        setOpenItemId((prevSetting) =>
          prevSetting === item.id ? null : item.id
        )
      }
      onEdit={() => setShowEditBundleModal(true)}
      canSetAdvice={canSetAdvice}
      onDelete={() => deleteMutation.mutate(props.item)}
      {...props}
      selectionOriginatingScreen={selectionOriginatingScreen}
    />
  );

  const showCreateBundleModal = route.params?.createBundle;

  return (
    <>
      <List
        data={data}
        loadMore={loadMore}
        hasMore={hasNextPage}
        loading={loading}
        renderItem={renderItem}
        ListEmptyComponent={
          <>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <RNText>Please click </RNText>
              <New></New>
              <RNText> to create a bundle</RNText>
            </View>
          </>
        }
      />
      {showEditBundleModal && (
        <Modal
          isVisible={showEditBundleModal}
          title={"Edit Bundle"}
          onClose={() => {
            setShowEditBundleModal(false);
          }}
          style={{ maxWidth: 400 }}
        >
          <BundleEdit
            item={data.find((item) => item.id === openItemId)}
            onSave={() => {
              queryClient.invalidateQueries([
                currentOrganisation.id,
                "bundles",
              ]);
            }}
          />
        </Modal>
      )}

      {showCreateBundleModal && (
        <Modal
          isVisible={showCreateBundleModal}
          title={"Create Bundle"}
          onClose={() => {
            navigation.setParams({ createBundle: false });
          }}
          style={{ maxWidth: 400 }}
        >
          <BundleCreateModal onSave={() => {}} />
        </Modal>
      )}
    </>
  );
}
