import React from 'react';
import styled from '@emotion/native';
import { ErrorMessage } from '@hookform/error-message';

const Text = styled.Text`
  color: ${(props) => props.theme.error};
  font-size: 16;
  margin-top: 10;
  margin-bottom: 10;
  margin-left: 10;
  margin-right: 10;
  text-align: center;
`;

export default ({ err, errors, name, style }) => {
  if (!name && err) {
    return (
      <Text testID={'error_global'} style={style}>
        {err}
      </Text>
    );
  }
  if (name && errors && errors[name]) {
    return (
      <Text>
        <ErrorMessage testID={'error_' + name} errors={errors} name={name} />
      </Text>
    );
  }
  return null;
};
