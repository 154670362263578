import React from "react";
import { View, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { currentOrganisationAtom, useAtom } from "../../../atoms";
import {  updateDistributionTag } from "../../../graphql";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import {
  FieldLabel,
  TextInput,
  ErrorMessage,
  Button,
} from "../../../components";

export default function ({ onClose, onSave, item }) {
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const queryClient = useQueryClient();

 
  const navigation = useNavigation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    formState,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: { name: item.name, id: item.id },
  });

  // console.log("What are the errors", errors);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const mutation = useMutation(
    ({ name }) => 
      updateDistributionTag({
        input: {
          name: name.trim(),
          id: item.id
        }
    
      }).then(async (res) => {await sleep(1000); return res}),
    {
      onMutate: () => {
   
      },
      // .catch((err) => {
      //   console.log("this is the err object", err);
      //   const message = err.errors?.[0]?.message;

      //   console.log("this is the message", message);
      //   setError("name", { message: err.message });
      // });

      onSuccess: ({data}) => {
        queryClient.invalidateQueries({
          queryKey: [currentOrganisation.id, "distributionLists"],
      });

        onSave();
        onClose();
      },
    }
  );



  return (
    <View>
      <FieldLabel>Name</FieldLabel>
      <TextInput
        name="name"
        control={control}
        errors={errors}
        rules={{ required: "List Name required" }}
      />
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        {/* <ErrorMessage err={error} /> */}
      </View>
      <View
        style={{
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          loading={mutation.isLoading}
          onPress={handleSubmit(mutation.mutate)}
        >
         {"Rename"}
        </Button>
      </View>
    </View>
  );
}
