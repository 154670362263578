import React, { useState, useMemo, useCallback } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { SwipeListView } from "react-native-swipe-list-view";
import { get, groupBy, sortBy } from "lodash";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useTheme } from "@emotion/react";
import { screen } from "../../../reactUtils";
import {
  MainScreen,
  Modal,
  RoundedCheckBox,
  Box,
  SearchInput,
  Accordion,
  CardSeperator,
  FilterByCategory,
  FieldLabel,
  TextInput,
  ErrorMessage,
  Button,
  SnackBar,
  PhoneInput,
} from "../../../components";
import {
  useAtom,
  atom,
  isWebAtom,
  userTodosTransformedAtom,
  setPdfId,
  setUserContactVerified,
} from "../../../atoms";
import {
  IOWNAError,
  formatDate,
  getUserFullName,
  testId,
  updateUserAttributes,
  verifyCurrentUserAttribute,
  verifyCurrentUserAttributeSubmit,
  onlySpecialities,
  ALL_PILLARS,
} from "../../../utils";
import { initialiseUserContent } from "../../../graphql";
import { useForm } from "../../../hooks";

const showPhoneVerifyModalAtom = atom(false);
const setShowPhoneVerifyModal = (v) => showPhoneVerifyModalAtom.update(v);

export const showFilterMenuAtom = atom(false);
export const setShowFilterMenu = (v) => showFilterMenuAtom.update(v);

export const searchTextAtom = atom("");
export const setSearchText = (v) => searchTextAtom.update(v);

export const sortModeAtom = atom("asc");
export const setSortMode = (v) => sortModeAtom.update(v);

export const filterByAtom = atom(null);
export const setFilterBy = (v) => filterByAtom.update(v);

export const showFiltersAtom = atom(false);
export const setShowFilters = (v) => showFiltersAtom.update(v);


const EnterVerificationCodeModal = ({
  navigation,
  onClose = () => {},
  onVerified = () => {},
  onClickBack = () => {},
}) => {
  const theme = useTheme();
  const [message, setMessage] = useState("");
  const { control, onSubmit, isSubmitting, errors, error } = useForm({
    initial: {
      verificationCode: "",
    },
    schema: (yup) => ({
      verificationCode: yup
        .string()
        .required("Please enter the verification code"),
    }),
    onSubmit: async ({ verificationCode }) => {
      const response = await verifyCurrentUserAttributeSubmit(
        "phone_number",
        verificationCode.trim()
      );
      if (response !== "SUCCESS") {
        throw new IOWNAError("Please enter a valid verification code");
      }
      try {
        await initialiseUserContent();
        onVerified();
        onClose();
        navigation.navigate("VerificationSuccessfulScreen");
      } catch (e) {
        updateUserAttributes({ phone_number: "" });
        throw new IOWNAError(
          "Phone number is already linked to another account"
        );
      }
    },
  });

  return (
    <View>
      <FieldLabel>Enter Verification Code</FieldLabel>
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <TextInput
          name="verificationCode"
          control={control}
          errors={errors}
          keyboardType={"numeric"}
        />
        <ErrorMessage err={error} />
      </View>
      <View style={{ marginTop: 50, alignItems: "flex-start" }}>
        <Button loading={isSubmitting} onPress={onSubmit}>
          Submit
        </Button>
      </View>
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ color: theme.text }}>DIDN'T RECEIVE A CODE</Text>
        <TouchableOpacity
          onPress={() => {
            verifyCurrentUserAttribute("phone_number");
            setMessage("A verification code has been resent");
          }}
        >
          <Text style={{ color: theme.primary, fontWeight: "500" }}>
            RESEND
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginTop: 20 }}>
        <TouchableOpacity onPress={onClickBack}>
          <Text style={{ color: theme.textPlaceholder, fontWeight: "500" }}>
            BACK
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginTop: 50 }}>
        <SnackBar
          visible={message}
          message={message}
          onPress={() => setMessage("")}
        />
      </View>
    </View>
  );
};

const VerifyYourDeviceModal = ({
  onClose = () => {},
  onVerified = () => {},
  ...props
}) => {
  const [enterVerificationModal, setEnterVerificationModal] = useState(false);
  const { control, onSubmit, isSubmitting, errors, error, watch } = useForm({
    initial: {},
    schema: (yup) => ({
      mobile: yup.string().required("Please enter the phone number"),
    }),
    onSubmit: async ({ countryCode, mobile }) => {
      const phoneNumber = parsePhoneNumberFromString(
        "+" + countryCode + mobile
      );
      if (!phoneNumber || !phoneNumber.isValid()) {
        throw new IOWNAError("Phone number is not a valid mobile number");
      }
      const response = await updateUserAttributes({
        phone_number: phoneNumber.number,
      });
      if (response !== "SUCCESS") {
        throw new IOWNAError("Technical Error Occurred");
      }
      await verifyCurrentUserAttribute("phone_number");
      setEnterVerificationModal(true);
    },
  });

  if (enterVerificationModal) {
    return (
      <EnterVerificationCodeModal
        onClose={() => {
          setEnterVerificationModal(false);
          onClose();
        }}
        onVerified={onVerified}
        onClickBack={() => setEnterVerificationModal(false)}
        {...props}
      />
    );
  }

  return (
    <View>
      <FieldLabel>Phone</FieldLabel>
      <PhoneInput
        name="mobile"
        initialNumber={""}
        control={control}
        errors={errors}
        watch={watch}
      />
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <ErrorMessage err={error} />
      </View>
      <View style={{ marginTop: 50, alignItems: "flex-start" }}>
        <Button loading={isSubmitting} onPress={onSubmit}>
          Verify your device
        </Button>
      </View>
    </View>
  );
};

const TodoCard = ({ item, navigation }) => {
  const handlePressTodoCard = (todo) => {
    switch (todo.type) {
      case "complete-profile": {
        if (!todo.done) {
          navigation.navigate("ProfileNavigator", {
            screen: "UpdateProfileScreen",
            params: { edit: false },
          });
        }
        break;
      }
      case "verify-phone-number": {
        if (!todo.done) {
          setShowPhoneVerifyModal(true);
        }
        break;
      }
      case "questionnaire": {
        if (todo.staticAdvice?.id) {
          setPdfId(todo.staticAdvice.id);
          navigation.navigate("PdfScreen", {
            id: todo.staticAdviceId,
            contentId: todo.id,
            consumer: true,
          });
        }
        break;
      }
      default: {
      }
    }
  };
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => handlePressTodoCard(item)}
    >
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        paddingHorizontal={20}
        paddingVertical={20}
      >
        <RoundedCheckBox checked={item.done} checkedColor="#007aff" />
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          marginLeft={10}
          alignItems="center"
        >
          <Box flex={1}>
            <Text style={{ fontSize: 14, color: "#004e7a" }}>{item.title}</Text>
            {item.description ? (
              <Text
                style={{ fontSize: 14, color: "#aeb1b5", fontWeight: "400" }}
              >
                {item.description}
              </Text>
            ) : null}
          </Box>
          {item.important ? (
            <AntDesign name="exclamationcircle" size={20} color="#ee4f32" />
          ) : null}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const HideCompleted = ({ checked, setChecked }) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      paddingHorizontal={20}
      paddingVertical={10}
    >
      <RoundedCheckBox
        size={16}
        checked={checked}
        onPress={() => setChecked((checked) => !checked)}
      />
      <Text style={{ fontSize: 12, color: "#004e7a", marginLeft: 10 }}>
        Hide Completed
      </Text>
    </Box>
  );
};

const PhoneVerifyModal = ({ navigation }) => {
  const showPhoneVerifyModal = useAtom(showPhoneVerifyModalAtom);
  return (
    <Modal
      isVisible={showPhoneVerifyModal}
      title="Verify your device"
      onClose={() => {
        setShowPhoneVerifyModal(false);
      }}
      style={{ maxWidth: 400 }}
    >
      <VerifyYourDeviceModal
        navigation={navigation}
        onVerified={() => setUserContactVerified(true)}
        onClose={() => {
          setShowPhoneVerifyModal(false);
        }}
      />
    </Modal>
  );
};

const AccordionTodoCards = ({ title, items, navigation }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Accordion
        showArrow={true}
        open={isOpen}
        expandIcon={<AntDesign name="pluscircleo" size={16} color="#004e7a" />}
        collapseIcon={
          <AntDesign name="minuscircleo" size={16} color="#004e7a" />
        }
        onPress={() => setIsOpen((isOpen) => !isOpen)}
        title={
          <Box
            {...testId(`${title}`)}
            flex={1}
            flexDirection="row"
            paddingHorizontal={20}
            paddingVertical={10}
          >
            <Text style={{ fontSize: 14, color: "#004e7a" }}>{title}</Text>
          </Box>
        }
        titleStyles={{ backgroundColor: "#efefef" }}
        bodyStyles={{ backgroundColor: "#ffffff" }}
      >
        {items.map((item, index) => {
          return (
            <Box key={item.id} backgroundColor="white">
              <TodoCard item={item} navigation={navigation} />
              {index !== items.length - 1 ? <CardSeperator /> : null}
            </Box>
          );
        })}
      </Accordion>
      <CardSeperator spacing={0} />
    </>
  );
};

const filterMenu = [
  {
    text: "Date",
    value: "date",
    sortKey: "createdAt",
    group: (results) =>
      groupBy(results, (item) => formatDate(item.createdAt, "dd MMM yyyy")),
  },
  {
    text: "Clinician",
    value: "clinician",
    sortKey: (item) => item["title"].toLowerCase(),
    group: (results) => groupBy(results, "createdByUser"),
  },
  {
    text: "Form",
    value: "questionnaire",
    sortKey: "createdAt",
    group: (results) =>
      groupBy(
        results.filter((i) => !!i.staticAdvice.form),
        "staticAdvice.displayName"
      ),
  },
  {
    text: "Conditions",
    value: "tags",
    group: (results) => {
      const map = { "General Medicine": [] };
      for (const item of results) {
        if (item.type === "questionnaire") {
          const specs = onlySpecialities(item.staticAdvice?.tags || []);
          if (specs.length === 0) {
            map["General Medicine"].push(item);
            continue;
          }
          const pillars = [];
          for (const [key, values] of Object.entries(ALL_PILLARS)) {
            for (const spec of values) {
              if (specs.includes(spec) && !pillars.includes(key)) {
                if (!map[key]) {
                  map[key] = [];
                }
                map[key].push(item);
              }
            }
          }
        }
      }
      return map;
    },
  },
];

const TodoScreen = screen(({ navigation }) => {
  const web = useAtom(isWebAtom);
  const todos = useAtom(userTodosTransformedAtom);
  const searchText = useAtom(searchTextAtom);
  const filterBy = useAtom(filterByAtom);
  const sortMode = useAtom(sortModeAtom);
  const showFilters = useAtom(showFiltersAtom);

  const [hideCompleted, setHideCompleted] = useState(false);

  const formatTitle = useCallback((item, filterBy, defaultTitle = "iOWNA") => {
    switch (filterBy) {
      case "clinician": {
        const creator = get(item, "0.createdByUser", null) || {
          id: "system-user-id",
          title: "",
          firstName: "iOWNA",
          lastName: "",
        };

        return getUserFullName(creator);
      }
      default:
        return defaultTitle;
    }
  }, []);

  const allTodos = useMemo(() => {
    let result = todos;
    if (hideCompleted) {
      result = result.filter((item) => !item.done);
    }
    if (searchText) {
      result = result.filter(
        (item) =>
          [item.title, item.description]
            .join("")
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) >= 0
      );
    }
    if (filterMenu.map((menu) => menu.value).includes(filterBy?.value)) {
      switch (filterBy.value) {
        case "questionnaire": {
          result = result.filter((item) => item.type === "questionnaire");
          result = sortBy(result, filterBy.sortKey);
          break;
        }
        case "date": {
          result = result.filter((item) => item.createdAt);
          break;
        }
        case "clinician": {
          result = result.filter((item) => item.createdBy);
          break;
        }
      }
    }
    // Group
    if (filterBy?.group) {
      const groups = filterBy.group(result);
      const groupedTodoList = Object.keys(groups).map((key) => ({
        id: key,
        title: formatTitle(groups[key], filterBy.value, key),
        todos: groups[key],
      }));

      result = sortBy(groupedTodoList, filterBy.sortKey || "title");
    }

    // Sort result
    if (sortMode === "desc") {
      result = [...result].reverse();
    }

    return result;
  }, [todos, hideCompleted, searchText, filterBy, sortMode]);

  return (
    <MainScreen
      backgroundColor={ "white"}
      isList={web ? false : true}
    >
      <Box backgroundColor="white">
        <Box flexDirection="row" alignItems="center" padding={10}>
          <Box flex={1}>
            <SearchInput
              text={searchText}
              suggestions={[]}
              onChangeText={(text) => setSearchText(text)}
              onSearch={() => {}}
              onClear={() => setSearchText("")}
            />
          </Box>
          {filterBy?.text ? (
            <Box
              backgroundColor={"#efefef"}
              paddingHorizontal={10}
              paddingVertical={4}
              marginRight={5}
              marginLeft={10}
              borderColor={"rgb(177, 179, 181)"}
              borderRadius={4}
              borderWidth={1}
            >
              <Text style={{ fontSize: 15, color: "#004e7a" }}>
                {filterBy?.text}
              </Text>
            </Box>
          ) : null}
        </Box>
        {showFilters ? (
          <FilterByCategory
            filterMenu={filterMenu}
            selected={filterBy}
            setSelected={setFilterBy}
            onApply={() => setShowFilters((showFilters) => !showFilters)}
          />
        ) : (
          <>
            <HideCompleted
              checked={hideCompleted}
              setChecked={setHideCompleted}
            />
            <CardSeperator spacing={0} />
            <SwipeListView
              useFlatList
              data={allTodos}
              keyExtractor={(item) => item.id}
              ListFooterComponent={<Box marginBottom={200} />}
              renderItem={({ item }) => {
                if (filterBy?.group) {
                  return (
                    <AccordionTodoCards
                      title={item.title}
                      items={item.todos}
                      navigation={navigation}
                    />
                  );
                }
                return (
                  <Box>
                    <TodoCard item={item} navigation={navigation} />
                    <CardSeperator />
                  </Box>
                );
              }}
            />
          </>
        )}
      </Box>
      <PhoneVerifyModal navigation={navigation} />
    </MainScreen>
  );
});

export default TodoScreen;
