import { useState, useEffect,  } from "react";
import type { ComponentProps } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import ChoicesQuestion from "./ChoicesQuestion";
import SliderQuestion from "./SliderQuestion";
import RatingQuestion from "./RatingQuestion";
import Input from "./Input";
import TextDisplay from "./TextDisplay";


import Form from "../../Form";

type QuestionHeaderT = {
  title: string;
  id: string; // make id optional as an id key should not be added to 'text' type questions
  required: boolean;
};

type QuestionHeaderWithoutIdT = Omit<QuestionHeaderT, "id">;


// Define types for various question components
type ChoicesQuestionT = ComponentProps<typeof ChoicesQuestion>["question"];
type SliderQuestionT = ComponentProps<typeof SliderQuestion>["question"];
type RatingQuestionT = ComponentProps<typeof RatingQuestion>["question"];
type InputQuestionT = ComponentProps<typeof Input>["question"];
type TextDisplayT = ComponentProps<typeof TextDisplay>["question"];

type QuestionDetailsT =
  | ({ type: "radio" } & ChoicesQuestionT)
  | ({ type: "slider" } & SliderQuestionT)
  | ({ type: "rating" } & RatingQuestionT)
  | ({ type: "input" } & InputQuestionT) ;
 

type QuestionT = (QuestionHeaderT & QuestionDetailsT) | (QuestionHeaderWithoutIdT & ({ type: "text" } & TextDisplayT)) ;

type QuestionTypesT = QuestionT["type"];

const questionSelector: { label: string; value: QuestionTypesT }[] = [
  { label: "Text", value: "text" },
  { label: "Input", value: "input" },
  { label: "Choices", value: "radio" },
  { label: "Slider", value: "slider" },
  { label: "Rating", value: "rating" },
];

const Question = ({
  updateItem,
  question: _question,
}: {
  question?: QuestionT;
  updateItem: (arg0: QuestionT) => void;
}) => {
  //make sure required set to false initially otherwise blocks of text become required and you can't get past those 'questions' in the form screen
  const { title, id, required, ..._questionDetails } = _question
    ? _question
    : { title: "title", id: "id here", required: false, type: undefined };

    
  const type = _questionDetails.type;
  const [questionType, setQuestionType] = useState<QuestionTypesT | undefined>(
    type
  );
  const [questionHeader, setQuestionHeader] = useState<QuestionHeaderT>({
    title,
    id,
    required,
  });
  const [questionDetails, setQuestionDetails] = useState<
    QuestionDetailsT | undefined | ({ type: "text" } & TextDisplayT)
  >(type !== undefined ? _questionDetails : undefined);

  useEffect(() => {
    if (questionHeader && questionDetails) {
      updateItem({
        ...questionHeader,
        ...questionDetails,
      });
    }
  }, [questionHeader, questionDetails, type]);

  useEffect(() => {
    if (questionType && (questionType === "text" || questionType === "input")) {
      setQuestionDetails({ type: questionType });
    }
  }, [questionType]);

  return (
    <>
        <Form.TextField
          value={questionHeader?.title}
          label={"Question title"}
          onChange={(val) =>
            setQuestionHeader((prev) => ({ ...prev, title: val }))
          }
          validate={(data) => {
            if (!data) {
              return {
                isSuccess: false,
                error: "Please enter some text",
              };
            }
            if (data.length < 3 || data.length > 150) {
              return {
                isSuccess: false,
                error: "Text must be more than 3 characters",
              };
            }
            return {
              isSuccess: true,
              data,
            };
          }}
        />
      


      {/* question id is not required to build forms and as this isn't entered on historic forms I have taken out the validation AC 01/08/2023 */}
      {/* Question id - conditionally render based on question type - not necessary for 'text' type questions */}    
      {questionType !== "text" && (<Form.TextField
        value={questionHeader?.id}
        label={"Question id"}
        onChange={(val) => setQuestionHeader((prev) => ({ ...prev, id: val }))}
        // validate={(data) => {
        //   if (!data) {
        //     return {
        //       isSuccess: false,
        //       error: "id is required",
        //     };
        //   }
        //   if (data.length < 1 || data.length > 100) {
        //     return {
        //       isSuccess: false,
        //       error: "id must be at least 1 character",
        //     };
        //   }
        //   return {
        //     isSuccess: true,
        //     data,
        //   };
        // }}
      />)}

      <Form.SwitchField
        value={questionHeader.required}
        onChange={(val) =>
          setQuestionHeader((prev) => ({ ...prev, required: val }))
        }
        label="Required"
      />

      <View style={{ paddingTop: "10" }}>
        <Form.DropdownField
          label="Option Type"
          placeholder="Option Type"
          onChange={(val) => {
            setQuestionType(val);
            setQuestionDetails(undefined);
          }}
          data={questionSelector}
          value={questionType}
          validate={(data) =>
            data !== undefined
              ? { isSuccess: true, data }
              : { isSuccess: false, error: "Option type is required" }
          }
        />
      </View>

      {questionType === "text" &&
      (questionDetails === undefined || questionDetails.type === "text") ? (
        <Form.SubForm name="text">
          <TextDisplay
            updateItem={(data) => setQuestionDetails({ ...data, type: "text" })}
            question={questionDetails}
          />
        </Form.SubForm>
      ) : null}

      {questionType === "input" &&
      (questionDetails === undefined || questionDetails.type === "input") ? (
        <Form.SubForm name="input">
          <Input
            updateItem={(data) =>
              setQuestionDetails({ ...data, type: "input" })
            }
            question={questionDetails}
          />
        </Form.SubForm>
      ) : null}

      {questionType === "slider" &&
      (questionDetails === undefined || questionDetails.type === "slider") ? (
        <Form.SubForm name="slider">
          <SliderQuestion
            updateItem={(data) =>
              setQuestionDetails({ ...data, type: "slider" })
            }
            question={questionDetails}
          />
        </Form.SubForm>
      ) : null}

      {questionType === "rating" &&
      (questionDetails === undefined || questionDetails.type === "rating") ? (
        <Form.SubForm name="rating">
          <RatingQuestion
            updateItem={(data) =>
              setQuestionDetails({ ...data, type: "rating" })
            }
            question={questionDetails}
          />
        </Form.SubForm>
      ) : null}

      {questionType === "radio" &&
      (questionDetails === undefined || questionDetails.type === "radio") ? (
        <Form.SubForm name="radio">
          <ChoicesQuestion
            updateItem={(data) =>
              setQuestionDetails({ ...data, type: "radio" })
            }
            question={questionDetails}
          />
        </Form.SubForm>
      ) : null}

    </>
  );
};

export default Question;
