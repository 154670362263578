// Inspired by https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/#handling-default-as-attributes
// This is an example implementation of a polymorphic react component. being polymorphic doesn't serve it much flexibility, it's
//   tied to FlatList because of it's props, but i'm keeping it as an example.

import React from "react";
import { FlatList, ActivityIndicator, Platform } from "react-native";

import Empty from "../../../components/Empty";

import { PolymorphicComponentPropWithRef, PolymorphicRef } from "./index";
import { isWeb } from "react-native-reanimated/lib/types/lib/reanimated2/PlatformChecker";

/**
 * This is the updated component props using PolymorphicComponentPropWithRef
 */
type ListProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    loading?: boolean;
    ListEmptyComponent?: React.ComponentPropsWithRef<C>["ListEmptyComponent"];
    ListFooterComponent?: React.ComponentPropsWithRef<C>["ListFooterComponent"];
    style?: React.ComponentPropsWithRef<C>["style"];
  }
>;

type ListType = <C extends typeof FlatList>(
  props: ListProps<C>
) => React.ReactElement | null;

const PolymorphicExampleList: ListType = React.forwardRef(
  <C extends typeof FlatList = typeof FlatList>(
    {
      as,
      loading = false,

      ListEmptyComponent = (
        <Empty
          title="Nothing is here yet..."
          description=""
        />
      ),
      ListFooterComponent = <ActivityIndicator size="large" />,
      style,
      ...props
    }: ListProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || FlatList;

    const isWeb = typeof window !== "undefined";

    return (
      <Component
        ListFooterComponentStyle={{ paddingVertical: 10 }}
        ListFooterComponent={loading ? ListFooterComponent : undefined}
        ListEmptyComponent={!loading ? ListEmptyComponent : undefined}
        {...props}
        style={[
          {
            flexGrow: 1,
            borderBottomColor: "#c6c6c8",
            borderBottomWidth: 1,
            maxHeight: Platform.OS !== 'web' ? undefined: "100%",
          },
          style,
        ]}
        ref={ref}
      />
    );
  }
);

export default PolymorphicExampleList;
