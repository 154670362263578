import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import {
  Box,
  Button,
  ErrorMessage,
  MainScreen,
  Touch,
  Modal,
  CenteredSpinner,
  NewButton,
  ButtonOutline,
} from "../../../components";
import { IOWNAError, testId, logEvent } from "../../../utils";
import ShareContentIcon from "../../../assets/icons/share_content.svg";
import ArrowForwardIcon from "../../../assets/icons/arrow-forward.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import ContactListGroup from "../../../assets/icons/manage_contacts.svg";
import ContentIcon from "../../../assets/icons/content.svg";
import BundleIcon from "../../../assets/icons/bundles.svg";
import AddNew from "../../../assets/icons/new.svg";
import { useNavigation, useRoute } from "@react-navigation/native";
import CreateContactModal from "../../../new/components/contacts/Create";
import { useQuery } from "react-query";
import DatePickerModal from "../SendPlanNavigator/Components/DatePickerModal";

import {
  createBulkNotification,
  getSingleDistributionContact,
  createDistributionContact,
  updateDistributionContact,
  sendContentToDistributionList,
  addContentBundleToContactList,
} from "../../../graphql";
import {
  useAtom,
  bundleAtom,
  setBundle,
  currentOrganisationAtom,
  isWebAtom,
  confirmAsync,
  currentUserIdAtom,
  selectedTagAtom,
  setTag,
  selectedContactAtom,
  showContactsModalAtom,
  setShowContactsModal,
  isUserContactVerifiedAtom,
} from "../../../atoms";

function NavigationLink({ name, icon, navigateTo, navigateParams, onPress }) {
  const navigation = useNavigation();
  const route = useRoute();
  return (
    <TouchableOpacity
      style={{
        marginLeft: 15,
        marginRight: 15,
      }}
      onPress={() => {
        onPress();
        navigation.navigate(navigateTo, {
          selectionOriginatingScreen: route.name,
          previousScreen: route.name,
          ...navigateParams,
        });
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          padding: 14,
          borderRadius: 35,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <View style={{ marginRight: 10 }}>{icon}</View>
          <Text style={{ fontSize: 20, fontWeight: "500", color: "#004E7A" }}>
            {name}
          </Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <ArrowForwardIcon width={28} height={28} color="#9B9B9B" />
        </View>
      </View>
    </TouchableOpacity>
  );
}

const ContentLink = ({
  name,
  icon,
  navigateTo,
  borderBottomWidth,
  sendType,
  setSendType,
}) => {
  const navigation = useNavigation();
  const web = useAtom(isWebAtom);
  const pad = web ? 20 : 14;

  return (
    <Touch
      marginLeft={15}
      marginRight={15}
      borderBottomColor="#D3D3D3"
      borderBottomWidth={borderBottomWidth}
      onPress={() => {
        navigation.navigate(navigateTo, {
          needToSelect: true,

          canSetAdvice: true,
          displayAs: "mobile",
        });
        setSendType = { sendType };
        setBundle({
          id: null,
          name: null,
          items: [],
        });
      }}
    >
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        backgroundColor="#FFFFFF"
        padding={pad}
        borderRadius={35}
      >
        <Box flex={1} flexDirection="row" alignItems="center">
          <Box marginRight={10}>{icon}</Box>
          <Text style={{ fontSize: 20, fontWeight: "500", color: "#004E7A" }}>
            {name}
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <ArrowForwardIcon width={28} height={28} color="#9B9B9B" />
        </Box>
      </Box>
    </Touch>
  );
};

const RecipientComponentItem = ({ item }) => {
  const navigation = useNavigation();
  const route = useRoute();
  // on the item delete we check if the object has a phone number - if it does, we know it's a contact
  // if it doesn't, we know it's a distribution list

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 15,
          backgroundColor: "#FFFFFF",
          padding: 14,
          borderBottomColor: "#dcd7d6",
          borderBottomWidth: 1,
        }}
      >
        <Text
          style={{
            fontSize: 20,
            fontWeight: "500",
            color: "#004E7A",
            width: "45%",
            textOverflow: "ellipsis",
          }}
          numberOfLines={1}
        >
          {item.name}
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: "55%",
            justifyContent: "flex-end",
          }}
        >
          <Text
            style={{
              fontSize: 20,
              fontWeight: "500",
              color: "#004E7A",
            }}
          >
            {item.phone}
          </Text>
          <View style={{ width: "12%", marginLeft: 5 }}>
            <AntDesign
              name="closecircle"
              size={20}
              color="#909090"
              onPress={() => {
                if (item.phone == undefined) {
                  navigation.setParams({
                    selectedDistributionLists:
                      route.params.selectedDistributionLists.filter(
                        (i) => i.id !== item.id
                      ),
                  });
                } else {
                  navigation.setParams({
                    selectedContacts: route.params.selectedContacts.filter(
                      (i) => i.id !== item.id
                    ),
                  });
                }
              }}
              style={{
                alignSelf: "flex-end",
              }}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const RecipientComponent = ({ clearRecipientError, currentOrganisationID }) => {
  const web = useAtom(isWebAtom);
  const pad = web ? 20 : 14;

  const route = useRoute();

  const selectedContacts = route.params?.selectedContacts
    ? route.params?.selectedContacts
    : [];
  const selectedDistributionLists = route.params?.selectedDistributionLists
    ? route.params?.selectedDistributionLists
    : [];

  const maxlimit = 11;

  const renderItem = (props) => (
    <RecipientComponentItem
      currentOrganisationID={currentOrganisationID}
      {...props}
    />
  );

  return (
    <View>
      <NavigationLink
        name="Contact"
        icon={<ProfileIcon width={28} height={28} color="#004D7A" />}
        navigateTo="SelectContactScreen"
        navigateParams={{
          view: "selecting",
          selectedContacts: selectedContacts,
        }}
        onPress={clearRecipientError}
      />

      <NavigationLink
        name="List"
        icon={<ContactListGroup width={32} height={32} color="#004D7A" />}
        navigateTo="SelectListScreen"
        navigateParams={{
          view: "selecting",
          selectedDistributionLists: selectedDistributionLists,
        }}
        onPress={clearRecipientError}
      />

      <Touch
        marginLeft={15}
        marginRight={15}
        borderBottomColor="#D3D3D3"
        borderBottomWidth={0}
        onPress={() => {
          setShowContactsModal(true);
        }}
      >
        <Box
          flex={1}
          flexDirection="row"
          alignItems="center"
          backgroundColor="#FFFFFF"
          padding={pad}
          borderRadius={35}
        >
          <Box flex={1} flexDirection="row" alignItems="center">
            <Box marginRight={10}>
              <AddNew width={28} height={28} color="#004D7A" />
            </Box>
            <Text style={{ fontSize: 20, fontWeight: "500", color: "#004E7A" }}>
              New
            </Text>
          </Box>
          <Box flexDirection="row" alignItems="center">
            <ArrowForwardIcon width={28} height={28} color="#9B9B9B" />
          </Box>
        </Box>
      </Touch>

      {selectedContacts.length > 0 ? (
        <FlatList
          data={selectedContacts}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        ></FlatList>
      ) : null}

      {selectedDistributionLists.length > 0 ? (
        <FlatList
          data={selectedDistributionLists}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        ></FlatList>
      ) : null}
    </View>
  );
};

const ContentComponent = ({ clearContentError }) => {
  const web = useAtom(isWebAtom);
  const pad = web ? 20 : 14;

  const route = useRoute();
  const navigation = useNavigation();

  const contents = route.params?.contents ? route.params?.contents : [];

  return (
    <View>
      <NavigationLink
        name="Content"
        icon={<ContentIcon width={28} height={28} color="#004D7A" />}
        navigateTo="ContentScreen"
        navigateParams={{
          view: "selecting",
          selected: contents,

          needToSelect: true,
          canSetAdvice: true,
          displayAs: "mobile",
        }}
        onPress={clearContentError}
      />

      <NavigationLink
        name="Bundle"
        icon={<BundleIcon width={32} height={32} color="#004D7A" />}
        navigateTo="SelectBundleScreen"
        navigateParams={{
          view: "selecting",
          selected: contents,

          needToSelect: true,
          canSetAdvice: true,
          displayAs: "mobile",
        }}
        onPress={clearContentError}
      />

      <View>
        {contents.map((item) => (
          <Box
            flex={1}
            flexDirection="row"
            alignItems="center"
            backgroundColor="#FFFFFF"
            padding={pad}
            justifyContent="space-between"
            key={item.id}
            marginLeft={15}
            marginRight={15}
            borderBottomColor="#dcd7d6"
            borderBottomWidth={1}
          >
            <Text style={{ fontSize: 15, color: "rgb(56, 47, 45)" }}>
              {item.displayName}
            </Text>

            <AntDesign
              name="closecircle"
              size={20}
              color="#909090"
              marginRight={5}
              onPress={() => {
                navigation.setParams({
                  contents: contents.filter((i) => i.id !== item.id),
                });
              }}
            />
          </Box>
        ))}
      </View>
    </View>
  );
};

const SendPlanScreen = ({ navigation, route }) => {
  const [submitting, setSubmitting] = useState(false);
  const web = useAtom(isWebAtom);
  const showContactsModal = useAtom(showContactsModalAtom);
  const [contentError, setContentError] = useState(null);
  const [recipientError, setRecipientError] = useState(null);
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const currentOrganisationID = currentOrganisationAtom.getValue().id;
  const [sendLater, setSendLater] = useState(false);

  const contents = route?.params?.contents ? route.params.contents : [];

  const selectedContacts = route.params?.selectedContacts
    ? route.params.selectedContacts
    : [];
  const selectedDistributionLists = route.params?.selectedDistributionLists
    ? route.params.selectedDistributionLists
    : [];

  const [contacts, setContacts] = useState(false);

  useEffect(() => {
    if (selectedContacts.length == 0 && selectedDistributionLists.length == 0) {
      setContacts(false);
    } else {
      setContacts(true);
    }
  }, [selectedContacts, selectedDistributionLists]);

  const bundle = useAtom(bundleAtom);

  if (bundle.items.length > 0) {
    bundle.items.map((item) => {
      if (!contents.find((i) => i.id === item.id)) {
        contents.push(item);
      }
    });
    setBundle({
      id: null,
      name: null,
      items: [],
    });
  }

  const contentList = contents;

  const onSubmit = async (sendAt) => {
    let sendDateTime = "";
    if (sendAt) {
      sendDateTime = sendAt.toLocaleString();
    }

    if (contents.length == 0) {
      setContentError("Please select either content or a bundle");
    }
    if (!contacts) {
      setRecipientError("Please select a contact or a list");
    }

    let newContactArray = [...selectedContacts, ...selectedDistributionLists];
    if (contents.length > 0 && contacts) {
      for (let i = 0; i < newContactArray.length; i++) {
        for (let j = 0; j < contents.length; j++) {
          const contact = newContactArray[i];
          const content = contents[j];

          await addContentBundleToContactList({
            input: {
              contactListId: contact.id,
              contentBundleId: content.id,
              sendAt: sendAt,
            },
          });
        }
      }

      // if (!res.data.createBulkNotification.accepted) {
      //   const failure = res.data.createBulkNotification.failures?.find(
      //     (item) => item.message
      //   );
      //   if (failure && failure.message) {
      //     if (failure.message.includes("message send limit")) {
      //       throw new IOWNAError(
      //         "You have reached maximum send limit for your organisation. "
      //       );
      //     }
      //     if (
      //       failure.message.includes(
      //         "Phone number provided is not a valid type"
      //       )
      //     ) {
      //       throw new Error("Phone number is not a valid mobile number");
      //     }
      //   } else {
      //     throw new Error("Phone number is not a valid mobile number");
      //   }
      // }
      logEvent("send_content_contact");
      const adviceWithForm = contents.find((item) => item.form);
      if (adviceWithForm) {
        logEvent("form_sent", {
          id: adviceWithForm.id,
        });
      }

      navigation.setParams({
        selectedContacts: [],
        selectedDistributionLists: [],
        contents: [],
      });
      navigation.navigate("ContentSentScreen", {
        bundle: "Content",
        number: "all numbers",
        contentList: contentList,
        sendDateTime: sendDateTime ? sendDateTime : null,
        name: newContactArray.length == 1 ? newContactArray[0].name : null,
      });
    }
  };

  const _onSubmitBlocked = (sendAt) => {
    // this piece of code defines what message shows when the user clicks the send button
    if (sendAt !== undefined) {
      setSendLater(true);
    }
    const onSubmitCalled = onSubmit(sendAt);
    setSubmitting(true);
    onSubmitCalled.then(() => {
      setSendLater(false);
      setSubmitting(false);
    });
    onSubmitCalled.catch(() => {
      setSubmitting(false);
      setSendLater(false);
    });
    return onSubmitCalled;
  };

  return (
    <MainScreen
      backgroundColor={web ? "#F2F2F7" : "#FFFFFF"}
      style={{
        flex: 1,
        borderColor: "rgb(177, 179, 181)",
        borderWidth: web ? 1 : 0,
        margin: web ? 40 : 0,
      }}
    >
      <View>
        <Text
          style={{
            fontSize: 18,
            fontWeight: "600",
            marginLeft: 25,
            marginTop: 22,
            marginBottom: 17,
          }}
        >
          Select Recipient
        </Text>
      </View>

      <RecipientComponent
        clearRecipientError={() => setRecipientError(null)}
        currentOrganisationID={currentOrganisationID}
      />

      {recipientError ? (
        <ErrorMessage err={recipientError}></ErrorMessage>
      ) : null}
      <View>
        <Text
          style={{
            fontSize: 18,
            fontWeight: "600",
            marginLeft: 25,
            marginTop: 22,
            marginBottom: 17,
          }}
        >
          Select Information
        </Text>
      </View>

      <ContentComponent clearContentError={() => setContentError(null)} />

      {!contentError ? <ErrorMessage err={contentError}></ErrorMessage> : null}

      <View style={{ height: 30 }}></View>
      {submitting ? (
        <View style={{ alignItems: "center", marginTop: 20 }}>
          <ActivityIndicator size="large" color="#004E7A" />
          <Text style={{ marginTop: 10 }}>
            {sendLater
              ? "Your content is being queued to send later"
              : "Content sending"}
          </Text>
        </View>
      ) : (
        <>
          <NewButton
            onPress={() => {
              _onSubmitBlocked();
              return new Promise(function (resolve, reject) {
                // Setting 2000 ms time
                setTimeout(resolve, 2000);
              });
            }}
            title={"Send"}
            type={"primary"}
            icon={<ShareContentIcon style={{ color: "#FFFFFF" }} />}
          />
          <DatePickerModal
            web={web}
            onSubmit={(date) => {
              _onSubmitBlocked(date);
            }}
          />
        </>
      )}

      {showContactsModal && (
        <Modal
          isVisible={showContactsModal}
          title={"Create a new Contact"}
          onClose={() => {
            setShowContactsModal(false);
          }}
          style={{ maxWidth: 400 }}
        >
          <CreateContactModal
            onSave={(item) => {
              navigation.setParams({
                selectedContacts: [
                  item,
                  ...route?.params?.selectedContacts.filter(
                    (i) => i.id !== item.id
                  ),
                ],
              });
            }}
          />
        </Modal>
      )}
    </MainScreen>
  );
};

export default SendPlanScreen;
