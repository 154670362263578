import { IOWNAError, sanitizeFilename } from "../../utils";
import { putSignedUrl } from "../../graphql";

interface Response {
  fileName: string;
}

const getPresignedUrl = async (file: any, allOrg: boolean | null): Promise<Response> => {
  if (!file) {
    throw new IOWNAError("You need to select a file");
  }
  if (file.size > 268435456) {
    throw new IOWNAError("You can only upload files under 250MB.");
  }
  if (allOrg === null) {
    throw new IOWNAError(
      "You need to select who should be able to view this content"
    );
  }
  const filename = sanitizeFilename(file.name);
  const ext = filename.split(".").pop();
  if (!["pdf", "mp4"].includes(ext)) {
    throw new IOWNAError("You can only upload a PDF or MP4 file");
  }
  const contentType = ext === "pdf" ? "application/pdf" : "video/mp4";
  await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(
        0,
        ext === "mp4" ? 12 : 4
      );
      let header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (ext === "pdf" && header !== "25504446") {
        reject(new IOWNAError("You can only upload a valid PDF file"));
      }
      const validFileSigs = [
        "000206674797069736f6d", // video/mp4
        "00020667479704d534e56", // video/mp4
        "00018667479706d703432", // video/mp4
        "00020667479706d703432", // video/m4v
        "00020667479704d345620", // video/m4v
        "000206674797071742020", // video/mov
        "000206D6F6F76" //video/mov
      ];
      if (ext === "mp4" && !validFileSigs.includes(header)) {
        reject(new IOWNAError("You can only upload a valid MP4 file"));
      }
      resolve();
    };
    fileReader.readAsArrayBuffer(file);
  });
  const res = await putSignedUrl({
    input: {
      filename: filename,
      // This is always true as the user has to accept the statement before uploading
      // We are in this case replacing.
      acceptedCopyrightStatement: true,
      contentType
    }
  });
  const putSignedRes = res.data.putSignedUrl;
  const metadata = JSON.parse(putSignedRes.metadata);
  await fetch(putSignedRes.url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": contentType,
      "Content-Disposition": `attachment; filename="${filename}"`,
      "x-amz-meta-acceptedcopyrightstatementat":
        metadata["acceptedCopyrightStatementAt"],
      "x-amz-meta-filename": metadata["filename"],
      "x-amz-meta-uploadedby": metadata["uploadedBy"]
    }
  });

  return { fileName: putSignedRes.key.slice(1) };
};

export default getPresignedUrl;