import React, { useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { useTheme } from "@emotion/react"
import { Controller } from 'react-hook-form';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { Ionicons } from '@expo/vector-icons';
import ErrorMessage from './ErrorMessage';
import ContextMenu from './ContentMenu';
import { testId } from '../utils';

export const Select = ({ modal, items, onChange, value }) => {
  const title = value ? items.find((item) => item.value === value)?.label : '';
  const theme = useTheme();
  const [width, setWidth] = useState(200);
  const style = {
    flexDirection: 'row',
    height: 30,
    marginTop: 10,
    borderBottomColor: theme.textInputBorderBottom,
    borderBottomWidth: 1,
    color: theme.textInputText,
  };
  return (
    <Menu renderer={ContextMenu} rendererProps={{ modal, width: width }}>
      <MenuTrigger>
        <View style={style} onLayout={(event) => setWidth(event.nativeEvent.layout.width)}>
          <Text style={{ flex: 1, fontSize: 16, color: title === 'Select' ? theme.textPlaceholder : 'black' }}>{title}</Text>
          <Ionicons name="ios-arrow-down" size={24} color="rgb(12, 82, 125)" style={{ marginRight: 10, marginLeft: 10 }} />
        </View>
      </MenuTrigger>
      <MenuOptions>
        <ScrollView style={{ maxHeight: 300 }} persistentScrollbar={true}>
          {items.map((item) => {
            const isSelected = item.value === value;
            const selectedStyle = item.value === value ? { backgroundColor: 'rgb(222, 252, 255)' } : {};
            return (
              <MenuOption key={item.value} onSelect={() => onChange(item.value)} style={[{ flexDirection: 'row' }, selectedStyle]}>
                <Text {...testId(`${item.label}`)} style={{ flex: 1, fontSize: 15, padding: 5 }}>{item.label}</Text>
                {isSelected ? <Ionicons name="ios-checkmark" size={24} color={'#2776E5'} style={{ marginRight: 10, marginLeft: 10 }} /> : null}
              </MenuOption>
            );
          })}
        </ScrollView>
      </MenuOptions>
    </Menu>
  );
};

export default function PickerInput({ name, modal = false, errors, control, items }) {
  return (
    <View {...testId('titleDropdown')}>
      <Controller
        control={control}
        name={name}
        render={({field: {onChange, value}}) => (
          <Select
            value={value}
            modal={modal}
            items={items}
            onChange={onChange}
          />
        )}
      />
      <ErrorMessage name={name} errors={errors} />
    </View>
  );
}
