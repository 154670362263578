import { useState, useMemo } from "react";

const useAList = <T>(initial: T[]) => {
  const [itemList, setItemList] = useState<{ key: number; data: T }[]>(
    initial ? initial.map((data, key) => ({ data, key })) : []
  );


  const [, setNewKey] = useState<number>(initial ? initial.length : 0);
  const withNewKey = (fn: (key: number) => void) => {
    setNewKey((newKey) => {
      fn(newKey);
      return newKey + 1;
    });
  };

  const deleteItem = (idx: number) => {
    setItemList((itemList) => itemList.filter((_, _idx) => _idx !== idx));
  };

  const moveItemUp = (idx: number) => {
    idx === 0
      ? console.log("up on first index")
      : setItemList((current) => moveDown(current, idx - 1));
  };

  const moveItemDown = (idx: number) => {
    console.log("index", idx);
    idx === itemList.length - 1
      ? console.log("down on last idx")
      : setItemList((current) => moveDown(current, idx));
  };

  const moveDown = <T>(a: T[], idx: number) => [
    ...a.slice(0, idx),
    a[idx + 1],
    a[idx],
    ...a.slice(idx + 2, a.length),
  ];

  const updateItem = (idx: number, data: T) => {
    setItemList((itemList) =>
      itemList.map((item, _idx) => (_idx == idx ? { ...item, data } : item))
    );
  };

  const items = useMemo(() => itemList.map((item) => item.data), [itemList]);

  return {
    render: <RT>(
      fn: ({
        item,
        idx,
        key,
        updateItem,
        deleteItem,
        moveItemUp,
        moveItemDown,
      }: {
        item: T;
        idx: number;
        key: number;
        updateItem: (arg0: T) => void;
        deleteItem: () => void;
        moveItemUp: () => void;
        moveItemDown: () => void;
      }) => RT
    ) =>
      itemList.map((item, idx) =>
        fn({
          idx,
          key: item.key,
          item: item.data,
          updateItem: (item: T) => updateItem(idx, item),
          deleteItem: () => deleteItem(idx),
          moveItemUp: () => moveItemUp(idx),
          moveItemDown: () => moveItemDown(idx),
        })
      ),
    addItem: (data: T) => {
      withNewKey((key) =>
        setItemList((itemList) => [
          ...itemList,
          {
            key,
            data,
          },
        ])
      );
    },
    length: itemList.length,
    items,
  };
};

export default useAList;
