import { useEffect, useState } from "react";

type MaybePromiseT<T> = T | Promise<T>;

const useValidateField = <ValidT, InvalidT>({
  onValid,
  validate,
  value: initialValue,
}: {
  onValid: (value: ValidT) => void;
  validate: (data: ValidT | InvalidT) => MaybePromiseT<
    | {
        isSuccess: true;
        data: ValidT;
      }
    | {
        isSuccess: false;
        error: string;
      }
  >;
  value: ValidT | InvalidT;
}) => {
  const [error, setError] = useState<string>();
  const [value, setValue] = useState<ValidT | InvalidT>(initialValue);
  useEffect(() => {
    const result = validate(value);
    Promise.resolve(result).then((result) => {
      if (result.isSuccess) {
        setError(undefined);
        onValid(result.data);
      } else {
        setError(result.error);
      }
    });
  }, [value]);

  return {
    onChange: (value: ValidT | InvalidT) => {
      setValue(value);
    },
    error,
    value,
  };
};

export default useValidateField;
