import React from "react";
import { Text as RNText } from "react-native";

export default function Text({ testID = "", children, ...props }) {
  return (
    <RNText
      testID={testID}
      numberOfLines={1}
      style={{
        fontSize: 25,
        flex: 1,
        color: "#004D7A",
        fontWeight: "500",
        ...props,
      }}
    >
      {children}
    </RNText>
  );
}
