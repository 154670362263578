import React, { useRef } from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { alert } from '../atoms';

const NetworkAlert = () => {
  const { isConnected } = useNetInfo({
    reachabilityLongTimeout: 300 * 1000, // 300s
    reachabilityShortTimeout: 300 * 1000, // 300s
    reachabilityRequestTimeout: 30 * 1000, // 30s
  });
  const wasConnected = useRef(false);
  if (wasConnected.current && !isConnected) {
    alert('Alert', 'You are not connected to the Internet. Please check your network settings');
  }
  wasConnected.current = !!isConnected;
  return null;
};

export default NetworkAlert;
