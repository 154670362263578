import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { HeaderButton } from "../../../components";
import { screen } from "../../../reactUtils";

import {setPdfId} from "../../../atoms";
import List from "../../../new/components/List";
import SelectListItem from "../../../new/components/activity/SelectListItem";
import { useNavigation } from '@react-navigation/native';


export default function ({ route }) {
  const { item } = route.params;
  const [view, setView] = useState("recipients");

  const data = item.activity.items;
  const content = item.content;
  const navigation = useNavigation();
  

  const renderItem = ({ item }) => {
    
    return(
    <SelectListItem item={item} onPress={() =>  {
      setPdfId(content.id);
      navigation.navigate("PdfScreen", {
        id: content.id,
        contentId: item.submission ? item.submission.id : null,
        consumer: true
      })}}/>
  

  )}

  return (
    <>
      <View style={styles.dashboardContainer}>
      <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
        <Text style={styles.contentTitle}>{item.content.displayName}</Text>
     
        </View>
        <View style={styles.tabContainer}>
          {/* <Text
            style={{ ...styles.barTextTitle, borderWidth: view === "dashboard" ? 1 : 0, borderColor: "lightgrey", borderTopLeftRadius: view === "dashboard" ? 10 : 0, borderTopRightRadius: 10, borderBottomColor: "white" }}
            onPress={() => setView("dashboard")}
          >
            Dashboard
          </Text> */}

          <Text
            style={{
              ...styles.barTextTitle, borderWidth: view === "recipients" ? 1 : 0, borderColor: "lightgrey", borderTopLeftRadius: view === "recipients" ? 10 : 0, borderTopRightRadius: 10, borderBottomColor: "white", 
            }}
            onPress={() => setView("recipients")}
          >
            Recipients
          </Text>
        </View>
        <View style={{ borderWidth: 0.5, borderColor: "lightgrey" }}></View>


        {view === "recipients" ? (
          <>
          <View>
            <List
             
              hideSearch={true}
              loading={false}
              flatListParams={{
                data: data,
                renderItem,
                onEndReached: () => {},
              }}
            />
            </View>
          </>
        ) : (
          <View>
          <View style={styles.dataContainer}>
            <VictoryContainer title="Bar Chart">
              <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={{ x: 15 }}
              >
                <VictoryBar data={pieData} barRatio={0.8} />
              </VictoryChart>
            </VictoryContainer>
          </View>
          </View>
        )}
      </View>
    </>
  );
}

//possible dashboard view
{
  /* <View style={styles.dashboardContainer}>
        <Text style={styles.contentTitle}>{item.content.displayName}</Text>
        <View style={styles.tabContainer}>
          <Text
            style={styles.barTextTitle}
            onPress={() => setView("dashboard")}
          >
            Dashboard
          </Text>
          <Text
            style={styles.barTextTitle}
            onPress={() => setView("recipients")}
          >
            Recipients
          </Text>
        </View>
        <View style={{ borderWidth: 0.5, borderColor: "lightgrey" }}></View>

        {view === "recipients" ? (
          <>
            <ContactList
              data={data}
              // loadMore={loadMore}
              // hasMore={hasNextPage}
              // loading={loading}
              renderItem={renderItem}
            />
          </>
        ) : (
          <View style={styles.dataContainer}>
            <VictoryContainer title="Bar Chart">
              <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={{ x: 15 }}
              >
                <VictoryBar data={pieData} barRatio={0.8} />
              </VictoryChart>
            </VictoryContainer>
          </View>
        )}
      </View> */
}

const styles = StyleSheet.create({
  dashboardContainer: {
    margin: 20,
    
    height: "100%",
    borderRadius: 10,
    backgroundColor: "white"
  },
  contentTitle: {
    fontSize: 30,
    fontWeight: "bold",
    margin: 10,
    color: "rgb(0, 78, 122)",
  },
  tabContainer: {
    
    backgroundColor: "white",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
  },
  barTextTitle: {
    fontSize: 20,
    fontWeight: "bold",
    
    color: "rgb(0, 78, 122)",
    padding: 5
  },
  dataContainer: {
    flexDirection: "row",
    width: "25%",
    justifyContent: "space-between",
    margin: 30,
    padding: 10,
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
  },
});
