import type { ComponentProps } from "react";
import { RadioInput } from "./Input";
import FieldWithValidation from "./FieldWithValidation";
import type { FieldWithValidationPropsT } from "./FieldWithValidation";

type InputT<T> = ComponentProps<typeof RadioInput<T>>;

const RadioField = <T,>({
  selections,
  value,
  onChange,
  label,
  validate,
}: InputT<T> & Omit<FieldWithValidationPropsT<T, undefined>, "render">) => {
  const _RadioInput = (
    props: Omit<ComponentProps<typeof RadioInput<T>>, "selections">
  ) => <RadioInput<T> {...props} selections={selections} />;

  return (
    <FieldWithValidation
      label={label}
      onChange={onChange}
      value={value}
      render={_RadioInput}
      validate={validate}
    />
  );
};

export default RadioField;
