import React, { useEffect, useState, useRef } from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { groupBy, sortBy, orderBy, omitBy, isEmpty } from "lodash";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import {
  Empty,
  Box,
  FilterByCategory,
  Accordion,
  CardSeperator,
  Modal,
  PatientUpload,
  AccordionButton,
} from "../../../components";
import ContentItem from "../../../new/components/library/ContentItem";
import {
  setPdfId,
  useAtom,
  isWebAtom,
  usePromise,
  atom,
  confirm,
  currentUserAtom,
} from "../../../atoms";
import { containerSmall } from "../../../reactUtils";
import {
  ALL_PILLARS,
  handleError,
  formatCalender,
  formatDate,
  getThumbnailUrl,
  onlySpecialities,
  testId,
} from "../../../utils";
import { options, optionsBack } from "../../../headerUtils";
import List from "../../../new/components/List";
import { useInfiniteQuery, useQueryClient, useMutation } from "react-query";
import {
  getUserContents,
  contentConnection,
  deleteContent,
} from "../../../graphql";

export const showFilterMenuAtom = atom(false);
export const setShowFilterMenu = (v) => showFilterMenuAtom.update(v);

export const filterByAtom = atom(null);
export const setFilterBy = (v) => filterByAtom.update(v);

export const showFiltersAtom = atom(false);
export const setShowFilters = (v) => showFiltersAtom.update(v);

//@ts-check 
const filterMenu = [
  {
    text: "Date",
    value: "date",
    sort: (results, sortMode) => orderBy(results, "createdAt", sortMode),
    sortKey: "createdAt",
    group: (results) =>
      groupBy(results, (item) => formatDate(item.createdAt, "dd MMM yyyy")),
  },
  {
    text: "Clinician",
    value: "clinician",
    sort: (results, sortMode) =>
      orderBy(
        results.filter((i) => i.__typename !== "Content"),
        "sentByUser.lastName",
        sortMode
      ),
    sortKey: (item) => item["title"].toLowerCase(),
    group: (results) =>
      groupBy(
        results,
        (item) =>
          `${item.sentByUser?.title} ${item.sentByUser?.firstName} ${item.sentByUser?.lastName}`
      ),
  },
  {
    text: "Form",
    value: "form",
    sort: (results, sortMode) =>
      orderBy(
        results.filter((r) => r.__typename === "UserContent"),
        "staticAdvice.displayName",
        sortMode
      ),
    sortKey: "staticAdvice.displayName",
    group: (results) =>
      groupBy(
        results.filter((i) => !!i.staticAdvice.form),
        "staticAdvice.displayName"
      ),
  },
  {
    text: "Conditions",
    value: "tags",
    sort: (results, sortMode) =>
      orderBy(
        results,
        (item) => {
          if (!item.staticAdvice) return null;
          const specs = onlySpecialities(item.staticAdvice.tags);
          if (specs.length === 0) {
            return "General Medicine";
          } else {
            return specs[0];
          }
        },
        sortMode
      ),
    group: (results) => {
      const map = { "General Medicine": [] };
      for (const item of results.filter((i) => !!i.staticAdvice)) {
        const specs = onlySpecialities(item.staticAdvice.tags);
        if (specs.length === 0) {
          map["General Medicine"].push(item);
          continue;
        }
        const pillars = [];
        for (const [key, values] of Object.entries(ALL_PILLARS)) {
          for (const spec of values) {
            if (specs.includes(spec) && !pillars.includes(key)) {
              if (!map[key]) {
                map[key] = [];
              }
              map[key].push(item);
              break;
            }
          }
        }
      }
      // want to sort the specialty list for conditions alphabetically
      const notSorted = omitBy(map, isEmpty);
      const sorted = Object.keys(notSorted)
        .sort()
        .reduce(function (acc, key) {
          acc[key] = notSorted[key];
          return acc;
        }, {});
      return sorted;
    },
  },
  {
    text: "My health records",
    value: "uploadedByMe",
    sort: (results, sortMode) => orderBy(results, "createdAt", sortMode),
    group: (results) => {
      const map = {};
      for (const item of results) {
        const tags = item.tags;
        const contentTags = [];

        // we iterate through the keys for ALL_PILLARS to create this list because we want to exclude any content tags which are content Types (e.g clinic letter/prescription)

        for (const key of Object.keys(ALL_PILLARS)) {
          if (tags.includes(key) && !contentTags.includes(key)) {
            if (!map[key]) {
              map[key] = [];
            }
            map[key].push(item);
          }
        }
      }

      // want to sort conditions alphabetically
      const notSorted = omitBy(map, isEmpty);
      const sorted = Object.keys(notSorted)
        .sort()
        .reduce(function (acc, key) {
          acc[key] = notSorted[key];
          return acc;
        }, {});
      return sorted;
    },
  },
];

const AccordionContentCards = ({ title, items, navigation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentItemId, setContentItemId] = useState(null);

  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    ({ id }) => {
      return deleteContent({
        input: {
          id: id,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contentConnection"]);
      },
      onError: (err) => {
        const message = err.errors?.[0]?.message;
        if (message) {
          alert(message);
          // throw new Error(message);
        } else {
          alert(JSON.stringify(err));
          // throw new Error(JSON.stringify(err));
        }
      },
    }
  );
  return (
    <>
      <Accordion
        showArrow={true}
        open={isOpen}
        expandIcon={<AntDesign name="pluscircleo" size={16} color="#004e7a" />}
        collapseIcon={
          <AntDesign name="minuscircleo" size={16} color="#004e7a" />
        }
        onPress={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
        title={
          <Box
            {...testId(`${title}`)}
            flex={1}
            flexDirection="row"
            paddingHorizontal={20}
            paddingVertical={10}
          >
            <Text style={{ fontSize: 14, color: "#004e7a" }}>
              {title == "Undefined undefined undefined" ? "iOWNA" : title}
            </Text>
          </Box>
        }
        titleStyles={{ backgroundColor: "#efefef" }}
        bodyStyles={{ backgroundColor: "#ffffff" }}
      >
        {items.map((item, index) => {
          return (
            <ContentItem
              key={item.id}
              item={item}
              navigation={navigation}
              isOpen={contentItemId === item.id}
              onOpen={(item) =>
                setContentItemId((prevSetting) =>
                  prevSetting === item.id ? null : item.id
                )
              }
              onDelete={() => deleteMutation.mutate(item)}
            />
          );
        })}
      </Accordion>
      <CardSeperator spacing={0} />
    </>
  );
};

const LibraryScreen = ({ navigation, route }) => {
  const user = useAtom(currentUserAtom);
  const [openItemId, setOpenItemId] = useState(null);

  const web = useAtom(isWebAtom);

  const filterBy = useAtom(filterByAtom);
  const showFilters = useAtom(showFiltersAtom);

  const showModal = route.params?.patientUpload;
  const sortMode = route.params?.sortMode || "desc";

  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState("");
  const userContentApiFn = (props) => getUserContents(props);
  const userContentPathFn = (result) => result.data.getUser.content;
  const {
    data: userContentPageData,
    fetchNextPage: userContentFetchNextPage,
    // hasNextPage: userContentHasNextPage,
    isFetchingNextPage: userContentIsFetchingNextPage,
    isLoading: _userContentIsLoading,
  } = useInfiniteQuery(
    ["userContents"],
    ({ pageParam }) => userContentApiFn(pageParam).then(userContentPathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );
  const userContentIsLoading =
    _userContentIsLoading || userContentIsFetchingNextPage;
  const _userContentData = userContentPageData
    ? userContentPageData.pages.flatMap((x) => x.items)
    : [];
  const userContentData = _userContentData.filter((item) => item.staticAdvice);

  const contentConnectionApiFn = (props) => contentConnection(props);
  const contentConnectionPathFn = (result) => result.data.contentConnection;
  const {
    data: contentConnectionPageData,
    fetchNextPage: contentConnectionFetchNextPage,
    // hasNextPage: contentConnectionHasNextPage,
    isFetchingNextPage: contentConnectionIsFetchingNextPage,
    isLoading: _contentConnectionIsLoading,
  } = useInfiniteQuery(
    ["contentConnection"],
    ({ pageParam }) =>
      contentConnectionApiFn({
        order: sortMode,
        ...pageParam,
      }).then(contentConnectionPathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const contentConnectionIsLoading =
    _contentConnectionIsLoading || contentConnectionIsFetchingNextPage;
  const contentConnectionData = contentConnectionPageData
    ? contentConnectionPageData.pages.flatMap((x) => x.items)
    : [];

  // Sort order is managed both here and in the api query until content is all moved over
  // be careful to switch it
  const _contents =
    filterBy?.value !== "uploadedByMe"
      ? contentConnectionData.concat(userContentData)
      : contentConnectionData;

  const __contents = filterBy?.sort
    ? filterBy?.sort(_contents, sortMode)
    : orderBy(_contents, "createdAt", sortMode);

  const ___contents = searchText
    ? __contents.filter((item) =>
        searchText
          .toLowerCase()
          .split(/\s+/)
          .every((searchFacet) =>
            [
              ...(!!item.name
                ? item.name?.toLowerCase().split(/\s+/)
                : item.staticAdvice?.displayName?.toLowerCase().split(/\s+/)),
            ].find((itemFacet) => itemFacet.includes(searchFacet))
          )
      )
    : __contents;

  const contents = filterBy?.group
    ? Object.entries(filterBy.group(___contents)).map(([k, v]) => ({
        title: k,
        contents: v,
        id: k,
      }))
    : ___contents;

  const contentIsLoading = userContentIsLoading || contentConnectionIsLoading;


  const fetchNextPage = () => {
    if (sortMode === "desc") {
      contentConnectionFetchNextPage();
      userContentFetchNextPage();
    } else {
      contentConnectionFetchNextPage();
      userContentFetchNextPage();
    }
  };

  const deleteMutation = useMutation(
    ({ id }) => {
      return deleteContent({
        input: {
          id: id,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contentConnection"]);
      },
      onError: (err) => {
        const message = err.errors?.[0]?.message;
        if (message) {
          alert(message);
          // throw new Error(message);
        } else {
          alert(JSON.stringify(err));
          // throw new Error(JSON.stringify(err));
        }
      },
    }
  );

  // __typename 'content' is patient uploaded material. __typename 'userContent' is content uploaded by clinician or organisation.
  {
    /* const renderItem = ({ item }) => {
    if (filterBy?.group) {
      return (
        <AccordionContentCards
          title={
            item.title.charAt(0).toUpperCase() +
            item.title.slice(1).toLowerCase()
          }
          items={item.contents}
          navigation={navigation}
        />
      );
    }
    return (
      <Box>
        {item.__typename === "Content" ? (
          <>
            <Accordion
              open={item.id === accordionOpenId}
              onPress={() =>
                setAccordionOpenId((currentId) =>
                  currentId !== item.id ? item.id : null
                )
              }
              title={
                <View style={{ flex: 1 }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        border: 1,
                        backgroundColor: "#ffffff",

                        marginHorizontal: web ? 20 : 10,
                        marginVertical: 10,
                        flex: web ? 1 : 2,
                      }}
                    > */
  }

  {
    /*  //downloadURL changed to point to inlineUrl so on click on thumbnail you open the item inline
     */
  }

  {
    /*                       
                      <Thumbnail
                        downloadUrl={item.inlineUrl}
                        thumbnailUrl={item.thumbnailUrl}
                      />
                    </View>

                    <View style={{ flex: 5, flexDirection: "row" }}>
                      <View
                        style={{
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",

                          flex: web ? 8 : 3,
                        }}
                      >
                        <View
                          style={{
                            justifyContent: "space-between",
                            margin: 10,
                          }}
                        >
                          {user ? (
                            <Text style={{ fontSize: 14 }}>
                              {user.firstName} {user.lastName}
                            </Text>
                          ) : (
                            <Text>you</Text>
                          )}
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "500",
                              marginVertical: 5,
                            }}
                          >
                            {item.name}
                          </Text>
                          <Text style={{ fontSize: 14 }}>
                            {item.createdAt
                              ? formatCalender(item.createdAt)
                              : ""}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              }
            >
              <AccordionButton
                items={[
                  {
                    title: "View",
                    onPress: () => {
                      navigation.navigate("SimpleContentViewScreen", {
                        uri: item.inlineUrl,
                        item: item,
                      });
                    },
                  },
                  {
                    title: "Delete",
                    onPress: confirm(
                      "Delete content",
                      `Are you sure you want to delete the content "${item.name}"?`,
                      deleteMutation.mutate(item, () => {
                        queryClient.invalidateQueries(["contents"]);
                      })
                    ),
                  },
                ]}
              />
            </Accordion>
          </>
        ) : (
          <Accordion
            open={item.id === accordionOpenId}
            onPress={() =>
              setAccordionOpenId((currentId) =>
                currentId !== item.id ? item.id : null
              )
            }
            title={
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      border: 1,
                      backgroundColor: "#ffffff",

                      marginHorizontal: web ? 20 : 10,
                      marginVertical: 10,
                      flex: web ? 1 : 2,
                    }}
                  >
                    <Thumbnail
                      downloadUrl={item.staticAdvice.inlineUrl}
                      thumbnailUrl={item.staticAdvice.thumbnailUrl}
                    />
                  </View>
                  <View style={{ flex: 5, flexDirection: "row" }}>
                    <View
                      style={{
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",

                        flex: web ? 8 : 3,
                      }}
                    >
                      <View
                        style={{ justifyContent: "space-between", margin: 10 }}
                      >
                        <Text
                          style={{ fontSize: 14 }}
                        >{`${item.organisation.name}`}</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            marginVertical: 5,
                          }}
                        >
                          {item.staticAdvice.displayName}
                        </Text>
                        <Text style={{ fontSize: 14 }}>
                          {item.createdAt ? formatCalender(item.createdAt) : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            }
          >
            <AccordionButton
              items={[
                {
                  title: "View",
                  onPress: () => {
                    if (item.staticAdvice.state === "archived") {
                      navigation.navigate("ContentExpiredScreen", {
                        contentName: item.staticAdvice.displayName,
                      });
                    } else {
                      setPdfId(item.staticAdviceId);
                      navigation.navigate("PdfScreen", {
                        id: item.staticAdviceId,
                        contentId: item.id,
                      });
                    }
                  },
                },
              ]}
            ></AccordionButton>
          </Accordion>
        )}
        <CardSeperator />
      </Box>
    );
  }; */
  }

  const renderItem = (props) => {
    if (filterBy?.group) {
      return (
        <AccordionContentCards
          title={
            props.item.title.charAt(0).toUpperCase() +
            props.item.title.slice(1).toLowerCase()
          }
          items={props.item.contents}
          navigation={navigation}
        />
      );
    }

    return (
      <ContentItem
        item={props.item}
        isOpen={openItemId === props.item.id}
        onOpen={(item) =>
          setOpenItemId((prevSetting) =>
            prevSetting === item.id ? null : item.id
          )
        }
        onDelete={() => deleteMutation.mutate(props.item)}
        {...props}
      />
    );
  };

  return (
    <>
      {showFilters ? (
        <FilterByCategory
          filterMenu={filterMenu}
          selected={filterBy}
          setSelected={setFilterBy}
          onApply={() => setShowFilters((showFilters) => !showFilters)}
        />
      ) : (
        <List
          setSearchText={setSearchText}
          flatListParams={{
            // data: userContentData,
            data: contents,

            renderItem,
            onEndReached: () => fetchNextPage(),
          }}
          loading={contentIsLoading}
          onEndReachedThreshold={0.5}
          ListEmptyComponent={<Empty title="Nothing is here yet..."></Empty>}
        />
      )}
      {showModal && (
        <Modal
          isVisible={showModal}
          title={"Upload Content"}
          style={{ maxWidth: 400 }}
          onClose={() => {
            queryClient.invalidateQueries(["contentConnection"]);
            navigation.setParams({ patientUpload: false });
          }}
        >
          <PatientUpload />
        </Modal>
      )}
    </>
  );
};

export const LibraryOptions = () => options("Library");

export const LibraryOptionsBack = (web) => optionsBack(web, "Library");

export default LibraryScreen;
