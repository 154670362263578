import React, { Fragment } from 'react';
import { View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Box, TextInput, ErrorMessage, AuthScreen, Text } from '../../components';
import { Field, FieldLabel, Footer, BackButton, Button } from './common';
import { signUp, verifyRecaptcha, IOWNAError, testId } from '../../utils';
import { useForm } from '../../hooks';
import { configAtom, useAtom, isWebAtom } from '../../atoms';
import { screen } from '../../reactUtils';

const SignUpScreen = ({ route, navigation }) => {
  const { email, isClinician } = route.params || {};
  const web = useAtom(isWebAtom);
  const { control, isSubmitting, onSubmit, error, errors } = useForm({
    initial: {
      signupEmail: email,
    },
    schema: (yup) => {
      const schema = {
        signupEmail: yup.string().required('Please enter your email').email('This is not a valid email'),
        signupPassword: yup.string().required('No password provided'),
        passwordConfirmation: yup
          .string()
          .required('Re-enter your password again')
          .oneOf([yup.ref('signupPassword')], 'Passwords must match'),
      };
      if (email) {
        schema.confirmEmail = yup
          .string()
          .required('Confirm your email')
          .email('This is not a valid email')
          .oneOf([yup.ref('signupEmail')], 'Emails must match');
      }
      if (isClinician) {
        schema.organisationName = yup.string().required('Please enter your organisation Name');
      }
      return schema;
    },
    onSubmit: async ({ signupEmail, signupPassword, organisationName }) => {
      // if (web) {
      //   if (!executeRecaptcha) {
      //     console.log('Execute recaptcha not yet available');
      //     return;
      //   }
      //   const token = await executeRecaptcha();
      //   const response = await verifyRecaptcha({ token });
      //   if (!response) {
      //     throw new IOWNAError('It seems like you are a robot. Please refresh the page and try again.');
      //   }
      // }
      await signUp({ email: signupEmail.toLowerCase(), password: signupPassword });
      if (organisationName) {
        await AsyncStorage.setItem('newOrganisationName', organisationName);
      }
      navigation.replace('ConfirmSignUpScreen', { email: signupEmail.toLowerCase() });
    },
  });
  return (
    <AuthScreen>
      <Box alignItems="center" justifyContent="center" marginBottom={10}>
        <Text color="white" fontSize={20}>
          Create a new account
        </Text>
      </Box>
      {isClinician ? (
        <Field>
          <FieldLabel>Organisation Name</FieldLabel>
          <TextInput name="organisationName" control={control} errors={errors} />
        </Field>
      ) : null}
      <Field>
        <FieldLabel>EMAIL</FieldLabel>
        <TextInput
          name="signupEmail"
          type="email-address"
          help={true}
          renderHelp={
            <Fragment>
              <View style={{ flexDirection: 'column', maxWidth: 300 }}>
                <Text fontSize={12} padding={3}>
                  If you have been invited to use iOWNA, you will have been granted access to an organisation. In order to provide this access, we
                  need you to register with the email address used to invite you.
                </Text>
                <Text fontSize={12} padding={3}>
                  If you would like to, use a different email please contact heretohelp@iowna.com
                </Text>
              </View>
            </Fragment>
          }
          control={control}
          errors={errors}
          readOnly={!!email}
        />
      </Field>
      {email ? (
        <Field>
          <FieldLabel>CONFIRM EMAIL</FieldLabel>
          <TextInput name="confirmEmail" type="email-address" control={control} errors={errors} />
        </Field>
      ) : null}
      <Field>
        <FieldLabel>PASSWORD</FieldLabel>
        <TextInput name="signupPassword" secure={true} help={true} errors={errors} control={control} />
      </Field>
      <Field>
        <FieldLabel>CONFIRM PASSWORD</FieldLabel>
        <TextInput name="passwordConfirmation" secure={true} errors={errors} control={control} />
      </Field>
      <ErrorMessage err={error} style={{ marginTop: 40, fontSize: 18, fontWeight: '500' }} />
      <Button
        marginTop={40}
        title={isClinician ? 'Start 3 month trial' : 'Create Account'}
        loading={isSubmitting}
        onPress={onSubmit}
        {...testId('buttonSignUp')}
      />
      <Footer style={{ marginTop: 40 }}>
        <BackButton onPress={() => (navigation.canGoBack() ? navigation.goBack() : navigation.replace('SelectScreen'))} />
      </Footer>
    </AuthScreen>
  );
};

const SignUpScreenWrapped = screen((props) => {
  const web = useAtom(isWebAtom);
  const config = useAtom(configAtom);
  return <SignUpScreen {...props} />;
});

export default SignUpScreenWrapped;
