import type { ReactNode } from "react";
import Field from "./Field";
import WithError from "./WithError";

const FieldWithError = ({
  label,
  input,
  error,
}: {
  label: string;
  input: ReactNode;
  error?: string;
}) => {
  return (
    <WithError error={error}>
      <Field label={label} input={input} />
    </WithError>
  );
};

export default FieldWithError;
