import React from "react";
import { View, Dimensions } from "react-native";
import styled from "@emotion/native";
import { useTheme } from "@emotion/react";
import Spinner from "./Spinner";

const ButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  height: 29;
`;

const TouchableOpacity = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  height: 29;
`;

const Text = styled.Text`
  color: ${(props) => props.theme.primary};
`;

export default ({
  loading = false,
  disabled = false,
  width = Math.min(Dimensions.get("window").width - 80, 123),
  borderRadius = 25,
  color,
  backgroundColor,
  fontSize = 15,
  onPress,
  border,
  borderWidth,
  children,
  ...props
}) => {
  const { buttonBackground, buttonBackgroundDisabled } = useTheme();
  const style = {
    width: width,
    borderRadius: borderRadius,
    borderColor: "#004D7A",
    borderWidth: 2,
    // backgroundColor: disabled ? backgroundColor || buttonBackgroundDisabled : backgroundColor || buttonBackground
  };
  const textStyle = {};
  if (color) {
    textStyle.color = color;
  }
  if (fontSize) {
    textStyle.fontSize = fontSize;
  }
  return (
    <ButtonContainer>
      {loading ? (
        <View style={{ width: width }}>
          <Spinner />
        </View>
      ) : (
        <TouchableOpacity
          style={style}
          disabled={disabled}
          onPress={onPress}
          {...props}
        >
          <Text style={textStyle}>{children}</Text>
        </TouchableOpacity>
      )}
    </ButtonContainer>
  );
};
