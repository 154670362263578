import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Box, Touch, SortButton } from "../../../components";
import { options } from "../../../headerUtils";
import TodoScreen, {
  sortModeAtom,
  setSortMode,
  showFiltersAtom,
  setShowFilters,
} from "./TodoScreen";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import VerificationSuccessfulScreen from "./VerificationSuccessfulScreen";
import { useAtom, isWebAtom } from "../../../atoms";
import FilterIcon from "../../../assets/icons/filter.svg";

const Header = () => {
  const sortMode = useAtom(sortModeAtom);
  const showFilters = useAtom(showFiltersAtom);
  return (
    <Box flexDirection="row" alignItems="center">
      <SortButton
        activeMode={sortMode}
        onPress={() =>
          setSortMode(sortModeAtom.getValue() === "asc" ? "desc" : "asc")
        }
      />
      <Touch
        paddingHorizontal={20}
        onPress={() => setShowFilters(!showFilters)}
      >
        <FilterIcon width={20} height={20} color="#004E7A" />
      </Touch>
    </Box>
  );
};

const Stack = createStackNavigator();
export default ({ route: { params } }) => {
  const web = useAtom(isWebAtom);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TodoScreen"
        component={TodoScreen}
        initialParams={params}
        options={options("To Do", () => (
          <Header />
        ))}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
      <Stack.Screen
        name="VerificationSuccessfulScreen"
        component={VerificationSuccessfulScreen}
      />
    </Stack.Navigator>
  );
};
