export default {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    version: {
      type: 'string',
    },
    title: {
      type: 'string',
    },
    subTitle: {
      type: 'string',
    },
    pages: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          show: {
            type: 'string',
          },
          questions: {
            type: 'array',
            items: {
              type: 'object',
              oneOf: [
                {
                  properties: {
                    type: {
                      type: 'string',
                      const: 'text',
                    },
                    title: {
                      type: 'string',
                    },
                    fontSize: {
                      type: 'string',
                      enum: ['large', 'normal'],
                    },
                    required: {
                      type: 'boolean',
                      default: false,
                    },
                    subTitle: {
                      oneOf: [
                        {
                          type: 'array',
                          items: {
                            type: 'string',
                          },
                        },
                        {
                          type: 'string',
                        },
                      ],
                    },
                    align: {
                      type: 'string',
                      enum: ['left'],
                    },
                  },
                  required: ['type', 'title', 'fontSize'],
                  additionalProperties: false,
                },
                {
                  properties: {
                    type: {
                      type: 'string',
                      const: 'radio',
                    },
                    id: {
                      type: 'string',
                    },
                    title: {
                      type: 'string',
                    },
                    shortName: {
                      type: 'string',
                    },
                    multi: {
                      type: 'boolean',
                    },
                    itemType: {
                      type: 'string',
                      enum: ['radio', 'checkbox'],
                      default: 'radio',
                    },
                    choices: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          id: {
                            type: ['number', 'string'],
                          },
                          name: {
                            type: 'string',
                          },
                        },
                      },
                    },
                    required: {
                      type: 'boolean',
                      default: false,
                    },
                    subTitle: {
                      oneOf: [
                        {
                          type: 'array',
                          items: {
                            type: 'string',
                          },
                        },
                        {
                          type: 'string',
                        },
                      ],
                    },
                  },
                  required: ['id', 'type', 'title', 'choices'],
                  additionalProperties: false,
                },
                {
                  properties: {
                    id: {
                      type: 'string',
                    },
                    title: {
                      type: 'string',
                    },
                    shortName: {
                      type: 'string',
                    },
                    type: {
                      type: 'string',
                      const: 'input',
                    },
                    inputType: {
                      type: 'string',
                      enum: ['textbox', 'date', 'numeric'],
                    },
                    required: {
                      type: 'boolean',
                      default: false,
                    },
                  },
                  required: ['id', 'type', 'title'],
                  additionalProperties: false,
                },
                {
                  properties: {
                    type: {
                      type: 'string',
                      const: 'slider',
                    },
                    id: {
                      type: 'string',
                    },
                    title: {
                      type: 'string',
                    },
                    shortName: {
                      type: 'string',
                    },
                    orientation: {
                      type: 'string',
                      enum: ['horizontal', 'vertical'],
                      default: 'horizontal',
                    },
                    ranges: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          from: {
                            type: 'number',
                          },
                          to: {
                            type: 'number',
                          },
                          color: {
                            type: 'string',
                          },
                          smiley: {
                            type: 'string',
                          },
                          toLabel: {
                            type: 'string',
                          },
                          fromLabel: {
                            type: 'string',
                          },
                        },
                        required: ['from', 'to', 'color', 'smiley'],
                      },
                    },
                    required: {
                      type: 'boolean',
                      default: false,
                    },
                    subTitle: {
                      oneOf: [
                        {
                          type: 'array',
                          items: {
                            type: 'string',
                          },
                        },
                        {
                          type: 'string',
                        },
                      ],
                    },
                  },
                  required: ['id', 'type', 'title', 'ranges'],
                  additionalProperties: false,
                },
                {
                  properties: {
                    type: {
                      type: 'string',
                      const: 'rating',
                    },
                    id: {
                      type: 'string',
                    },
                    title: {
                      type: 'string',
                    },
                    shortName: {
                      type: 'string',
                    },
                    scale: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          value: {
                            type: 'number',
                          },
                          topLabel: {
                            type: 'string',
                          },
                          bottomLabel: {
                            type: 'string',
                          },
                        },
                        required: ['value'],
                      },
                    },
                    required: {
                      type: 'boolean',
                      default: false,
                    },
                    subTitle: {
                      oneOf: [
                        {
                          type: 'array',
                          items: {
                            type: 'string',
                          },
                        },
                        {
                          type: 'string',
                        },
                      ],
                    },
                  },
                  required: ['id', 'type', 'title', 'scale'],
                  additionalProperties: false,
                },
              ],
            },
          },
          buttonRight: {
            type: 'string',
          },
        },
        additionalProperties: false,
        required: ['name', 'questions'],
      },
    },
    results: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
        required: ['label', 'value'],
      },
    },
  },
  additionalProperties: false,
  required: ['id', 'version', 'title', 'subTitle', 'pages'],
};
