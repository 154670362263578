import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Platform,
  Animated,
} from "react-native";
import { differenceInMilliseconds } from "date-fns";
import { MainScreen } from "../../components";
import { useGlobal } from "../../hooks";
import { changeOrg } from "../../utils";
import IconLogo from "../../assets/images/logo.svg";
import {
  organisationsTransformedAtom,
  useAtom,
  setCurrentOrganisation,
  isWebAtom,
} from "../../atoms";
import { screen } from "../../reactUtils";

export default screen(({ navigation }) => {
  const [, updateData] = useGlobal();
  const web = useAtom(isWebAtom);
  const organisations = useAtom(organisationsTransformedAtom);
  const onPress = (item) => {

    setCurrentOrganisation(item);
    changeOrg(updateData, item);
    navigation.navigate("HomeNavigator", {});
  };

  const isLicenseExpired = (item) => {
    if (item.licenses.items.length === 0) {
      return false;
    }
    const activeLicense = item.licenses.items.find((license) => license.active);
    if (activeLicense) {
      return activeLicense.expiresOn
        ? differenceInMilliseconds(
            new Date(activeLicense.expiresOn),
            new Date()
          ) < 0
        : false;
    }
    return true;
  };

  return (
    <MainScreen
      statusMargin={true}
      backgroundColor="white"
      style={{ flex: 1, alignItems: "center" }}
      isList={true}
    >
      <View style={{ alignItems: "center", marginBottom: 20 }}>
        <IconLogo width={180} height={web ? 180 : 80} />
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: web ? 10 : 0,
          }}
        >
          <Text
            style={{
              fontSize: web ? 20 : 18,
              fontWeight: "500",
              color: "rgb(57, 63, 70)",
            }}
          >
            Please select an Organisation
          </Text>
        </View>
      </View>
      <FlatList
        data={organisations}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          const isExpired = isLicenseExpired(item);
          return (
            <TouchableOpacity
              style={{
                padding: 20,
                margin: web ? 20 : 10,
                backgroundColor: isExpired ? "#AEB1B5" : "#004E7A",
                cursor: isExpired ? "not-allowed" : "pointer",
              }}
              activeOpacity={1}
              onPress={() => {
                if (!isExpired) {
                  onPress(item);
                }
              }}
              testID={`Choose Organisation`}
              accessibilityLabel={Platform.select({
                android: `Choose Organisation`,
              })}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "500",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {item.name}
              </Text>
              {isExpired ? (
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "500",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  (Expired)
                </Text>
              ) : null}
            </TouchableOpacity>
          );
        }}
      />
    </MainScreen>
  );
});
