import React, { useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Button, TextInput, AuthScreen, ErrorMessage, SnackBar } from '../../components';
import { Title, Text, Field, FieldLabel, ButtonContainer, Footer, FooterLabel, FooterValue } from './common';
import { confirmSignUp, resendSignUp } from '../../utils';
import { useForm } from '../../hooks';

export default ({ route, navigation }) => {
  const { email } = route.params;
  const [showResent, setShowResent] = useState(false);
  const { control, isSubmitting, onSubmit, error, errors } = useForm({
    schema: (yup) => ({
      code: yup.string().required('Please enter the code sent to your email'),
    }),
    onSubmit: async ({ code }) => {
      await confirmSignUp({ email: email.toLowerCase(), code: ('' + code).trim() });
      navigation.replace('SignInScreen', { isSignout: true, email: email.toLowerCase() });
    },
  });
  return (
    <AuthScreen>
      <Title>
        <Text>A confirmation code was sent to your email. Please use that to complete your sign up.</Text>
      </Title>
      <Field>
        <FieldLabel>CODE</FieldLabel>
        <TextInput
          name="code"
          type="numeric"
          control={control}
          errors={errors}
          accessibilityLabel={Platform.select({ android: 'TextInput_code' })}
          testID={'TextInput_code'}
          hasNumbersOnly
        />
      </Field>
      <ErrorMessage err={error} />
      <ButtonContainer>
        <Button loading={isSubmitting} onPress={onSubmit}>
          CONFIRM
        </Button>
      </ButtonContainer>
      <Footer style={{ marginTop: 30 }}>
        <FooterLabel>DIDN'T RECEIVE A CODE</FooterLabel>
        <TouchableOpacity
          onPress={() => {
            resendSignUp({ email });
            setShowResent(true);
          }}
        >
          <FooterValue>RESEND</FooterValue>
        </TouchableOpacity>
        <FooterLabel></FooterLabel>
        <TouchableOpacity onPress={navigation.goBack}>
          <FooterValue>BACK</FooterValue>
        </TouchableOpacity>
      </Footer>
      <SnackBar
        visible={showResent}
        message={`Confirmation Code resent to ${email}`}
        onPress={() => {
          setShowResent(false);
        }}
      />
    </AuthScreen>
  );
};
