import React from "react";
import { View, Text, StatusBar, Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ThemeProvider } from "@emotion/react";
import includes from "lodash/includes";
import CookieBanner from "../CookieBanner";
import BaseScreen from "./BaseScreen";
import { MainTheme } from "../../themes";
import Spinner from "../Spinner";
import Empty from "../Empty";

const MainScreen = ({
                      backgroundColor,
                      statusMargin = false,
                      style = {},
                      hasAuth = true,
                      loading = false,
                      error = null,
                      isList = false,
                      scrollEnabled = true,
                      children
                    }) => {
  const Container = isList ? View : KeyboardAwareScrollView;
  const ContainerProps = isList ? { style: { flex: 1 } } : {};
  const statusStyle = statusMargin ? { marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 } : {};
  return (
    <ThemeProvider theme={MainTheme}>
      <BaseScreen style={{
        zIndex: -1,
        backgroundColor: backgroundColor || MainTheme.background, ...statusStyle,
      }}>
        <Container extraHeight={200} contentContainerStyle={{ flexGrow: 1 }} {...ContainerProps}
                   scrollEnabled={scrollEnabled}>
          {!hasAuth ? (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
              <Empty title="Unauthorized" description="You don't have the right permissions to access this page" />
            </View>
          ) : loading ? (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
              <Spinner />
            </View>
          ) : error ? (
            typeof error === "string" && includes(error.toLowerCase(), "network") ? (
              <Empty title="You are not connected to the Internet" description="Please check your network settings" />
            ) : (
              <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <Text testID={"error_global"} style={{ fontSize: 22, color: "#FF3e50" }}>
                  {error}
                </Text>
              </View>
            )
          ) : (
            <View style={{ flex: 1, ...style }}>{children}</View>
          )}
        </Container>
        <CookieBanner />
      </BaseScreen>
    </ThemeProvider>
  );
};

export default MainScreen;
