import React, { useState, useContext, useEffect, useMemo } from "react";
import { Keyboard } from "react-native";
import { useForm as useHookForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useImmer } from "use-immer";
import * as yup from "yup";
import { get } from "lodash";
import Sentry from "../sentry";
import { getErrorMessage, handleError, IOWNAError } from "./utils";
import {
  search,
  getDistributionTagContacts,
  getOrganisationDistributionTags,
  getOrganisationUserRoles,
  getOrganisationRoleInvites,
  getOrganisationBundles,
  listOrganisation,
  listAdvice,
  getOrganisationDistributionContacts,
  getUserContents,
} from "./graphql";
import {
  currentOrganisationAtom,
  currentUserSystemRolesAtom,
  useAtom,
  alert,
} from "./atoms";
import debounce from "lodash.debounce";


export function useForm({ initial, schema, showAlert = false, onSubmit }) {
  const [error, setError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    trigger,
    watch,
    setValue,
  } = useHookForm({
    defaultValues: initial,
    resolver: yupResolver(yup.object().shape(schema(yup))),
  });
  return {
    control: control,
    isSubmitting: isSubmitting,
    error: error,
    errors: errors,
    watch: watch,
    setValue: setValue,
    reset: reset,
    onSubmit: async (e) => {
      setError(null);
      setSubmitting(true);
      try {
        await trigger();
        if (Object.keys(errors).length > 0) {
          return;
        }
        await handleSubmit(onSubmit)(e);
        Keyboard.dismiss();
        reset();
      } catch (err) {
        const msg = getErrorMessage(err);
        if (showAlert && msg) {
          alert("Error", msg);
          return;
        }
        if (msg === "") {
          Sentry.withScope((scope) => {
            scope.setExtras({ error: JSON.stringify(err), err });
            Sentry.captureException(err);
          });
          setError("Oops something went wrong");
        } else {
          setError(msg);
        }
      } finally {
        setSubmitting(false);
      }
    },
  };
}

const initialState = {
  roleInvites: { items: [] },
  userRoles: { items: [] },
  distributionContacts: { items: [] },
  distributionTags: { items: [] },
  distributionTagContacts: { items: [] },
  allOrganisations: { items: [] },
  advices: { items: [] },
};
const GlobalState = React.createContext();
export const useGlobal = () => useContext(GlobalState);
export const GlobalProvider = ({ initialValue = initialState, children }) => {
  const [data, updateData] = useImmer(initialValue);
  return (
    <GlobalState.Provider value={[data, updateData]}>
      {children}
    </GlobalState.Provider>
  );
};

export const useLocalStorage = (key, initial) => {
  const [data, setData] = useState(initial);
  useEffect(() => {
    AsyncStorage.getItem(key).then((value) =>
      setData(JSON.parse(value || "null"))
    );
  }, []);
  return [
    data,
    (value) => {
      AsyncStorage.setItem(key, JSON.stringify(value));
      setData(value);
    },
  ];
};

export const useHasAnyRoles = (roles) => {
  const systemRoles = useAtom(currentUserSystemRolesAtom);
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const currentRoles = currentOrganisation
    ? systemRoles.concat(currentOrganisation.roles)
    : systemRoles;
  for (const role of roles) {
    for (const currentRole of currentRoles) {
      if (currentRole === role) {
        return true;
      }
    }
  }
  return false;
};

export const useHasOnlyRole = (role) => {
  const currentOrganisation = useAtom(currentOrganisationAtom);
  if (currentOrganisation?.roles?.length === 1) {
    return currentOrganisation.roles[0] === role;
  }
  return false;
};

export const useQuery = (storeKey, apiResKey, gqlOp, variables) => {
  const [data, updateData] = useGlobal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reset, setReset] = useState(false);

  const setList = (items) => {
    updateData((draft) => {
      draft[storeKey] = items;
    });
  };
  useEffect(() => {
    setLoading(true);
    gqlOp(variables)
      .then(({ data: res }) => {
        const response = get(res, apiResKey);
        if (variables.nextToken) {
          setList({
            ...response,
            items: [...data[storeKey].items, ...response.items],
          });
        } else {
          setList(response);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(getErrorMessage(err));
        setLoading(false);
      });
  }, [JSON.stringify(variables), reset]);
  return [
    data[storeKey] || [],
    setList,
    loading,
    error,
    () => setReset(!reset),
  ];
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useOrganisationQuery = (
  storeKey,
  apiResKey,
  gqlOp,
  variables,
  orgIdKey = "id"
) => {
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const orgId = currentOrganisation ? currentOrganisation.id : "";
  return useQuery(storeKey, apiResKey, gqlOp, {
    ...variables,
    [orgIdKey]: orgId,
  });
};

export const useDistributionTags = (variables = {}) =>
  useOrganisationQuery(
    "distributionTags",
    "getOrganisation.distributionTags",
    getOrganisationDistributionTags,
    variables
  );

export const useDistributionTagContacts = (variables = {}) =>
  useQuery(
    "distributionTagContacts",
    "distributionTag.contacts",
    getDistributionTagContacts,
    variables
  );

export const useUserRoles = (variables = {}) =>
  useOrganisationQuery(
    "userRoles",
    "getOrganisation.userRoles",
    getOrganisationUserRoles,
    variables
  );

export const useDistributionContacts = (variables = {}) =>
  useOrganisationQuery(
    "distributionContacts",
    "getOrganisation.distributionContacts",
    getOrganisationDistributionContacts,
    variables
  );

export const useOrganisationBundle = (variables = {}) =>
  useOrganisationQuery(
    "bundles",
    "getOrganisation.bundles",
    getOrganisationBundles,
    variables
  );

export const useRoleInvites = (variables = {}) =>
  useOrganisationQuery(
    "roleInvites",
    "getOrganisation.roleInvites",
    getOrganisationRoleInvites,
    variables
  );

export const useListOrganisation = (variables = {}) =>
  useQuery("allOrganisations", "listOrganisation", listOrganisation, variables);

export const useListAdvice = (variables = {}) =>
  useOrganisationQuery(
    "advices",
    "listAdvice",
    listAdvice,
    variables,
    "organisationId"
  );

export const useUserContents = (variables = {}) =>
  useQuery("userContents", "getUser.content", getUserContents, variables);

export function usePagination(data, itemsPerPage, nextToken, reset) {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  useEffect(() => {
    const totalPage = nextToken
      ? Math.ceil(data.length / itemsPerPage) + 1
      : Math.ceil(data.length / itemsPerPage);
    if (totalPage > 0) {
      setMaxPage(totalPage);
      if (totalPage < currentPage) {
        setCurrentPage(nextToken ? totalPage - 1 : totalPage);
      }
    } else {
      setMaxPage(0);
    }
  }, [data.length, nextToken, itemsPerPage, reset]);
  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  };
  const next = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  };
  const prev = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };
  return {
    next,
    prev,
    currentData,
    currentPage,
    setCurrentPage,
    maxPage,
    total: data.length,
  };
}

export const useSearch = ({
  organisationId,
  resultFields,
  resultMap,
  search,
  searchKey,
}) => {
  const [searchState, setSearchState] = useState({
    phrase: "",
    requestedPhrase: "",
    data: [],
  });

  const [data, setData] = useState([]);
  useEffect(() => setData(searchState.data), [searchState]);

  const [debouncedRequestedSearchPhrase, _setDebouncedRequestedSearchPhrase] =
    useState("");

  const setDebouncedRequestedSearchPhrase = useMemo(() =>
    debounce(_setDebouncedRequestedSearchPhrase, 500)
  );

  useEffect(() => {
    return () => {
      setDebouncedRequestedSearchPhrase.cancel();
    };
  }, [setDebouncedRequestedSearchPhrase]);

  useEffect(
    () =>
      setSearchState((prevState) => ({
        ...prevState,
        data: [],
        requestedPhrase: debouncedRequestedSearchPhrase,
      })),
    [debouncedRequestedSearchPhrase]
  );

  useEffect(() => {
    if (
      !!searchState.requestedPhrase &&
      searchState.requestedPhrase !== searchState.phrase
    ) {
      const requestedPhrase = searchState.requestedPhrase;
      const promise = search({
        organisationId: organisationId,
        body: JSON.stringify({
          query: requestedPhrase,
          page: {
            size: 80,
            current: 1,
          },
          result_fields: resultFields,
        }),
      });
      promise
        .then((res) => JSON.parse(res.data[searchKey]))
        .then((res) => JSON.parse(res.body))
        .then((searchBody) => {
          if (searchBody.error) {
            console.log("searchBody.error", searchBody.error);
            Sentry.captureException(searchBody);
            throw new IOWNAError(
              "error searching, pease reload the application and try again"
            );
          }
          const searchItems = searchBody?.results
            ? searchBody.results.map(resultMap)
            : [];
          setSearchState((prevState) =>
            prevState.requestedPhrase === requestedPhrase
              ? { ...prevState, phrase: requestedPhrase, data: searchItems }
              : prevState
          );
        });
    }
  }, [searchState]);

  return {
    searching: searchState.phrase !== searchState.requestedPhrase,
    searchList: data,
    onChangeText: setDebouncedRequestedSearchPhrase,
  };
};
