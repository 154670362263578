import React, { useEffect, useMemo, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useTheme } from "@emotion/react";
import { Menu, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { useNavigation } from "@react-navigation/native";

import {
  Accordion,
  AccordionButton,
  Button,
  CheckBox,
  Empty,
  ErrorMessage,
  FieldLabel,
  HeaderButton,
  Modal,
  Picker,
  Radio,
  Spinner,
  Tag,
  TextInput
} from "../../../components";
import FilterOffIcon from "../../../assets/icons/filter_off.svg";
import FilterOnIcon from "../../../assets/icons/filter_on.svg";
import { useForm, useHasAnyRoles } from "../../../hooks";
import {
  ALL_CONTENT_TAGS,
  ALL_SPECIALITIES,
  handleError,
  IOWNAError,
  onlySpecialities,
  onlyTags,
  sanitizeFilename,
  transformContentTag
} from "../../../utils";
import {
  createAdvice,
  createOrganisationBundle,
  deleteAdvice,
  getOrganisationBundleNames,
  getSingleAdvice,
  listAdvice,
  putSignedUrl,
  search,
  updateAdvice
} from "../../../graphql";
import { BreadCrumb, UserName } from "../../../containers";
import {
  atom,
  confirm,
  currentOrganisationAtom,
  currentUserIdAtom,
  isWebAtom,
  setPdfId,
  useAtom
} from "../../../atoms";
import { options, optionsBack } from "../../../headerUtils";
import { containerWithoutLoader } from "../../../reactUtils";
import List from "../../../new/components/List";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import ContentFilter from "../../../new/components/content/Filter";
import * as DocumentPicker from "expo-document-picker";
import getPresignedUrl from "../../../new/lib/preSignedUrl";

const SEARCH_PAGE_SIZE = 20;

const showModalAtom = atom(false);
const setShowModal = (v) => showModalAtom.update(v);

const onDeleteAdvice = (item, updateData) => {
  confirm(
    "Delete content",
    `Are you sure you want to delete the content "${item.displayName}"?`,
    handleError(async (id, updateData) => {
      await deleteAdvice({
        input: {
          id: id
        }
      });
      updateData();
    })
  )(item.id, updateData);
};

const ContentScreen = ({ navigation, route }) => {
  const { canSetAdvice = false, canSendAdvice = true } = route.params || {};
  const web = useAtom(isWebAtom);
  const showModal = useAtom(showModalAtom);
  const [selectedItem, setSelectedItem] = useState(null);
  const currentOrganisation = useAtom(currentOrganisationAtom);
  const hasUsers = useHasAnyRoles([
    "system_admin",
    "organisation_owner",
    "organisation_admin"
  ]);
  const hasCuratorRole = useHasAnyRoles(["curator"]);
  const [contentFilterOn, setContentFilterOn] = useState(false);
  const hasGlobalCuratorRole = useHasAnyRoles([
    "system_admin",
    "system_curator"
  ]);

  const filterRestrictedContent = (items) => {
    const contentRestriction = !!currentOrganisation?.contentRestriction;
    if (contentRestriction) {
      return items.filter((item) =>
        item.organisationId === "global" ? item.tags?.includes("pils") : true
      );
    }
    return items;
  };

  const all_content_tags = ALL_CONTENT_TAGS.filter(
    (item) => !["medicalDirectory", "productDirectory"].includes(item)
  );

  const filterShape = [
    {
      name: "Specialty",
      item: ALL_SPECIALITIES.map((item) => ({
        name: item,
        item: item
      })).sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      )
    },
    {
      name: "Content type",
      item: all_content_tags
        .map((item) => ({
          name: transformContentTag(item),
          item: item
        }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
    },
    {
      name: "Product Directory",
      item: "productDirectory"
    },
    {
      name: "Medical Directory",
      item: "medicalDirectory"
    },
    {
      name: "iOWNA wide",
      item: "_orgIsGlobal"
    },
    {
      name: "My practice content",
      item: "_orgIsLocal"
    }
  ];
  const baseFilterItems = ["_orgIsGlobal", "_orgIsLocal"];

  const [localStorageContentTags, setLocalStorageContentTags] = useState({
    isLoading: true,
    data: ["__loading_local_storage", ...baseFilterItems]
  });

  const [filterItems, _setFilterItems] = useState(localStorageContentTags.data);

  useEffect(() => {
    AsyncStorage.getItem("localStorageTagsV3")
      .then((value) => (value ? JSON.parse(value) : baseFilterItems))
      .then((tags) =>
        setLocalStorageContentTags({ isLoading: false, data: tags })
      );
  }, []);

  useEffect(() => {
    _setFilterItems(localStorageContentTags.data);
  }, [localStorageContentTags]);

  const setFilterItems = (fn) =>
    _setFilterItems((prevState) => {
      const newState = fn(prevState);
      return !!["_orgIsGlobal", "_orgIsLocal"].some((t) => newState.includes(t))
        ? newState
        : [
          prevState.includes("_orgIsLocal") ? "_orgIsGlobal" : "_orgIsLocal",
          ...newState
        ];
    });
  const resetFilterItems = () => _setFilterItems(baseFilterItems);

  const selectedSpecialtyTags = ALL_SPECIALITIES.filter((t) =>
    filterItems.includes(t)
  );
  const selectedContentTypeTags = all_content_tags.filter((t) =>
    filterItems.includes(t)
  );

  const wrappedFilterFn = (items) =>
    items.filter(
      (item) =>
        (selectedSpecialtyTags.length === 0 ||
          selectedSpecialtyTags.some((t) => item.tags?.includes(t))) &&
        (selectedContentTypeTags.length === 0 ||
          selectedContentTypeTags.some((t) => item.tags?.includes(t))) &&
        ((!filterItems.includes("medicalDirectory") &&
            !filterItems.includes("productDirectory")) ||
          (filterItems.includes("medicalDirectory") &&
            item.tags?.includes("medicalDirectory")) ||
          (filterItems.includes("productDirectory") &&
            item.tags?.includes("productDirectory"))) &&
        ((filterItems.includes("_orgIsGlobal") &&
            item.organisationId === "global") ||
          (filterItems.includes("_orgIsLocal") &&
            item.organisationId !== "global"))
    );

  const localStorageContentTagsMutation = useMutation((tags) =>
    AsyncStorage.setItem("localStorageTagsV3", JSON.stringify(tags))
  );
  useEffect(() => {
    !filterItems.includes("__loading_local_storage") &&
    localStorageContentTagsMutation.mutate(filterItems);
  }, [filterItems]);

  const [filterUsed, setFilterUsed] = useState(
    baseFilterItems.sort() !== filterItems.sort()
  );
  useEffect(() => {
    const sortedBaseFilterItems = baseFilterItems.slice().sort();
    setFilterUsed(
      sortedBaseFilterItems.length !== filterItems.length ||
      !filterItems
        .slice()
        .sort()
        .every((v, i) => v === sortedBaseFilterItems[i])
    );
  }, [filterItems]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (props) => {
        return web ? (
          <>
            <View style={{ flexDirection: "row" }}>
              <Header {...props} />

              <HeaderButton
                icon={
                  filterUsed ? (
                    <FilterOnIcon
                      style={{ color: "#004D7A", paddingRight: 5 }}
                    />
                  ) : (
                    <FilterOffIcon
                      style={{
                        color: "#004E7A",
                        marginRight: 5,
                        paddingRight: 5
                      }}
                    />
                  )
                }
                title="Filter"
                onPress={() => {
                  setContentFilterOn((prevState) => !prevState);
                }}
              />
            </View>
          </>
        ) : (
          <>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => setContentFilterOn((prevState) => !prevState)}
              >
                {filterUsed ? (
                  <FilterOnIcon color="#004D7A" style={{ marginRight: 15 }} />
                ) : (
                  <FilterOffIcon color="#AAAAAA" style={{ marginRight: 15 }} />
                )}
              </TouchableOpacity>
            </View>
          </>
        );
      }
    });
  }, [filterUsed]);

  const [searchText, setSearchText] = useState("");
  const searchApiFn = (searchText, currentPage = 0) =>
    !!searchText
      ? search({
        organisationId: currentOrganisation?.id,
        body: JSON.stringify({
          query: searchText,
          page: {
            size: SEARCH_PAGE_SIZE,
            current: currentPage + 1
          },
          filters: {
            tags: filterItems
          },
          result_fields: {
            id: {
              raw: {}
            },
            tags: {
              raw: {}
            },
            title: {
              raw: {}
            },
            organisation_id: {
              raw: {}
            }
          }
        })
      })
      : new Promise(() => []);

  const searchPathFn = (result) => {
    const parsed_result = JSON.parse(result.data.search);
    const searchBody = JSON.parse(parsed_result.body);
    if (searchBody.error) {
      console.log("searchBody.error", searchBody.error);
      Sentry.captureException(searchBody);
      throw new IOWNAError(
        "error searching, please reload the application and try again"
      );
    }

    const searchItems = searchBody?.results
      ? searchBody.results.map((item) => ({
        id: item.id.raw,
        displayName: item.title.raw,
        tags: item.tags.raw,
        organisationId: item.organisation_id.raw,
        search: true
      }))
      : [];

      const nextPage = searchItems.length === SEARCH_PAGE_SIZE ? searchBody.meta.page.current : undefined

    return {items: searchItems, nextPage};
  };

  const {
    data: searchPageData,
    fetchNextPage: searchLoadMore,
    hasNextPage: searchHasMore,
    isFetchingNextPage: searchIsFetchingNextPage,
    isLoading: searchIsLoading
  } = useInfiniteQuery(
    [currentOrganisation.id, "contents", filterItems, searchText],
    ({pageParam}) => searchApiFn(searchText, pageParam).then(searchPathFn),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage
    }
  );

  const searchData = searchPageData
    ? searchPageData.pages.flatMap((x) => x.items)
    : [];

  const searchList = !!searchData ? searchData : [];

  const searching = searchIsLoading || searchIsFetchingNextPage;

  const apiFn = (props) =>
    listAdvice({
      organisationId: currentOrganisation?.id,
      ...props
    });

  const pathFn = (result) => result.data.listAdvice;

  const {
    data: pageListData,
    fetchNextPage: listLoadMore,
    hasNextPage: listHasMore,
    isFetchingNextPage: isListFetchingNextPage,
    isLoading: isListLoading
  } = useInfiniteQuery(
    [currentOrganisation.id, "contents"],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined
    }
  );

  const listLoading = isListLoading || isListFetchingNextPage;

  const listData = pageListData
    ? pageListData.pages.flatMap((x) => x.items)
    : [];

  const queryClient = useQueryClient();

  const displayOtherList = !!searchText;
  const otherLoading = searching;

  const loading = displayOtherList ? otherLoading : listLoading;

  const data = wrappedFilterFn(
    filterRestrictedContent(displayOtherList ? searchList : listData)
  );

  if (data.length < 20 && !loading) {
    if (displayOtherList && searchHasMore) searchLoadMore();
    else if (!displayOtherList && listHasMore) listLoadMore();
  }

  const [accordianOpenId, setAccordianOpenId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddToBundleModal, setShowAddToBundleModal] = useState(false);

  const renderItem = ({ item }) =>
    route.params?.view === "selecting" ? (
      <TouchableOpacity
        style={{
          flexDirection: "row",
          borderBottomColor: "#c6c6c8",
          borderBottomWidth: 1,
          padding: 20
        }}
        onPress={() => {
          setPdfId(item.id);
          navigation.navigate("PdfScreen", {
            id: item.id,
            canSetAdvice: canSetAdvice,
            canSendAdvice: canSendAdvice,

            item: item,
            selected: route.params?.selected,
            selectionOriginatingScreen:
            route.params?.selectionOriginatingScreen
          });
        }}
      >
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 16, color: "#000000" }}>
            {item.displayName}
          </Text>
        </View>
      </TouchableOpacity>
    ) : (
      <Accordion
        open={item.id === accordianOpenId}
        onPress={() =>
          setAccordianOpenId((currentId) =>
            currentId !== item.id ? item.id : null
          )
        }
        title={
          <View
            style={{
              flexDirection: "row",
              padding: 20,
              flex: 1
            }}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 16, color: "#000000" }}>
                {item.displayName}
              </Text>
            </View>
          </View>
        }
      >
        <AccordionButton
          items={[
            {
              title: "View",
              onPress: () => {
                setPdfId(item.id);
                navigation.navigate("PdfScreen", {
                  id: item.id,
                  canSetAdvice: canSetAdvice,
                  canSendAdvice: canSendAdvice,

                  item: item,
                  selected: route.params?.selected,
                  selectionOriginatingScreen:
                  route.params?.selectionOriginatingScreen
                });
              }
            },
            ...(hasUsers
              ? [
                {
                  title: "Add to bundle",
                  onPress: () => {
                    setSelectedItem(item);
                    setShowAddToBundleModal(true);
                  }
                }
              ]
              : []),
            ...(hasCuratorRole || hasGlobalCuratorRole || hasUsers
              ? [
                {
                  title: "Edit",
                  onPress: () => {
                    setSelectedItem(item);
                    setShowEditModal(true);
                  }
                },
                {
                  title: "Delete",
                  onPress: () =>
                    onDeleteAdvice(item, () =>
                      queryClient.invalidateQueries([
                        currentOrganisation.id,
                        "contents"
                      ])
                    )
                }
              ]
              : [])
          ]}
        />
      </Accordion>
    );

  return (
    <>
      {!contentFilterOn ? (
        <List
          setSearchText={setSearchText}
          flatListParams={{
            data,
            renderItem,
            onEndReached: () => {
              if (displayOtherList && searchHasMore) {
                searchLoadMore()
              } else {
                listLoadMore() 
              }
            } 
          }}
          loading={loading}
          onEndReachedThreshold={0.5}
          ListEmptyComponent={
            <Empty
              title="Nothing is here yet..."
              description="Add some content to see it here"
            ></Empty>
          }
        />
      ) : (
        <ContentFilter
          name="category"
          filterItems={filterItems}
          setFilterItems={setFilterItems}
          resetFilterItems={resetFilterItems}
          items={filterShape}
          onPress={() => setContentFilterOn(false)}
        />
      )}

      {showModal && (
        <Modal
          isVisible={showModal}
          title={"Upload Content"}
          style={{ maxWidth: 450 }}
          onClose={(state, newItem) => {
            if (newItem && state === "created") {
              queryClient.invalidateQueries([
                currentOrganisation.id,
                "contents"
              ]);
            }
            setShowModal(false);
          }}
        >
          <UploadModal
            organisationId={currentOrganisation?.id}
            organisationName={currentOrganisation?.name}
            item={null}
          />
        </Modal>
      )}
      {showEditModal && (
        <Modal
          isVisible={showEditModal}
          title={"Edit content"}
          style={{ maxWidth: 450 }}
          onClose={(state, newItem) => {
            if (newItem && state === "updated") {
              queryClient.invalidateQueries([
                currentOrganisation.id,
                "contents"
              ]);
            }
            setShowEditModal(false);
          }}
        >
          <UploadModal
            organisationId={currentOrganisation?.id}
            organisationName={currentOrganisation?.name}
            item={selectedItem}
          />
        </Modal>
      )}
      {showAddToBundleModal && (
        <Modal
          style={{ maxWidth: 450 }}
          isVisible={showAddToBundleModal}
          title={"Add to bundle"}
          onClose={(state, newItem) => {
            queryClient.invalidateQueries([currentOrganisation.id, "contents"]);
            setShowAddToBundleModal(false);
          }}
        >
          <AddToBundleModal
            organisationId={currentOrganisation?.id}
            organisationName={currentOrganisation?.name}
            item={selectedItem}
          />
        </Modal>
      )}
    </>
  );
};

const SelectFileButton = ({ onPress, title }) => {
  const styles = StyleSheet.create({
    appButtonContainer: {
      backgroundColor: "transparent",
      borderRadius: 10,
      paddingVertical: 5,
      paddingHorizontal: 12,
      borderColor: "black",
      borderWidth: 1
    },
    appButtonText: {
      fontSize: 18,
      color: "black",
      alignSelf: "center"
    }
  });

  return <View style={styles.appButtonContainer}>
    <TouchableOpacity onPress={onPress}>
      <Text style={styles.appButtonText}>{title}</Text>
    </TouchableOpacity>
  </View>;
};

const AddToBundleModal = ({ item, organisationId, onClose }) => {
  const bundleApiFn = useMemo(
    () => (props) =>
      getOrganisationBundleNames({
        id: organisationId,
        ...props
      })
  );
  const bundlePathFn = useMemo(
    () => (result) => result.data.getOrganisation.bundles
  );

  const {
    data: pageBundleData,
    // TODO
    // getOrganisationBundleNames doesn't currently support pagination
    // this is hella bad and needs to be fixed, but for now we'll do a big fetch
    // fetchNextPage: bundleLoadMore,
    // hasNextPage: bundleHasMore,
    isFetchingNextPage: isBundleFetchingNextPage,
    isLoading: isBundleLoading
  } = useInfiniteQuery(
    [organisationId, "bundles"],
    ({ pageParam }) => bundleApiFn(pageParam).then(bundlePathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined
    }
  );

  const bundleLoading = isBundleLoading || isBundleFetchingNextPage;

  const bundleData = pageBundleData
    ? pageBundleData.pages.flatMap((x) => x.items)
    : [];

  const { control, onSubmit, isSubmitting, errors, error } = useForm({
    initial: {},
    schema: () => {
    },
    onSubmit: async ({ bundleId }) => {
      const bundle = bundleData.find((i) => i.id === bundleId);
      try {
        await createOrganisationBundle({
          input: {
            ...bundle,
            organisationId: organisationId,
            adviceIds: [...bundle.adviceIds, item.id]
          }
        });
      } catch (err) {
        throw err;
      }
      onClose();
    }
  });
  return (
    <View style={{ paddingTop: 20 }}>
      <FieldLabel>Select Bundle</FieldLabel>
      {bundleLoading ? (
        <Spinner size="large" />
      ) : (
        <Picker
          name="bundleId"
          modal={true}
          control={control}
          items={bundleData
            .filter((i) => !i.adviceIds.includes(item.id))
            .map((item) => ({ label: item.name, value: item.id }))}
        />
      )}

      <View
        style={{
          alignItems: "center",
          justifyContent: "center"
          // marginTop: 20,
        }}
      >
        <ErrorMessage err={error} />
      </View>
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "center"
        }}
      >
        <Button width={null} loading={isSubmitting} onPress={onSubmit}>
          Add to bundle
        </Button>
      </View>
    </View>
  );
};

const CheckedOption = ({ value, checked, text, onSelect }) => (
  <TouchableOpacity
    style={{ paddingTop: 15, paddingBottom: 15, flex: 1 }}
    onPress={onSelect}
  >
    <View
      style={{
        flexDirection: "row"
      }}
    >
      <Text style={{ flex: 1 }}>{checked ? "\u2713   " : "    "}</Text>
      <Text style={{ flex: 6 }}>{text}</Text>
    </View>
  </TouchableOpacity>
);

const optionsStyles = {
  optionsContainer: {
    padding: 5,
    width: 250,
    borderRadius: "5px"
  },
  optionsWrapper: {
    width: 240
  },
  optionTouchable: {
    underlayColor: "gold",
    activeOpacity: 70
  }
};

const TagsList = ({ items, tags, add, remove, type }) => (
  <Menu>
    <MenuTrigger>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {tags.length === 0 ? (
          <Ionicons name="ios-add-circle-outline" size={24} />
        ) : null}
        {tags.map((tag) => (
          <Tag key={tag} text={transformContentTag(tag)} type={type} />
        ))}
      </View>
    </MenuTrigger>
    <MenuOptions customStyles={optionsStyles}>
      <ScrollView style={{ maxHeight: 400 }}>
        {items.map((tag) => {
          const checked = tags.includes(tag);
          return (
            <CheckedOption
              key={`${tag}`}
              checked={checked}
              onSelect={() => {
                if (checked) {
                  remove(tag);
                } else {
                  add(tag);
                }
              }}
              text={transformContentTag(tag)}
            />
          );
        })}
      </ScrollView>
    </MenuOptions>
  </Menu>
);

const UploadModal = ({ organisationId, organisationName, item, onClose }) => {
  const theme = useTheme();
  const web = useAtom(isWebAtom);

  const navigation = useNavigation();
  const hasCurator = useHasAnyRoles(["curator"]);
  const hasGlobalCurator = useHasAnyRoles(["system_admin", "system_curator"]);
  const hasSystemAdmin = useHasAnyRoles(["system_admin"]);
  const currentUserId = useAtom(currentUserIdAtom);
  const [avStatus, setAVStatus] = useState("");
  const [newAdvice, setNewAdvice] = useState();
  const [file, setFile] = useState(null);

  // Update file is used to store the file that is being updated
  const [updateFile, setUpdateFile] = useState(null);
  const [updateFileLabel, setUpdateFileLabel] = useState(null);

  const [accepted, setAccepted] = useState(false);
  const [allOrg, setAllOrg] = useState(hasGlobalCurator ? null : false);
  const [tags, setTags] = useState(item ? item.tags || [] : []);
  const addTag = (tag) => setTags(tags.concat(tag));
  const removeTag = (tag) => setTags(tags.filter((item) => item !== tag));

  const canEdit = (item && hasCurator) || hasSystemAdmin;
  const { control, onSubmit, isSubmitting, errors, error, watch } = useForm({
    initial: {
      title: item ? item.displayName : ""
    },
    schema: (yup) => ({
      title: yup.string().required("Please enter the content title").max(120)
    }),
    onSubmit: async ({ title, type }) => {
      const displayName = title.trim();
      const message =
        type === "news"
          ? `Hi\nPlease download our latest communication regarding ${displayName} using this link %s.\nEnsure you save this document as the link will expire in %d days.\nRegards iOWNA\nReply STOP to unsubscribe. Msg&Data Rates May Apply`
          : `Hi\nPlease download your ${displayName} health & wellbeing plan using this link %s\nEnsure you save the plan as the link will expire in %d days.\nRegards iOWNA\nReply STOP to unsubscribe. Msg&Data Rates May Apply`;
      // Make a check for previous file
      if (item) {
        setAccepted(true);
        // Start building edit
        const staticAdvice = {
          id: item.id,
          displayName: displayName,
          message: message,
          avStatus: null,
          tags: tags
        };

        if (updateFile) {
          staticAdvice.filename = (await getPresignedUrl(updateFile, true)).fileName;
        }

        const advRes = await updateAdvice({
          input: staticAdvice
        });

        setAVStatus("SCANNING");

        let intervalRef;
        let count = 0;
        intervalRef = setInterval(async () => {
          const getRes = await getSingleAdvice({
            id: advRes.data.updateAdvice.id
          });

          const newAvStatus = getRes.data.getAdvice.avStatus;
          if (count === 200) {
            clearInterval(intervalRef);
          }
          if (newAvStatus !== null) {
            setNewAdvice(getRes.data.getAdvice);
            setAVStatus(newAvStatus);
            clearInterval(intervalRef);
          }
          count += 1;
        }, 1000);


      } else {
        if (!file) {
          throw new IOWNAError("You need to select a file");
        }
        if (file.size > 268435456) {
          throw new IOWNAError("You can only upload files under 250MB.");
        }
        if (allOrg === null) {
          throw new IOWNAError(
            "You need to select who should be able to view this content"
          );
        }
        if (!accepted) {
          throw new IOWNAError(
            "You need to accept the copyright statement before you can upload this content"
          );
        }
        const filename = sanitizeFilename(file.name);
        const ext = filename.split(".").pop();
        if (!["pdf", "mp4"].includes(ext)) {
          throw new IOWNAError("You can only upload a PDF or MP4 file");
        }
        const contentType = ext === "pdf" ? "application/pdf" : "video/mp4";
        await new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onloadend = (e) => {
            const arr = new Uint8Array(e.target.result).subarray(
              0,
              ext === "mp4" ? 12 : 4
            );
            let header = "";
            for (var i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            if (ext === "pdf" && header !== "25504446") {
              reject(new IOWNAError("You can only upload a valid PDF file"));
            }
            const validFileSigs = [
              "0001c6674797069736f6d", // video/mp4
              "000186674797069736f6d", // video/mp4
              "000206674797069736f6d", // video/mp4
              "0001c667479704d534e56", // video/mp4
              "00018667479704d534e56", // video/mp4
              "00020667479704d534e56", // video/mp4
              "0001c667479706d703432", // video/m4v
              "00018667479706d703432", // video/mp4
              "00020667479706d703432", // video/m4v
              "0001c667479704d345620", // video/m4v
              "00018667479704d345620", // video/m4v
              "00020667479704d345620", // video/m4v
              "0001c6674797071742020", // video/mov
              "000186674797071742020", // video/mov
              "000206674797071742020", // video/mov
              "000206D6F6F76" //video/mov
            ];
            if (ext === "mp4" && !validFileSigs.includes(header)) {
              reject(new IOWNAError("You can only upload a valid MP4 file"));
            }
            resolve();
          };
          fileReader.readAsArrayBuffer(file);
        });
        const res = await putSignedUrl({
          input: {
            filename: filename,
            acceptedCopyrightStatement: accepted,
            contentType
          }
        });
        const putSignedRes = res.data.putSignedUrl;
        const metadata = JSON.parse(putSignedRes.metadata);
        await fetch(putSignedRes.url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": contentType,
            "Content-Disposition": `attachment; filename="${filename}"`,
            "x-amz-meta-acceptedcopyrightstatementat":
              metadata["acceptedCopyrightStatementAt"],
            "x-amz-meta-filename": metadata["filename"],
            "x-amz-meta-uploadedby": metadata["uploadedBy"]
          }
        });
        const advRes = await createAdvice({
          input: {
            filename: res.data.putSignedUrl.key.slice(1), // remove starting slash for now
            displayName: displayName,
            message: message,
            tags: tags,
            organisationId: allOrg ? null : organisationId
          }
        });
        setAVStatus("SCANNING");
        let intervalRef;
        let count = 0;
        intervalRef = setInterval(async () => {
          const getRes = await getSingleAdvice({
            id: advRes.data.createAdvice.id
          });
          const newAvStatus = getRes.data.getAdvice.avStatus;
          if (count === 200) {
            clearInterval(intervalRef);
          }
          if (newAvStatus !== null) {
            setNewAdvice(getRes.data.getAdvice);
            setAVStatus(newAvStatus);
            clearInterval(intervalRef);
          }
          count += 1;
        }, 1000);
      }
    }
    // end of if
  });
  if (avStatus === "SCANNING") {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 100
        }}
      >
        <Text
          style={{ color: theme.primary, fontSize: 16, textAlign: "center" }}
        >
          Scanning the uploaded file
        </Text>
        <Text
          style={{ color: theme.primary, fontSize: 16, textAlign: "center" }}
        >
          Please wait until it's finished
        </Text>
        <View style={{ marginTop: 50 }}>
          <Spinner size="large" />
        </View>
      </View>
    );
  } else if (avStatus === "INFECTED") {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 100
        }}
      >
        <Text style={{ color: theme.error, fontSize: 16, textAlign: "center" }}>
          The file you uploaded failed our virus detector.
        </Text>
        <Text style={{ color: theme.error, fontSize: 16, textAlign: "center" }}>
          Please Upload another file
        </Text>
      </View>
    );
  } else if (avStatus === "CLEAN") {
    setTimeout(() => {
      onClose("created", newAdvice);
    }, 2000);
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 100
        }}
      >
        <Text
          style={{ color: theme.primary, fontSize: 16, textAlign: "center" }}
        >
          File uploaded Successfully
        </Text>
      </View>
    );
  } else {
    return (
      <View>
        <FieldLabel>Content File</FieldLabel>
        {item ? null : (
          <View
            style={{
              borderWidth: 1,
              borderColor: theme.textInputBorderBottom,
              padding: "5px"
            }}
          >
            {file ? (
              <View
                style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
              >
                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                  File: {file.name}
                </Text>
                <TouchableOpacity
                  style={{ flex: 1, alignItems: "flex-end" }}
                  onPress={() => setFile(null)}
                >
                  <Ionicons
                    name="ios-close-circle-outline"
                    color="black"
                    size={24}
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={{ cursor: "pointer" }}>
                <input
                  hidden
                  id="file"
                  type="file"
                  accept="application/pdf,video/mp4"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <label
                  htmlFor="file"
                  style={{ fontSize: 16, fontWeight: "500" }}
                >
                  Select File
                </label>
              </View>
            )}
          </View>
        )}
        <FieldLabel>Title</FieldLabel>
        <TextInput
          name="title"
          width="100%"
          control={control}
          errors={errors}
        />
        {item?.createdBy ? (
          <FieldLabel>
            Uploaded By: <UserName id={item.createdBy} />
          </FieldLabel>
        ) : null}
        {item &&
          <><FieldLabel>Modify File</FieldLabel>
            <SelectFileButton
              title={updateFileLabel || "Select File"}
              size="sm"
              onPress={() =>
                DocumentPicker.getDocumentAsync({
                  //copyToCacheDirectory set to true allows the expo-file-system to read the file immediately after it is picked
                  copyToCacheDirectory: true,
                  type: ["application/pdf", "image/jpeg", "image/jpg", "image/png"]
                }).then((result) => {
                  //result object comes back differently from mobile document picker and web document picker. If there is a result.file (web) then file = result.file otherwise we send result
                  if (result.file != undefined) {
                    setUpdateFile(result.file);
                    setUpdateFileLabel(result.file.name);
                    console.log(result.file);
                  } else {
                    console.log(result);
                  }
                })
              }
            ></SelectFileButton></>}
        <FieldLabel>Tags</FieldLabel>
        <TagsList
          items={ALL_CONTENT_TAGS}
          tags={onlyTags(tags)}
          add={addTag}
          remove={removeTag}
          type="tag"
        />
        <FieldLabel>Specialties</FieldLabel>
        <TagsList
          items={ALL_SPECIALITIES}
          tags={onlySpecialities(tags)}
          add={addTag}
          remove={removeTag}
          type="speciality"
        />
        {item || !hasGlobalCurator ? null : (
          <View>
            <FieldLabel>
              Who should be able to view and send this content?
            </FieldLabel>
            <View style={{ marginLeft: 10 }}>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignItems: "center"
                }}
              >
                <Radio
                  checked={allOrg !== null ? allOrg : false}
                  onPress={() => setAllOrg(true)}
                />
                <Text style={{ marginLeft: 10 }}>
                  All iOWNA professional users
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignItems: "center"
                }}
              >
                <Radio
                  checked={allOrg !== null ? !allOrg : false}
                  onPress={() => setAllOrg(false)}
                />
                <Text style={{ marginLeft: 10 }}>
                  Only members of {organisationName}
                </Text>
              </View>
            </View>
          </View>
        )}
        {file && file.name.endsWith(".mp4") ? (
          <View style={{ flexDirection: "row", marginTop: 60 }}>
            <Text>
              Please make sure to upload short videos of up to 2 mins in length
            </Text>
          </View>
        ) : null}
        <View style={{ flexDirection: "row", marginTop: 60 }}>
          <View style={{ marginTop: 3 }}>
            <CheckBox
              checked={accepted}
              onPress={() => setAccepted(!accepted)}
            />
          </View>
          <Text style={{ marginLeft: 10 }}>
            I confirm that the copyright holder of this document has given
            permission for distribution via the iOWNA platform
          </Text>
        </View>
        {item && web ? (
          <>
            <TouchableOpacity
              disabled={!canEdit}
              style={{
                borderColor: canEdit ? "rgb(0, 78, 122)" : "gray",
                borderWidth: 1,
                padding: 8,
                borderRadius: 3,
                marginTop: 20
              }}
              onPress={() => {
                onClose();
                setPdfId(item.id);
                navigation.navigate("FormEditorScreen");
              }}
            >
              <Text style={{ color: canEdit ? "rgb(0, 78, 122)" : "gray" }}>
                Edit Form
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={!canEdit}
              style={{
                borderColor: canEdit ? "rgb(0,78,122)" : "gray",
                borderWidth: 1,
                padding: 8,
                borderRadius: 3,
                marginTop: 20
              }}
              onPress={() => {
                onClose();
                setPdfId(item.id);
                navigation.navigate("FormBuilderScreen");
              }}
            >
              <Text style={{ color: canEdit ? "rgb(0, 78, 122)" : "gray" }}>
                Form Builder
              </Text>
            </TouchableOpacity>
          </>
        ) : null}
        <View
          style={{
            marginTop: 20,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ErrorMessage err={error} />
        </View>
        <View
          style={{
            marginTop: 40,
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Button
            loading={isSubmitting}
            onPress={onSubmit}
            disabled={!accepted}
          >
            {item ? "Update" : "Upload"}
          </Button>
        </View>
      </View>
    );
  }
};

const Header = containerWithoutLoader(({ route }) => {
  const web = useAtom(isWebAtom);
  const hasCuratorRole = useHasAnyRoles(["curator"]);
  const navigation = useNavigation();
  const hasGlobalCuratorRole = useHasAnyRoles([
    "system_admin",
    "system_curator"
  ]);
  return (
    <View style={{ flexDirection: "row" }}>
      {web && (hasCuratorRole || hasGlobalCuratorRole) ? (
        <>
          <HeaderButton
            icon="ios-cloud-upload"
            title="Upload"
            onPress={() => {
              setShowModal(true);
            }}
          />
          <HeaderButton
            icon="ios-document"
            title="Editor"
            onPress={() => {
              navigation.navigate("EditorScreen");
            }}
          />
        </>
      ) : null}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 22
  },
  item: {
    margin: 5,
    color: "#004D7A",
    fontSize: 18,
    alignSelf: "center"
  },
  listTagItem: {
    marginLeft: 24,
    marginTop: 10,
    color: "black",
    fontSize: 15
  }
});

export const ContentOptions = (web) => {
  if (web) {
    return options(
      () => <BreadCrumb parts={["currentOrganisation.name", "Content"]} />,
      (props) => <Header {...props} />
    );
  }
  return options("Content");
};

export const ContentOptionsBack = (web) => {
  if (web) {
    optionsBack(web, () => (
      <BreadCrumb parts={["currentOrganisation.name", "Content"]} />
    ));
  } else {
    return options("Content");
  }
};

export default ContentScreen;
