import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { options, optionsBack } from '../../../headerUtils';
import ConsumerScreen from './ConsumerScreen';
import WelcomeScreen, { WelcomeOptions } from './WelcomeScreen';
import { useAtom, isWebAtom } from '../../../atoms';

const Stack = createStackNavigator();

export default () => {
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator>
      <Stack.Screen name="ConsumerScreen" component={ConsumerScreen} options={web ? options('Home', ) : options()} />
      <Stack.Screen name="WelcomeScreen" component={WelcomeScreen} options={web ? optionsBack(web, 'Welcome to iOWNA', null) : WelcomeOptions(web)} />
    </Stack.Navigator>
  );
};
