import React, {useEffect} from "react";
import { View, Text, SectionList } from "react-native";
import { MainScreen, Empty, Touch } from "../../../components";
import { useDistributionTags } from "../../../hooks";
import { IOWNAError, getSections, handleError } from "../../../utils";


export default function SelectListScreenMobile({ route, navigation }) {
  const [list, , loading, error] = useDistributionTags();
  const sections = getSections(list.items, "name");



  if (!loading && sections.length === 0) {
    return (
      <MainScreen
        backgroundColor="white"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Empty
          title="You currently have no distribution list"
          description="Please create a distribution list"
        />
      </MainScreen>
    );
  }
  return (
    <MainScreen backgroundColor="#eeeceb" loading={loading} error={error}>
      <View style={{ backgroundColor: "white" }}>
        <SectionList
          sections={sections}
          keyExtractor={(item) => item.id}
          renderSectionHeader={({ section: { title } }) => (
            <View style={{ backgroundColor: "#EBEBF1", padding: 5 }}>
              <Text style={{ fontSize: 22, color: "#004E7A", marginLeft: 15 }}>
                {title}
              </Text>
            </View>
          )}
          renderItem={({ item }) => (
            <Touch
              flexDirection="row"
              borderBottomColor="#c6c6c8"
              borderBottomWidth={1}
              padding={20}
              onPress={handleError(() => {
                const listItem = {
                  id: item.id,
                  name: item.name,
                  type: "distributionList",
                };
               

                if (item.contacts.items.length === 0) {
                  throw new IOWNAError(
                    "The Distribution List you selected has no contacts in it"
                  );
                }

                navigation.navigate(route.params.previousScreen, {
                  ...route.params.previousScreenParams,
                  selectedDistributionLists: [
                    listItem,
                    ...route.params.selectedDistributionLists.filter((e) => listItem.id !== e.id),
                  ],
                });
              })}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 16, color: "#000000" }}>
                  {item.name}
                </Text>
              </View>
            </Touch>
          )}
        />
      </View>
    </MainScreen>
  );
}
