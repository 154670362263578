import React from "react";
import { TouchableOpacity, Text, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useAtom, isWebAtom } from "../atoms";
import { CenteredSpinner } from "./Spinner";

const HeaderRight = ({
  icon,
  customIcon,
  title,
  backgroundColor,
  onPress,
  hasBorder = true,
  ...props
}) => {
  const web = useAtom(isWebAtom);
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: web && hasBorder ? 1 : 0,
        borderColor: "#004E7A",
        borderRadius: 5,
        marginRight: web ? 30 : 0,
        backgroundColor: backgroundColor,
      }}
      onPress={onPress}
      {...props}
    >
      {icon ? (
        typeof icon === "string" ? (
          <Ionicons
            name={icon}
            size={24}
            color="#004E7A"
            style={{ marginRight: 10 }}
          />
        ) : (
          icon
        )
      ) : null}
      {customIcon ? customIcon : null}
      {web || !icon ? (
        <Text
          style={{
            fontSize: web ? 16 : 18,
            fontWeight: "500",
            color: "#004E7A",
            paddingRight: 5,
          }}
        >
          {title}
        </Text>
      ) : null}
    </TouchableOpacity>

    // <TouchableOpacity

    //   style={{ flexShrink: 1, flexDirection: 'row', paddingLeft: 30,  marginRight: 5, paddingTop: 5, alignItems: 'center' }} onPress={onPress} {...props}>

    //   <Text style={{ fontSize: 15, fontWeight: '500', color: 'rgb(0, 78, 122)' }}>{title}</Text>

    // </TouchableOpacity>
  );
};

export default HeaderRight;
