import React from "react";
import { Platform } from "react-native";
import { options, optionsBack } from "../../../headerUtils";

import { HeaderButton } from "../../../components";
import { BreadCrumb } from "../../../containers";
import { createStackNavigator } from "@react-navigation/stack";
import ContentScreen, { ContentOptions } from "./ContentScreen";
import PdfScreen, { PdfOptions } from "./PdfScreen";
import FormScreen, { FormOptions } from "./FormScreen";
import { useAtom, isWebAtom } from "../../../atoms";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

const Stack = createStackNavigator();
export default () => {
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator screenOptions={{ gestureEnabled: false }}>
      <Stack.Screen
        name="ContentScreen"
        component={ContentScreen}
        options={ContentOptions(web)}
      />

      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
      {web ? (
        <Stack.Screen
          name="EditorScreen"
          component={require("./EditorScreen").default}
          initialParams={{}}
          options={require("./EditorScreen").EditorOptions(web)}
        />
      ) : null}
      {web ? (
        <Stack.Screen
          name="FormEditorScreen"
          initialParams={{}}
          component={require("./FormEditorScreen").default}
          options={require("./FormEditorScreen").FormEditorOptions(web)}
        />
      ) : null}
     
    <Stack.Screen
          name="FormBuilderScreen"
          initialParams={{}}
          component={require("./FormBuilderScreen").default}
          options={require("./FormBuilderScreen").FormBuilderScreenOptions(web)}
        /> 
     
    </Stack.Navigator>
  );
};
