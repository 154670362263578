import React from "react";
import { Platform, View, Text } from "react-native";

const Tag = ({ text, showCursor, type = "tag" }) => {
  const fontSize = 14;
  const bgColor = type === "tag" ? "rgba(177, 247, 252, 0.5)" : "#AF95A633";
  const borderColor = type === "tag" ? "rgba(0, 196, 255, 0.5)" : "#AF95A6";
  const color = type === "tag" ? "rgb(113, 113, 113)" : "#00567ECC";
  const style = { fontSize, color };
  if (Platform.OS === "web") {
    style.cursor = showCursor ? "pointer" : "default";
  }
  return (
    <View
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
        marginRight: 5,
        marginTop: 5,
        backgroundColor: bgColor,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: borderColor,
      }}
    >
      <Text style={style}>{text}</Text>
    </View>
  );
};

export default Tag;
