import { View } from "react-native";
export const decorators = [
  (Story) => (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      <Story />
    </View>
  ),
];
export const parameters = {
  backgrounds: {
    default: "plain",
    values: [
      { name: "plain", value: "white" },
      { name: "warm", value: "hotpink" },
      { name: "cool", value: "deepskyblue" },
    ],
  },
  controls: {
    matchers: {
      color: /(background|color)$/i,
      date: /Date$/,
    },
  },
};
