import React from "react";
import { Platform } from "react-native";

import { createStackNavigator } from "@react-navigation/stack";
import { BreadCrumb } from "../../../containers";
import { options } from "../../../headerUtils";
import ActivityScreen, {
  OptionsBack as ActivityOptionsBack,
} from "./ActivityScreen";

import { useAtom, isWebAtom, setShowContactsModal } from "../../../atoms";
import ResultsScreen, { ResultOptionsBack } from "./ResultsScreen";
import ContactsScreen from "./ContactsScreen";
import FormScreen, { FormOptions } from "./../ContentNavigator/FormScreen";
import PdfScreen, { PdfOptions } from "./../ContentNavigator/PdfScreen";
import { Ionicons } from "@expo/vector-icons";
import Touch from "../../../components/Touch";

const Stack = createStackNavigator();
export default ({ route, navigation }) => {
  const web = useAtom(isWebAtom);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ContactsScreen"
        component={ContactsScreen}
        options={options(() => (
          <BreadCrumb parts={["currentOrganisation.name", "Activity"]} />
        ))}
        initialParams={route.params}
      />
      <Stack.Screen
        name="ProfileScreen"
        component={ActivityScreen}
        options={ActivityOptionsBack(web)}
        initialParams={route.params}
      />
      <Stack.Screen
        name="ResultsScreen"
        component={ResultsScreen}
        options={ResultOptionsBack(web, "Results")}
      />
      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        // options={({ navigation }) => ({
        //   headerShown: true,
        //   gestureEnabled: false,
        //   headerLeft: () => (
        //     <Touch
        //       marginLeft={10}
        //       onPress={() => {
        //         navigation.goBack();
        //       }}
        //     >
        //       <Ionicons name="arrow-back-outline" size={32} color="#004e79" />
        //     </Touch>
        //   ),
        // })}
        options={FormOptions(web)}
      />
    </Stack.Navigator>
  );
};
