import React, { useState, useEffect, useCallback } from "react";
import { View, Text, Pressable } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import useSelect from "../../hooks/useSelect";

interface DataT<T> {
  readonly label: string;
  readonly value: T;
}

const RadioInput = <T,>({
  selections,
  onChange,
  value: _selected,
}: {
  selections: DataT<T>[];
  onChange: (item: T) => void;
  value?: T;
}) => {
  const { selected, render } = useSelect({
    data: selections,
    initialValue: _selected,
  });

  useEffect(() => {
    if (selected.value !== undefined) {
      onChange(selected.value);
    }
  }, [selected]);

  return (
    <View style={{ paddingBottom: "15" }}>
      {render(({ label, key, selected, select }) => {
        return (
          <Pressable key={key} onPress={select}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {selected ? (
                <Ionicons name="radio-button-on" size={24} color="black" />
              ) : (
                <Ionicons name="radio-button-off" size={24} color="black" />
              )}
              <Text>{label}</Text>
            </View>
          </Pressable>
        );
      })}
    </View>
  );
};

export default RadioInput;
