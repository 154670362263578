import React, { useState } from 'react';
import { View, Text, Animated } from 'react-native';
// import MultiSlider from '@ptomasroos/react-native-multi-slider';
import MultiSlider from '../components/MultiSlider';

const AnimatedView = Animated.createAnimatedComponent(View);
const width = 36;
const CustomLabel = ({ vertical, position, value, ranges }) => {
  let smiley = '';
  for (const range of ranges) {
    if (value >= range.from && value <= range.to) {
      smiley = range.smiley;
      break;
    }
  }
  // top: -(position - width / 2)
  const move = vertical ? {} : Number.isFinite(position) ? { left: position - width / 2 } : {};
  const bottom = vertical ? 0 : 15;
  return (
    <View style={{ position: 'relative' }}>
      <AnimatedView
        style={[
          {
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: bottom,
          },
          move,
        ]}
      >
        <Text style={{ fontSize: 30 }}>{smiley}</Text>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
            borderRadius: 4,
            backgroundColor: 'rgb(84, 139, 244)',
            width: 36,
            height: 26,
          }}
        >
          <Text style={{ fontSize: 14, fontWeight: '500', color: 'white', textAlign: 'center' }}>{value}</Text>
        </View>
      </AnimatedView>
    </View>
  );
};

const Thermometer = ({ vertical, value, ranges, disabled, onChange, setOuterScrollEnabled }) => {
  const [oneValue, setOneValue] = useState([value]);
  let trackColor = 'tomato';
  const labels = [];
  const options = Array.from(
    ranges.reduce((acc, item) => {
      for (let i = item.from; i <= item.to; i++) {
        acc.add(i);
        if (i === item.to) {
          labels.push({
            index: i,
            stepLabel: item.toLabel ? item.toLabel : '' + i,
          });
        } else if (i === item.from) {
          labels.push({
            index: i,
            stepLabel: item.fromLabel ? item.fromLabel : '' + i,
          });
        } else {
          labels.push({
            index: i,
            stepLabel: ' ',
          });
        }
      }
      return acc;
    }, new Set())
  ).sort((a, b) => a - b);
  for (const range of ranges) {
    if (value >= range.from && value <= range.to) {
      trackColor = range.color;
    }
  }
  return (
    <MultiSlider
      values={oneValue}
      sliderLength={310}
      enableLabel={true}
      optionsArray={options}
      vertical={vertical}
      showSteps={true}
      showStepsLabels={true}
      showStepMarkers={false}
      stepsAs={labels}
      enabledOne={!disabled}
      customLabel={({ oneMarkerValue, oneMarkerLeftPosition }) => (
        <CustomLabel vertical={vertical} position={oneMarkerLeftPosition} value={oneMarkerValue} ranges={ranges} />
      )}
      markerStyle={{
        width: 42,
        height: 42,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        borderRadius: 4,
        backgroundColor: '#FFF',
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
          width: 2,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 5,
      }}
      disabledMarkerStyle={{
        width: 42,
        height: 42,
        paddingBottom: 10,
        borderRadius: 4,
        backgroundColor: '#d3d3d3',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      markerContainerStyle={{
        height: 56,
      }}
      selectedStyle={{
        backgroundColor: trackColor,
      }}
      unselectedStyle={{
        backgroundColor: 'silver',
      }}
      trackStyle={{
        height: 10,
      }}
      stepLabelStyle={{
        marginTop: 10,
        userSelect: 'none',
      }}
      onValuesChange={(values) => setOneValue(values)}
      onValuesChangeFinish={() => onChange(oneValue[0])}
      setOuterScrollEnabled={setOuterScrollEnabled}
    />
  );
};

export default Thermometer;
