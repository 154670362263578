import React from "react";
import { Text, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { MainScreen, Box, Badge, Touch } from "../../../components";
import {
  useAtom,
  isWebAtom,
  currentUserAtom,
  userNotificationsCountAtom,
  userTodosCountAtom,
} from "../../../atoms";
import { screen } from "../../../reactUtils";
import ArrowForwardIcon from "../../../assets/icons/arrow-forward.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import NotificationsIcon from "../../../assets/icons/notifications.svg";
import ShareContentIcon from "../../../assets/icons/share_content.svg";
import ContentIcon from "../../../assets/icons/content.svg";
import WelcomeImg from "../../../assets/images/iowna-welcome.png";
import ActivityHistoryIcon from "../../../assets/icons/activity_history.svg";

const Link = ({ title, name, icon, count, navigateTo }) => {
  const navigation = useNavigation();
  const web = useAtom(isWebAtom);
  const pad = web ? 20 : 14;
  return (
    <Touch marginTop={20} onPress={() => navigation.navigate(navigateTo)}>
      <Box marginLeft={15} marginBottom={10}>
        <Text style={{ color: "#9C9C9C", fontWeight: "500" }}>{title}</Text>
      </Box>
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        backgroundColor="#F2F2F2"
        padding={pad}
        borderRadius={12}
      >
        <Box flex={1} flexDirection="row" alignItems="center">
          <Box marginRight={10}>{icon}</Box>
          <Text style={{ fontSize: 20, fontWeight: "500", color: "#004E7A" }}>
            {name}
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          {count > 0 ? (
            <Box marginRight={15}>
              <Badge size="large" count={count} />
            </Box>
          ) : null}
          <ArrowForwardIcon width={28} height={28} color="#9B9B9B" />
        </Box>
      </Box>
    </Touch>
  );
};

const PractitionerScreen = screen((props) => {
  const user = useAtom(currentUserAtom);
  const web = useAtom(isWebAtom);
  const todosCount = useAtom(userTodosCountAtom);
  const notificationsCount = useAtom(userNotificationsCountAtom);
  const welcomeHeight = web ? 250 : 140;
  return (
    <MainScreen backgroundColor="white">
      <Box flex={1} padding={20}>
        <Text style={{ fontSize: 20, fontWeight: "500", color: "#004E7A" }}>
          {" "}
          Hello {user.firstName || ""}
        </Text>
        <Touch
          backgroundColor="#01567E"
          marginVertical={20}
          borderRadius={16}
          elevation={5}
          shadowColor="#000000"
          shadowOpacity={0.5}
          shadowRadius={4}
          onPress={() => props.navigation.navigate("WelcomeScreen")}
        >
           <Image
            source={WelcomeImg}
            style={{
              width: "100%",
              height: welcomeHeight,
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
            }}
          />
          <Box
            backgroundColor="#F7F7F7"
            padding={20}
            borderBottomLeftRadius={16}
            borderBottomRightRadius={16}
          >
            <Text style={{ fontSize: 20, fontWeight: "500", color: "#004E7A" }}>
              Welcome to iOWNA
            </Text>
          </Box>
        </Touch>
        <Link
          title="Send information to contacts"
          name="Share Content"
          navigateTo="SendPlanNavigator"
          icon={<ShareContentIcon width={28} height={28} color="#004E7A" />}
        />
        <Link
          title="View interactions with contacts"
          name="Activity History"
          navigateTo="ActivityNavigator"
          icon={<ActivityHistoryIcon width={30} height={30} color="#004E7A" />}
        />
        <Link
          title="View information recently received"
          name="Notifications"
          navigateTo="NotificationsNavigator"
          icon={<NotificationsIcon width={28} height={28} color="#004E7A" />}
          count={notificationsCount}
        />
        <Link
          title="Access iOWNA library"
          name="Content"
          navigateTo="ContentNavigator"
          icon={<ContentIcon width={28} height={28} color="#004E7A" />}
        />
        <Link
          title="Manage your contacts"
          name="Contacts"
          navigateTo="ContactsNavigator"
          icon={<ProfileIcon width={28} height={28} color="#004E7A" />}
        />
      </Box>
    </MainScreen>
  );
});

export default PractitionerScreen;
