import React, { useState } from "react";
import { Text, Platform, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import MediaViewer from "../../../new/components/MediaViewer";
import { screen, containerWithoutLoader } from "../../../reactUtils";
import { HeaderButton, SpinnerModal } from "../../../components";
import { optionsBack } from "../../../headerUtils";
import { handleError, sanitizeFilename } from "../../../utils";
import * as WebBrowser from "expo-web-browser";
import * as FileSystem from "expo-file-system";
import * as Linking from "expo-linking";
import * as Sharing from "expo-sharing";

export const PdfScreen = screen(() => {
  const route = useRoute();

  const { uri } = route.params;

  return <MediaViewer uri={uri} />;
});

const ContentDownload = containerWithoutLoader(() => {
  const route = useRoute();
  const [showModal, setShowModal] = useState(false);

  const { item } = route.params;

  const filename = sanitizeFilename(item.name) + ".pdf";

  return (
    <>
      <HeaderButton
        icon="ios-download"
        title="Download"
        onPress={handleError(
          async () => {

            const url = item.downloadUrl;

            if (Platform.OS === "web") {
              Linking.openURL(url);
            } else if (Platform.OS === "android") {
              WebBrowser.openBrowserAsync(url);
            } else {
              setShowModal(true);
              const downloadResumable = FileSystem.createDownloadResumable(
                url,
                FileSystem.documentDirectory + filename,
                {}
              );
              const { uri } = await downloadResumable.downloadAsync();
              await Sharing.shareAsync(uri);
            }
          },
          () => setShowModal(false)
        )}
      />
      <SpinnerModal isVisible={showModal} onClose={() => setShowModal(false)} />
    </>
  );
});

const ContentTitle = containerWithoutLoader(() => {
  const route = useRoute();

  const { item } = route.params;
  return (
    <View style={{ marginLeft: 13, marginRight: 10 }}>
      <Text
        style={{ fontSize: 20, fontWeight: "500", color: "rgb(0, 78, 122)" }}
      >
        {item.name}
      </Text>
    </View>
  );
});

export const SingleContentOptions = (web) =>
  optionsBack(
    web,
    () => <ContentTitle />,

    () => <ContentDownload />
  );

export default PdfScreen;
