import { useState, useCallback } from "react";

interface DataT<T> {
  readonly label: string;
  readonly value: T;
}

const useSelect = <T,>({
  data,
  initialValue,
}: {
  data: DataT<T>[];
  initialValue?: T;
}) => {
  const [selected, setSelected] = useState(
    data.find((e) => e.value === initialValue)
  );

  const render = <RT, >(
    callbackfn: (value: typeof selectionsMap[number]) => RT
  ) => selectionsMap.map(callbackfn);


  const selectionsMap = data.map((item) => ({
    // value: item.value,
    label: item.label,
    key: item.label,
    selected: selected?.value === item.value,
    select: useCallback(() => {
      setSelected(item);
    }, [setSelected]),
  }));

  return {
    selected: { label: selected?.label, value: selected?.value },
    render: render,
    selections: selectionsMap,
  };
};

  export default useSelect;