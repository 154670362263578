import React, { useState, useEffect, useRef } from 'react';
import { Platform, View, Text, TouchableWithoutFeedback, TextInput, Animated, Keyboard } from 'react-native';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { Ionicons } from '@expo/vector-icons';
import ContextMenu from './ContentMenu';
import { useAtom, isWebAtom } from '../atoms';
import { testId } from '../utils';

const Search = ({ text, suggestions, onChangeText, onSearch, onClear }) => {
  const web = useAtom(isWebAtom);
  const [focused, setFocused] = useState(false);
  const [width, setWidth] = useState(200);
  const clearAnimation = useRef(new Animated.Value(0));

  const menuRef = useRef();
  useEffect(() => {
    if (text.length === 0) {
      Animated.timing(clearAnimation.current, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
      onClear();
    }
    if (text.length > 0) {
      Animated.timing(clearAnimation.current, {
        toValue: 1,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
  }, [text]);
  useEffect(() => {
    if (suggestions.length > 0) {
      menuRef.current.open();
    } else {
      menuRef.current.close();
    }
  }, [suggestions.length]);
  const onSearchPressed = () => {
    if (web) {
      Keyboard.dismiss();
    }
    onSearch();
  };
  const height = web ? 30 : 40;
  const iconSize = web ? 20 : 24;
  const border = web
    ? { borderWidth: 1, borderColor: focused ? '#0366d6' : 'rgb(177, 179, 181)', boxShadow: focused ? '0 0 0 3px rgba(3, 102, 214, 0.3)' : 'none' }
    : {};
  const inputStyle = {
    flex: 1,
    height: height,
    fontSize: 16,
    paddingLeft: 10,
  };
  if (Platform.OS === 'web') {
    inputStyle.outline = '';
  }
  return (
    <Menu ref={menuRef} renderer={ContextMenu} rendererProps={{ modal: false, width: width }}>
      <MenuTrigger style={{ height: height }}>
        <View
          onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
          style={{
            flex: 1,
            flexDirection: 'row',
            height: height,
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: web ? '#ffffff' : '#f7f7f7',
            borderRadius: 10,
            ...border,
          }}
        >
          <TouchableWithoutFeedback {...testId('SearchButton')} onPress={onSearchPressed}>
            <View style={{ marginLeft: 10 }}>
              <Ionicons name="ios-search" size={iconSize} color="#848488" />
            </View>
          </TouchableWithoutFeedback>
          <TextInput
            {...testId('SearchInput')}
            style={inputStyle}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            value={text}
            onChangeText={onChangeText}
            placeholder="Search"
            placeholderTextColor="#848488"
            onSubmitEditing={onSearchPressed}
            autoCorrect={false}
            // blurOnSubmit={this.props.blurOnSubmit}
            returnKeyType="search"
            keyboardType="default"
            keyboardAppearance="default"
            autoCapitalize="words"
            // onFocus={this.onFocus}
            underlineColorAndroid="transparent"
            accessibilityTraits="search"
          />
          <TouchableWithoutFeedback {...testId('ClearButton')} onPress={onClear}>
            <Animated.View style={{ marginRight: 10, opacity: clearAnimation.current }}>
              <Ionicons name="ios-close-circle" size={iconSize} color="#848488" />
            </Animated.View>
          </TouchableWithoutFeedback>
        </View>
      </MenuTrigger>
      <MenuOptions>
        {suggestions.map((item) => {
          return (
            <MenuOption
              key={item}
              onSelect={() => {
                onChangeText(item, true);
                menuRef.current.close();
                onSearch(item);
              }}
              style={{ flexDirection: 'row' }}
            >
              <Text style={{ flex: 1, fontSize: web ? 15 : 17, padding: web ? 5 : 10 }}>{item}</Text>
            </MenuOption>
          );
        })}
      </MenuOptions>
    </Menu>
  );
};

export default Search;
