import React from "react";
import { View, Dimensions, StyleSheet } from "react-native";
import styled from "@emotion/native";
import { useTheme } from "@emotion/react"
import Spinner from "./Spinner";

const ButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  height: 50;
`;

const TouchableOpacity = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  height: 50;
`;

const Text = styled.Text`
  color: ${(props) => props.theme.buttonText};
`;

export default ({
  loading = false,
  disabled = false,
  width = Math.min(Dimensions.get("window").width - 80, 300),
  borderRadius = 25,
  color,
  backgroundColor,
  fontSize = 20,
  icon = false,
  onPress,
  children,
  ...props
}) => {
  const { buttonBackground, buttonBackgroundDisabled } = useTheme();
  const style = {
    flexDirection: "row",
    width: width,
    paddingHorizontal: 15,
    borderRadius: borderRadius,
    backgroundColor: disabled
      ? backgroundColor || buttonBackgroundDisabled
      : backgroundColor || buttonBackground,
  };

  const textStyle = {};
  if (color) {
    textStyle.color = color;
  }
  if (fontSize) {
    textStyle.fontSize = fontSize;
  }

  return (
    <ButtonContainer>
      {loading ? (
        <View style={{ width: width }}>
          <Spinner />
        </View>
      ) : (
        <TouchableOpacity
          style={style}
          disabled={disabled}
          onPress={onPress}
          {...props}
        >
          {icon ? <View style={styles.icon}>{icon}</View> : null}
          <Text style={textStyle}>{children}</Text>
        </TouchableOpacity>
      )}
    </ButtonContainer>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginRight: 10,
    marginBottom: 5,
  },
});
