import React from 'react';
import { get } from 'lodash';
import { containerSmall } from '../reactUtils';
import { nameTitle } from '../utils';
import { usePromise, useAtom, currentUserIdAtom } from '../atoms';
import { getUserById } from '../graphql';
import { Text } from '../components';

export default containerSmall(({ id }) => {
  const user = usePromise(getUserById, { id }, 'data.getUser');
  const title = get(user, 'title', '');
  const firstName = get(user, 'firstName', '');
  const lastName = get(user, 'lastName', '');
  const currentUserId = useAtom(currentUserIdAtom);
  if (id === currentUserId) {
    return <Text>You</Text>;
  }
  return (
    <Text>
      {nameTitle(title)} {firstName} {lastName}
    </Text>
  );
});
